import * as Request from "../Request";
interface FloorInfo {
  id: string;
  owner_id: string;
  floor_name: string;
  sort: string;
  num_table?: string;
}

export interface Result {
  status: number;
  message?: string;
  data?: FloorInfo[];
}
export const gListFloorByOwner = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gfloorByOwner.php`, "POST", { owner_id })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const addFloorByOwner = async (
  owner_id: number,
  floor_name: { floor_name: string; color: string }[] = []
) => {
  return await Request.fetchAPI(`owner/add-floor.php`, "POST", {
    owner_id,
    floor_name: JSON.stringify(floor_name),
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
export const DeleteFloor = async (floor_id: string) => {
  return await Request.fetchAPI(`owner/delete-floor.php`, "POST", { floor_id })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};

export const EditFloorName = async (
  floor_id: string,
  floor_name: string,
  owner_id: number,
  color: string
) => {
  return await Request.fetchAPI(`owner/edit-floor.php`, "POST", {
    floor_id,
    floor_name,
    owner_id,
    color,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
