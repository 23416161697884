import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface ListStaffTip {
  id?: string,
  percent?: string,
  delf?: string,
  timestamp?: string
}

const initialStaffTipState: ListStaffTip[] = [];

const StaffTipSlice = createSlice({
  name: "stafftip",
  initialState: initialStaffTipState,
  reducers: {
    setListStaffTip: (state, action: PayloadAction<ListStaffTip[]>) => action.payload,

    clearStaffTip: (state) => initialStaffTipState,
  },
});
export const selectorListStaffTip = (state: { stafftip: ListStaffTip[] }) =>
  state.stafftip;

export const {
  setListStaffTip,
  clearStaffTip
} = StaffTipSlice.actions;
export default StaffTipSlice.reducer;
