import * as Request from "../Request";

export const InformationOwner = async (UserIdStore: number) => {
  return await Request.fetchAPI(
    "owner/gInfoOwner.php?id=" + UserIdStore,
    "GET",
    {}
  )
    .then((res: IinfoOwner) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        counponMessage: [],
      };
    });
};
export interface Result<T> {
  status: number;
  message?: string;
  data: T;
}
export interface PointGame {
  id: string;
  point_flappybird: string;
  point_whackamole: string;
  is_show_redemption_game: number;
  plays_flappybird: number;
  plays_whackamole: number;
}
export const ChangeInformationOwner = async (
  UserIdStore: number,
  StoreName: string,
  GoogleBusinessID: string,
  ReviewMessage: string,
  TypeSendReviewMessage: number,
  TimeSendMessage: number,
  BirthdayMessage: string,
  TimeSendBirthdayMessage: number,
  PointMessage: string,
  PointsCheckin: number,
  PercentPoint: number,
  PointsReward: number,
  TimeSendMessageCoupon: number,
  InputFirstName: boolean,
  InputDayOfBirth: boolean,
  ShowCustomerPoint: boolean,
  customMessageContent: string = "",
  hourSendCustomMessage: number,
  minuteSendMessage: number,
  TypeSendCustomMessage: number,
  review_message_voucher_id: number,
  birthday_voucher_id: number,
  point_voucher_id: number,
  custom_message_voucher_id: number,
  counponMessage: CounponMessage[],
  timeZone: number,
  urlOrder: string = "",
  point_page: number = 0,
  discount_page: string = "",
  feedback_staff_message: string = "",
  type_send_review_staff: number = 0,
  review_staff_voucher_id: number = 0,
  time_send_review_staff: number = 0
) => {
  return await Request.fetchAPI("owner/settingInformation.php", "post", {
    id_store: UserIdStore,
    name_store: StoreName,
    id_google: GoogleBusinessID,
    review_message: ReviewMessage,
    type_send_review_message: TypeSendReviewMessage,
    time_send_message: TimeSendMessage,
    birthday_message: BirthdayMessage,
    time_send_birthday_message: TimeSendBirthdayMessage,
    point_message: PointMessage,
    points_checkin: PointsCheckin,
    percent_point: PercentPoint,
    points_reward: PointsReward,
    time_send_message_coupon: TimeSendMessageCoupon,
    Input_first_name: InputFirstName,
    Input_day_of_birth: InputDayOfBirth,
    show_customer_point: ShowCustomerPoint,
    customMessageContent,
    hourSendCustomMessage,
    minuteSendMessage,
    TypeSendCustomMessage,
    review_message_voucher_id,
    birthday_voucher_id,
    point_voucher_id,
    custom_message_voucher_id,
    counponMessage: JSON.stringify(counponMessage),
    urlOrder,
    point_page,
    discount_page,
    timeZone,
    feedback_staff_message,
    type_send_review_staff,
    review_staff_voucher_id,
    time_send_review_staff,
  })
    .then((res: IChangeInfoOwner) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export interface InfoTip {
  id: string;
  percent: string;
}
export interface ResultTip {
  message: string;
  status: number;
  listTip: InfoTip[];
  tax: string;
  fee_stripe: string;
}

export interface IinfoOwnerData {
  address?: string;
  bank_CVC?: string;
  bank_date_expired?: string;
  bank_number?: string;
  birthday_message?: string;
  code_change_password?: string;
  email?: string;
  googleBusinessID?: string;
  id?: number;
  img?: string;
  input_day_of_birth?: number | undefined;
  input_first_name?: number | undefined;
  name?: string;
  // password?: string;
  percent_point?: number;
  phone?: string;
  point_message?: string;
  points_checkin?: number;
  points_reward?: number;
  review_message?: string;
  rewardPoint?: number;
  role?: number;
  sellPhone?: string;
  show_customer_point?: number | undefined;
  status?: number;
  store_city?: string;
  store_state?: string;
  store_unit?: string;
  store_zipcode?: string;
  time_send_birthday_message?: number;
  time_send_message?: number;
  time_send_message_coupon?: number;
  type_send_review_message?: number;
  username?: string;
  minute_send_message?: string;
  hourSendCustomMessage?: string;
  minuteSendMessage?: string;
  customMessageContent?: string;
  TypeSendCustomMessage?: string;
  custom_message_voucher_id?: string;
  point_voucher_id?: string;
  birthday_voucher_id?: string;
  review_message_voucher_id?: string;
  returnUrl?: string;
  url_order?: string;
  point_page?: string;
  discount_page?: string;
  timeZoneId?: string;
  feedback_staff_message?: string;
  type_send_review_staff?: string;
  review_staff_voucher_id?: string;
  time_send_review_staff?: string;
}
export interface CounponMessage {
  admin_id: string;
  id: string;
  message: string;
  option_time: string;
  time_value: string;
  type: string;
  voucher_id: string | number;
}
export interface IinfoOwner {
  message: string;
  status: number;
  data: IinfoOwnerData;
  counponMessage: CounponMessage[];
}

export interface IChangeInfoOwner {
  message: string;
  status: number;
  data: [];
}

export interface LinkOwnerInfo {
  id: string;
  facebook_link: string;
  instagram_link: string;
}
//send

export const sendReview = async () => {
  return await Request.fetchAPI("owner/job_review.php", "get", {})
    .then((res: Request.Response<[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const sendBirthDay = async () => {
  return await Request.fetchAPI("owner/job_birthday.php", "get", {})
    .then((res: Request.Response<[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const sendCoupon = async () => {
  return await Request.fetchAPI("owner/job_coupon.php", "get", {})
    .then((res: Request.Response<[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

//lấy thông tin tip/tax

export const gListInfoTip = async (owner_id: number) => {
  return await Request.fetchAPI("owner/gInfoTipTaxOwner.php", "post", {
    owner_id,
  })
    .then((res: ResultTip) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        listTip: [],
        tax: "0",
        fee_stripe: "0",
      };
    });
};
//cập nhật tip/tax

export const updateInfoTip = async (
  owner_id: number,
  arrTip: number[],
  tax_amount: number = 0,
  fee_stripe: number
) => {
  return await Request.fetchAPI("owner/saveInfoTipTax.php", "post", {
    owner_id,
    arrTip: JSON.stringify(arrTip),
    tax_amount,
    fee_stripe,
  })
    .then((res: ResultTip) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};

//cập nhật tip/tax ở máy terminal

export const updateInfoTipTerminal = async (
  owner_id: number,
  arrTip: number[]
) => {
  return await Request.fetchAPI("stripe/saveTipTerminal.php", "post", {
    owner_id,
    arrTip: JSON.stringify(arrTip),
  })
    .then((res: ResultTip) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};

export const gConfigGame = async (owner_id: number) => {
  return await Request.fetchAPI("owner/gConfigGame.php", "post", { owner_id })
    .then((res: Result<PointGame>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const upConfigGame = async (
  owner_id: number,
  point_flappybird: number,
  point_whackamole: number,
  plays_flappybird: number,
  plays_whackamole: number
) => {
  return await Request.fetchAPI("owner/saveConfigGame.php", "post", {
    owner_id,
    point_flappybird,
    point_whackamole,
    plays_flappybird,
    plays_whackamole,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const upShowRewardGame = async (
  owner_id: number,
  is_show_redemption_game: number
) => {
  return await Request.fetchAPI("owner/upShowRewardGame.php", "post", {
    owner_id,
    is_show_redemption_game,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const editPhoneOwner = async (owner_id: number, phoneNumber: string) => {
  return await Request.fetchAPI("owner/editPhoneOwner.php", "post", {
    owner_id,
    phoneNumber,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const gInfoLinkeOwner = async (id: number) => {
  return await Request.fetchAPI("owner/gInfoLinkeOwner.php", "post", { id })
    .then((res: Result<LinkOwnerInfo>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const updateLinkOwner = async (
  id: number,
  instagram_link = "",
  facebook_link = ""
) => {
  return await Request.fetchAPI("owner/updateLinkOwner.php", "post", {
    id,
    instagram_link,
    facebook_link,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
