import * as Request from "../Request";

export interface SeatOwner {
    id: string
    tax: string
    fee_stripe: string
    show_rank: string
    seat_reservation: string
    owner_id: string
    delf: string
    timestamp: string
}

export interface Result<T> {
    status: number,
    message?: string,
    data: T,
}
export const vnpayCreatePayment = async (owner_id: number, amount: number, vnp_TxnRef: string, order_id?: string) => {
    return await Request.fetchAPI(`/vnpay_php/bpos_vnpay_create_payment.php`, "POST", { owner_id, amount, vnp_TxnRef, order_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};