import { Toast, ToastContainer } from "react-bootstrap";
import "./Styles.css"


interface ModalToastProps {
    show: boolean;
    handleClose: () => void;
    content: string;
}

const ModalToast = ({
    show,
    handleClose,
    content
}: ModalToastProps) => {
    return (
        <>
            <ToastContainer
                className="mt-3 toast-container-notification bg-white"
                position="top-center"
                style={{ zIndex: 99999999 }}

            >
                <Toast onClose={handleClose} className="w-100 py-2 bg-eeeeee " delay={3000} show={show} autohide>

                    <Toast.Body className=" text-center"><p className="text-center mb-0 text-notification">{content}</p></Toast.Body>
                </Toast>
            </ToastContainer>

        </>

    );
};

export default ModalToast;
