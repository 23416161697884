import { FormGroup, Modal } from "react-bootstrap";
import "./styles.css";
import { useEffect, useState } from "react";
import {
  ListPrinterConnect,
  addPrinterConnect,
} from "../../../../../../api/apiOwner/SettingPrinterApi";
import { getLanguageUI } from "../../../../../../Services/languageUI";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../../../store/slices/userSlice";

interface ModalAddPrinterProps {
  show: boolean;
  handleClose: (status?: boolean) => void;
  owner_id: number;
  setShowLoading: (status: boolean) => void;
  listPrinterWindow: string[];
  listPrinterConnect: ListPrinterConnect[];
}
const ModalAddPrinter = ({
  handleClose,
  show,
  owner_id,
  listPrinterWindow = [],
  listPrinterConnect = [],
  setShowLoading,
}: ModalAddPrinterProps) => {
  const [printerName, setPrinterName] = useState("");
  const [Message, setMessage] = useState("");
  const languageUI = getLanguageUI().setting;
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const handleChange = (event: any, value: number) => {
    if (event.target.checked) {
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((item) => item !== value));
    }
  };

  const Addprint = async () => {
    if (printerName === "" || printerName.trim() === "") {
      setMessage(languageUI.printerIsRequired);
    } else {
      setMessage("");
      setShowLoading(true);
      const req = await addPrinterConnect(
        owner_id,
        printerName,
        checkedValues,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setShowLoading(false);
        handleClose(true);
      } else {
        setShowLoading(false);
        setMessage(req?.message ?? "fail");
      }
    }

    // if (listPrinterChoosed.length === 0) {
    //   setMessage('Printer name is required!');
    // } else {
    //   setMessage('');
    //   setShowLoading(true);
    //   const req = await addMultipPrinterConnect(owner_id, listPrinterChoosed);
    //   if (Number(req?.status) === 1) {
    //     setShowLoading(false)
    //     handleClose(true)
    //   } else {
    //     setShowLoading(false)
    //     setMessage(req?.message ?? 'fail')
    //   }
    // }
  };
  useEffect(() => {
    if (show) {
      // setListPrinter(listPrinterWindow.filter(item => !listPrinterConnect.some(prin => prin?.printer_name === item)))
      // setListPrinterChoosed([])
      setPrinterName("");
      setCheckedValues([]);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="modal-add-printer"
    >
      <Modal.Body>
        <h1 className="text-center">{languageUI.addPrinter}</h1>
        <h4>{languageUI.printerName}</h4>
        {/* <div className="d-flex flex-column gap-2">
          {listPrinter.map((item, i) => (
            <Form.Check // prettier-ignore
              type={'checkbox'}
              id={'chekc' + i}
              label={item}
              checked={listPrinterChoosed.includes(item)}
              onClick={(e: any) => handleChoose(e.target.checked, item)}
            />
          ))}
        </div> */}
        <div className="box-input-add-printer-name">
          <input
            type="text"
            className="w-100 px-3 py-3 border-none input-enter-printer-name"
            placeholder={languageUI.enterPrinterName}
            value={printerName}
            onChange={(e) => setPrinterName(e.target.value)}
          />
        </div>
        <div className="d-flex flex-column gap-1 py-2">
          <div className="title-ques-add-printer">
            {languageUI.titleQuesAddPrinter}
          </div>
          <div className="d-flex gap-2">
            <input
              id="checkbox-1"
              className="input-check-add-printer"
              type="checkbox"
              onChange={(e) => handleChange(e, 2)}
            />
            <label htmlFor="checkbox-1" className="title-select-option">
              {languageUI.beverage}
            </label>
          </div>
          <div className="d-flex gap-2">
            <input
              id="checkbox-2"
              className="input-check-add-printer"
              type="checkbox"
              onChange={(e) => handleChange(e, 1)}
            />
            <label htmlFor="checkbox-2" className="title-select-option">
              {languageUI.appetizer}
            </label>
          </div>
          <div className="d-flex gap-2">
            <input
              id="checkbox-3"
              className="input-check-add-printer"
              type="checkbox"
              onChange={(e) => handleChange(e, 3)}
            />
            <label htmlFor="checkbox-3" className="title-select-option">
              {languageUI.entree}
            </label>
          </div>
          <div className="d-flex gap-2">
            <input
              id="checkbox-4"
              className="input-check-add-printer"
              type="checkbox"
              onChange={(e) => handleChange(e, 4)}
            />
            <label htmlFor="checkbox-4" className="title-select-option">
              {languageUI.everythingElse}
            </label>
          </div>
          <div className="d-flex gap-2">
            <input
              id="checkbox-5"
              className="input-check-add-printer"
              type="checkbox"
              onChange={(e) => handleChange(e, 5)}
            />
            <label htmlFor="checkbox-5" className="title-select-option">
              {`Soup and Stews`}
            </label>
          </div>{" "}
          <div className="d-flex gap-2">
            <input
              id="checkbox-6"
              className="input-check-add-printer"
              type="checkbox"
              onChange={(e) => handleChange(e, 6)}
            />
            <label htmlFor="checkbox-6" className="title-select-option">
              {`Desserts`}
            </label>
          </div>{" "}
          <div className="d-flex gap-2">
            <input
              id="checkbox-7"
              className="input-check-add-printer"
              type="checkbox"
              onChange={(e) => handleChange(e, 7)}
            />
            <label htmlFor="checkbox-7" className="title-select-option">
              {`Salad`}
            </label>
          </div>
          <div className="d-flex gap-2">
            <input
              id="checkbox-8"
              className="input-check-add-printer"
              type="checkbox"
              onChange={(e) => handleChange(e, 8)}
            />
            <label htmlFor="checkbox-8" className="title-select-option">
              {`Grill`}
            </label>
          </div>
        </div>
        {Message !== "" && (
          <div className="text-danger text-center ">{Message}</div>
        )}
        <div className="mt-2 me-3 flex justify-content-end">
          <div
            className="text-white font-bold h-40px px-5 bg-primary w-fit d-flex justify-content-center align-items-center rounded-3"
            onClick={Addprint}
          >
            {languageUI.save}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalAddPrinter;
