import { Modal, Button, Form } from "react-bootstrap";
import './Styles.css'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react";
import React from "react";
import { CustomerSelected, sentSMSToCustomer } from "../../../../../api/apiOwner/customerApi";
import * as userSlice from "../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../../components/ModalLoading";
import ModalToast from "../../ModalToast/ModalToastErr";
import { ComboVoucher } from "../../../../../api/apiOwner/comboApi";
import { getLanguageUI } from "../../../../../Services/languageUI";
interface ModalSendSMSReviewProps {
    show: boolean;
    comboVoucher: ComboVoucher[];
    onHide: () => void;
    listCustomerSelected?: CustomerSelected[],
}


const ModalSendSMSReview: React.FC<ModalSendSMSReviewProps> = ({
    show,
    listCustomerSelected = [],
    comboVoucher = [],
    onHide
}) => {
    const UserIdStore = useSelector(userSlice.selectorUserId);
    const maxlength = 200
    const [contentMessage, setContentMessage] = useState<string>('');
    const [messErrorSendOneCustomer, setMessErrorSendOneCustomer] = useState<string>('');
    const [showToast, setShowToas] = useState<boolean>(false)
    const [messageSuccess, setMessageSuccess] = useState<string>('')
    const [ShowLoading, setShowLoading] = useState<boolean>(false);
    const [voucherID, setVoucherID] = useState<number>(0)
    const SubmitSentSMS = async () => {
        if (listCustomerSelected.length === 0) {
            setMessErrorSendOneCustomer("Please choose customer!");
            return;
        }
        if (contentMessage === "") {
            setMessErrorSendOneCustomer("Please enter message!");
            return;
        }
        if (contentMessage.length > 200) {
            setMessErrorSendOneCustomer(
                "Messages cannot exceed 200 characters. Please re-enter messages!"
            );
            return;
        }
        if (UserIdStore) {
            setShowLoading(true)
            const reqSent = await sentSMSToCustomer(UserIdStore, contentMessage, listCustomerSelected, voucherID);
            if (Number(reqSent?.status) === 1) {
                setMessageSuccess(reqSent?.message);
                setShowToas(true)
                setShowLoading(false)
                onHide()
            } else {
                setMessErrorSendOneCustomer(reqSent?.message)
                setShowLoading(false)
            }
        }


    }

    useEffect(() => {
        if (!show) {
            setContentMessage('')
            setMessErrorSendOneCustomer('')
            setMessageSuccess('')
            setVoucherID(0)
        }
    }, [show])

    const languageUI = getLanguageUI().reviewPage
    return (
        <>
            <ModalToast show={showToast} handleClose={() => setShowToas(false)} content={messageSuccess} />
            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
            <Modal show={show} onHide={onHide} centered className="custom-radius-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="flex">
                            <span className="text-18px font-urbansist fw-600 text-212134 ">{languageUI.sendSMS} </span>
                            <span className="text-18px font-urbansist fw-600 text-FF7B2C ml-1">{languageUI.to}  {listCustomerSelected.length} customer</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="custom-radius-text-area">
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Control as="textarea" rows={3} value={contentMessage} onChange={(e) => {
                                if (e.target.value && e.target.value.length > 200) {
                                    return;
                                }
                                setContentMessage(e.target.value)
                            }} />
                        </Form.Group>
                    </Form>
                    <div className="w-100 d-flex justify-content-end px-3">
                        <small className="text-muted text-right">
                            {contentMessage.length}/{maxlength}
                        </small>
                    </div>
                </Modal.Body>
                <div className='px-2 ' >
                    <Select
                        className='w-100'
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-5"
                        placeholder='Choose voucher ID'
                        value={voucherID}
                        onChange={(e) => setVoucherID(Number(e.target.value))}
                    >
                        <MenuItem value={0}>Choose voucher ID</MenuItem>
                        {comboVoucher.map((vov, v) => (
                            <MenuItem value={Number(vov?.id)} key={`custom${v}`}>{vov?.voucher_name}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="p-2 cursor" onClick={onHide}>
                    <div className="text-danger text-center">{messErrorSendOneCustomer}</div >
                    <button className="flex justify-center w-100 radius-3 p-2 bg-0FA54A  text-white border-none" onClick={SubmitSentSMS}>{languageUI.sendNow}</button>

                </div>
                {/* <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={onHide}>
                    Save Changes
                </Button>
            </Modal.Footer> */}
            </Modal>
        </>
    );
};

export default ModalSendSMSReview