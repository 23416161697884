import "../../StylesSetting.css";
import {
  imgLeaf,
  imgLeaf2,
  checkedAddReader,
} from "../../../../components/Image";
import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import ModalLoading from "../../../../components/ModalLoading";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import {
  ChangeNameReaderStripe,
  CreateLocationReader,
  removeTerminalStripe,
} from "../../../../api/apiOwner/stripeApi";
import Select from "react-select";
import ModalOTPDeleteReader from "../ModalDeleteReader/ModalOTPDeleteTable";
import { getLanguageUI } from "../../../../Services/languageUI";
import { useDispatch } from "react-redux";
import * as flagSlice from "../../../../store/slices/FlagSettingSlice";
interface ConectStripeProp {
  ChangeSetting: () => void;
}
const AddReaderStripe: React.FC<ConectStripeProp> = ({ ChangeSetting }) => {
  const languageUI = getLanguageUI().setting;
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState<any>("");
  const [ShowLoading, setShowLoading] = React.useState<boolean>(false);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [name, setName] = React.useState<string>("");
  const [country, setCountry] = React.useState<string>("US");
  const [addressLine1, setAddressLine1] = React.useState<string>("");
  const [nameReader, setNameReader] = React.useState<string>("WisePOS E");
  const [state, setState] = React.useState<string>("");
  const [zipCode, setZipCode] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [registationCode, setRegistationCode] = React.useState<string>("");
  const [nameLocationOld, setLocationOld] = React.useState<string>("");
  const [showModalOTPDeleteReader, setShowModalOTPDeleteReader] =
    React.useState<boolean>(false);
  const dismisModalOTPDeleteReader = () => {
    setShowModalOTPDeleteReader(false);
  };

  const handleChecked = () => {
    if (name === "") {
      setMessage("name is required");
    } else if (country === "") {
      setMessage("country is required");
    } else if (addressLine1 === "") {
      setMessage("Address line 1 is required");
    } else if (nameReader === "") {
      setMessage("address Line 2 is required");
    } else if (state === "") {
      setMessage("state is required");
    } else if (zipCode === "") {
      setMessage("zip code is required");
    } else if (city === "") {
      setMessage("name reader is required");
    } else if (registationCode === "") {
      setMessage("registation code is required");
    } else {
      setMessage("");
      CreateLocationReaderfc();
    }
  };
  const SetNameReaderStripe = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const getNameReaderStripe = await ChangeNameReaderStripe(
        UserIdStore,
        pos_id
      );
      if (Number(getNameReaderStripe?.status) === 1) {
        setLocationOld(getNameReaderStripe?.data ?? "");
      } else {
      }
      setShowLoading(false);
    }
  };
  const CreateLocationReaderfc = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const location = await CreateLocationReader(
        UserIdStore,
        name,
        city,
        addressLine1,
        country,
        zipCode,
        state,
        registationCode,
        nameReader,
        pos_id
      );
      if (Number(location?.status) === 1) {
        SetNameReaderStripe();
        ChangeSetting();
      } else {
        setMessage(location.message ?? "");
      }
      setShowLoading(false);
    }
  };
  const [countries, setCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState({});

  React.useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry({ value: "US", label: "🇺🇸 United States" });
      });
  }, []);
  React.useEffect(() => {
    SetNameReaderStripe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeReader = async () => {
    if (UserIdStore) {
      const req = await removeTerminalStripe(UserIdStore);
      if (Number(req?.status) === 1) {
        setMessage("");
        SetNameReaderStripe();
      } else {
        setMessage(req?.message ?? "Fail");
        setShowLoading(false);
      }
    }
  };

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <ModalOTPDeleteReader
        handleClose={dismisModalOTPDeleteReader}
        show={showModalOTPDeleteReader}
        UserIdStore={UserIdStore}
        setShowLoading={(e) => setShowLoading(e)}
        handleSuccess={removeReader}
      />
      <div className="w-100 h-100vh flex justify-center items-center box-add-reader">
        <div className="w-80 bg-white  radius-3  position-relative h-100 overflow-y-scroll">
          <div className="w-100">
            <p className="text-center text-4A4A6A text-8 fw-600 font-urbansist ml-2 mt-2">
              {languageUI.addTerminalStripe}
            </p>
          </div>
          <div className="w-100 d-flex flex-nowrap">
            <div className="position-relative w-50">
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">
                  {languageUI.nameForYourReference}
                </span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    placeholder="Brijack Reader"
                    type={"text"}
                    value={name}
                    onChange={(e: any) => {
                      setName(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">City</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    placeholder="Address line 1"
                    type={"text"}
                    value={city}
                    onChange={(e: any) => {
                      setCity(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">State</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    placeholder="FL,Ca,..."
                    type={"text"}
                    value={state}
                    onChange={(e: any) => {
                      setState(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>

              <div className="flex flex-column mb-2 custom-p-password d-none">
                <span className="text-5 mb-2">Name reader</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    placeholder="Wi"
                    type={"text"}
                    value={nameReader}
                    onChange={(e: any) => {
                      setNameReader(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className="w-50 position-relative">
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">Country</span>
                <Select
                  className="box-select-country"
                  options={countries}
                  value={selectedCountry}
                  onChange={(e: any) => {
                    setSelectedCountry(e);
                    setCountry(e?.value ?? "");
                    ChangeSetting();
                  }}
                />
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">Address Line 1</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    placeholder="Address Line 1"
                    type={"text"}
                    value={addressLine1}
                    onChange={(e: any) => {
                      setAddressLine1(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">Zip Code</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    placeholder="Address Line 1"
                    type={"text"}
                    value={zipCode}
                    onChange={(e: any) => {
                      setZipCode(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">Registation Code</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    placeholder="apple-grape-orange"
                    type={"text"}
                    value={registationCode}
                    onChange={(e: any) => {
                      setRegistationCode(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="w-100">
            <p className="text-danger fs-12 mb-2 text-italic text-center">
              {message}
            </p>
            <div className="flex items-center justify-content-center pb-2 px-14">
              <div className="pl-2 w-50">
                <button
                  className="w-100 radius-2 text-5 bg-0FA54A  text-white py-2 border-none"
                  onClick={handleChecked}
                >
                  {nameLocationOld === ""
                    ? languageUI.addTerminalReader
                    : languageUI.changeTerminalReader}
                </button>
              </div>
            </div>
          </div>
          {nameLocationOld !== "" && (
            <div className="w-100 d-flex justify-content-center mt-2 mb-3">
              <div className="w-fit d-flex align-items-center gap-3">
                <p className="text-center text-4A4A6A text-8 fw-600 font-urbansist mb-0">
                  {nameLocationOld}
                </p>
                <div className="d-flex align-items-center gap-1">
                  <img
                    src={checkedAddReader}
                    alt=""
                    className="h-50px w-35px"
                  />
                  <p className="text-center text-4695de text-8 fw-600 font-urbansist mb-0">
                    {languageUI.connected}
                  </p>
                  <div
                    className="btn-delete-account-stripe ms-4"
                    onClick={() => setShowModalOTPDeleteReader(true)}
                  >
                    {languageUI.delete}
                  </div>
                </div>
              </div>
            </div>
          )}
          <img src={imgLeaf} alt="" className="custom-position-img" />
          <img src={imgLeaf2} alt="" className="custom-position-img2" />
        </div>
      </div>
    </>
  );
};
export default AddReaderStripe;
