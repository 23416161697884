import { Form, InputGroup, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "../Styles.css";
import { useEffect, useState } from "react";
import React from "react";
import Select from "react-select";
import moment from "moment-timezone";
import { bgQRCodeVNPay, reader } from "../../../components/ImgExport";
import QRCode from "react-qr-code";
import { formatCurrencyVND } from "../../../utils/format";
// import ModalDatePicker from "react-mobile-datepicker-ts";

interface ModalQrcodeVnpayProps {
  show: boolean;
  qrUrl: string;
  handleClose: () => void;
  amount?: number;
}

const ModalQrcodeVnpay = ({
  show,
  handleClose,
  qrUrl,
  amount = 0,
}: ModalQrcodeVnpayProps) => {
  return (
    <>
      <Modal
        show={show}
        centered
        className="modal-delete-customer modal-import-inventory"
      >
        <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
          <Modal.Body>
            <div className="p-5">
              <div className="d-flex gap-1 ">
                <p className="mb-0 text-stripe-total">Total:</p>
                <p className="mb-0 text-stripe-total-price">{formatCurrencyVND(amount)}</p>
              </div>
              <div className="w-100 d-flex justify-content-center py-3">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: "224px", height: "238px", padding: "11px" }}
                >
                  <img
                    src={bgQRCodeVNPay}
                    alt=""
                    className="w-full h-full position-absolute"
                  />
                  <QRCode
                    style={{ width: "100%", height: "100%" }}
                    id="qrcode"
                    value={`${qrUrl}`}
                    level={"H"}
                  />
                </div>
              </div>
              <div
                className="d-flex justify-content-center py-2 box-back-reader"
                onClick={handleClose}
              >
                Cancel
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalQrcodeVnpay;
