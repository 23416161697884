import { useState } from "react";
import { BorderColorOutlinedIcon } from "../../../../../../../components/Image";
import DrawerSalaryDetail from "../../Drawer/DrawerSalaryDetail";
import * as StaffApi from "../../../../../../../api/apiOwner/StaffApi";
import ModalLoading from "../../../../../../../components/ModalLoading";
import React from "react";
import { ListPrinterByCatalog } from "../../../../../../../api/apiOwner/SettingPrinterApi";
interface ContentEmployeeWorkingProps {
    no: number;
    employee: string;
    clockIn: string;
    clockOut: string;
    workingTime: string;
    id_timesheet: string;
    listPrinterName?:ListPrinterByCatalog[]
    ReloadWorking?: () => void
}
const ContentWorkingEmployee = ({
    workingTime,
    no,
    employee,
    clockIn,
    clockOut = '',
    id_timesheet = '',
    listPrinterName=[],
    ReloadWorking
}: ContentEmployeeWorkingProps) => {
    const [showDrawerSalaryDetail, setDrawerSalaryDetail] = useState<boolean>(false)
    const [ShowLoading, setShowLoading] = useState<boolean>(false);
    const [detailTimeSheet, setDetailTimeSheet] = useState<StaffApi.DetailTimeSheet>({})

    const gDetailTimeSheet = async () => {
        setShowLoading(true)
        setDrawerSalaryDetail(true)
        const req = await StaffApi.DetailStaffTimesheet(id_timesheet);
        if (Number(req?.status) === 1) {
            setDetailTimeSheet(req?.data ?? {})
            setShowLoading(false)
        } else {
            setShowLoading(false)
            setDrawerSalaryDetail(false)
        }
    }
    return (
        <>
            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
            <tr className="d-flex align-items-center">
                <td className=" w-5 ps-2 border-bottom-none  d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">{no}</td>
                <td className="w-35 border-bottom-none d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">{employee}</td>
                <td className="ml-3 border-bottom-none w-15 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
                    {clockIn}
                </td>
                <td className="border-bottom-none w-15 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">{clockOut ? clockOut : "-"}
                </td>
                <td className="border-bottom-none w-20 d-flex align-items-center text-4 fw-600 text-228BE6 font-urbansist">{clockOut ? workingTime : "-"}</td>
                <td className="border-bottom-none w-10 d-flex align-items-center justify-between text-4 fw-600 text-666687 font-urbansist">

                    <div onClick={() => gDetailTimeSheet()} className="w-100 d-flex justify-content-center">
                        <BorderColorOutlinedIcon className="text-12B064" />
                    </div>
                </td>
            </tr>
            <DrawerSalaryDetail showDrawerSalaryDetail={showDrawerSalaryDetail} dismistDrawerSalaryDetail={() => setDrawerSalaryDetail(false)} detailTimeSheet={detailTimeSheet} ReloadWorking={() => { ReloadWorking?.(); gDetailTimeSheet() }} listPrinterName={listPrinterName}/>
        </>
    )
}
export default ContentWorkingEmployee