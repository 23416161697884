import { useState } from "react";
import ModalAddToCard from "../ModalAddToCard/ModalAddToCard";
import * as ChooseMenu from "../../../../api/apiCheckin/chooseMenuApi";
import ModalLoading from "../../../../components/ModalLoading";
import default_product from "../../../../assets/images/default_product.png";
import { plus } from "../../../../components/ImgExport";
import { formatCurrencyVND, FormatDolla } from "../../../../utils/format";
import "./Styles.css";
import React from "react";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";
import { foodItem } from "../../../../api/apiCheckin/orderApi";

interface FoodMenuItemProps {
  link: any;
  img: string;
  menu_id?: string;
  className?: string;
  name: string;
  price: number;
  priceOld?: number;
  description?: string;
  customerID?: string;
  ownerID?: string;
  foodTotal: number;
  side_id?: string;
  ordersServed?: number;
  showHappyHour?: number;
  setFootTotal: (num: number) => void;
  handleAddToCart: () => void;
  handelAddCartSale?: () => void;
  rank: number;
}

const FoodMenuItem = ({
  ordersServed,
  link,
  menu_id,
  img,
  className,
  name,
  price = 0,
  priceOld = 0,
  description,
  customerID = "",
  ownerID = "",
  foodTotal = 0,
  side_id = "",
  showHappyHour = 0,
  setFootTotal,
  handelAddCartSale,
  handleAddToCart,
  rank,
}: FoodMenuItemProps) => {
  const languageUI = getLanguageCheckIn();
  const [showModalAddToCard, setShowAddToCard] = useState(false);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [detailFood, setDetailFood] = useState<ChooseMenu.InfoFood>();
  const [noteFood, setNoteFood] = useState<ChooseMenu.NoteFood[]>([]);
  const [side_Dishes, setSide_Dishes] = useState<ChooseMenu.Side_Dishes[]>([]);
  const [listNoteOptionFood, setlistNoteOptionFood] = useState<
    ChooseMenu.listNoteOptionFood[]
  >([]);
  const dissmisModalAddToCard = () => {
    setShowAddToCard(false);
  };

  const gInfoFood = async () => {
    if (menu_id) {
      setShowLoading(true);
      const infoFood = await ChooseMenu.gInfoFood(menu_id);
      if (Number(infoFood?.status) === 1 && infoFood?.data?.InfoFood) {
        setDetailFood(infoFood?.data?.InfoFood);
        setNoteFood(infoFood?.data?.NoteFood ?? []);
        setSide_Dishes(infoFood?.data?.Side_Dishes ?? []);
        setlistNoteOptionFood(infoFood?.data?.listNoteOptionFood ?? []);
        setShowLoading(false);
        setShowAddToCard(true);
      } else {
        setShowLoading(false);
      }
    }
  };
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="h-100">
        <div
          className={`h-100 rounded-5 shadow-sm bg-F6F6F9 px-2 py-2 d-flex flex-column overflow-hidden position-relative`}
          onClick={gInfoFood}
        >
          <div className="">
            <div className="  mb-2">
              {showHappyHour > 0 ? (
                <div className=" position-relative d-flex gap-2">
                  <div className="d-flex gap-2px">
                    {/* <div className="text-dollar-old">$</div> */}
                    <del className="text-price-food-old">
                      {formatCurrencyVND(priceOld)}
                    </del>
                  </div>
                  <div className="d-flex gap-2px">
                    {/* <div className="text-dollar">$</div> */}
                    <div className="text-price-food">{formatCurrencyVND(price)}</div>
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-2px">
                  {/* <div className="text-dollar">$</div> */}
                  <div className="text-price-food">{formatCurrencyVND(price)}</div>
                </div>
              )}

              <div className="btn-add-food-cart ">
                <div className="w-100 h-100 position-relative">
                  <img src={plus} alt="" className="btn-icon-plus" />
                </div>
              </div>
            </div>
            <div className="height-120px w-100">
              <img
                src={img}
                className="img-fluid w-100 height-120px object-fit-cover aspect-ratio-16-11 rounded-5 mb-1 mb-sm-1 mb-md-2 mb-lg-3 flex-shrink-0"
                onError={(e) => {
                  e.currentTarget.src = default_product;
                }}
                alt=""
              />
            </div>
          </div>
          <div className="flex justify-between flex-column h-100  food-item-info  pb-1 ">
            {/* <div className="d-flex align-items-center food-item-info flex-wrap flex-sm-wrap flex-md-nowrap flex-lg-nowrap"> */}
            <div className="d-flex justify-content-between flex-column px-2">
              <div>
                <h4 className="text-name-food text-two-line-name mb-1">
                  {name}
                </h4>
                <div className="text-describe">{description}</div>
              </div>
            </div>
            {Number(ordersServed) > 0 && (
              <h6 className="text-right text-4a4a6a m-0 pt-1 flex-shrink-0">
                {rank > 0 ? `#${rank} - ` : ""}
                {ordersServed} {languageUI.OrdersServed}
              </h6>
            )}
          </div>
        </div>
      </div>
      <ModalAddToCard
        ownerID={ownerID}
        customerID={customerID}
        detailFood={detailFood}
        noteFood={noteFood}
        side_Dishes={side_Dishes}
        side_id={side_id}
        price={price}
        foodTotal={foodTotal}
        setFootTotal={setFootTotal}
        name={name}
        description={description}
        handleClose={dissmisModalAddToCard}
        setShowLoading={(load) => setShowLoading(load)}
        show={showModalAddToCard}
        listOptionNote={listNoteOptionFood}
        handelAddCartSale={handelAddCartSale}
      />
    </>
  );
};

export default FoodMenuItem;
