import "./Styles.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { formatCurrencyVND } from "../../../../utils/format";
import default_product from "../../../../assets/images/default_product.png";
import { useEffect, useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import NoteFoodItem from "./NoteFoodItem/NoteFoodItem";
import ModalAddSideDishFood from "../ModalAddSideDishFood/ModalAddSideDishFood";
import {
  gListNoteOptionByFood,
  listNoteOptionFood,
  NoteOptionChoose,
} from "../../../../api/apiCheckin/chooseMenuApi";
import { getLanguageUI } from "../../../../Services/languageUI";

interface FoodOrderItemsProps {
  foodName: string;
  noteFood_id: string;
  price: number;
  imageFood: string;
  noteFood: string;
  quantity: number;
  food_id: string;
  index: number;
  noteChef: string;
  statusOrderGo: number;
  optionNoteFoodChoose: NoteOptionChoose[];
  changeQuantity: (index: number, quantity: number) => void;
  changeStatusOrder: (index: number, statusOrderGo: number) => void;
  changeNoteOrder: (index: number, note: string) => void;
  copyItemOrder: (index: number) => void;
  addNoteOptionCart: (
    index: number,
    options: listNoteOptionFood[],
    note: string,
    priceSide: number
  ) => void;
}

const FoodOrderItems = ({
  foodName = "",
  price = 0,
  imageFood = "",
  noteFood = "",
  quantity = 0,
  food_id,
  changeQuantity,
  noteFood_id,
  index,
  statusOrderGo,
  noteChef = "",
  optionNoteFoodChoose = [],
  changeStatusOrder,
  changeNoteOrder,
  copyItemOrder,
  addNoteOptionCart,
}: FoodOrderItemsProps) => {
  const languageUI = getLanguageUI().posMenu;
  const [optionNoteFood, setoptionNoteFood] = useState<listNoteOptionFood[]>(
    []
  );
  const [showModalSideDish, setShowModalSideDish] = useState(false);
  const [noteFoodItem, setNotFoodItem] = useState("");

  const gListNoteOptionFood = async () => {
    const res = await gListNoteOptionByFood(
      Number(food_id),
      optionNoteFoodChoose
    );
    if (Number(res?.status) === 1 && res?.data && res?.data?.length > 0) {
      setoptionNoteFood(res.data);
      setShowModalSideDish(true);
    }
  };

  return (
    <div className="border-items-food-order pb-1">
      <div className="h-55px d-flex justify-content-between align-items-center">
        <div className="d-flex gap-2">
          <img
            src={imageFood}
            alt=""
            className="h-55px w-55px flex-shink-0 rounded-12px"
            onError={(e) => {
              e.currentTarget.src = default_product;
            }}
          />
          <div className="">
            <p className="name-food-order-items mb-0 text-truncate maxwidth-100px">
              {foodName}
            </p>
            <div className="d-flex">
              <p className="mb-0 name-price-food-order-items h-fit">
                {formatCurrencyVND(price)}
              </p>
              {/* <p className="name-dollar-food-order-items h-fit">$</p> */}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center flex-shrink-0">
          <div className="w-full h-full d-flex align-items-center gap-2">
            <button
              className={`${statusOrderGo === 2
                  ? "btn-type-togo-order-true"
                  : "btn-type-togo-order-false"
                }`}
              onClick={() => changeStatusOrder(index, 2)}
            >
              {languageUI.togo}
            </button>
            <button
              className={`${statusOrderGo === 1
                  ? "btn-type-togo-order-true"
                  : "btn-type-togo-order-false"
                }`}
              onClick={() => changeStatusOrder(index, 1)}
            >
              {languageUI.forHere}
            </button>
            <button
              className={`border-none h-40px bg-white`}
              onClick={() => copyItemOrder(index)}
            >
              <ContentCopyOutlinedIcon />
            </button>
            <button
              className={`border-none h-40px bg-white`}
              // onClick={() => setShowModalSideDish(true)}
              onClick={gListNoteOptionFood}
            >
              <AddCircleOutlinedIcon className="text-primary" />
            </button>
          </div>
          <button
            className="h-40px w-44px rounded-42px border-1px-primary bg-white"
            onClick={() => changeQuantity(index, quantity - 1)}
          >
            <RemoveIcon className="text-12B064" />
          </button>
          <div className="w-42px d-flex justify-content-center align-items-center">
            <p className="mb-0 number-food-order">{quantity}</p>
          </div>
          <button
            className="h-40px w-44px rounded-42px border-1px-primary bg-white"
            onClick={() => changeQuantity(index, quantity + 1)}
          >
            <AddIcon className="text-12B064" />
          </button>
        </div>
      </div>
      <div className="mt-2">
        <textarea
          className="note-food-item focus-visiable-none"
          placeholder={languageUI.pleaseEnterNoteFood}
          id="textarea"
          value={noteFoodItem}
          onChange={(e) => {
            setNotFoodItem(e.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              changeNoteOrder(index, noteFoodItem);
              setNotFoodItem("");
            }
          }}
        ></textarea>
      </div>
      {noteChef && (
        <div className="d-flex gap-2 flex-wrap">
          <NoteFoodItem noteFoodItem={noteChef} />
        </div>
      )}
      <div className="ps-2 mt-1">
        {noteFood && <p className="m-0 note-food-order">- {noteFood}</p>}
      </div>

      <ModalAddSideDishFood
        handleClose={() => {
          setShowModalSideDish(false);
        }}
        show={showModalSideDish}
        index={index}
        optionNoteFood={optionNoteFood}
        addNoteOptionCart={addNoteOptionCart}
      />
    </div>
  );
};

export default FoodOrderItems;
