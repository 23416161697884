// import axiosClient, { ResponseSuccess } from "../axiosClient";
import * as Request from "../Request";

export interface listOwner {
  customer_id: number;
  cryptor_id: string;
  packed_id: number;
  customerName: string;
  cellPhone: string;
  storeName: string;
  storePhone: string;
  googleID: string;
  google_review_date: string;
  google_review_rating: string;
  google_review_stars: string;
  address: string;
  detail_address: string;
  email: string;
  packageName: string;
  packageType: string;
  packageDateJoin: string;
  smsRemain: number;
  status: number;
  ratingWhenJoint: string;
  ratingNow: string;
  lastCard: string;
  suit_of_unit: string;
  store_city: string;
  store_state: string;
  zipcode: string;
  income_lastyear: number;
  income_lastmonth: number;
  package_expiry: number;

  Jan: ReportMonth;
  Feb: ReportMonth;
  Mar: ReportMonth;
  Apr: ReportMonth;
  May: ReportMonth;
  Jun: ReportMonth;
  Jul: ReportMonth;
  Aug: ReportMonth;
  Sep: ReportMonth;
  Oct: ReportMonth;
  Nov: ReportMonth;
  Dec: ReportMonth;
  next_Jan: ReportMonth;
  next_Feb: ReportMonth;
  next_Mar: ReportMonth;
  next_Apr: ReportMonth;
  next_May: ReportMonth;
  next_Jun: ReportMonth;
  next_Jul: ReportMonth;
  next_Aug: ReportMonth;
  next_Sep: ReportMonth;
  next_Oct: ReportMonth;
  next_Nov: ReportMonth;
  next_Dec: ReportMonth;
}

export interface ReportMonth {
  income: number;
  countCustomer: number;
  star_number: string | number;
  user_ratings_total: number;
}

export interface IGroupCustomerData {
  customer_id?: number;
  packed_id?: number;
  customerName?: string;
  cellPhone?: string;
  storeName?: string;
  storePhone?: string;
  googleID?: string;
  address?: string;
  packageName?: string;
  packageType?: string;
  packageDateJoin?: string;
  smsRemain?: any;
  ratingWhenJoint?: any;
  ratingNow?: any;
  status?: string;
  cryptor_id?: string;
}
export interface IListGroupCustomer {
  message: string;
  status: number;
  data: listOwner[];
}

export interface ITotalOwnerData {
  total_owner?: number;
  total_owner_active?: number;
  total_owner_no_active: number;
}

export interface ITotalOwner {
  message: string;
  status: number;
  data: ITotalOwnerData;
}

export const ListOwner = async (
  UserIdStore: number,
  Type: number,
  typeCustomer: number = 0,
  yearSearch: number,
  nextYear: number = 0
) => {
  return await Request.fetchAPI(
    "admin/groupCustomer.php?admin_id=" +
      UserIdStore +
      "&&type=" +
      Type +
      "&&typeCustomer=" +
      typeCustomer +
      "&&yearSearch=" +
      yearSearch +
      "&&nextYear=" +
      nextYear,
    "GET",
    {}
  )
    .then((res: IListGroupCustomer) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const SendAllSMSForOwner = async (
  adminId: number,
  type: number,
  messages: string
) => {
  return await Request.fetchAPI("admin/send_sms_all_customer.php", "POST", {
    admin_id: adminId,
    type: type,
    messages: messages,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const setActiveAccount = async (owner_id: number, status: number) => {
  return await Request.fetchAPI("owner/setActiveAccount.php", "POST", {
    owner_id,
    status,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const changPasscodeOwner = async (
  owner_id: number,
  passcode: string
) => {
  return await Request.fetchAPI("owner/ChangePasscodeOwner.php", "POST", {
    owner_id,
    passcode,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const changPassWordOwner = async (
  owner_id: number,
  password: string
) => {
  return await Request.fetchAPI("owner/ChangePassWordOwner.php", "POST", {
    owner_id,
    password,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const SendSMSOwner = async (
  UserIdStore: number,
  id_customer: number,
  MessageSend: string
) => {
  return await Request.fetchAPI("admin/send_SMS.php", "POST", {
    // id_owner: UserIdStore,
    // id_customer: id_customer,
    // messages: MessageSend,
    admin_id: UserIdStore,
    owner_id: id_customer,
    messages: MessageSend,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const cTotalOwner = async (Type: any) => {
  return await Request.fetchAPI(
    "admin/countTotalOwner.php?type=" + Type,
    "GET",
    {}
  )
    .then((res: ITotalOwner) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: {
          total_owner: 0,
          total_owner_active: 0,
          total_owner_no_active: 0,
        },
      };
    });
};

export const updateIncomeCustomer = async (
  owner_id: number,
  type_edit: number,
  amount: number
) => {
  return await Request.fetchAPI("admin/updateIncomeCustomer.php", "POST", {
    // id_owner: UserIdStore,
    // id_customer: id_customer,
    // messages: MessageSend,
    owner_id,
    type_edit,
    amount,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const updateGoogleReview = async (
  owner_id: number,
  google_review_date: string,
  google_review_rating: string,
  google_review_stars: string
) => {
  return await Request.fetchAPI("admin/updateGoogleReview.php", "POST", {
    owner_id,
    google_review_date,
    google_review_rating,
    google_review_stars,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
