import { Modal } from "react-bootstrap";
import "./Styles.css";
import OrderListITem from "../../../webcheckin/components/OrderListITem/OrderListITem";
import { useEffect, useState } from "react";
import { formatCurrencyVND } from "../../../../utils/format";
import { arrowUpViewBill, strashRed } from "../../../../components/ImgExport";
import ModalDeleteBillCustomer from "../ModalDeleteBillCustomer/ModalDeleteBillCustomer";
import {
  DetailBill,
  FoodDetail,
} from "../../../../api/apiOwner/custommerRecieptApi";
import moment from "moment-timezone";
import OrderListITemPrint from "../../../webcheckin/components/OrderListITem/OrderListITemPrint";
import React from "react";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import * as SettingPrinterApi from "../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../api/apiOwner/printerApi";
import ModalToast from "../ModalToast/ModalToastErr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { getLanguageUI } from "../../../../Services/languageUI";
import { gDeleteBillOrder } from "../../../../api/apiOwner/customerApi";

interface ModalShowBillOrderCustomerProps {
  show: boolean;
  detailOrder?: DetailBill;
  UserIdStore?: number;
  listPrinterName?: SettingPrinterApi.ListPrinterByCatalog[];
  handleClose: () => void;
  removeOrderById?: (order: string) => void;
}

const ModalShowBillOrderCustomer = ({
  show,
  detailOrder,
  UserIdStore,
  listPrinterName = [],
  handleClose,
  removeOrderById,
}: ModalShowBillOrderCustomerProps) => {
  const languageUI = getLanguageUI().customerReceipts;
  const languagePrinterUI = getLanguageUI().printPage;

  const [heightFoodBillCustomer, setHeightFoodBillCustomer] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [listFood, setListFood] = useState<FoodDetail[]>([]);
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const [openToolTip, setOpenToolTip] = useState(false);

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };
  const dismisModalDelete = () => {
    setShowModalDelete(false);
  };

  const handleDeleteBillSucces = async () => {
    if (UserIdStore && detailOrder?.id) {
      const req = await gDeleteBillOrder(
        Number(UserIdStore),
        Number(detailOrder?.id)
      );
      if (req.status) {
        handleClose();
        removeOrderById?.(detailOrder?.id);
      } else {
        console.log(req.message);
      }
    }
  };
  useEffect(() => {
    if (show) {
      if (detailOrder?.listFood) {
        setListFood(detailOrder?.listFood ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const PrintCard = async () => {
    try {
      const rawhtml =
        `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6, h4 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
              font-weight: 400;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px">
            <h3 style="width: 100%; margin:  auto; text-align: center">${languageUI.customerReceipts
        }</h3>
            <div style="width: 100%;">
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${detailOrder?.table_name
          ? `${languagePrinterUI.tableName}: ${detailOrder?.table_name} -`
          : ""
        }Order#${detailOrder?.id}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${languagePrinterUI.printDateTime
        }:</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${moment(
          detailOrder?.timestart,
          "YYYY-MM-DD hh:mm:ss"
        ).format("DD/MM/YYYY - hh:mm:ss")}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${languagePrinterUI.customer
        }: ${detailOrder?.custommer_name ? detailOrder?.custommer_name : "---"
        }</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${languagePrinterUI.phoneNumber
        }: ${detailOrder?.phone ? detailOrder?.phone : "---"}</h6>
            </div>
            
            

           ${listFood
          .map(
            (cart, i) =>
              `
              <div style="display: table; width: 100%">
           <div style = "display: table-row; padding-top: 10px; width: 100% ; padding-right: 10px;" >

                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                <h6  style=" margin: 0; text-align: start;">${i + 1}.${cart?.name
              }</h6>
                
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <p class="quanlity-number; margin-top: 10px !important "  style="font-size: 24px ; ">${Number(
                cart?.quantity
              )}x${formatCurrencyVND(Number(cart?.price))}</p>
                  ${Number(cart?.form_order) !== 1
                ? `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">Take away</h6>`
                : `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">Diner</h6>`
              }
                </div>
              </div >
              </div >
              <div style="width: 100%">
                ${cart?.note
                ? `<p class=""  style="font-size: 24px ; ">-${cart?.note}</p>`
                : ""
              }
                    
                  ${cart?.notefood_name
                ? `<p class=""  style="width: 100% ; white-space: nowrap; font-size: 24px ">-${cart?.notefood_name}</p>`
                : ""
              }
              </div>

              `
          )
          .join(" ")}
           <div style="width: 100%;">
                <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
            </div>
      
           <div style="display: table; width: 100%;  padding-right: 40px;">
        <div style = "display: table-row; padding-top: 0px; width: 100%" >
          <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.subTotal}:</p>
          </div>
          <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
              <p style="font-size: 24px ; ">${Number(detailOrder?.sub_total)
          ? formatCurrencyVND(Number(detailOrder?.sub_total))
          : 0
        }</p>
            </div>
          </div>
          </div>
           <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.tax}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">${Number(detailOrder?.sub_total)
          ? formatCurrencyVND(
            (Number(detailOrder?.sub_total) *
              Number(detailOrder?.tax_percent)) /
            100
          )
          : 0
        }</p>
          </div>
        </div>
        </div>
        <div style="display: table; width: 100%">
          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
              <p style="font-size: 24px ; ">${languageUI.staffOrder}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
              <p style="font-size: 24px ; ">${detailOrder?.staff_order}</p>
            </div>
          </div>
        </div>
        <div style="display: table; width: 100%">
          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
              <p style="font-size: 24px ; ">${languagePrinterUI.discount}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
              <p style="font-size: 24px ; ">${formatCurrencyVND(
          Number(detailOrder?.discount)
        )}</p>
            </div>
          </div>
        </div>
           <div style="display: table; width: 100%">
          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">Tip:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">${formatCurrencyVND(
          Number(detailOrder?.tip_amount)
        )}</p>
          </div>
        </div>
        </div>
        ` +
        (Number(detailOrder?.feePrice) > 0
          ? `<div style="display: table; width: 100%">

            <div style="display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
                <p style="font-size: 24px ; ">${languagePrinterUI.ConvenienceFee
          }:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <p style="font-size: 24px ; ">${formatCurrencyVND(
            Number(detailOrder?.feePrice)
          )}</p>
              </div>
            </div>
          </div>`
          : ``) +
        `
          <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.tipFor}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">${detailOrder?.staff_name ? detailOrder?.staff_name : "---"
        }</p>
          </div>
        </div>
        </div>
          
         
           <div style="display: table; width: 100%">

        <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <h6>${languagePrinterUI.total}:</h6>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <h6>${formatCurrencyVND(Number(detailOrder?.total_order))}</h6>
            </div>
        </div>
        </div>
        
            <div style="width: 100%; margin-top: 38px">
            <div style="width: 100%;">
            <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
        </div>

              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${nameStore}</h5>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${addressStore}</h5>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${phoneOwnerStore}</h5>
            </div>
            <h3 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">${languagePrinterUI.thanks
        }!</h2>
          </div >
          </body >
          </html >
  `;
      if (listPrinterName.length > 0) {
        listPrinterName.map(async (item) => {
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            item.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);

  return (
    <>
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <Modal show={show} onHide={handleClose} className="right-customer">
        <Modal.Dialog className="bg-gray-light">
          <Modal.Body>
            <div className="d-flex flex-column justify-content-between h-100vh pb-3">
              <div className="p-3 border-bottom position-relative">
                <div className="d-flex align-items-center gap-2">
                  {Number(detailOrder?.table_id) > 0 && (
                    <div className="box-name-table-customer">
                      {detailOrder?.table_name}
                    </div>
                  )}

                  <div className="d-flex align-items-center gap-2px">
                    <p className="mb-0 order-customer">Order</p>
                    <p className="mb-0 id-order-customer">#{detailOrder?.id}</p>
                  </div>
                </div>
                <div className="mt-2">
                  <div className="d-flex align-items-end justify-content-between mb-1">
                    <p className="title-infor-bill-customer mb-0">
                      {languageUI.printDateTime}
                    </p>
                    <p className="content-infor-bill-customer mb-0">
                      {moment(
                        detailOrder?.timestart,
                        "YYYY-MM-DD hh:mm:ss"
                      ).format("DD/MM/YYYY - hh:mm:ss")}
                    </p>
                  </div>
                  <>
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <p className="title-infor-bill-customer mb-0">
                        {languageUI.customer}
                      </p>
                      <p className="content-infor-bill-customer mb-0">
                        {Number(detailOrder?.custommer_id) > 0
                          ? detailOrder?.custommer_name
                          : "-----"}
                      </p>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <p className="title-infor-bill-customer mb-0">
                        {languageUI.phoneNumber}
                      </p>
                      <p className="content-infor-bill-customer mb-0">
                        {Number(detailOrder?.custommer_id) > 0
                          ? detailOrder?.phone
                          : "-----"}
                      </p>
                    </div>
                  </>
                </div>
                <div
                  className={`d-flex flex-column gap-2 ${heightFoodBillCustomer
                    ? "custom-h-food-bill-customer-1"
                    : "custom-h-food-bill-customer-2"
                    }  overflow-y-scroll scroll-hidden`}
                >
                  <OrderListITem orderInfo={listFood} status={true} />
                </div>
                <div className="custom-view-bill">
                  <div
                    className="btn-view-bill"
                    onClick={() => {
                      setHeightFoodBillCustomer(!heightFoodBillCustomer);
                    }}
                  >
                    <img
                      src={arrowUpViewBill}
                      alt=""
                      className={`${heightFoodBillCustomer === false ? "rorate-180" : ""
                        }`}
                    />
                    <p className="mb-0 text-view-bill">
                      {heightFoodBillCustomer === false
                        ? languageUI.viewLess
                        : languageUI.viewMore}
                    </p>
                  </div>
                </div>
              </div>
              <div className="pt-22px">
                <div
                  className={`px-3 border-bottom ${heightFoodBillCustomer === true ? "" : "d-none"
                    } `}
                >
                  <div
                    className={`d-flex align-items-end justify-content-between mb-1 `}
                  >
                    <p className="title-pay-bill-customer mb-0">
                      {languageUI.staffOrder}
                    </p>
                    <p className="price-pay-bill-customer mb-0">
                      {detailOrder?.staff_order
                        ? detailOrder?.staff_order
                        : "----"}
                    </p>
                  </div>
                  <div
                    className={`d-flex align-items-end justify-content-between mb-1 `}
                  >
                    <p className="title-pay-bill-customer mb-0">
                      {languageUI.subTotal}
                    </p>
                    <p className="price-pay-bill-customer mb-0">

                      {Number(detailOrder?.sub_total)
                        ? formatCurrencyVND(Number(detailOrder?.sub_total))
                        : 0}
                    </p>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mb-1">
                    <p className="title-pay-bill-customer mb-0">
                      {languageUI.tax}
                    </p>
                    <p className="price-pay-bill-customer mb-0">

                      {Number(detailOrder?.sub_total)
                        ? formatCurrencyVND(
                          (Number(detailOrder?.tax_percent) *
                            Number(detailOrder?.sub_total)) /
                          100
                        )
                        : 0}{" "}
                    </p>
                  </div>
                </div>
                <div className="px-3 pt-2">
                  <div className="d-flex align-items-end justify-content-between mb-1">
                    <p className="title-pay-bill-customer align-items-end mb-0">
                      {languageUI.discount}
                    </p>
                    <div className="d-flex gap-2px align-items-end">
                      <p className="price-pay-bill-customer d-flex mb-0 h-fit">
                        {formatCurrencyVND(Number(detailOrder?.discount))}
                      </p>
                      {/* <p className="price-pay-bill-customer-dollar  text-8E8EA9">
                        $
                      </p> */}
                    </div>
                  </div>
                  {Number(detailOrder?.tip_amount) > 0 && (
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <p className="title-pay-bill-customer mb-0">
                        {languageUI.tipfor} {detailOrder?.staff_name}
                      </p>
                      <div className="d-flex gap-2px align-items-end">
                        <p className="price-pay-bill-customer mb-0 h-fit">
                          {formatCurrencyVND(Number(detailOrder?.tip_amount))}
                        </p>
                        {/* <p className="price-pay-bill-customer-dollar  text-8E8EA9">
                          $
                        </p> */}
                      </div>
                    </div>
                  )}
                  {Number(detailOrder?.feePrice) > 0 && (
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div className="tooltip-payment-web-checkin">
                          <Tooltip
                            className=""
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={openToolTip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="right"
                            title={languageUI.theConvenienceFee}
                          >
                            <div
                              className="d-flex gap-1 align-items-end w-fit"
                              onClick={() => setOpenToolTip(true)}
                            >
                              <div className="title-pay-bill-customer mb-0">
                                {languageUI.ConvenienceFee}
                              </div>
                              <HelpOutlineSharpIcon className="" />
                            </div>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                      <div className="d-flex gap-2px align-items-end">
                        <p className="price-pay-bill-customer mb-0 h-fit">
                          {formatCurrencyVND(Number(detailOrder?.feePrice))}
                        </p>
                        {/* <p className="price-pay-bill-customer-dollar  text-8E8EA9">
                          $
                        </p> */}
                      </div>
                    </div>
                  )}
                  <div className="d-flex align-items-end justify-content-between mb-1">
                    <p className="title-pay-bill-customer mb-0 d-flex align-items-end">
                      {languageUI.total}
                    </p>
                    <div className="d-flex gap-2px align-items-end">
                      <p className="price-pay-bill-customer-total mb-0 h-fit mb-1">
                        {formatCurrencyVND(Number(detailOrder?.total_order))}
                      </p>
                      {/* <p className="price-pay-bill-customer-dollar text-FF7B2C">
                        $
                      </p> */}
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center gap-2px mt-2"
                  onClick={() => {
                    setShowModalDelete(true);
                  }}
                >
                  <p className="delete-bill-customer mb-0">
                    {languageUI.deleteBill}
                  </p>
                  <img src={strashRed} alt="" />
                </div>
                <div className="px-3">
                  <div className="btn-print-bill-customer" onClick={PrintCard}>
                    {languageUI.print}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none">
              <div
                className="d-flex flex-column w-100"
                id="print-bill-customer"
              >
                <div className="border-bottom-black position-relative">
                  <div>
                    <p className="mb-0 id-order-customer text-black line-height-normal">
                      {nameStore}
                    </p>
                    <div className="d-flex gap-1 align-items-end">
                      <p className="mb-0 id-order-customer text-black line-height-normal">
                        Add:
                      </p>
                      <p className="mb-0 text-items-order-print text-black">
                        {addressStore}
                      </p>
                    </div>
                    {/* <div className="d-flex gap-1 align-items-end">
                      <p className="mb-0 id-order-customer  text-black line-height-normal">Phone:</p>
                      <p className="mb-0 text-items-order-print text-black">{phoneOwnerStore}</p>
                    </div> */}
                  </div>
                  <div className="d-flex align-items-center gap-2 text-black">
                    {Number(detailOrder?.table_id) > 0 && (
                      <div className="title-infor-bill-customer-print line-height-normal">
                        {detailOrder?.table_name}
                      </div>
                    )}
                    <div className="d-flex align-items-center gap-2px">
                      <p className="mb-0 order-customer line-height-normal text-black">
                        Order
                      </p>
                      <p className="mb-0 id-order-customer line-height-normal text-black">
                        #{detailOrder?.id}
                      </p>
                    </div>
                  </div>
                  <div className="border-bottom-black">
                    <div className="">
                      <p className="title-infor-bill-customer-print mb-0 text-black">
                        Print date - time
                      </p>
                      <p className="title-infor-bill-customer-print mb-0 text-black">
                        {moment(
                          detailOrder?.timestart,
                          "YYYY-MM-DD hh:mm:ss"
                        ).format("DD/MM/YYYY - hh:mm:ss")}
                      </p>
                    </div>
                    <div className="d-flex align-items-end justify-content-between">
                      <p className="title-infor-bill-customer-print mb-0 text-black">
                        Customer
                      </p>
                      <p className="title-infor-bill-customer-print mb-0 text-black">
                        {Number(detailOrder?.custommer_id) > 0
                          ? detailOrder?.custommer_name
                          : "-----"}
                      </p>
                    </div>
                    <div className="d-flex align-items-end justify-content-between">
                      <p className="title-infor-bill-customer-print mb-0 text-black">
                        Phone number
                      </p>
                      <p className="title-infor-bill-customer-print flex-shink-0 mb-0 text-black">
                        {Number(detailOrder?.custommer_id) > 0
                          ? detailOrder?.phone
                          : "-----"}
                      </p>
                    </div>
                  </div>
                  <div className={`d-flex flex-column gap-2`}>
                    <OrderListITemPrint orderInfo={listFood} status={true} />
                  </div>
                </div>
                <div className="">
                  <div className={` border-bottom-black gap-0`}>
                    <div
                      className={`d-flex align-items-end justify-content-between m-0 p-0 line-height-normal `}
                    >
                      <p className="title-pay-bill-customer m-0 p-0 line-height-normal text-black">
                        Sub total
                      </p>
                      <p className="price-pay-bill-customer m-0 p-0 line-height-normal text-black">
                        {Number(detailOrder?.sub_total)
                          ? formatCurrencyVND(Number(detailOrder?.sub_total))
                          : 0}
                        $
                      </p>
                    </div>
                    <div className="d-flex align-items-end justify-content-between m-0 p-0 line-height-normal">
                      <p className="title-pay-bill-customer m-0 p-0 line-height-normal text-black">
                        Tax
                      </p>
                      <p className="price-pay-bill-customer m-0 p-0 line-height-normal text-black">
                        {Number(detailOrder?.sub_total)
                          ? formatCurrencyVND(
                            (Number(detailOrder?.tax_percent) *
                              Number(detailOrder?.sub_total)) /
                            100
                          )
                          : 0}

                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-end justify-content-between mb-0">
                      <p className="title-pay-bill-customer align-items-end mb-0 text-black line-height-normal">
                        Discount
                      </p>
                      <div className="d-flex gap-2px align-items-end">
                        <p className="price-pay-bill-customer d-flex mb-0 h-fit text-black line-height-normal">
                          {detailOrder?.discount}$
                        </p>
                      </div>
                    </div>
                    {Number(detailOrder?.tip_amount) > 0 && (
                      <div className="mb-0">
                        <p className="title-pay-bill-customer mb-0 text-black line-height-normal flex-shink-0">
                          Tip for:{" "}
                        </p>
                        <div className="d-flex gap-1 align-items-start justify-between line-height-normal">
                          <p className="title-pay-bill-customer mb-0 text-black line-height-normal">
                            {detailOrder?.staff_name}
                          </p>
                          <p className="price-pay-bill-customer mb-0 h-fit text-black line-height-normal flex-shink-0">
                            {formatCurrencyVND(Number(detailOrder?.tip_amount))}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="d-flex align-items-end justify-content-between mb-0">
                      <p className="title-pay-bill-customer mb-0 d-flex align-items-end text-black line-height-normal">
                        Total
                      </p>
                      <div className="d-flex gap-2px align-items-end">
                        <p className="price-pay-bill-customer-total mb-0 h-fit mb-0 text-black line-height-normal">
                          {formatCurrencyVND(Number(detailOrder?.total_order))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ModalDeleteBillCustomer
              show={showModalDelete}
              UserIdStore={UserIdStore}
              handleClose={dismisModalDelete}
              handleSuccess={handleDeleteBillSucces}
            />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalShowBillOrderCustomer;
