// import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  qrCodeDecoBottomLeft,
  qrCodeDecoTopRight,
  qrCodeDemo,
  qrCodeFrame,
} from "../../components/Image";
import { withSwal } from "react-sweetalert2";
import html2canvas from "html2canvas";

// components
import PageTitle from "../../components/PageTitle";
import QRCode from "react-qr-code";
import * as Request from "../../api/Request";
import { useLocation, useSearchParams } from "react-router-dom";

interface CustomerType {
  id_customer: string;
}

const QRCodeOwner = withSwal((props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let id_customer = searchParams.get("id_customer");
  const capture = async () => {
    const QRImg: any = document.querySelector("#QR_img");
    html2canvas(QRImg).then(function (canvas) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = "QR_image.png";
      link.href = canvas.toDataURL("image/png");
      link.target = "_blank";
      link.click();
    });
    // html2canvas(QRImg).then(function(canvas) {
    // //   document.body.appendChild(canvas);
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF();
    //     // pdf.addImage()
    //     pdf.addImage(imgData, 'JPEG', 50, 0, 100, 140, 'NONE');
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save("download.pdf");
    // });
  };

  const printQR = async () => {
    // const QRImg: any = document.querySelector("#QR_img");
    // window.print();
  };

  const { swal } = props;
  const handleDownloadQR = () => {
    swal
      .fire({
        title: "You want download ?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28bb4b",
        cancelButtonColor: "#f34e4e",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then(function (result: { value: any; dismiss: any }) {
        if (result.value) {
          capture();
        }
      });
  };
  const handlePrintQR = () => {
    swal
      .fire({
        title: "You want print ?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28bb4b",
        cancelButtonColor: "#f34e4e",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then(function (result: { value: any; dismiss: any }) {
        if (result.value) {
          // const QRImg: any = document.querySelector("#QR_img");
          var divContents: any =
            document.querySelector("#QR_img_print")?.innerHTML;
          var a: any = window.open();
          // var a: any = window.open('', '', 'height=100, width=100');
          a.document.write("<html>");
          a.document.write("<body > <h1>Div contents are <br>");
          a.document.write(divContents);
          a.document.write("</body></html>");
          a.document.close();
          a.print();
          // printQR();
        }
      });
  };

  // useEffect(() => {
  //   let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
  //   let menuItemElOwner = document?.querySelectorAll(".menu-owner");
  //   menuItemElAdmin.forEach((item, i) => {
  //     menuItemElAdmin[i]?.classList.add("d-none");
  //   });
  //   menuItemElOwner.forEach((item, i) => {
  //     menuItemElOwner[i]?.classList.remove("d-none");
  //   });
  // }, []);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "QR Code", path: "#" },
          { label: "Scan QR Code", path: "#", active: true },
        ]}
        title={"QR Code"}
      />
      <div className="pb-5 pt-3">
        <Row className="mb-5">
          <Col xs={12} md={12} lg={12} xl={12} className="mx-auto">
            <div className="text-end">
              <Button
                variant="primary"
                className="me-0 me-sm-0 me-md-2 me-lg-2 w-xs-100 mb-2 mb-sm-2 mb-md-0 mb-lg-0"
                onClick={handleDownloadQR}
              >
                <i className="fe-download me-1"></i> Download QR Code
              </Button>
              <Button
                variant="outline-primary"
                className="w-xs-100"
                onClick={handlePrintQR}
              >
                <i className="fe-printer me-1"></i> Print QR Code
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={5} xl={4} className="mx-auto qr-code-col">
            <div
              className="py-5 px-4 rounded-5 bg-white border-bottom border-5 border-primary shadow position-relative"
              id="QR_img"
            >
              <img
                src={qrCodeDecoTopRight}
                className="img-fluid"
                style={{ position: "absolute", right: 0, top: 30, height: 135 }}
              />
              <img
                src={qrCodeDecoBottomLeft}
                className="img-fluid"
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 30,
                  height: 200,
                }}
              />
              <h5
                className="text-center fs-4 mt-3"
                style={{ lineHeight: "1.5" }}
              >
                Open Your Phone Camera
                <br />
              </h5>
              <h3
                className="mb-4 text-center fs-1"
                style={{ lineHeight: "1.5" }}
              >
                SCAN TO VIEW MENU
              </h3>
              <div className="text-center">
                <div
                  className="d-flex align-items-center justify-content-center mx-auto position-relative"
                  style={{ width: "75%" }}
                >
                  <img src={qrCodeFrame} className="img-fluid w-100" />
                  {/* <img src={qrCodeDemo} alt="qr-img" className="img-fluid position-absolute start-0 end-0 p-3" /> */}
                  <div
                    className="img-fluid position-absolute start-0 end-0 text-center mx-auto"
                    style={{ width: "75%" }}
                  >
                    <QRCode
                      style={{ width: "100%" }}
                      id="qrcode"
                      value={`${Request.URL_QRCode}/${id_customer}`}
                      level={"H"}
                    />
                  </div>
                </div>
              </div>
              {/* <img src={qrCodeDemo} alt="qr-img" className="img-fluid px-5" /> */}
              <h5
                className="text-center fs-4 mt-3"
                style={{ lineHeight: "1.5" }}
              >
                Operate by
                <br />
                <span className="fw-bold">BriJack Check-in Menu</span>
              </h5>
            </div>
            <div
              className="d-none"
              style={{
                borderBottom: 5,
                position: "relative",
                borderRadius: "0.75rem",
                borderColor: "#0B2F88",
                backgroundColor: "#fff",
                paddingTop: "4.5rem",
                paddingBottom: "4.5rem",
                paddingLeft: "2.25rem",
                paddingRight: "2.25rem",
              }}
              id="QR_img_print"
            >
              <img
                src={qrCodeDecoTopRight}
                style={{
                  position: "absolute",
                  right: 0,
                  top: 30,
                  height: 135,
                  maxWidth: "100%",
                }}
              />
              <img
                src={qrCodeDecoBottomLeft}
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 30,
                  height: 200,
                  maxWidth: "100%",
                }}
              />
              <h2
                style={{
                  fontSize: "calc(1.35rem + 1.2vw)",
                  textAlign: "center",
                  lineHeight: "1.5",
                  marginBottom: "2.25rem",
                }}
              >
                SCAN TO GET
                <br />
                OUR MENU
              </h2>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "75%",
                  }}
                >
                  <img
                    src={qrCodeFrame}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      height: 350,
                      position: "relative",
                    }}
                  />
                  <img
                    src={qrCodeDemo}
                    alt="qr-img"
                    className="img-fluid position-absolute start-0 end-0 p-3"
                    style={{
                      maxWidth: "100%",
                      height: 320,
                      position: "absolute",
                      left: 0,
                      right: 0,
                      padding: "1.5rem",
                    }}
                  />
                </div>
              </div>
              {/* <img src={qrCodeDemo} alt="qr-img" className="img-fluid px-5" /> */}
              <h5
                style={{
                  textAlign: "center",
                  fontSize: "1.125rem",
                  lineHeight: "1.5",
                }}
              >
                Operate by
                <br />
                <span style={{ fontWeight: "bold" }}>
                  BriJack Check-in Menu
                </span>
              </h5>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
});

export default QRCodeOwner;
