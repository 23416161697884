/* eslint-disable @typescript-eslint/no-unused-vars */
import "./Styles.css"
import { clockCheckIn, clockCheckOut, usersTable } from "../../components/ImgExport";
import { useEffect, useState } from "react";
import ContentTableClockInOut from "./components/ContentTableClockInOut/ContentTableClockInOut";
import ModalCheckInClock from "./components/ModalCheckInClock/ModalCheckInClock";
import ModalCheckOutClock from "./components/ModalCheckOutClock/ModalCheckOutClock";
import * as clockinApi from "../../api/apiOwner/clockinApi";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { getLanguageUI } from "../../Services/languageUI";
import React from "react";


interface ClockInClockOutProps {
}
const ClockInClockOut: React.FC<ClockInClockOutProps> = () => {
    const languageUI = getLanguageUI().clockInOut
    const UserIdStore = useSelector(userSlice.selectorUserId);
    const [showModalCheckIn, setShowModalCheckIn] = useState(false)
    const [listClockIn, setListClickIn] = useState<clockinApi.ClockInList[]>([])
    const [pageCurrent, setPageCurrent] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1)
    const [pageGoTo, setPageGoTo] = useState<number>();
    const [showModalCheckOut, setShowModalCheckOut] = useState(false)
    const [idStaffSelected, setIdStaffSelected] = useState<string>('')//id nhân viên đang đc chọn
    const [timmingId, setTimmingId] = useState<string>('')//id timing clock đang đc chọn

    const dismisModalCheckOut = (status: boolean = false) => {
        setShowModalCheckOut(false)
        if (status) {
            gListClock(pageCurrent)
        }
    }
    const dismisModalCheckIn = (status: boolean = false) => {
        setShowModalCheckIn(false)
        if (status) {
            gListClock(pageCurrent)
        }
    }
    const gListClock = async (page: number = 1) => {
        if (UserIdStore) {
            const Res_status = await clockinApi.gListCustomerReceipts(UserIdStore, page);
            if (Number(Res_status.status) === 1) {
                setListClickIn(Res_status?.data ?? []);
                setTotalPage(Number(Res_status?.total) ?? 1)
            }
        }
    }
    useEffect(() => {
        setPageGoTo(pageCurrent)
        gListClock(pageCurrent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCurrent])
    useEffect(() => {
        gListClock(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            <div className=" pt-header px-3 custom-h-body-page-clock-in-out" >
                <div className="d-flex justify-content-between pt-4">
                    <div className="w-30 h-88px rounded-12px bg-white d-flex align-items-center p-2 drop-shadow-btn-check" onClick={() => {
                        setShowModalCheckIn(true);
                    }}>
                        <img src={clockCheckIn} alt="" className="me-2" />
                        <p className="mb-0 text-check-in-clock" >{languageUI.clock_In}</p>
                    </div>
                    <div className="w-30 h-88px rounded-12px bg-white d-flex align-items-center p-2 drop-shadow-btn-check"
                        onClick={() => {
                            setShowModalCheckOut(true);
                        }}
                    >
                        <img src={clockCheckOut} alt="" className="me-2" />
                        <p className="mb-0 text-check-in-clock">{languageUI.clock_Out}</p>
                    </div>
                    <div className="w-30 h-88px rounded-12px bg-transparent d-flex align-items-center p-2">
                        {/* <img src={clockStaffTicket} alt="" className="me-2" />
                        <div>
                            <p className="mb-0 text-check-in-clock">Staff ticket</p>
                            <p className="mb-0 print-ticket">Print ticket</p>
                        </div> */}

                    </div>
                </div>
                <div className="w-100 bg-white rounded-12px mt-4">
                    <p className="text-customer-receipts p-3 mb-0">{languageUI.clockInOut}</p>
                    <div className="table-container">
                        <table className="w-100 px-2 table-customer ">
                            <tr className="w-100 bg-F6F6F9 header-table-customer h-54px d-flex align-items-center border-y">
                                <th className="text-title-table w-10 ps-2  d-flex align-items-center">{languageUI.no}</th>
                                <th className="text-title-table w-37 d-flex align-items-center gap-2"><img src={usersTable} alt="" /> {languageUI.employee}</th>
                                <th className="text-title-table w-17 d-flex align-items-center ">{languageUI.clockIn}</th>
                                <th className="text-title-table w-17 d-flex align-items-center ">{languageUI.clockOut}</th>
                                <th className="text-title-table w-16 d-flex align-items-center">{languageUI.workTime}</th>
                                <th className="text-title-table w-5 d-flex align-items-center"></th>

                            </tr>

                            <tbody className="clock-in-clock-out">
                                {listClockIn.map((item, i) => (
                                    <ContentTableClockInOut key={i} staffChoosed={timmingId} setStaffChoosed={(id: string, staffid: string) => { setTimmingId(id); setIdStaffSelected(staffid); }} timing_id={item.id} staffId={item?.staff_id} clockIn={item?.clock_in} clockOut={item?.clock_out} employee={item?.name} numerical={(i + 1) + (15 * Number(pageCurrent - 1))} workTime={item?.working_time} />
                                ))}
                            </tbody>
                        </table>
                        {/* {totalPage > 1 && ( */}
                        {/* <div className="w-100 h-66px d-flex justify-content-center align-items-center border-top">
                            <div className="d-flex h-fit d-flex  align-items-center">
                                <p className="mb-0 h-fit text-page-table-bottom">Total {totalPage} pages</p>
                                <button className="btn-page-customer-receipts" onClick={() => {
                                    pageCurrent > 1 ? setPageCurrent(pageCurrent - 1) : setPageCurrent(1);
                                }}>
                                    <img src={arrowLeftPage} alt="" />
                                </button>
                                {pagingRender()}


                                <button className="btn-page-customer-receipts" onClick={() => {
                                    pageCurrent != totalPage ? setPageCurrent(pageCurrent + 1) : setPageCurrent(totalPage);
                                }}>
                                    <img src={arrowRightPage} alt="" />
                                </button>
                                <p className="mb-0 text-page-table-bottom me-2">Go to</p>
                                <div className="input-text-page">
                                    <input type="number" className="w-100 text-page-table-bottom border-none px-2"
                                        value={pageGoTo}
                                        readOnly={totalPage === 1}
                                        onChange={(e) => {
                                            if (e.target.value) {
                                                setPageGoTo(Number(e.target.value))
                                            } else {
                                                setPageGoTo(undefined)
                                            }
                                        }}
                                        onBlur={(e) => {
                                            setPageCurrent(Number(e.target.value) < 0 ? 1 : Number(e.target.value) > totalPage ? totalPage : Number(e.target.value));
                                        }}
                                    />
                                </div>
                            </div>
                        </div> */}
                        {/* )} */}
                    </div>
                </div>
            </div>
            <ModalCheckInClock handleClose={dismisModalCheckIn} show={showModalCheckIn} UserIdStore={UserIdStore} />
            <ModalCheckOutClock handleClose={dismisModalCheckOut} show={showModalCheckOut} UserIdStore={UserIdStore} />
        </div>
    );
};

export default ClockInClockOut;
