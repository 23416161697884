import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface ListPercentTip {
  id?: string,
  percent?: string,
  delf?: string,
  timestamp?: string
}

const initialTipPercentState: ListPercentTip[] = [];

const TipPercenSlice = createSlice({
  name: "tippercent",
  initialState: initialTipPercentState,
  reducers: {
    setListTipPercent: (state, action: PayloadAction<ListPercentTip[]>) => action.payload,

    clearTipPercent: (state) => initialTipPercentState,
  },
});
export const selectorListTipPercent = (state: { tippercent: ListPercentTip[] }) =>
  state.tippercent;

export const {
  setListTipPercent,
  clearTipPercent
} = TipPercenSlice.actions;
export default TipPercenSlice.reducer;
