import { parse, format } from "date-fns";
import CryptoJS from "crypto-js";

export const FormatDolla = (dolla: number = 0) => {
  if (!dolla) {
    return '0.00'
  }
  return Number(dolla) % 2 !== 0 ? Number(dolla)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,") : (dolla).toString();
};
export function formatCurrencyVND(amount: number): string {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
    minimumFractionDigits: 0, // Số lẻ có thể bỏ qua
    maximumFractionDigits: 0, // Bạn có thể tùy chỉnh số chữ số sau dấu thập phân
  });

  return formatter.format(amount);
}
//parse fomat dola to number
export const parseDolla = (formattedNumberString: string) => {
  const convertedNumber = parseFloat(formattedNumberString.replace(/,/g, ""));
  return convertedNumber;
};
export function formatPhoneNumber(phoneNumberString: any) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
}
export const formatDate = (originalDateString: string) => {
  const originalDate = new Date(originalDateString);
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, "0");
  const day = String(originalDate.getDate()).padStart(2, "0");
  const hours = String(originalDate.getHours()).padStart(2, "0");
  const minutes = String(originalDate.getMinutes()).padStart(2, "0");
  const seconds = String(originalDate.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
export const FormatNumber = (number: number) => {
  const formatNumber = (num: number): string => {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return formatNumber(number);
};
export function convertDateString(dateString: string, fomattype: string) {
  const dateObject = parse(dateString, "EEE MMM dd yyyy", new Date());
  return format(dateObject, fomattype);
}
export const isNumber = (val: any) => {
  // Kiểm tra nếu val là một số
  return !isNaN(val) && val !== "";
};

// export const decrypt = (cipherText: string, secretKey: string) => {
//   // Giải mã dữ liệu
//   const iv = CryptoJS.enc.Base64.parse(cipherText.slice(0, 24));
//   const encryptedData = cipherText.slice(24);

//   const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey, { iv });
//   const result = bytes.toString(CryptoJS.enc.Utf8);

//   return result || "Decryption failed or empty"; // Kiểm tra kết quả
// };
export const decrypt = (encodedData: string, key: string) => {
  const decoded = atob(encodedData);
  const [data, hmac] = decoded.split(":");

  // Tạo HMAC từ dữ liệu
  const calculatedHmac = CryptoJS.HmacSHA256(data, key).toString();

  // So sánh HMAC
  if (calculatedHmac !== hmac) {
    throw new Error("Invalid data");
  }

  return data;
};
