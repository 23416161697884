import * as Request from "../Request";

export interface Result<T> {
  message: string;
  status: number;
  data: T;
  phoneNumber: string;
}

export const ListPackage = async () => {
  return await Request.fetchAPI("owner/gListPacked.php", "GET", {})
    .then((res: Result<IListPackageData[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        phoneNumber: ''
      };
    });
};

export const GetMyPacked = async (UserIdStore: number) => {
  return await Request.fetchAPI(
    "owner/gPacked_Owner.php?id_owner=" + UserIdStore,
    "GET",
    {
      // }).then((res:MyPackageData) => {
    }
  )
    .then((res: IMyPackage) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const Payment = async (ownerId: any, packageId: any) => {
  return await Request.fetchAPI("stripe/payment.php", "POST", {
    owner_id: ownerId,
    packed_id: packageId,
  })
    .then((res: Request.Response<IListPackage[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const updatePackage = async (
  ownerId: any,
  packageId: any,
  quantitySms: number,
  dayUpdate: any
) => {
  return await Request.fetchAPI("owner/upgradePacked.php", "POST", {
    id_owner: ownerId,
    id_packed: packageId,
    cms: quantitySms,
    date_register: dayUpdate,
  })
    .then((res: Request.Response<IListPackage[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const cancelPackage = async (
  ownerId: any,
  packageId: any
) => {
  return await Request.fetchAPI("owner/cancelPacked.php", "POST", {
    owner_id: ownerId,
    packed_id: packageId,
  })
    .then((res: Request.Response<IListPackage[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const changeCardNumber = async (owner_id: number, bankNumber: string, bankCVC: string, bankDateCard: string) => {
  return await Request.fetchAPI(
    "owner/editCardOwner.php",
    "POST",
    {
      owner_id, bankNumber, bankCVC, bankDateCard
    }
  )
    .then((res: IMyPackage) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//cập nhật thẻ ver 3
export const changeCardNumberV3 = async (owner_id: number, tokenpayment: string, lastCart: string) => {
  return await Request.fetchAPI(
    "stripe/editCardOwner_v3.php",
    "POST",
    {
      owner_id, tokenpayment, lastCart
    }
  )
    .then((res: IMyPackage) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export interface IListPackageData {
  id?: number;
  package_expiry?: number;
  package_type?: number;
  price?: number;
  cms?: number;
  description?: string;
  package_name: string;
  typeCard: string;
  save: number;
  corlor: string;
  iconlink: string;
}

export interface IListPackage {
  message: string;
  status: number;
  data: IListPackageData[];
}

export interface IMyPackageData {
  id: number;
  package_expiry?: number;
  package_type?: number;
  price?: number;
  cms: number | 0;
  description?: string;
  status: number;
  type: number;
  totalsms: number | 0;
  package_name: string;
  typeCard: string;
  date_register: string;
  bank_CVC: string,
  bank_date_card: string
  bank_number: string
  last4: string
}

export interface IMyPackage {
  message: string;
  status: number;
  data: IMyPackageData[];
}
