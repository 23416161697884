import { Modal } from "react-bootstrap";
import "./Styles.css";
import { useEffect, useState } from "react";
import * as custommerRecieptApi from "../../../../api/apiOwner/custommerRecieptApi";
import React from "react";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import { PrintOrderPage } from "../../../../api/apiOwner/printerApi";
import * as SettingPrinterApi from "../../../../api/apiOwner/SettingPrinterApi";
import ModalToast from "../ModalToast/ModalToastErr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalOTPOwnerStaff from "../ModalOTPOwnerStaff/ModalOTPOwnerStaff";
import { getLanguageUI } from "../../../../Services/languageUI";
import { FormatDolla } from "../../../../utils/format";

interface ModalShowBillOrderOwnerProps {
  show: boolean;
  timeOrder: string;
  CustomerName: string;
  phone: string;
  orderId: string;
  tableId: string;
  tableName: string;
  handleClose: () => void;
  handleDeleteBillSucces: () => void;
  listPrinterName: SettingPrinterApi.ListPrinterByCatalog[];
}

const ModalShowBillOrderOwner = ({
  show,
  CustomerName,
  phone,
  timeOrder,
  orderId = "",
  tableId = "",
  tableName = "",
  handleClose,
  handleDeleteBillSucces,
  listPrinterName,
}: ModalShowBillOrderOwnerProps) => {
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const languageUI = getLanguageUI().orderPage;
  const languagePrint = getLanguageUI().printPage;
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  const [listFood, setListFood] = useState<custommerRecieptApi.ProductInfo[]>(
    []
  );
  const [listTotalBill, setListTotalBill] = useState({
    sub_total: 0,
    tax_percent: 0,
    feePrice: 0,
    discount: 0,
    tip_amount: 0,
    total_order: 0,
    vat_amount: 0,
    staff_name: "",
  });
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [showModalDeleteBillOrder, setShowModalDeleteBillOrder] =
    useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const [listSide, setListSide] = useState<custommerRecieptApi.listSide[]>([]);
  const dismisModalDeleteBillOrder = () => {
    setShowModalDeleteBillOrder(false);
  };
  const languageUIbill = getLanguageUI().customerReceipts;
  const languagePrinterUI = getLanguageUI().printPage;
  const gListProduct = async () => {
    const req = await custommerRecieptApi.gListProductOrder(orderId);
    if (Number(req?.status) === 1) {
      setListFood(req?.data ?? []);
      setListSide(req?.listSide ?? []);
      setListTotalBill({
        sub_total: req.sub_total,
        tax_percent: req.tax_percent,
        feePrice: req.feePrice,
        discount: req.discount,
        tip_amount: req.tip_amount,
        total_order: req.total_order,
        vat_amount: req.vat_amount,
        staff_name: req.staff_name,
      });
    } else {
    }
  };

  useEffect(() => {
    if (show) {
      gListProduct();
    }
  }, [show]);

  // function PrintCard() {
  //   const printUID = new Date().getTime();
  //   const config: any = {
  //     printable: "print-bill-owner", //id DOM element
  //     documentTitle: `CardPrint-${printUID}`, //title html document send to iframe
  //     targetStyles: ["*"], //accepts all the styles
  //     targetStyle: ["*"], //accepts all the styles
  //     frameId: "cardprint", //custom frame id
  //     type: "html", //print type html
  //     onError: (error: any) => {
  //       console.error("Error Printing: ", error);
  //     },
  //     onPrintDialogClose: () => {
  //       console.info("Print Window Closed");
  //     }
  //   };

  //   printJS(config);
  // }

  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);

  const PrintCard = async () => {
    try {
      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (itempr) => {
          // if (
          //   itempr?.printType.some((pr) => pr === 4) ||
          //   itempr?.printType.some((zItem) =>
          //     listFood.some((iItem) => iItem.typeFood === zItem)
          //   )
          // ) {
          const rawhtml2 = `
            <!DOCTYPE html>
            <html lang="en">
  
            <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Hóa đơn</title>
            <style type="text/css">
              .name-food {
                font-weight: 600;
              }
  
              .quanlity-number {
                font-weight: 600;
              }
  
              p, h6 {
                margin-bottom: 0;
                margin-top: 0;
                line-height: normal;
              }
  
              .fw-normal{
                font-weight: 400;
              }
            </style>
  
            </head>
  
            <body>
  
            <div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px">
              <h3 style="width: 100%; margin:  auto; text-align: center">${
                languagePrint.bill
              }</h3>
              <div style="width: 100%;">
                <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                  Number(tableId) > 0
                    ? `${languagePrint.tableName}: ${tableName} -`
                    : ""
                } Order#${orderId}</h6>
                   <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                     languagePrint.customer
                   }: ${CustomerName ? CustomerName : "---"}</h6>
                   <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                     languagePrint.phoneNumber
                   }: ${phone ? phone : "---"}</h6>
  
              </div>
              <div style="display: table; width: 100%">
              ${listFood
                .map(
                  (cart, i) =>
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    // itempr?.printType?.some(
                    //   (ty) => ty === 4 || ty === cart.typeFood
                    // )
                    //   ?
                    `<div style = "display: table-row; padding-top: 10px; width: 100%; padding-right: 10px;" >
                  <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                  <h6  style=" margin: 0; text-align: start; font-size: 24px " >${
                    i + 1
                  }.${cart?.name}</h6>
  
                  </div>
                  <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                  <h6 class="quanlity-number; margin-toh6: 4px">${Number(
                    cart?.quantity
                  )}x${FormatDolla(Number(cart?.price))}</h6>
                    ${
                      Number(cart.form_order) === 2
                        ? `<h6 style=" flex-shrink: 0; width: fit-content; white-space: nowrap; font-weight: 600;">${languagePrint.takeAway}</h6>`
                        : `<h6 style=" flex-shrink: 0; width: fit-content; white-space: nowrap; font-weight: 600;">${languagePrint.diner}<h6>`
                    }
                  </div>
                </div >
                <div style = "display: table-row; padding-top: 10px; width: 100%; padding-right: 10px;" >
                  <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                  <p  style=" margin: 0; text-align: start; font-size: 24px " >${
                    cart?.note ? `-${cart?.note}` : ""
                  }</p>
                  <p  style=" margin: 0; text-align: start; font-size: 24px " >${
                    cart?.notefood_name ? `-${cart?.notefood_name}` : ""
                  }</p>
                  </div>
                  
                </div >
                `
                  // : ``
                )
                .join(" ")}
           
          
  
              </div>
              ${
                listSide.length
                  ? `
              <h6 style=" margin: 0; text-align: start; font-size: 24px " ><li>${
                languagePrint.freeSide
              }</li></h6>
              ${listSide
                .map(
                  (item, index) =>
                    `
              <h6 style=" margin: 0; text-align: start; font-size: 24px " >${
                index + 1
              } ${item.name} (x${item.quantity})</h6>
              `
                )
                .join(" ")}
            `
                  : ""
              }
              <div style="width: 100%; margin-top: 38px">
              <div style="width: 100%;">
              <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
          </div>
  
                <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${nameStore}</h5>
                <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${addressStore}</h5>
                <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${phoneOwnerStore}</h5>
              </div>
              <h3 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">${
                languagePrint.thanks
              }!</h3>
            </div>
            </body>
  
            </html>
            `;
          const rawhtml =
            `
              <!DOCTYPE html>
              <html lang="en">
    
              <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Hóa đơn</title>
              <style type="text/css">
                .name-food {
                  font-weight: 600;
                }
    
                .quanlity-number {
                  font-weight: 600;
                }
    
                p, h6, h4 {
                  margin-bottom: 0;
                  margin-top: 0;
                  line-height: normal;
                }
    
                .fw-normal{
                  font-weight: 400;
                }
              </style>
    
              </head>
    
              <body>
    
              <div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px">
                <h3 style="width: 100%; margin:  auto; text-align: center">${
                  languageUIbill.customerReceipts
                }</h3>
                <div style="width: 100%;">
                <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                  tableName
                    ? `${languagePrinterUI.tableName}: ${tableName} -`
                    : ""
                }Order#${orderId}</h6>
                  <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                    languagePrinterUI.printDateTime
                  }:</h6>
                 
                  <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                    languagePrinterUI.customer
                  }: ${CustomerName ? CustomerName : "---"}</h6>
                  <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                    languagePrint.phoneNumber
                  }: ${phone ? phone : "---"}</h6>
                </div>
                
                
    
               ${listFood
                 .map(
                   (cart, i) =>
                     `
                  <div style="display: table; width: 100%">
               <div style = "display: table-row; padding-top: 10px; width: 100% ; padding-right: 10px;" >
    
                    <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                    <h6  style=" margin: 0; text-align: start;">${i + 1}.${
                       cart?.name
                     }</h6>
                    
                    </div>
                    <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                    <p class="quanlity-number; margin-top: 10px !important "  style="font-size: 24px ; ">${Number(
                      cart?.quantity
                    )}x${Number(cart?.price)}$</p>
                      ${
                        Number(cart?.form_order) !== 1
                          ? `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">Take away</h6>`
                          : `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">Diner</h6>`
                      }
                    </div>
                  </div >
                  </div >
                  <div style="width: 100%">
                    ${
                      cart?.note
                        ? `<p class=""  style="font-size: 24px ; ">-${cart?.note}</p>`
                        : ""
                    }
                        
                      ${
                        cart?.notefood_name
                          ? `<p class=""  style="width: 100% ; white-space: nowrap; font-size: 24px ">-${cart?.notefood_name}</p>`
                          : ""
                      }
                  </div>
    
                  `
                 )
                 .join(" ")}
               <div style="width: 100%;">
                    <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
                </div>
          
               <div style="display: table; width: 100%;  padding-right: 40px;">
            <div style = "display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
                <p style="font-size: 24px ; ">${languagePrinterUI.subTotal}:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
                  <p style="font-size: 24px ; ">$${
                    Number(listTotalBill?.sub_total)
                      ? FormatDolla(Number(listTotalBill?.sub_total))
                      : 0
                  }</p>
                </div>
              </div>
              </div>
               <div style="display: table; width: 100%">
    
              <div style = "display: table-row; padding-top: 0px; width: 100%" >
                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
                <p style="font-size: 24px ; ">${languagePrinterUI.tax}:</p>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
                <p style="font-size: 24px ; ">$${
                  Number(listTotalBill?.sub_total)
                    ? FormatDolla(
                        (Number(listTotalBill?.sub_total) *
                          Number(listTotalBill?.tax_percent)) /
                          100
                      )
                    : 0
                }</p>
              </div>
            </div>
            </div>
              <div style="display: table; width: 100%">
    
              <div style = "display: table-row; padding-top: 0px; width: 100%" >
                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
                <p style="font-size: 24px ; ">${languagePrinterUI.discount}:</p>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
                <p style="font-size: 24px ; ">$${FormatDolla(
                  Number(listTotalBill?.discount)
                )}</p>
                </div>
            </div>
            </div>
               <div style="display: table; width: 100%">
              <div style = "display: table-row; padding-top: 0px; width: 100%" >
                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
                <p style="font-size: 24px ; ">Tip:</p>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
                <p style="font-size: 24px ; ">$${FormatDolla(
                  Number(listTotalBill?.tip_amount)
                )}</p>
              </div>
            </div>
            </div>
            ` +
            (Number(listTotalBill?.feePrice) > 0
              ? `<div style="display: table; width: 100%">
    
                <div style="display: table-row; padding-top: 0px; width: 100%" >
                  <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
                    <p style="font-size: 24px ; ">${
                      languagePrinterUI.ConvenienceFee
                    }:</p>
                  </div>
                  <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                    <p style="font-size: 24px ; ">$${FormatDolla(
                      Number(listTotalBill?.feePrice)
                    )}</p>
                  </div>
                </div>
              </div>`
              : ``) +
            `
              <div style="display: table; width: 100%">
    
              <div style = "display: table-row; padding-top: 0px; width: 100%" >
                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
                <p style="font-size: 24px ; ">${languagePrinterUI.tipFor}:</p>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
                <p style="font-size: 24px ; ">${
                  listTotalBill?.staff_name ? listTotalBill?.staff_name : "---"
                }</p>
              </div>
            </div>
            </div>
              
             
               <div style="display: table; width: 100%">
    
            <div style = "display: table-row; padding-top: 0px; width: 100%" >
                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
                <h6>${languagePrinterUI.total}:</h6>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
                <h6>$${FormatDolla(Number(listTotalBill?.total_order))}</h6>
                </div>
            </div>
            </div>
            
                <div style="width: 100%; margin-top: 38px">
                <div style="width: 100%;">
                <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
            </div>
                  <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${nameStore}</h5>
                  <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${addressStore}</h5>
                  <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${phoneOwnerStore}</h5>
                </div>
                <h3 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">${
                  languagePrinterUI.thanks
                }!</h2>
              </div >
              </body >
              </html >
      `;
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            itempr.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
            // setShowToastError(true)
            // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
          }
          // }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <Modal show={show} onHide={handleClose} className="right-customer">
        <Modal.Dialog className="bg-gray-light">
          <Modal.Body>
            <div className="d-flex flex-column justify-content-between h-100vh pb-3">
              <div className="p-3 border-bottom position-relative">
                <div className="d-flex align-items-center gap-2">
                  {Number(tableId) > 0 && (
                    <div className="box-name-table-customer">{tableName}</div>
                  )}

                  <div className="d-flex align-items-center gap-2px">
                    <p className="mb-0 order-customer">Order</p>
                    <p className="mb-0 id-order-customer">#{orderId}</p>
                  </div>
                </div>
                <div className="mt-2 border-bottom">
                  <div className="d-flex align-items-end justify-content-between mb-1">
                    <p className="title-infor-bill-customer mb-0">
                      {languageUI.timeOrder}
                    </p>
                    <p className="content-infor-bill-customer mb-0">
                      {timeOrder}
                    </p>
                  </div>
                  <>
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <p className="title-infor-bill-customer mb-0">
                        {languageUI.customer}
                      </p>
                      <p className="content-infor-bill-customer mb-0">
                        {CustomerName ? CustomerName : "-----"}
                      </p>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mb-1">
                      <p className="title-infor-bill-customer mb-0">
                        {languageUI.phoneNumber}
                      </p>
                      <p className="content-infor-bill-customer mb-0">
                        {phone ? phone : "-----"}
                      </p>
                    </div>
                  </>
                </div>
                <div
                  className={`d-flex flex-column gap-2 custom-h-food-bill-order-owner  overflow-y-scroll scroll-hidden overflow-x-hidden`}
                >
                  {/* <OrderListITem orderInfo={listFood} status={true} /> */}
                  {listFood.map((item, i) => (
                    <div className="mt-2" key={i}>
                      <div className="justify-content-between d-flex align-items-start">
                        <div className="d-flex gap-3 w-80">
                          <div className="mb-0 title-pay-bill-customer ">{`${
                            i + 1
                          }.${item?.name}`}</div>
                          <div className="h-30px px-3 rounded-3 title-infor-bill-customer flex-shink-0 mb-0 text-white d-flex align-items-center bg-primary">
                            {Number(item?.form_order) === 1
                              ? languagePrint.diner
                              : languagePrint.takeAway}
                          </div>
                        </div>
                        <p className="mb-0 title-pay-bill-customer flex-shink-0">
                          x{item?.quantity}
                        </p>
                      </div>
                      <div className="d-flex gap-2 mt-2">
                        {item?.note && (
                          <div className="box-note-food-order-owner text-blueviolet h-fit">
                            {item?.note}
                          </div>
                        )}
                        {item?.notefood_name && (
                          <div className="box-note-food-order-owner text-blueviolet flex-shink-0 h-fit text-primary">
                            {item?.notefood_name}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {listSide.length > 0 && (
                    <>
                      <p className="title-pay-bill-customer mb-0">
                        <li>{languageUI.sideDish}</li>
                      </p>
                      {listSide.map((item, index) => (
                        <div className="ps-3">
                          <p className="text-side-dish mb-0 text-414151f">
                            {index + 1}. {item.name} (x{item.quantity})
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="">
                <div className="px-3 d-flex gap-3 align-items-center">
                  <div
                    className="btn-delete-bill-order"
                    onClick={() => setShowModalDeleteBillOrder(true)}
                  >
                    {languageUI.delete}
                  </div>
                  <div className="btn-print-bill-customer" onClick={PrintCard}>
                    {languageUI.print}
                  </div>
                </div>
              </div>
              <ModalOTPOwnerStaff
                show={showModalDeleteBillOrder}
                handleClose={dismisModalDeleteBillOrder}
                handleSuccess={handleDeleteBillSucces}
                UserIdStore={UserIdStore}
              />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalShowBillOrderOwner;
