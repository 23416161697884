import { useEffect, useState } from "react";
import { Row, Col, Form, Dropdown, Button } from "react-bootstrap";
import { FormInput } from "../../components";

// components
import PageTitle from "../../components/PageTitle";

import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";
import * as SettingInformationAPI from "../../api/apiOwner/SettingInformation";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

const EditSettingInformationOfCustomer = () => {
  const location = useLocation()
  const languageUI = getLanguageCheckIn();
  const [searchParams, setSearchParams] = useSearchParams();
  const [StoreName, setStoreName] = useState<any>();
  const [GoogleBusinessID, setGoogleBusinessID] = useState<any>("");
  const [ReviewMessage, setReviewMessage] = useState<any>("");
  const [TypeSendReviewMessage, setTypeSendReviewMessage] = useState<number>(0);
  const [TimeSendMessage, setTimeSendMessage] = useState<number>(0); // hour
  const [BirthdayMessage, setBirthdayMessage] = useState<any>("");
  const [TimeSendBirthdayMessage, setTimeSendBirthdayMessage] =
    useState<number>(0);
  const [PointMessage, setPointMessage] = useState<any>("");
  const [PointsCheckin, setPointsCheckin] = useState<number>(0);
  const [PercentPoint, setPercentPoint] = useState<number>(0);
  const [PointsReward, setPointsReward] = useState<number>(0);
  const [TimeSendMessageCoupon, setTimeSendMessageCoupon] = useState<number>(0);

  const [InputFirstName, setInputFirstName] = useState<boolean>(true);
  const [InputDayOfBirth, setInputDayOfBirth] = useState<boolean>(true);
  const [ShowCustomerPoint, setShowCustomerPoint] = useState<boolean>(true);

  const [UserName, setUserName] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);

  const GetInfomationOwner = async () => {
    if (searchParams.get('idcustomer')) {
      setShowLoading(true);
      const Infomation = await SettingInformationAPI.InformationOwner(
        Number(searchParams.get('idcustomer'))
      );
      if (Number(Infomation?.status) === 1 && Infomation?.data) {
        setStoreName(Infomation?.data?.name);
        setGoogleBusinessID(Infomation?.data?.googleBusinessID);
        setReviewMessage(Infomation?.data?.review_message);
        setTypeSendReviewMessage(
          Infomation?.data?.type_send_review_message
            ? Infomation?.data?.type_send_review_message
            : 0
        );
        setTimeSendMessage(
          Infomation?.data?.time_send_message
            ? Infomation?.data?.time_send_message
            : 0
        );
        setBirthdayMessage(Infomation?.data?.birthday_message);
        setTimeSendBirthdayMessage(
          Infomation?.data?.time_send_birthday_message
            ? Infomation?.data?.time_send_birthday_message
            : 0
        );
        setPointMessage(Infomation?.data?.point_message);
        setPointsCheckin(
          Infomation?.data?.points_checkin
            ? Infomation?.data?.points_checkin
            : 0
        );
        setPercentPoint(
          Infomation?.data?.percent_point ? Infomation?.data?.percent_point : 0
        );
        setPointsReward(
          Infomation?.data?.points_reward ? Infomation?.data?.points_reward : 0
        );
        setTimeSendMessageCoupon(
          Infomation?.data?.time_send_message_coupon
            ? Infomation?.data?.time_send_message_coupon
            : 0
        );
        if (Number(Infomation?.data?.input_first_name) === 0) {
          setInputFirstName(true);
        } else {
          setInputFirstName(false);
        }

        if (Number(Infomation?.data?.input_day_of_birth) === 0) {
          setInputDayOfBirth(true);
        } else {
          setInputDayOfBirth(false);
        }

        if (Number(Infomation?.data?.show_customer_point) === 0) {
          setShowCustomerPoint(true);
        } else {
          setShowCustomerPoint(false);
        }

        setUserName(Infomation?.data?.email);
        setMessage("");
      } else {
        setStoreName("");
        setGoogleBusinessID("");
        setReviewMessage("");
        setTypeSendReviewMessage(0);
        setTimeSendMessage(0);
        setBirthdayMessage("");
        setTimeSendBirthdayMessage(0);
        setPointMessage("");
        setPointsCheckin(0);
        setPercentPoint(0);
        setPointsReward(0);
        setTimeSendMessageCoupon(0);
        setInputFirstName(true);
        setInputDayOfBirth(true);
        setShowCustomerPoint(true);
        setUserName("");
        setMessage("");
      }
      setShowLoading(false);
    }
  };

  const changeSettingInfomation = async () => {
    // if (searchParams.get('idcustomer') ) {
    //   setShowLoading(true);
    //   const ChangeInformationOwner =
    //     await SettingInformationAPI.ChangeInformationOwner(
    //       Number(searchParams.get('idcustomer') ),
    //       StoreName,
    //       GoogleBusinessID,
    //       ReviewMessage,
    //       TypeSendReviewMessage,
    //       TimeSendMessage,
    //       BirthdayMessage,
    //       TimeSendBirthdayMessage,
    //       PointMessage,
    //       PointsCheckin,
    //       PercentPoint,
    //       PointsReward,
    //       TimeSendMessageCoupon,
    //       InputFirstName,
    //       InputDayOfBirth,
    //       ShowCustomerPoint
    //     );
    //   if (ChangeInformationOwner?.status == 1) {
    //     setMessage("");
    //     // Swal.fire({
    //     //   position: "center",
    //     //   icon: "success",
    //     //   title: "Create success",
    //     //   showConfirmButton: false,
    //     //   timer: 1500,
    //     // });
    //     navigate("/web-admin/group-customer-admin");
    //     // console.log("Đổi mật khẩu thành công");
    //   } else {
    //     setMessage(
    //       ChangeInformationOwner?.message
    //         ? ChangeInformationOwner?.message
    //         : "Something went wrong. Please try again later!"
    //     );
    //   }
    //   setShowLoading(false);
    // } else {
    //   console.log("Chưa đăng nhập");
    //   navigate("/web-admin/login");
    // }
  };

  useEffect(() => {
    if (searchParams.get('idcustomer')) {
      setShowLoading(false);
      GetInfomationOwner();
    }
  }, [location.pathname, searchParams.get('idcustomer')]);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Setting", path: "#" },
          { label: "Information", path: "#", active: true },
        ]}
        title={"Setting information"}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="py-3 px-2 rounded-3 mb-2 bg-white shadow">
        <div className="d-flex align-items-center flex-wrap mb-2">
          <h4 className="header-title me-1 mb-0 mt-n2 flex-shrink-0">
            {languageUI.Storename}:
          </h4>
          <Col xs={12} sm={12} md={6} lg={3}>
            <FormInput
              label=""
              type="text"
              name="text"
              containerClass={"mb-2"}
              value={StoreName}
              placeholder={languageUI.Storename}
              key="text"
              onChange={(e: any) => {
                setStoreName(e.target.value);
              }}
            />
          </Col>
        </div>
        <div className="d-flex align-items-center flex-wrap mt-2 mb-2">
          <h4 className="header-title me-1 mb-0 mt-n2 flex-shrink-0">
            Google Business ID:
          </h4>
          <Col xs={12} sm={12} md={6} lg={7}>
            <FormInput
              label=""
              type="text"
              name="text"
              containerClass={"mb-2"}
              value={GoogleBusinessID}
              placeholder="Enter Google ID"
              key="text"
              onChange={(e: any) => {
                setGoogleBusinessID(e.target.value);
              }}
            />
          </Col>
        </div>
        {/* <Row>
                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div>
                            <FormInput
                                label="Brand name:"
                                type="text"
                                name="text"
                                containerClass={'mb-2'}
                                placeholder="Enter your Brand name"
                                key="text"
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div>
                            <FormInput
                                label="Google ID:"
                                type="text"
                                name="text"
                                containerClass={'mb-2'}
                                placeholder="Enter your Google ID"
                                key="text"
                            />
                        </div>
                    </Col>
                </Row> */}
        <Row className="mx-n1">
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className="px-1">
            <div>
              <h4 className="header-title fs-5">{languageUI.Askforreviewmessage}:</h4>
              <div className="position-relative">
                <FormInput
                  label=""
                  type="textarea"
                  name="textarea"
                  className="resize-none p-2 fs-6"
                  rows="5"
                  placeholder=" Hi {{Sir or Madam or Friend}} (in Check-in)
                                This is......................
                                Thank you for visit us today!
                                Can you give us a review. Under 5 is our fail."
                  containerClass={"mb-2"}
                  key="textarea"
                  value={ReviewMessage}
                  maxLength={260}
                  onChange={(e: any) => {
                    setReviewMessage(e?.target?.value);
                  }}
                />
                <small
                  className="count-text position-absolute"
                  style={{ bottom: 5, right: 10 }}
                >
                  {ReviewMessage?.length}/260
                </small>
              </div>
              <p className="text-capitalize mb-2 fs-6">
                Time send message ask for review
              </p>
              <Row className="mx-n1 mb-2">
                <Col xs={12} sm={12} md={6} lg={3} className="px-1">
                  <Form.Check
                    type="radio"
                    id={`ask-review-1`}
                    label={`No send`}
                    className={`ps-0 d-flex fs-6`}
                    name="time-send-ask-review"
                    checked={TypeSendReviewMessage === 0 ? true : false}
                    onClick={(e: any) => {
                      setTypeSendReviewMessage(0);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} className="px-1">
                  <Form.Check
                    type="radio"
                    id={`ask-review-2`}
                    label={`Right away`}
                    className={`ps-0 d-flex fs-6`}
                    name="time-send-ask-review"
                    checked={TypeSendReviewMessage === 1 ? true : false}
                    onClick={(e: any) => {
                      setTypeSendReviewMessage(1);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} className="px-1">
                  <Form.Check
                    type="radio"
                    id={`ask-review-3`}
                    label={`Custom`}
                    className={`ps-0 d-flex fs-6`}
                    name="time-send-ask-review"
                    checked={TypeSendReviewMessage === 2 ? true : false}
                    onClick={(e: any) => {
                      setTypeSendReviewMessage(2);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} className="px-1">
                  <div className="d-flex">
                    <input
                      typeof="number"
                      className="form-control py-0 px-1 text-center fs-6"
                      style={{ maxHeight: 21 }}
                      value={TimeSendMessage}
                      onChange={(e: any) => {
                        setTimeSendMessage(e.target.value);
                      }}
                    />
                    <label className="form-check-label ms-1">Hour</label>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="px-1 border-start border-end setting-birthday"
          >
            <div>
              <h4 className="header-title fs-5">Birthday message:</h4>
              <div className="position-relative">
                <FormInput
                  label=""
                  type="textarea"
                  name="textarea"
                  className="resize-none p-2 fs-6"
                  rows="5"
                  placeholder=" Hi {{Sir or Madam or Friend}} (in Check-in)
                                This is......................
                                We want to wish you a good birthday and have a small present
                        for your next visit, with 10% discount. Enjoy!"
                  containerClass={"mb-2"}
                  key="textarea"
                  value={BirthdayMessage}
                  maxLength={260}
                  onChange={(e: any) => {
                    setBirthdayMessage(e?.target?.value);
                  }}
                />
                <small
                  className="count-text position-absolute"
                  style={{ bottom: 5, right: 10 }}
                >
                  {BirthdayMessage?.length}/260
                </small>
              </div>
              <div>
                <Row className="mx-n1">
                  <Col xs={12} sm={12} md={12} lg={12} className="px-1">
                    <div>
                      <p className="mb-2 fs-6">Time Send Birthday message</p>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} className="px-1">
                    <Row className="mx-n1">
                      <Col xs={12} sm={12} md={5} lg={5} className="px-1">
                        <Form.Check
                          type="radio"
                          id={`birthday-message-1`}
                          label={`No send`}
                          className={`ps-0`}
                          name="time-send-birthday-message"
                          checked={TimeSendBirthdayMessage === 0 ? true : false}
                          onClick={(e: any) => {
                            setTimeSendBirthdayMessage(0);
                          }}
                        />
                      </Col>
                      <Col xs={12} sm={12} md={7} lg={7} className="px-1">
                        <Form.Check
                          type="radio"
                          id={`birthday-message-2`}
                          label={`On Birthday day`}
                          className={`ps-0`}
                          name="time-send-birthday-message"
                          checked={TimeSendBirthdayMessage === 1 ? true : false}
                          onClick={(e: any) => {
                            setTimeSendBirthdayMessage(1);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="px-1 mt-2 mt-sm-2 mt-md-0 mt-lg-0"
          >
            <div>
              <h4 className="header-title fs-5">
                Coupon for read certain point message:
              </h4>
              <div className="position-relative">
                <FormInput
                  label=""
                  type="textarea"
                  name="textarea"
                  className="resize-none p-2 fs-6"
                  rows="5"
                  placeholder=" Hi {{Sir or Madam or Friend}} (in Check-in)
                                This is......................
                                Thank you for being our loyal customer!
                                    we happy to give you 10% off for you next visit."
                  containerClass={"mb-2"}
                  key="textarea"
                  value={PointMessage}
                  maxLength={260}
                  onChange={(e: any) => {
                    setPointMessage(e.target.value);
                  }}
                />
                <small
                  className="count-text position-absolute"
                  style={{ bottom: 5, right: 10 }}
                >
                  {PointMessage?.length}/260
                </small>
              </div>
              <div className="text-start mt-2 d-flex align-items-center justify-content-start flex-wrap rounded-3 bg-light py-2 px-2 fs-6">
                Reward
                <input
                  // typeof='number'
                  className="form-control py-0 px-1 text-center mx-1 fs-6"
                  style={{ minHeight: 21, maxWidth: 35 }}
                  value={PointsCheckin}
                  onChange={(e: any) => {
                    setPointsCheckin(e.target.value);
                  }}
                />
                points every check-in time
              </div>
              <div className="text-start mt-2 d-flex align-items-center justify-content-start flex-wrap bg-light py-2 px-2 fs-6">
                Give customer
                <input
                  // typeof='number'
                  className="form-control py-0 text-center px-1 mx-1 fs-6"
                  style={{ minHeight: 21, maxWidth: 35 }}
                  value={PercentPoint}
                  onChange={(e: any) => {
                    setPercentPoint(e.target.value);
                  }}
                />
                % off ever
                <input
                  typeof="number"
                  className="form-control py-0 px-1 text-center mx-1 fs-6"
                  style={{ minHeight: 21, maxWidth: 35 }}
                  value={PointsReward}
                  onChange={(e: any) => {
                    setPointsReward(e.target.value);
                  }}
                />
                points
              </div>
              <div>
                <p className="text-capitalize mt-2 fs-6">
                  Time Send Message for Coupon for read certain point message
                </p>
              </div>
              <Col xs={12} sm={12} md={12} lg={12} className="px-1">
                <Row className="mx-n1">
                  <Col xs={12} sm={12} md={12} lg={12} className="px-1">
                    <Form.Check
                      type="radio"
                      id={`reward-message-1`}
                      label={`No send`}
                      className={`ps-0`}
                      name="time-send-reward-message"
                      checked={TimeSendMessageCoupon === 0 ? true : false}
                      onClick={(e: any) => {
                        setTimeSendMessageCoupon(0);
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} className="px-1">
                    <Form.Check
                      type="radio"
                      id={`reward-message-2`}
                      label={`Send our this coupon when they reach`}
                      className={`ps-0`}
                      name="time-send-reward-message"
                      checked={TimeSendMessageCoupon === 1 ? true : false}
                      onClick={(e: any) => {
                        setTimeSendMessageCoupon(1);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </div>
          </Col>
        </Row>
        <Dropdown.Divider />
        <Form.Check
          type="switch"
          id="rating-allow-customer"
          className="ps-0 mb-1 fw-bold d-flex fs-6"
          label="Allow customer input FIRST NAME when check-in"
          checked={InputFirstName}
          // checked={InputFirstName == 0 ? true : false}
          onChange={(e: any) => {
            setInputFirstName(!InputFirstName);
          }}
        />
        <Form.Check
          type="switch"
          id="rating-allow-day-of-brith"
          className="ps-0 mb-1 fw-bold d-flex fs-6"
          label="Allow customer input DAY OF BIRTH when check-in"
          checked={InputDayOfBirth}
          // checked={InputDayOfBirth == 0 ? true : false}
          onChange={(e: any) => {
            setInputDayOfBirth(!InputDayOfBirth);
          }}
        />
        <Form.Check
          type="switch"
          id="rating-allow-customer-point"
          className="ps-0 mb-1 fw-bold d-flex fs-6"
          label="Show customer point when check-in"
          checked={ShowCustomerPoint}
          // checked={ShowCustomerPoint == 0 ? true : false}
          onChange={(e: any) => {
            setShowCustomerPoint(!ShowCustomerPoint);
          }}
        />
        <div className="text-end mt-3">
          <Button
            variant="primary"
            onClick={() => {
              changeSettingInfomation();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditSettingInformationOfCustomer;
