import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Styles.css";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import {
  AccountInforAdmin,
  AccountStaff,
  ClockIconMenu,
  CustomerAdmin,
  CustomerIconMenu,
  FeedbackIcon,
  InventoryIconMenu,
  KitChenCounter,
  MenuPOS,
  QRCodeIcon,
  ReceipTax,
  ReportIconMenu,
  SettingAdmin,
  SettingIconClock,
  StaffMenuIcon,
  SubcriptionAdmin,
  SubscriptionIconMenu,
  TablePOS,
  VoucherIconMenu,
  reviewMenuOwner,
} from "../../components/SVGChangeColor/SVGChangeColor";
import ModalOTPOwnerStaff from "../../pages/webowner/components/ModalOTPOwnerStaff/ModalOTPOwnerStaff";
import React from "react";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import ModalOTPLogOut from "../../pages/webowner/components/ModalOTPLogOut/ModalOTPLogOut";
// import reviewMenuOwner from '../../components/SVGChangeColor/SVGChangeColor';

const LeftMenuAdmin = () => {
  const languageUI = getLanguageCheckIn();
  const navigate = useNavigate();
  // const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const [statusTable, setStatusTable] = useState(true);
  const is_ownerMenu = localStorage.getItem("is_ownerMenu");
  const [showModalOTPOwner, setShowModalOTPOwner] = useState(false);
  const [showModalLogOut, setShowModalLogOut] = useState(false);

  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [optionAccountStaff, setOptionAccountStaff] = useState(false);
  const [optionSetting, setOptionSetting] = useState(false);
  const dismisModalOTPOwner = () => {
    setShowModalOTPOwner(false);
  };
  const dismisModalLogOut = () => {
    setShowModalLogOut(false);
  };

  const HandleOtpOwner = () => {
    setStatusTable(!statusTable);
    localStorage.setItem("is_ownerMenu", "1");
  };

  useEffect(() => {
    if (is_ownerMenu && is_ownerMenu === "1") {
      setStatusTable(false);
    } else {
      setStatusTable(true);
    }
  }, []);
  const sidebarNavStaffItems = [
    {
      display: "QRCode",
      icon: QRCodeIcon,
      to: "/web-owner/qrcode",
      section: ["/web-owner/qrcode"],
    },
    {
      display: "Table POS",
      icon: TablePOS,
      to: "/web-owner/tablepos",
      section: ["/web-owner/tablepos"],
    },
    {
      display: "Menu POS",
      icon: MenuPOS,
      to: "/web-owner/posmenu",
      section: ["/web-owner/posmenu"],
    },

    {
      display: " Clock in/ Clock out",
      icon: ClockIconMenu,
      to: "/web-owner/clockinclockout",
      section: ["/web-owner/clockinclockout"],
    },
  ];
  const sidebarNavOwnerItems = [
    {
      display: "Settings",
      icon: SettingIconClock,
      to: "/web-owner/settingownerinformation",
      section: ["/web-owner/settingownerinformation"],
    },
    {
      display: "Set up table",
      icon: TablePOS,
      to: "/web-owner/setuptable",
      section: ["/web-owner/setuptable"],
    },
    {
      display: "Menu template",
      icon: MenuPOS,
      to: "/web-owner/menutemplate",
      section: ["/web-owner/menutemplate"],
    },
    {
      display: "Customer",
      icon: CustomerIconMenu,
      to: "/web-owner/customer",
      section: ["/web-owner/customer"],
    },
    {
      display: "Customer Reciepts",
      icon: ReceipTax,
      to: "/web-owner/customerreciepts",
      section: ["/web-owner/customerreciepts"],
    },
    {
      display: "Report",
      icon: ReportIconMenu,
      to: "/web-owner/report",
      section: [
        "/web-owner/report",
        "/web-owner/report/incomedetail",
        "/web-owner/report/incomedatedetail",
      ],
    },
    {
      display: "Review",
      icon: reviewMenuOwner,
      to: "/web-owner/review",
      section: ["/web-owner/review", "/web-owner/detailreview"],
    },
    {
      display: "Inventory",
      icon: InventoryIconMenu,
      to: "/web-owner/inventory",
      section: ["/web-owner/inventory"],
    },
    {
      display: "Staffs",
      icon: StaffMenuIcon,
      to: "/web-owner/staffs",
      section: [
        "/web-owner/staffs",
        "/web-owner/creatstaffs",
        "/web-owner/editstaffs",
      ],
    },
    {
      display: "Set up Voucher",
      icon: VoucherIconMenu,
      to: "/web-owner/setupvoucher",
      section: ["/web-owner/setupvoucher"],
    },
    {
      display: "Subscription",
      icon: SubscriptionIconMenu,
      to: "/web-owner/subscription",
      section: ["/web-owner/subscription"],
    },
    {
      display: "Feedback",
      icon: FeedbackIcon,
      to: "/web-owner/feedback",
      section: ["/web-owner/feedback"],
    },
    {
      display: "Kitchen & Counter",
      icon: KitChenCounter,
      to: "/kitchencounter",
      section: ["/kitchencounter"],
    },
  ];
  const Logout = () => {
    // dispatchUser(userSlice.clearUser());
    navigate("/auth/logout");
    // https://brijackrestaurants.com/auth/logout
  };
  const curPath = window.location.pathname;
  // change active index
  useEffect(() => {
    const curPath = window.location.pathname;

    if (
      sidebarNavOwnerItems.some((item) =>
        item?.section.some((child) => child === curPath)
      )
    ) {
      if (is_ownerMenu && is_ownerMenu !== "1") {
        navigate("/");
      }
    }

    // setActiveIndex(curPath.length === 0 ? 0 : activeItem2);

    // console.log(window.location.pathname.split('/'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <p className="mb-0 name-brand-store w-fit">ICE MAGIC</p>
        <p className="mb-0 pos-brand-store w-fit">POS SYSTEM</p>
      </div>
      <div className="h-menu-admin">
        <div
          className={`sidebar__menu__item ${curPath === "/web-admin" ? "active" : ""
            }`}
          onClick={() => navigate("/web-admin")}
        >
          <div
            className={`sidebar__menu__item__icon p-1 ${curPath === "/web-admin" ? "active" : ""
              }`}
          >
            <AccountStaff
              fill={curPath === "/web-admin" ? "#FF7B2C" : "white"}
            />
          </div>
          <div className="sidebar__menu__item__text">
            {languageUI.Accountstaff}
          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <div
            className={`sidebar__menu__item ${curPath === "/web-admin/customer-overview-admin" ||
                curPath === "/web-admin/group-customer-admin" ||
                curPath === "/web-admin/create-customer-admin" ||
                curPath.search("/web-admin/edit-setting-customer-infor/") > -1 ||
                curPath.search("/web-admin/qrcode/") > -1 ||
                curPath === "/web-admin/device-customer" ||
                curPath === "/web-admin/customer-device-detail" ||
                curPath === "/web-admin/infor-device-order" ||
                curPath === "/web-admin/feedback"
                ? "active"
                : ""
              }`}
            onClick={() => setOptionAccountStaff(!optionAccountStaff)}
          >
            <div
              className={`sidebar__menu__item__icon p-1 ${curPath === "/web-admin/customer-overview-admin" ||
                  curPath === "/web-admin/group-customer-admin" ||
                  curPath === "/web-admin/create-customer-admin" ||
                  curPath.search("/web-admin/edit-setting-customer-infor/") >
                  -1 ||
                  curPath.search("/web-admin/qrcode/") > -1 ||
                  curPath === "/web-admin/device-customer" ||
                  curPath === "/web-admin/customer-device-detail" ||
                  curPath === "/web-admin/infor-device-order" ||
                  curPath === "/web-admin/feedback"
                  ? "active"
                  : ""
                }`}
            >
              <CustomerAdmin
                fill={
                  curPath === "/web-admin/customer-overview-admin" ||
                    curPath === "/web-admin/group-customer-admin" ||
                    curPath === "/web-admin/create-customer-admin" ||
                    curPath.search("/web-admin/edit-setting-customer-infor/") >
                    -1 ||
                    curPath.search("/web-admin/qrcode/") > -1 ||
                    curPath === "/web-admin/device-customer" ||
                    curPath === "/web-admin/customer-device-detail" ||
                    curPath === "/web-admin/infor-device-order" ||
                    curPath === "/web-admin/feedback"
                    ? "#FF7B2C"
                    : "white"
                }
              />
            </div>
            <div className="sidebar__menu__item__text">
              {languageUI.Customer}
            </div>
          </div>
          <div
            className={`d-flex flex-column gap-1 ps-2 ${optionAccountStaff === true ? "" : "d-none"
              }`}
          >
            <div
              className={`sidebar__menu__item ${curPath === "/web-admin/customer-overview-admin" ? "active" : ""
                }`}
              onClick={() => navigate("/web-admin/customer-overview-admin")}
            >
              <div className="sidebar__menu__item__text">
                {languageUI.Overview}
              </div>
            </div>
            <div
              className={`sidebar__menu__item ${curPath === "/web-admin/group-customer-admin" ||
                  curPath.search("/web-admin/edit-setting-customer-infor/") >
                  -1 ||
                  curPath.search("/web-admin/qrcode/") > -1
                  ? "active"
                  : ""
                }`}
              onClick={() => navigate("/web-admin/group-customer-admin")}
            >
              <div className="sidebar__menu__item__text">
                {languageUI.Groupcustomer}
              </div>
            </div>
            <div
              className={`sidebar__menu__item ${curPath === "/web-admin/create-customer-admin" ? "active" : ""
                }`}
              onClick={() => navigate("/web-admin/create-customer-admin")}
            >
              <div className="sidebar__menu__item__text">
                {languageUI.Createcustomer}
              </div>
            </div>
            <div
              className={`sidebar__menu__item ${curPath === "/web-admin/device-customer" ||
                  curPath === "/web-admin/customer-device-detail" ||
                  curPath === "/web-admin/infor-device-order"
                  ? "active"
                  : ""
                }`}
              onClick={() => navigate("/web-admin/device-customer")}
            >
              <div className="sidebar__menu__item__text">
                {languageUI.Devicecustomer}
              </div>
            </div>
            <div
              className={`sidebar__menu__item ${curPath === "/web-admin/feedback" ? "active" : ""
                }`}
              onClick={() => navigate("/web-admin/feedback")}
            >
              <div className="sidebar__menu__item__text">Feedback</div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <div
            className={`sidebar__menu__item ${curPath === "/web-admin/setting-change-password" ||
                curPath === "/web-admin/setting-update-phone-number" ||
                curPath === "/web-admin/setting-config"
                ? "active"
                : ""
              }`}
            onClick={() => setOptionSetting(!optionSetting)}
          >
            <div
              className={`sidebar__menu__item__icon p-1 ${curPath === "/web-admin/setting-update-phone-number" ||
                  curPath === "/web-admin/setting-change-password" ||
                  curPath === "/web-admin/setting-config"
                  ? "active"
                  : ""
                }`}
            >
              <SettingAdmin
                fill={
                  curPath === "/web-admin/setting-update-phone-number" ||
                    curPath === "/web-admin/setting-change-password" ||
                    curPath === "/web-admin/setting-config"
                    ? "#FF7B2C"
                    : "white"
                }
              />
            </div>
            <div className="sidebar__menu__item__text">
              {languageUI.Setting}
            </div>
          </div>
          <div
            className={`d-flex flex-column gap-1 ps-2 ${optionSetting == true ? "" : "d-none"
              }`}
          >
            <div
              className={`sidebar__menu__item ${curPath === "/web-admin/setting-change-password" ? "active" : ""
                }`}
              onClick={() => navigate("/web-admin/setting-change-password")}
            >
              <div className="sidebar__menu__item__text">
                {languageUI.Changepassword}
              </div>
            </div>
            <div
              className={`sidebar__menu__item ${curPath === "/web-admin/setting-update-phone-number"
                  ? "active"
                  : ""
                }`}
              onClick={() => navigate("/web-admin/setting-update-phone-number")}
            >
              <div className="sidebar__menu__item__text">
                {languageUI.Updatephonenumber}
              </div>
            </div>
            <div
              className={`sidebar__menu__item ${curPath === "/web-admin/setting-config" ? "active" : ""
                }`}
              onClick={() => navigate("/web-admin/setting-config")}
            >
              <div className="sidebar__menu__item__text">
                {languageUI.confirm}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`sidebar__menu__item ${curPath === "/web-admin/subscription-admin" ? "active" : ""
            }`}
          onClick={() => navigate("/web-admin/subscription-admin")}
        >
          <div
            className={`sidebar__menu__item__icon p-1 ${curPath === "/web-admin/subscription-admin" ? "active" : ""
              }`}
          >
            <SubcriptionAdmin
              fill={
                curPath === "/web-admin/subscription-admin"
                  ? "#FF7B2C"
                  : "white"
              }
            />
          </div>
          <div className="sidebar__menu__item__text">
            {languageUI.Subscription}
          </div>
        </div>
        <div
          className={`sidebar__menu__item ${curPath === "/web-admin/acccount-infor" ? "active" : ""
            }`}
          onClick={() => navigate("/web-admin/acccount-infor")}
        >
          <div
            className={`sidebar__menu__item__icon p-1 ${curPath === "/web-admin/acccount-infor" ? "active" : ""
              }`}
          >
            <AccountInforAdmin
              fill={
                curPath === "/web-admin/acccount-infor" ? "#FF7B2C" : "white"
              }
            />
          </div>
          <div className="sidebar__menu__item__text">
            {languageUI.Accountinfo}
          </div>
        </div>
      </div>
      <div
        className="d-flex align-items-center ps-3 gap-2 custom-fixed-logout py-2 w-100 bg-0FA54A"
        onClick={() => Logout()}
      >
        <div className="w-40px rounded-12px bg-3460cb h-40px d-flex justify-content-center align-items-center">
          <LogoutIcon className="h-24px w-24px text-white" />
        </div>
        <p className="mb-0 sidebar__menu__item__text">Log out</p>
      </div>
      <ModalOTPOwnerStaff
        handleClose={dismisModalOTPOwner}
        show={showModalOTPOwner}
        UserIdStore={UserIdStore}
        handleSuccess={HandleOtpOwner}
      />
    </div>
  );
};

export default LeftMenuAdmin;
