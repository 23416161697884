import React, { useEffect, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useDraggable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import "./Styles.css";

function Draggable(props: {
  id: string;
  tY: number;
  tX: number;
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {
  const [tYNew, setTYNew] = useState<number>(0);
  const [tXNew, setTXNew] = useState<number>(0);
  const [tYNew2, setTYNew2] = useState<number>();
  const [tXNew2, setTXNew2] = useState<number>();

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  // const style = transform ? {
  //   transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,

  // } : undefined;

  useEffect(() => {
    setTXNew(props.tX);
    setTYNew(props.tY);
  }, [props.tX, props.tY]);
  useEffect(() => {
    setTXNew2(transform?.x);
    setTYNew2(transform?.y);
  }, [transform?.x, transform?.y]);
  return (
    <button
      className="touch-action"
      ref={setNodeRef}
      style={{
        transform: `translate3d(${tXNew2 ? tXNew + tXNew2 : tXNew}px, ${
          tYNew2 ? tYNew + tYNew2 : tYNew
        }px, 0)`,
      }}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </button>
  );
}
interface DraggableTableProps {
  id: string;
  tranform: {
    tranformX: number;
    tranformY: number;
  };
}
const App = () => {
  const [draggEndX, setDraggEndX] = useState<DraggableTableProps[]>([
    {
      id: "1",
      tranform: {
        tranformX: 0,
        tranformY: 0,
      },
    },
    {
      id: "2",
      tranform: {
        tranformX: 0,
        tranformY: 0,
      },
    },
  ]);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 50,
      },
    })
  );
  function handleDragEnd(event: DragEndEvent) {
    const { active, delta } = event;
    console.log(event);
    const newDragg = draggEndX.map((item) => {
      return item.id === active.id
        ? {
            ...item,
            tranform: {
              tranformX: delta.x + item.tranform.tranformX,
              tranformY: delta.y + item.tranform.tranformY,
            },
          }
        : item;
    });
    setDraggEndX(newDragg);
    // if (over && active.data.current.supports.includes(over.data.current.type)) {
    //   // do stuff
    // }
  }
  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      {draggEndX.map((item, i) => (
        <Draggable
          children="click me"
          id={item.id}
          key={i}
          tX={item.tranform.tranformX}
          tY={item.tranform.tranformY}
        />
      ))}
    </DndContext>
  );
};

export default App;
