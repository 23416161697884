import * as languageUser from "./languageUser";
export var Language = {
  EN: {
    LG: 1,
    viewOurMenu: "View our menu",
    pleaseEnterPhone: "Please enter your phone number!",
    phoneNumberFormatConsistsOf10Numbers:
      "Phone number format consists of 10 numbers. Please enter again!",
    checkOurTermsPlease: "Check our terms please!",
    go: "Go",

    yourPoints: "Your points",
    ForEveryDoolarYouSpend:
      "For every dollar you spend, you will earn an equivalent amount of point",

    exploreUniqueFlavors: "Explore Unique Flavors",
    atOurRestaurant: "at Our Restaurant",
    Whatareyoulookingfor: "What are you looking for?",
    Search: "Search",
    Back: "Back",
    Powerby: "Power by",
    Revieworder: "Review order",
    Nodata: "No data",
    Addedyourchoice: "Added your choice",
    OrdersServed: "Orders Served",
    Ownerisnotfound: "Owner is not found!",
    Tableisnotfound: "Table is not found!",
    Foodisnotfound: "Food is not found!",
    Custommerisnotfound: "Custommer is not found!",
    Pleasechoosenoteforthisfood: "Please choose note for this food!",
    Chooseasidedishes: "Choose a side dishes",
    Note: "Note",
    NoteFood: "Note Food",
    Morerequest: "More request?",
    Addtocart: "Add to cart",
    HistoryOrder: "History Order",
    TimeOrder: "Time Order",
    TotalItems: "Total Items",
    ItemsTotal: "Items Total",
    Invoicenumber: "Invoice number",
    Printdatetime: "Print date - time",
    Customer: "Customer",
    Dinein: "Dine in",
    Takeaway: "Take away",
    Discount: "Discount",
    Conveniencefee: "Convenience fee",
    TheconveniencefeeischargedpertransactionbyathirdpartyvendorWedonotretainanyportionofthisfee:
      "The convenience fee is charged per transaction by a third-party vendor. We do not retain any portion of this fee",
    Total: "Total",

    Review: "Review",
    yourorder: "your order",
    Ordermore: "Order more",
    InvoiceDetails: "Invoice details",
    ChooseFreeSideHere: "Choose Free Side Here",
    SideDish: "Side Dish",
    Youlikesomemoredrink: "You like some more drink",
    Youwanttoaddsomethingelse: "You want to add  something else",
    Confirmordersend: "Confirm order & send it to the kitchen",
    Pleasechooseadish: "Please choose a dish!",
    confirm: "Confirm",
    ListSideDish: "List Side Dish",
    Pleasechooseapaymentmethod: "Please choose a payment method!",
    Pleaseselectstafftoreceivetips: "Please select staff to receive tips!",
    Tippingmustbegreaterthan: "Tipping must be greater than 0!",
    YouhavediscountcodeApplynow: "You have discount code? Apply now!",
    Apply: "Apply",
    Addtip: "Add tip",
    CustomTipAmount: "Custom Tip Amount",
    Employeesaretipped: "Employees are tipped",
    Employeewanttotip: "Employee want to tip",
    PaymentMethod: "Payment method",
    Yourpaymentmethod: "Your payment method",
    Orderlistandprices: "Order list and prices",
    Paynow: "Pay now",
    PleaseEnterTip: "Please Enter Tip!",
    Entertip: "Enter tip",
    Close: "Close",
    SaveChanges: "Save Changes",
    Forcashpaymentskindly: "For cash payments, kindly",
    proceedtothecounter: "proceed to the counter.",
    Needhelp: "Need help?",
    Callstaff: "Call staff",
    Wewillnotifyyourwaiteranddontforgetyouarealwayswelcometopayatthecounter:
      "We will notify your waiter and don't forget you are always welcome to pay at the counter",
    Gotit: "Got it",
    HiwegotyourequestedOneofourteammemberwillbewithyourshortly:
      "Hi, we got you requested. One of our team member will be with your shortly",
    Selectemployeewanttotip: "Select employee want to tip",
    WejuswanttomakesuretheTipgoestotherightperson:
      "We jus want to make sure the Tip goes to the right person",
    Select: "Select",
    AddVisacardinformation: "Add Visa card information",
    PayWith: "Pay with",
    Thanksforyourpayment: "Thanks for your payment!",
    Yourpaymentwasnotsuccessful: "Your payment was not successful!",
    PaymentSuccess: "Payment Success",
    PaymentFailed: "Payment Failed",
    Youmightnowclosethiswindow: "You might now close this window.",
    Ifyouwishtoorderinthefuture: "If you wish to order in the future",
    PleaserescantheQRcode: "Please re-scan the QR code",
    OrvisitourGoogleMapspagetoaccesstheorderinglink:
      "Or visit our Google Maps page to access the ordering link.",
    Thisensuresyourorderis: "This ensures your order is",
    processedcorrectly: "processed correctly.",
    Thankyourforvisitustoday: "Thank your for visit us today",
    Welookforwardtoservingyouinthefuture:
      "We look forward to serving you in the future",
    Mybill: "My bill",
    Changemethod: "Change method",
    Home: "Home",
    Yourorderwillbereadyin: "Your order will be ready in",
    Westarttoprocessingyourordernow: "We start to processing your order now!",
    Tablenumber: "Table number",
    Download: "Download",
    Rewards: "Rewards",
    Score: "Score",
    Time: "Time",
    Startgame: "Start game",
    Exit: "Exit",
    Endgame: "End game",
    Playagain: "Play again",
    Start: "Start",
    BestScore: "Best Score",
    Again: "Again",
    point: "point",
    Remainingbalance: "Remaining balance",
    Change: "Change",
    Fullname: "Full name",
    EnterFullName: "Enter Full Name",
    Pleaseenteryourfullname: "Please enter your full name",
    Onceyouaccumulate: "Once you accumulate",
    pointswewill: "points, we will",
    provideyouwithadiscountof: "provide you with a discount of",
    off: "off",
    Pleaseenteryourbirthday: "Please enter your birthday!",
    Yourbirthday: "Your birthday",
    Birthday: "Birth day",
    Dohaveyouaccount: "Do have you  account?",
    Phonenumber: "Phone number",
    Iacceptthe: "I accept the",
    termsofuse: "terms of use",
    and: "and",
    privacypolicy: "privacy policy",
    Chooseyourordermethod: "Choose your order method",
    Reservation: "Reservation",
    Pickup: "Pick up",
    Delivery: "Delivery",

    Today: "To day",
    Tomorow: "Tomorow",
    Pleasechooseday: "Please choose day!",
    Clockinmalformed: "Clock in malformed",
    Pleaseenterseatsnumber: "Please enter seats number!",
    Customerinformationnotfoundpleaseloginagain:
      "Customer information not found, please log in again!",
    Youhaveanewtablereservation: "You have a new table reservation",
    TablebookingfailedPleasetryagainlater:
      "Table booking failed. Please try again later!",
    Listreservation: "List reservation",
    Booking: "Booking",
    Chooseday: "Choose day",
    Choosetime: "Choose time",
    Seats: "Seats",
    OrderTable: "Order",
    submit: "Submit",
    Enterseat: "Enter seat",
    Pleasewaitforconfirmation: "Please wait for confirmation!",
    Doyouwantcanclebookingtable: "Do you want cancle booking table?",
    Cancel: "Cancel",
    ok: "OK",
    Tablereservationsconfirmed: "Table reservations confirmed",
    Customerscanceltables: "Customers cancel tables.",
    Date: "Date",
    Seat: "seat",
    Productnotfound: "Product not found!",
    Pleaseentertheaddress: "Please enter the address!",
    Deliveryinfo: "Delivery info",
    Shipto: "Ship to",
    Addnewaddress: "Add new address",
    Shippingfee: "Shipping fee",
    ChooseAddress: "Choose Address",
    Setasdefault: "Set as default",
    Delete: "Delete",
    Cannotdeletedefaultaddress: "Cannot delete default address",
    Fullnameisrequire: "Fullname is require",
    Phonenumberisrequire: "Phone number is require",
    Phonenumberisnotinthecorrectformat:
      "Phone number is not in the correct format",
    Addressisrequire: "Address is require",
    Enteryouraddress: "Enter your address",
    Unitsuit: "Unit/suit",
    Passwordisrequired: "Password is required",
    Username: "Username",
    EnteryourUsernameEmail: "Enter your Username / Email",
    Password: "Password",
    EnteryourPassword: "Enter your Password",
    ForgetYourPassword: "Forget Your Password?",
    Logintosystem: "Login to system",
    Pleaseenteryouremail: "Please enter your email",
    Emailyouenteredisnotintheformat: "Email you entered is not in the format",
    Pleaseentercodegetfromyouremail: "Please enter code get from your email",
    ForgotPasswordSuccess: "Forgot Password Success!",
    SomethingwentwrongPleasetryagainlater:
      "Something went wrong. Please try again later!",
    EnteryourEmail: "Enter your Email",
    Code: "Code",
    Entercode: "Enter code",
    WehavesentthecodetoyouremailChecktogetit:
      "We have sent the code to your email. Check to get it !",
    GetCode: "Get Code",
    ResetPassword: "Reset Password",
    Login: "Login",
    Usernameisrequired: "Username is required",
    pleaseEnterUsername: "Please enter username",
    pleaseEnterYourPassword: "Please enter your password",
    Name: "Name",
    Phone: "Phone",
    Status: "Status",
    Action: "Action",
    All: "All",
    Addstaffsuccess: "Add staff success!",
    PleaseenterPermission: "Please enter Permission",
    Editstaffsuccess: "Edit staff success",
    Deletestaffsuccess: "Delete staff success",
    WebAdmin: "Web Admin",
    Liststaff: "List staff",
    Adduser: "Add user",
    Role: "Role",
    ChooseRole: "ChooseRole",
    Create: "Create",
    Edituser: "Edit user",
    Deleteuser: "Delete user",
    Save: "Save",
    Addnew: "Add new",
    records: "records",
    Display: "Display",
    Page: "Page",
    of: "of",
    Gotopage: "Go to page",

    Income: "Income",
    Datestart: "Date start",
    to: "to",
    Datefinish: "Date finish",
    BasicMonthly: "Basic Monthly",
    PleaseenteraddSMSnumber: "Please enter addSMS number",
    AddSmssuccess: "Add Sms success.",
    AddSmsErrortryagain: "Add Sms Error, try again",
    PleaseenterMessage: "Please enter Message!",
    Messagescannotexceed160charactersPleasereentermessages:
      "Messages cannot exceed 160 characters. Please re-enter messages",
    Sentmessagesuccess: "Sent message success",
    error: "error",
    Allcustomer: "All customer",
    Basicmonthlycustomer: "Basic monthly customer",
    Basicyearlycustomer: "Basic yearly customer",
    Basic2yearscustomer: "Basic 2 years customer",
    Largemonthlycustomer: "Large monthly customer",
    Largeyearlycustomer: "Large yearly customer",
    Large2yearscustomer: "Large 2 years customer",
    Suppermonthlycustomer: "Supper monthly customer",
    Supperyearlycustomer: "Supper yearly customer",
    Supper2yearscustomer: "Supper 2 years customer",
    Customername: "Customer name",
    StoreInfo: "Store Info",
    Datejoin: "Date join",
    Dateexpire: "expire",
    remain: "remain",
    Lastcard: "Last card",
    Active: "Active",
    View: "View",
    AddSMS: "Add SMS",
    Reset: "Reset",
    Access: "Access",
    Totalowner: "Total owner",
    Message: "Message",
    Messagescanonlycontain160characters:
      "Messages can only contain 160 characters",
    Send: "Send",
    Numberofmessagecharacters: "Number of message characters",
    EnterNumberSMS: "Enter Number SMS",
    addquantitysms: "add quantity sms",
    Add: "Add",
    Stopservice: "Stop service",
    SendEmailtoallcustomerinthisgroup:
      "Send Email to all customer in this group",
    SendSMSAllCustomer: "Send SMS All Customer",
    onCard: "on Card",
    Last4: "Last 4#",
    New: "New",
    Old: "Old",
    Sendemailtogroup: "Send email to group",
    Title: "Title",
    Content: "Content",
    EnterContentEmail: "Enter Content Email",
    Passcodemustcontain4characters: "Passcode must contain 4 characters",
    Reenterincorrectpasscode: "Re-enter incorrect passcode",
    Updatepasscodesuccessful: "Update passcode successful",
    Updatepasscodefailed: "Update passcode failed",
    Passwordmustcontain4characters: "Password must contain 4 characters",
    Reenterincorrectpassword: "Re-enter incorrect password",
    Updatepasswordsuccessful: "Update password successful",
    Updatepasswordfailed: "Update password failed",
    Changepasscode: "Change passcode",
    NewPasscode: "New Passcode",
    Enternewpasscode: "Enter new passcode",
    RetypePasscode: "Retype Passcode",
    Enternewpasscodetoconfirm: "Enter new passcode to confirm",
    NewPassword: "New Password",
    Enternewpassword: "Enter new password",
    RetypePassword: "Retype Password",
    Enternewpasswordtoconfirm: "Enter new password to confirm",
    Createsuccess: "Create success",

    Payment: "Payment",
    Doyouwanttopay: "Do you want to pay?",
    YesIwant: "Yes,I want",
    Storedetail: "Store detail",
    Contactperson: "Contact person",
    Storename: "Store name",
    Cellphone: "Cell phone",
    Address: "Address",
    Emailaddress: "Email address",
    Suitofunit: "Suit of unit",
    City: "City",
    States: "States",
    OwnerPassCode: " Owner Pass Code",
    loginOwnerMode: "log in Owner Mode",
    Webpassword: "Web password",
    loginPOS: "log in POS",
    Enterpassword: "Enter password",
    DetailAddress: "Detail Address",
    ShippingAddress: "Shipping Address",
    Enteryourdetailaddress: "Enter your detail address",
    Subscription: "Subscription",
    Paymentstatus: "Payment status",
    Paid: "Paid",
    Unpaid: "Unpaid",
    Cardinformation: "Card information",
    PleaseselectInvoice: "Please select Invoice!",
    CustomerDetail: "Customer Detail",
    Shippiingaddress: "Shippiing address",
    Device: "Device",
    month: "month",
    Processed: "Processed",
    Show: "Show",
    Filter: "Filter",
    Detail: "Detail",
    OrderDeviceDetail: "Order Device Detail",
    Info: "Info",
    RestaurantPhone: "Restaurant Phone",
    Timezone: "Time zone",
    Oldpass: "Old pass",
    Enteryouoldpass: "Enter you old pass",
    Retypenewpass: "Retype new pass",
    Changephonesuccess: "Change phone success",
    Settingphonenumber: "Setting phone number",
    Updatephonenumber: "Update phone number",
    Setting: "Setting",
    Areyousuredeleteequipment: "Are you sure delete equipment?",
    equipmentwillbedeletedwhenyouclickOK:
      "equipment will be deleted when you click OK",
    LandingPageURL: "Landing Page URL",
    ReturnURL: "Return URL",
    Update: "Update",
    CopySuccess: "Copy Success",
    UpdateSuccess: "Update Success",
    equipment: "Equipment",
    Price: "Price",
    Edit: "Chỉnh sửa",
    Thefileyouselectedisnotinthecorrectformat:
      "The file you selected is not in the correct format!",
    Equipmentnameisrequire: "Equipment name is require",
    Equipmentpriceisrequire: "Equipment price is require",
    pricemustbegreaterthan0: "price must be greater than 0",
    Createnewequipment: "Create new equipment",
    UploadequipmentImages: "Upload equipment Images",
    Recommendedimageformats: "Recommended image formats",
    Atleast: "At least",
    foroptimaldisplay: "for optimal display",
    EquipmentName: "Equipment Name",
    Description: "Description",
    Editequipment: "Edit equipment",
    CurrentlythesystemdoesnothaveanypackagesPleasecomebacklater:
      "Currently the system does not have any packages. Please come back later",
    Pleaseentersmsquatity: "Please enter sms quantity!",
    Pleaseenterprice: "Please enter price",
    Editpackedsuccess: "Edit packed success",
    Type: "Type",
    Cash: "Cash",
    EditPackage: "Edit package",
    Emailalreadyexistsonthesystem: "Email already exists on the system",
    UpdateErrorEmailalreadyexistsonthesystem:
      "Update ErrorEmailalreadyexistsonthesystem",
    UpdateErrorPleasetryagain: "Update Error, Please try again",
    Accountstaff: "Account staff",
    Overview: "Overview",
    Groupcustomer: "Group customer",
    Createcustomer: "Create customer",
    Devicecustomer: "Device customer",
    Changepassword: "Change password",
    Config: "Config",
    Accountinfo: "Account info",
    Logout: "Log out",
    Whatsincluded: "What’s included",
    Pleasechooseyourpackage: "Please choose your package!",
    Month: "Tháng",
    Year: "Năm",
    Alltheequipment: "All the equipment",
    Buy: "Buy",
    Weakpassword: "Weak password",
    Askforreviewmessage: "Ask for review message",
    Wegotyourorderand: "We got your order and",
    starttoprocessingitnow: "start to processing it now!",
    Wouldyouliketoplayagame: "Would you like to play a game",
    whileyourewaiting: "while you're waiting",
    ForHere: "For Here",
    Togo: "To go",
    Enternumberofguest: "Enter number of guest?",
    Pleaseletusknowthetotalnumberofpeopleinyourgroupsowecanbetterarrangefoodpreparationtimetoserveyouefficiently: "Please let us know the total number of people in your group so we can better arrange food preparation time to serve you efficiently.",
    LikeusonFacebooktoget: "Like us on Facebook to get",
    updatesonourfood: "updates on our food!",
    OrfollowusonInstagramto: "Or follow us on Instagram to",
    getfoodupdatesaswell: "get food updates as well!"
  },
  VN: {
    LG: 1,
    Askforreviewmessage: "Yêu cầu thông báo đánh giá",
    Weakpassword: "Mật khẩu yếu",
    Year: "Năm",
    Buy: "Mua",
    Alltheequipment: "Tất cả thiết bị",
    Month: "Tháng",
    Whatsincluded: "Bao gồm",
    Pleasechooseyourpackage: "Vui lòng chọn gói của bạn!",
    Logout: "Đăng xuất",
    Config: "Cấu hình",
    Accountinfo: "Trang cá nhân",
    Changepassword: "Thay đổi mật khẩu",
    Devicecustomer: "Khách hàng thiết bị",
    Createcustomer: "Tạo mới khách hàng",
    Groupcustomer: "Nhóm khách hàng",
    Overview: "Tổng quan",
    Accountstaff: "Quản lý tài khoản",
    UpdateErrorPleasetryagain: "Update Error, Please try again",
    UpdateErrorEmailalreadyexistsonthesystem:
      "Cập nhật thất bại, Email đã tồn tại trên hệ thống",
    Emailalreadyexistsonthesystem: "Email đã tồn tại trên hệ thống",
    EditPackage: "Chỉnh sửa package",
    Cash: "Tiền mặt",
    Type: "Loại",
    Pleaseenterprice: "Vui lòng nhập giá!",
    Editpackedsuccess: "Cập nhật package thành công",
    Pleaseentersmsquatity: "Vui lòng nhập số SMS!",
    CurrentlythesystemdoesnothaveanypackagesPleasecomebacklater:
      "Hiện tại hệ thống không có bất kỳ gói nào. Vui lòng quay lại sau",
    Editequipment: "Chỉnh sửa thiết bị",
    EquipmentName: "Tên thiết bị",
    Atleast: "Chí ít",
    Description: "Mô tả",
    foroptimaldisplay: "để hiển thị tối ưu",
    Recommendedimageformats: "Định dạng hình ảnh được đề xuất",
    UploadequipmentImages: "Tải ảnh thiết bị lên",
    pricemustbegreaterthan0: "Giá phải lớn hơn 0",
    Createnewequipment: "Tạo mới thiết bị",
    Equipmentnameisrequire: "Vui lòng nhập tên thiết bị!",
    Equipmentpriceisrequire: "Vui lòng nhập giá!",
    Thefileyouselectedisnotinthecorrectformat:
      "File bạn chọn không đúng định dạng",
    Edit: "Chỉnh sửa",
    Price: "Giá",
    equipment: "Thiết bị",
    Update: "Cập nhật",
    UpdateSuccess: "Cập nhật thành công",
    CopySuccess: "Sao chép thành công",
    ReturnURL: "Trang lời cảm ơn",
    Setting: "Cài đặt",
    LandingPageURL: "Landing Page URL",
    Areyousuredeleteequipment: "Bạn chắc chắn muốn xóa thiết bị",
    equipmentwillbedeletedwhenyouclickOK:
      "Thiết bị sẽ bị xóa khi bạn nhấp vào OK",
    Updatephonenumber: "Cập nhật số điện thoại",
    Changephonesuccess: "Thay đổi số điện thoại thành công",
    Settingphonenumber: "Cài đặt số điện thoại",
    Oldpass: "Mật khẩu cũ",
    Enteryouoldpass: "Nhập lại mật khẩu cũ",
    Retypenewpass: "Nhập lại mật khẩu cũ",
    Info: "Thông tin",
    Timezone: "Múi giờ",
    RestaurantPhone: "Điện thoại nhà hàng",
    OrderDeviceDetail: "Chi tiết đơn hàng",
    Detail: "Chi tiết",
    Filter: "Bộ lọc",
    Show: "Hiển thị",
    Processed: "Xử lý",
    viewOurMenu: "",
    pleaseEnterPhone: "Vui lòng nhập số điện thoại!",
    phoneNumberFormatConsistsOf10Numbers: "Số điện thoại phải bao gồm 10 số!",
    checkOurTermsPlease: "Xin vui lòng kiểm tra các điều khoản của chúng tôi!",
    yourPoints: "Điểm của bạn",
    ForEveryDoolarYouSpend:
      "Với mỗi đô la bạn chi tiêu, bạn sẽ kiếm được số điểm tương đương",
    go: "Tiếp theo",
    exploreUniqueFlavors: "Khám phá hương vị độc đáo",
    atOurRestaurant: "tại nhà hàng của chúng tôi",
    Whatareyoulookingfor: "Bạn đang tìm kiếm gì?",
    Search: "Tìm kiếm",
    Back: "Trở về",
    Powerby: "Vận hành bởi",
    Revieworder: "Đặt món",
    Nodata: "Không có dữ liệu",
    Addedyourchoice: "Đã thêm lựa chọn của bạn",
    OrdersServed: "Đã bán",
    Ownerisnotfound: "Không tìm thấy owner!",
    Tableisnotfound: "Không tìm thấy tên bàn",
    Foodisnotfound: "Không tìm thấy món ăn",
    Custommerisnotfound: "Không tìm thấy khách hàng",
    Pleasechoosenoteforthisfood: "Xin hãy chọn ghi chú cho món ăn này!",
    Chooseasidedishes: "Chọn một món ăn phụ",
    Note: "Ghi chú",
    NoteFood: "Ghi chú món ăn",
    Morerequest: "Thêm ghi chú",
    Addtocart: "Thêm vào giỏ hàng",

    HistoryOrder: "Lịch sử đơn hàng",
    TimeOrder: "Thời gian",
    TotalItems: "Tổng món",
    ItemsTotal: "Tổng giá",
    Invoicenumber: "Số hóa đơn",
    Printdatetime: "Ngày in",
    Customer: "Khách hàng",
    Dinein: "Tại bàn",
    Takeaway: "Mang đi",
    Discount: "Giảm giá",
    Conveniencefee: "Phí thanh toán",
    TheconveniencefeeischargedpertransactionbyathirdpartyvendorWedonotretainanyportionofthisfee:
      "Phí thanh toán được tính cho mỗi giao dịch bởi nhà cung cấp bên thứ ba. Chúng tôi không giữ lại bất kỳ phần nào của khoản phí này.",
    Total: "Tổng cộng",
    Review: "Xem lại",
    yourorder: "Đơn đặt hàng",
    Ordermore: "Đặt thêm",
    InvoiceDetails: "Chi tiết hóa đơn",
    ChooseFreeSideHere: "Chọn món ăn kèm",
    SideDish: "Món ăn kèm",
    Youlikesomemoredrink: "Chọn thêm đồ uống",
    Youwanttoaddsomethingelse: "Món ăn khác",
    Confirmordersend: "xác nhận",
    Pleasechooseadish: "Vui lòng chọn món ăn!",
    confirm: "Xác nhận",
    ListSideDish: "Danh sách món ăn kèm",
    Pleasechooseapaymentmethod: "Vui lòng chọn phương thức thanh toán!",
    Pleaseselectstafftoreceivetips: "Vui lòng chọn nhân viên nhận tip!",
    Tippingmustbegreaterthan: "Tipping must be greater than 0!",
    YouhavediscountcodeApplynow: "Bạn có mã giảm giá? Áp dụng ngay bây giờ!",
    Apply: "Áp dụng",
    Addtip: "Thêm tip",
    CustomTipAmount: "Tùy chỉnh tiền tip",
    Employeesaretipped: "Nhân viên được tip",
    Employeewanttotip: "Nhân viên muốn tip",
    PaymentMethod: "Phương thức thanh toán",
    Yourpaymentmethod: "Phương thức thanh toán",
    Orderlistandprices: "Danh sách món ăn",
    Paynow: "Thanh toán",
    PleaseEnterTip: "Vui lòng nhập tip!",
    Entertip: "Nhập tip",
    Close: "Đóng",
    SaveChanges: "Lưu thay đổi",
    Forcashpaymentskindly: "Đối với thanh toán bằng tiền mặt, vui lòng",
    proceedtothecounter: "tiến hành đến quầy.",
    Needhelp: "Cần giúp đỡ?",
    Callstaff: "Gọi nhân viên",
    Wewillnotifyyourwaiteranddontforgetyouarealwayswelcometopayatthecounter:
      "Chúng tôi sẽ thông báo cho người phục vụ của bạn và đừng quên bạn luôn được chào đón thanh toán tại quầy",
    Gotit: "OK",
    HiwegotyourequestedOneofourteammemberwillbewithyourshortly:
      "Xin chào, chúng tôi đã nhận được yêu cầu của bạn. Một trong những thành viên trong nhóm của chúng tôi sẽ sớm có mặt với bạn",
    Selectemployeewanttotip: "Chọn nhân viên muốn tip",
    WejuswanttomakesuretheTipgoestotherightperson:
      "Chúng tôi muốn đảm bảo rằng Tiền boa đến đúng người",
    Select: "Chọn",
    AddVisacardinformation: "Thêm thông tin thẻ Visa",
    PayWith: "Thanh toán",
    Thanksforyourpayment: "Cảm ơn bạn đã thanh toán!",
    Yourpaymentwasnotsuccessful: "Thanh toán của bạn không thành công!",
    PaymentSuccess: "Thanh toán thành công",
    PaymentFailed: "Thanh toán thất bại",
    Youmightnowclosethiswindow: "Bây giờ bạn có thể đóng cửa sổ này.",
    Ifyouwishtoorderinthefuture: "Nếu bạn muốn đặt hàng trong tương lai",
    PleaserescantheQRcode: "Vui lòng quét lại mã QR",
    OrvisitourGoogleMapspagetoaccesstheorderinglink:
      "Hoặc truy cập trang Google Maps của chúng tôi để truy cập liên kết đặt hàng.",
    Thisensuresyourorderis: "Điều này đảm bảo đơn đặt hàng của bạn",
    processedcorrectly: "được xử lý đúng cách.",
    Thankyourforvisitustoday: "Cảm ơn bạn đã ghé thăm chúng tôi hôm nay",
    Welookforwardtoservingyouinthefuture:
      "Chúng tôi mong được phục vụ bạn trong tương lai",
    Mybill: "Hóa đơn của tôi",
    Changemethod: "Thay đổi phương thức thanh toán",
    Home: "Trang chủ",
    Yourorderwillbereadyin: "Đơn đặt hàng của bạn sẽ sẵn sàng",
    Westarttoprocessingyourordernow:
      "Chúng tôi bắt đầu xử lý đơn đặt hàng của bạn ngay bây giờ!",
    Tablenumber: "Tên bàn",
    Download: "Tải xuống",
    Rewards: "Đổi thưởng",
    Score: "Điểm",
    Time: "Thời gian",
    Startgame: "Chơi",
    Exit: "Thoát",
    Endgame: "Kết thúc",
    Playagain: "Chơi lại",
    Start: "Bắt đầu",
    BestScore: "Kỷ lục",
    Again: "Tiếp tục",
    point: "điểm",
    Remainingbalance: "Còn lại",
    Change: "Quy đổi",
    Fullname: "Họ tên",
    EnterFullName: "Nhập họ tên",
    Pleaseenteryourfullname: "Vui lòng nhập họ tên!",
    Onceyouaccumulate: "Một khi bạn tích lũy",
    pointswewill: "điểm, bạn sẽ",
    provideyouwithadiscountof: "cung cấp cho bạn mã giảm giá",
    off: "",
    Pleaseenteryourbirthday: "Vui lòng nhập ngày sinh!",
    Yourbirthday: "Ngày sinh",
    Birthday: "Ngày sinh",
    Dohaveyouaccount: "Bạn có tài khoản không?",
    Phonenumber: "Số điện thoại",
    Iacceptthe: "Tôi chấp nhận",
    termsofuse: "điều khoản sử dụng",
    and: "và",
    privacypolicy: "chính sách bảo mật",

    Chooseyourordermethod: "Chọn phương thức đặt hàng",
    Reservation: "Đặt bàn",
    Pickup: "Đến lấy",
    Delivery: "Giao hàng",
    Today: "Hôm nay",
    Tomorow: "Ngày mai",
    Pleasechooseday: "Vui lòng chọn ngày!",
    Clockinmalformed: "Thời gian không đúng định dạng",
    Pleaseenterseatsnumber: "Vui lòng nhập số ghế",
    Customerinformationnotfoundpleaseloginagain:
      "Không tìm thấy thông tin khách hàng, vui lòng đăng nhập lại!",
    Youhaveanewtablereservation: "Bạn có đơn đặt bàn mới",
    TablebookingfailedPleasetryagainlater:
      "Đặt bàn không thành công. Vui lòng thử lại sau!",
    Listreservation: "Danh sách đặt bàn",
    Booking: "Đơn đặt bàn",
    Chooseday: "Chọn ngày",
    Choosetime: "Thời gian",
    Seats: "Số ghế",
    OrderTable: "Đặt bàn",
    submit: "OK",
    Enterseat: "Nhập số ghế",
    Pleasewaitforconfirmation: "Vui lòng chờ xác nhận!",
    Doyouwantcanclebookingtable: "Bạn có muốn đặt bàn cancle không?",
    Cancel: "Hủy",
    ok: "OK",
    Tablereservationsconfirmed: "Đã xác nhận đặt bàn",
    Customerscanceltables: "Khách hàng hủy bàn.",
    Date: "Ngày đặt",
    Seat: "ghế",
    Productnotfound: "Không tìm thấy món ăn!",
    Pleaseentertheaddress: "Vui lòng nhập địa chỉ!",
    Deliveryinfo: "Thông tin giao hành",
    Shipto: "Giao đến",
    Addnewaddress: "Thêm mới địa chỉ",
    Shippingfee: "Phí vận chuyển",
    ChooseAddress: "Chọn địa chỉ",
    Setasdefault: "Đặt làm mặc định",
    Delete: "Xóa",
    Cannotdeletedefaultaddress: "Không thể xóa địa chỉ mặc định",
    Fullnameisrequire: "Họ tên là bắt buộc",
    Phonenumberisrequire: "Số điện thoại là bắt buộc",
    Phonenumberisnotinthecorrectformat: "Số điện thoại không đúng định dạng",
    Addressisrequire: "Địa chỉ là bắt buộc",
    Enteryouraddress: "Nhập địa chỉ của bạn",
    Unitsuit: "Số nhà, tên đường",
    Passwordisrequired: "Mật khẩu là bắt buộc",
    Username: "Tên tài khoản",
    EnteryourUsernameEmail: "Nhập tên tài khoản / email",
    Password: "Mật khẩu",
    EnteryourPassword: "Nhập mật khẩu",
    ForgetYourPassword: "Quên mật khẩu?",
    Logintosystem: "Đăng nhập vào hệ thống",
    Pleaseenteryouremail: "Nhập email",
    Emailyouenteredisnotintheformat: "Email không đúng định dạng",
    Pleaseentercodegetfromyouremail: "Vui lòng nhập mã nhận từ email của bạn",
    ForgotPasswordSuccess: "khôi phục mật khẩu thành công!",
    SomethingwentwrongPleasetryagainlater:
      "Đã xảy ra sự cố. Vui lòng thử lại sau!",
    EnteryourEmail: "Nhập email",
    Code: "mã code",
    Entercode: "Nhập mã code",
    WehavesentthecodetoyouremailChecktogetit:
      "Chúng tôi đã gửi mã đến email của bạn. Kiểm tra để có được nó!",
    GetCode: "Lấy mã",
    ResetPassword: "Đặt lại mật khẩu",
    Login: "Đăng nhập",
    Usernameisrequired: "Tên tài khoản là bắt buộc",
    pleaseEnterUsername: "Vui lòng nhập tên tài khoản",
    pleaseEnterYourPassword: "Vui lòng nhập mật khẩu của bạn",
    Name: "Tên",
    Phone: "Điện thoại",
    Status: "Trạng thái",
    Action: "Thao tác",
    All: "Tất cả",
    Addstaffsuccess: "Thêm nhân viên thành công!",
    PleaseenterPermission: "Vui lòng chọn Quyền",
    Editstaffsuccess: "Chỉnh sửa nhân viên thành công",
    Deletestaffsuccess: "Xóa nhân viên thàng công",
    WebAdmin: "Quản trị viên",
    Liststaff: "Danh sách nhân viên",
    Adduser: "Thêm tài khoản",
    Role: "Quyền",
    ChooseRole: "Chọn quyền",
    Create: "Tạo mới",
    Edituser: "Chỉnh sửa tài khoản",
    Deleteuser: "Xóa tài khoản",
    Save: "Lưu",
    Addnew: "Thêm mới",
    records: "kết quả",
    Display: "Hiển thị",
    Page: "Trang",
    of: "của",
    Gotopage: "Đi đến trang",
    Income: "Thu nhập",
    Datestart: "Ngày bắt đầu",
    to: "đến",
    Datefinish: "Ngày hoàn thành",
    BasicMonthly: "Tháng cơ bản",
    PleaseenteraddSMSnumber: "Vui lòng nhập số sms",
    AddSmssuccess: "Thêm sms thành công.",
    AddSmsErrortryagain: "Thêm sms thất bại, hãy thử lại!",
    PleaseenterMessage: "Vui lòng nhập nội dung tin nhắn!",
    Messagescannotexceed160charactersPleasereentermessages:
      "Tin nhắn không được vượt quá 160 ký tự.Vui lòng nhập lại tin nhắn",
    Sentmessagesuccess: "Gửi thành công",
    error: "lỗi",
    Allcustomer: "Tất cả Khách hàng",
    Basicmonthlycustomer: "Basic monthly",
    Basicyearlycustomer: "Basic yearly",
    Basic2yearscustomer: "Basic 2 years",
    Largemonthlycustomer: "Large monthly",
    Largeyearlycustomer: "Large yearly",
    Large2yearscustomer: "Large 2 years",
    Suppermonthlycustomer: "Supper monthly",
    Supperyearlycustomer: "Supper yearly",
    Supper2yearscustomer: "Supper 2 years",
    Customername: "Tên khách hàng",
    StoreInfo: "Thông tin cửa hàng",
    Datejoin: "ngày mua",
    Dateexpire: "ngày hết hạn",
    remain: "còn lại",
    Lastcard: "Số thẻ",
    Active: "Hoạt động",
    View: "Xem",
    AddSMS: "Thêm SMS",
    Reset: "Đặt lại",
    Access: "Truy cập",
    Totalowner: "Tổng số khách hàng",
    Message: "Tin nhắn",
    Messagescanonlycontain160characters: "Tin nhắn chỉ có thể chứa 160 ký tự",
    Send: "Gửi",
    Numberofmessagecharacters: "Số ký tự tin nhắn",
    EnterNumberSMS: "Nhập số SMS",
    addquantitysms: "Thêm số SMS",
    Add: "Thêm",
    Stopservice: "Dừng dịch vụ",
    SendEmailtoallcustomerinthisgroup:
      "Gửi email cho tất cả khách hàng được chọn",
    SendSMSAllCustomer: "Gửi SMS cho tất cả khách hàng",
    onCard: "Trên thẻ",
    Last4: "4 số cuối",
    New: "Mới",
    Old: "Cũ",
    Sendemailtogroup: "Gửi email đến nhóm khách hàng",
    Title: "Tiêu đề",
    Content: "Nội dung",
    EnterContentEmail: "Nhập nội dung email",
    Passcodemustcontain4characters: "Mật mã phải gồm 4 ký tự",
    Reenterincorrectpasscode: "Nhập lại mật mã không chính xác",
    Updatepasscodesuccessful: "Cập nhật mật mã thành công",
    Updatepasscodefailed: "Cập nhật mật mã thất bại",
    Passwordmustcontain4characters: "Mật khẩu phải gồm 4 ký tự",
    Reenterincorrectpassword: "Nhập lại mật khẩu không chính xác",
    Updatepasswordsuccessful: "Cập nhật mật khẩu thành công",
    Updatepasswordfailed: "Cập nhật mật khẩu thất bại",
    Changepasscode: "Thay đổi mật mã",
    NewPasscode: "Mật mã mới",
    Enternewpasscode: "Nhập mật mã mới",
    RetypePasscode: "Nhập lại mật mã",
    Enternewpasscodetoconfirm: "Nhập lại mật mã để xác nhận",
    NewPassword: "Mật khẩu mới",
    Enternewpassword: "Nhập mật khẩu mới",
    RetypePassword: "Nhập lại mật khẩu",
    Enternewpasswordtoconfirm: "Nhập lại mật khẩu để xác nhận",
    Createsuccess: "Tạo mới thành công",
    Payment: "Thanh toán",
    Doyouwanttopay: "Bạn có muốn thanh toán không?",
    YesIwant: "Đồng ý",
    Storedetail: "Chi tiết cửa hàng",
    Contactperson: "Người liên hệ",
    Storename: "Tên cửa hàng",
    Cellphone: "Di dộng",
    Address: "Địa chỉ",
    Emailaddress: "Địa chỉ email",
    Suitofunit: "Huyện",
    City: "Tỉnh/quận",
    States: "Xã",
    OwnerPassCode: " Mật mã",
    loginOwnerMode: "Đăng nhập chủ sở hữu",
    Webpassword: "Mật khẩu",
    loginPOS: "Đăng nhập vào POS",
    Enterpassword: "Nhập mật khẩu",
    DetailAddress: "Địa chỉ chi tiết",
    ShippingAddress: "Địa chỉ giao hàng",
    Enteryourdetailaddress: "Nhập địa chỉ chi tiết của bạn",
    Subscription: "Đăng ký",
    Paymentstatus: "Trạng thái thanh toán",
    Paid: "Đã thanh toán",
    Unpaid: "Chưa thanh toán",
    Cardinformation: "Thông tin thẻ",
    PleaseselectInvoice: "Vui lòng chọn hóa đơn",
    CustomerDetail: "Chi tiết khách hàng",
    Shippiingaddress: "Địa chỉ giao hàng",
    Device: "Thiết bị",
    month: "tháng",
    Wegotyourorderand: "Chúng tôi đã nhận được đơn hàng của bạn và",
    starttoprocessingitnow: "bắt đầu xử lý ngay bây giờ!",
    Wouldyouliketoplayagame: "Bạn có muốn chơi một trò chơi",
    whileyourewaiting: "trong khi bạn đang chờ đợi không",
    ForHere: "Tại quán",
    Togo: "Mang đi",
    Enternumberofguest: "Nhập số lượng khách?",
    Pleaseletusknowthetotalnumberofpeopleinyourgroupsowecanbetterarrangefoodpreparationtimetoserveyouefficiently: "Vui lòng cho chúng tôi biết tổng số người trong nhóm của bạn để chúng tôi có thể sắp xếp thời gian chuẩn bị thức ăn để phục vụ bạn một cách hiệu quả hơn.",
    LikeusonFacebooktoget: "Hãy thích chúng tôi trên Facebook",
    updatesonourfood: "để nhận cập nhật về ẩm thực của chúng tôi!",
    OrfollowusonInstagramto: "Hoặc theo dõi chúng tôi trên Instagram",
    getfoodupdatesaswell: "để nhận thông tin cập nhật về thực phẩm!"
  },
};

export const getLanguageCheckIn = () => {
  var lg = languageUser.getLanguageUser();
  Language.EN.LG = lg;
  Language.VN.LG = lg;
  if (lg !== 1) {
    const pElement = document.querySelectorAll("p");
    const inputElement = document.querySelectorAll("input");
    const divElement = document.querySelectorAll("div");
    const textareaElement = document.querySelectorAll("textarea");
    const h3Element = document.querySelectorAll("h3");
    const h5Element = document.querySelectorAll("h5");
    const h4Element = document.querySelectorAll("h4");

    if (pElement && pElement !== null) {
      for (let index = 0; index < pElement.length; index++) {
        pElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (textareaElement && textareaElement !== null) {
      for (let index = 0; index < textareaElement.length; index++) {
        textareaElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (inputElement && inputElement !== null) {
      for (let index = 0; index < inputElement.length; index++) {
        inputElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h5Element && h5Element !== null) {
      for (let index = 0; index < h5Element.length; index++) {
        h5Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h3Element && h3Element !== null) {
      for (let index = 0; index < h3Element.length; index++) {
        h3Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h4Element && h4Element !== null) {
      for (let index = 0; index < h4Element.length; index++) {
        h4Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (divElement && divElement !== null) {
      for (let index = 0; index < divElement.length; index++) {
        divElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
  }
  if (lg === 1) {
    return Language.EN;
  } else {
    return Language.VN;
  }
};
