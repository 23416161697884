import { memo, useEffect, useState } from "react";
import "./Styles.css";
import { formatCurrencyVND } from "../../../../utils/format";
import { plus } from "../../../../components/ImgExport";
import ModalAddFoodOrder from "../ModalAddFoodOrder/ModalAddFoodOrder";
import default_product from "../../../../assets/images/default_product.png";
import * as ChooseMenu from "../../../../api/apiCheckin/chooseMenuApi";
import * as ListDetailFoodSlice from "../../../../store/slices/ListDetailFoodSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../components/ModalLoading";
import React from "react";
interface FoodItemsProps {
  showImgFood: boolean;
  tableId?: string;
  food_id?: string;
  price?: number;
  image?: string;
  foodName?: string;
  priceOld?: number;
  side_id?: string;
  noteChef?: string;
  handleChange?: (note: string) => void;
  addToCart?: (
    food_id: string,
    foodName: string,
    price: number,
    side_id: string,
    maxSide: number,
    typeFood: number,
    priceSide?: number,
    noteFood_id?: string,
    noteFood_name?: string,
    notechef?: string,
    imageFood?: string,
    statusOrder?: number,
    optionNoteFoodChoose?: ChooseMenu.NoteOptionChoose[]
  ) => void;
  showHappyHour?: number;
}

const FoodItems = ({
  showImgFood,
  food_id = "",
  foodName = "",
  price = 0,
  image = "",
  priceOld,
  side_id = "",
  addToCart,
  noteChef = "",
  handleChange,
  showHappyHour = 0,
  tableId,
}: FoodItemsProps) => {
  const [showModalAddFood, setShowModalAddFood] = useState(false);
  const InfoFoodOffline = useSelector(ListDetailFoodSlice.selectorListInfoFood);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [noteFood, setNoteFood] = useState<ChooseMenu.NoteFood[]>([]);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [listNoteOptionFood, setlistNoteOptionFood] = useState<
    ChooseMenu.listNoteOptionFood[]
  >([]);
  const [maxSide, setMaxSide] = useState(0);
  const [typeFood, setTypeFood] = useState(0);

  const dismisModalAddFood = () => {
    setShowModalAddFood(false);
  };
  useEffect(() => {
    // Đăng ký sự kiện kiểm tra trạng thái kết nối mạng
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Hủy đăng ký sự kiện khi component bị hủy
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  const gInfoFood = async () => {
    if (food_id) {
      if (!isOnline && InfoFoodOffline) {
        setNoteFood(
          InfoFoodOffline.filter((item) => item?.id === food_id)[0]?.NoteFood ??
            []
        );
        setShowModalAddFood(true);
      } else {
        setShowLoading(true);
        const infoFood = await ChooseMenu.gInfoFood(food_id);
        if (Number(infoFood?.status) === 1 && infoFood?.data?.InfoFood) {
          if (
            infoFood?.data?.NoteFood?.length === 0 &&
            infoFood?.data?.Side_Dishes?.length === 0 &&
            infoFood?.data?.listNoteOptionFood?.length === 0
          ) {
            setShowLoading(false);
            addToCart?.(
              food_id,
              foodName,
              price,
              side_id,
              maxSide,
              typeFood,
              0,
              "",
              "",
              "",
              image,
              !tableId ? 2 : 1,
              []
            );
          } else {
            setNoteFood(infoFood?.data?.NoteFood ?? []);
            setShowModalAddFood(true);
            setShowLoading(false);
            setlistNoteOptionFood(infoFood?.data?.listNoteOptionFood ?? []);
            setMaxSide(Number(infoFood.data.InfoFood.max_side) ?? 0);
            setTypeFood(Number(infoFood?.data?.InfoFood?.typeFood) ?? 1);
          }
        } else {
          setShowLoading(false);
        }
      }
    }
  };
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div
        className={`w-100px  ${
          showImgFood ? " d-flex align-items-center" : "h-full"
        }`}
      >
        <div
          className={`p-0 position-relative rounded-5 shadow-sm bg-white  py-1 overflow-hidden h-100 w-100 `}
          onClick={gInfoFood}
        >
          <div
            className={`btn-add-food-cart-pos-menu ${
              showImgFood ? "d-none" : ""
            }`}
          >
            <div className="w-100 h-100 position-relative">
              <img src={plus} alt="" className="btn-icon-plus-pos-menu" />
            </div>
          </div>
          <div className={`${showImgFood ? "d-none" : ""} mt-1`}>
            <img
              src={image}
              className="img-fluid w-100 height-60px object-fit-cover rounded-3 mb-1 flex-shrink-0 mt-2"
              onError={(e) => {
                e.currentTarget.src = default_product;
              }}
              alt="food-items"
            />
          </div>
          {showHappyHour > 0 ? (
            <div className={`${showImgFood ? "d-none" : ""} position-relative`}>
              <div className=" gap-2px h-32px ">
                <div className="d-flex align-items-center gap-2px ps-2">
                  <div
                    className={`text-price-food-pos-menu ${
                      String(price).length > 9 ? "text-12px" : ""
                    }`}
                  >
                    {formatCurrencyVND(price)}
                  </div>
                </div>
                <div className="d-flex gap-1 px-4">
                  <del
                    className={`text-price-food-pos-menu-old ${
                      String(priceOld).length > 9 ? "text-12px" : ""
                    }`}
                  >
                    {formatCurrencyVND(priceOld ?? 0)}
                  </del>
                </div>
              </div>
            </div>
          ) : (
            <div className={`${showImgFood ? "d-none" : ""}`}>
              <div className="d-flex align-items-center gap-2px ps-2">
                {/* <div className="text-dollar-pos-menu mb-1">$</div> */}
                <div
                  className={`text-price-food-pos-menu ${
                    String(price).length > 9 ? "text-12px" : ""
                  }`}
                >
                  {formatCurrencyVND(price)}
                </div>
              </div>
            </div>
          )}
          <div className="px-2">
            <div>
              <h4
                className={`text-name-food-pos-menu text-two-line-name   ${
                  showImgFood ? "m-0" : "mb-1"
                }`}
              >
                {foodName}
              </h4>
            </div>
          </div>
        </div>
        <ModalAddFoodOrder
          imageFood={image}
          food_id={food_id}
          foodName={foodName}
          noteFood={noteFood}
          side_id={side_id}
          price={price}
          maxSide={maxSide}
          typeFood={typeFood}
          addToCart={addToCart}
          show={showModalAddFood}
          handleClose={dismisModalAddFood}
          listOptionNote={listNoteOptionFood}
          tableID={tableId ?? ""}
        />
      </div>
    </>
  );
};

export default memo(FoodItems);
