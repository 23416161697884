import * as Request from "../Request";
interface InforOwnerByCode {
    id: string,
    email: string,
    code_change_password: string,
    username: string,
    password: string,
    name: string,
    img: string,
    googleBusinessID: string,
    rewardPoint: string,
    sellPhone: string,
    phone: string,
    address: string,
    store_unit: string,
    bank_number: string,
    bank_date_expired: string,
    bank_CVC: string,
    store_city: string,
    store_state: string,
    store_zipcode: string,
    input_first_name: string,
    input_day_of_birth: string,
    show_customer_point: string,
    review_message: string,
    type_send_review_message: string,
    time_send_message: string,
    birthday_message: string,
    time_send_birthday_message: string,
    point_message: string,
    points_checkin: string,
    percent_point: string,
    points_reward: string,
    time_send_message_coupon: string,
    status: string,
    type_send_review_staff: string
}
interface phoneOnline {
    id: string,
    name: string,
    phone: string,
    gender: string,
    points: string,
    date_checkin: string,
    delf: string,
    status: string,
    type: string
}
export interface Result<T> {
    status: number,
    message: string,
    data: T
}

export const addToken = async (customer_id: string, owner_id: number) => {
    return await Request.fetchAPI(`checkin/add_token.php`, "POST", { customer_id, owner_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};

export const checkTokenCustomer = async (customer_id: string, token: string, owner_id: number) => {
    return await Request.fetchAPI(`checkin/checkToken.php`, "POST", { customer_id, token, owner_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};

export const clearTokenCustomer = async (token: string) => {
    return await Request.fetchAPI(`checkin/clearToken.php`, "POST", { token })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};

export const checkSentSmsCustomer = async (id_owner: number, id_customer: string) => {
    return await Request.fetchAPI(`checkin/sendSmsCheckin.php`, "POST", { id_owner, id_customer })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};
export const conversionPointCustomer = async (owner_id: number, customer_id: string) => {
    return await Request.fetchAPI(`checkin/pointConversion.php`, "POST", { owner_id, customer_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};
export const gLoginOrderOnline = async (phone: number, ownerCode: string) => {
    return await Request.fetchAPI(`checkin/loginPhoneOnline.php`, "POST", { phone, ownerCode })
        .then((res: Result<phoneOnline[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
            };
        });
};
export const gInforOwnerByCode = async (ownerCode: string) => {
    return await Request.fetchAPI(`checkin/gInfoOwnerByCode.php`, "POST", { ownerCode })
        .then((res: Result<InforOwnerByCode>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};
export const gBirthAndNameCustomer = async (customer_id: string, username: string, birthday: string) => {
    return await Request.fetchAPI(`checkin/uBirthAndNameCustomer.php`, "POST", { username, birthday, customer_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};