import { arrowLeft, chervonRightPrimary } from "../../../../../../components/ImgExport";
import { useEffect, useRef, useState } from "react";
import './Styles.css';
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { getLanguageUI } from "../../../../../../Services/languageUI";
interface calculateSalaryEmployeeProps {
    startWeek: String,
    endWeek: String

}
const CalculateSalaryEmployee: React.FC<calculateSalaryEmployeeProps> = ({ endWeek, startWeek }) => {
    const navigate = useNavigate()
    return (
        <div className="calculate-Salary-box">
            <div>
                {startWeek} -   {endWeek}
            </div>
            <img src={chervonRightPrimary} alt="" onClick={() => navigate('/web-owner/tablesalary2week', { state: { endWeek, startWeek } })} />
        </div>
    )
}
interface SalaryEach2WeekProps {
}
const SalaryEach2Week: React.FC<SalaryEach2WeekProps> = () => {
    const languageUI = getLanguageUI().staffPage
    const navigate = useNavigate()
    const [weeksInYear, setWeeksInYear] = useState<{ start: Date, end: Date }[]>([])
    const ref = useRef<HTMLDivElement>(null);
    const DateNow = moment().format("YYYY-MM-DD");
    function getWeeksInYear() {
        const years = (new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'))).getFullYear(); // Năm bạn muốn hiển thị
        const firstDayOfYear = new Date(years, 0, 1); // Ngày đầu tiên của năm
        const lastDayOfYear = new Date(years, 11, 31); // Ngày cuối cùng của năm

        // Tính toán ngày thứ 2 đầu tiên
        let firstMonday = firstDayOfYear;
        while (firstMonday.getDay() !== 1) {
            firstMonday.setDate(firstMonday.getDate() + 1);
        }
        const weeks = [];
        let currentWeekStart = firstMonday;

        while (currentWeekStart <= lastDayOfYear) {
            const currentWeekEnd = new Date(currentWeekStart);
            currentWeekEnd.setDate(currentWeekStart.getDate() + 13); // Set đến cuối tuần

            weeks.push({
                start: new Date(currentWeekStart),
                end: new Date(currentWeekEnd),
            });

            currentWeekStart = new Date(currentWeekEnd);
            currentWeekStart.setDate(currentWeekEnd.getDate() + 1); // Bắt đầu tuần mới
        }

        setWeeksInYear(weeks);
        setTimeout(() => {
            scrollToElement()
        }, 1000);
    }

    const scrollToElement = () => {
        if (ref.current) {
            // Sử dụng scrollIntoView để cuộn đến phần tử
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    useEffect(() => {
        getWeeksInYear()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="box-salary-for-employee">
                <div className="header-salary-for-employee">
                    <div className="btn-back-salary-week" onClick={() => navigate('/web-owner/staffs', { state: { tabSelect: 1 } })}>
                        <img src={arrowLeft} alt="" />
                        {languageUI.back}
                    </div>
                    <p className="text-title-salary-week">{languageUI.caculateSalary2Week} {languageUI.fromMondayToSunday}</p>
                    <div className="btn-back-salary-week opacity-0 hidden">
                        <img src={arrowLeft} alt="" />
                        Back
                    </div>
                </div>
                <div className="box-container-salary-for-element">
                    <div className="container-salary-for-element scroll-hidden">
                        {
                            weeksInYear.map((week, index) => (
                                <>
                                    {DateNow >= moment(week.start).format("YYYY-MM-DD") && DateNow <= moment(week.end).format("YYYY-MM-DD") ?
                                        (
                                            <div key={index} ref={ref} className="text-white" style={{ backgroundColor: "#4a81d4" }}>
                                                {((week.end).getDate()) - ((week.start).getDate()) < 0 ? (
                                                    <div className="mt-4"></div>
                                                ) : ''}
                                                <CalculateSalaryEmployee key={index} endWeek={week.end.toDateString()} startWeek={week.start.toDateString()} />
                                            </div>
                                        ) : (
                                            <div key={index} >
                                                {((week.end).getDate()) - ((week.start).getDate()) < 0 ? (
                                                    <div className="mt-4"></div>
                                                ) : ''}
                                                <CalculateSalaryEmployee key={index} endWeek={week.end.toDateString()} startWeek={week.start.toDateString()} />
                                            </div>
                                        )
                                    }
                                </>


                            ))
                        }

                    </div>
                </div>

            </div>
        </>
    )
}
export default SalaryEach2Week