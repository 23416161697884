import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// components
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import "./StylesSetting.css";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import * as flagSlice from "../../store/slices/FlagSettingSlice";
import { withSwal } from "react-sweetalert2";
import {
  PriorityHighIcon,
  HttpsOutlinedIcon,
  SellOutlinedIcon,
  AccountBalanceIcon,
} from "../../components/Image";
import Infomation from "./components/ContentSetting/infomationContent";
import PassWord from "./components/ContentSetting/passWord";
import React from "react";
import TipAndTagSetting from "./components/ContentSetting/TipAndTagSetting";
import {
  ComboOption,
  ComboVoucher,
  gComboTimeZone,
  gComboVoucher,
} from "../../api/apiOwner/comboApi";
import ConectStripe from "./components/ContentSetting/ConectStripe";
import AddReaderStripe from "./components/ContentSetting/AddReaderStripe";
import ConnectPrinter from "./components/ContentSetting/ConnectPrinter";
import UpSale from "./components/ContentSetting/UpSale";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import TerminalIcon from "@mui/icons-material/Terminal";
import HappyHour from "./components/ContentSetting/HappyHour";
import { useDispatch } from "react-redux";
import { getLanguageUI } from "../../Services/languageUI";
import Swal from "sweetalert2";
import UpdateLink from "./components/ContentSetting/updateLink";
import EmailMarketing from "./components/ContentSetting/EmailMarketing";

const SettingInformation = withSwal((props: any) => {
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const statusSetting = useSelector(flagSlice.selectorSetting);
  const dispatch = useDispatch();
  const languageUI = getLanguageUI().setting;
  const [comboVoucher, setComboVoucher] = useState<ComboVoucher[]>([]);
  const [listTimeZone, setListTimeZone] = useState<ComboOption[]>([]);
  const [activeKey, setActiveKey] = useState<string>("information");

  const gComboVoucherOwner = async () => {
    if (UserIdStore) {
      const req = await gComboVoucher(UserIdStore);
      if (Number(req?.status) === 1) {
        setComboVoucher(req?.data ?? []);
      }
    }
  };
  const gComboTime = async () => {
    const req = await gComboTimeZone();
    if (Number(req?.status) === 1) {
      setListTimeZone(req?.data ?? []);
    }
  };

  const poupIsSave = (key: string) => {
    if (statusSetting) {
      Swal.fire({
        title: "Are you sure?",
        text: "You have unsaved changes in your page which will be lost, are you sure you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          setActiveKey(key);
          dispatch(flagSlice.clearSetting());
        }
      });
    } else {
      setActiveKey(key);
    }
  };
  const ChangeSetting = async () => {
    if (!statusSetting) {
      dispatch(flagSlice.setIsSetting(true));
    }
  };
  useEffect(() => {
    gComboVoucherOwner();
    gComboTime();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <div className="">
        <Topbar pageName="Home Settings" />
      </div> */}
      <div className="bg-white h-100vh">
        <div className=" mt-18 h-100 ">
          <div className="d-flex  justify-between h-100">
            <div className="w-100 h-100">
              <Tabs
                defaultActiveKey="information"
                id="justify-tab-example"
                className="bg-white py-2 rounded-2 w-100 custom-position-header-tab custom-tabs z-20 overflow-x-scroll"
                onSelect={(e) => {
                  poupIsSave(e ?? "information");
                }}
                activeKey={activeKey}
                // onClick={poupIsSave}
              >
                <Tab
                  eventKey="information"
                  className="custom-tabs"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <PriorityHighIcon className="me-1 icon-priority" />
                      {languageUI.information}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA pl-3 pt-3">
                    <Infomation
                      comboVoucher={comboVoucher}
                      listTimeZone={listTimeZone}
                      ChangeSetting={ChangeSetting}
                    />
                  </div>
                </Tab>

                <Tab
                  eventKey="password"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <HttpsOutlinedIcon className="me-1 text-4" />
                      {languageUI.password}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA h-100">
                    <PassWord ChangeSetting={ChangeSetting} />
                  </div>
                </Tab>

                <Tab
                  eventKey="TipAndTax"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <SellOutlinedIcon className="me-1 text-4" />
                      {languageUI.tiptax}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA">
                    <TipAndTagSetting ChangeSetting={ChangeSetting} />
                  </div>
                </Tab>

                {/* <Tab eventKey="connectPrinter"
                  title={
                    <span className='flex items-center text-title-tab-setting'>
                      <LocalPrintshopOutlinedIcon className="me-1 text-4" />
                      {languageUI.connectPrinter}
                    </span>}  >
                  <div className="bg-F8F9FA">
                    <ConnectPrintSetting />
                  </div>
                </Tab> */}
                <Tab
                  eventKey="connectStripe"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <AccountBalanceIcon className="me-1 text-4" />
                      {languageUI.connectStripe}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA">
                    <ConectStripe ChangeSetting={ChangeSetting} />
                  </div>
                </Tab>
                <Tab
                  eventKey="addReaderStripe"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <TerminalIcon className="me-1 text-4" />
                      {languageUI.addTerminalStripe}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA">
                    <AddReaderStripe ChangeSetting={ChangeSetting} />
                  </div>
                </Tab>
                <Tab
                  eventKey="connectPrinter"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <LocalPrintshopIcon className="me-1 text-4" />
                      {languageUI.connectPrinter}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA h-connect-printer">
                    <ConnectPrinter />
                  </div>
                </Tab>
                <Tab
                  className=""
                  eventKey="upSale"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <FastfoodIcon className="me-1 text-4" />
                      {languageUI.upSale}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA">
                    <UpSale />
                  </div>
                </Tab>
                <Tab
                  eventKey="happyHour"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <FastfoodIcon className="me-1 text-4" />
                      {languageUI.happyHour}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA">
                    <HappyHour ChangeSetting={ChangeSetting} />
                  </div>
                </Tab>
                <Tab
                  eventKey="updateLink"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <FastfoodIcon className="me-1 text-4" />
                      {languageUI.updateLink}
                    </span>
                  }
                >
                  <div className="bg-F8F9FA">
                    <UpdateLink ChangeSetting={ChangeSetting} />
                  </div>
                </Tab>
                {/* <Tab
                  eventKey="emailMarketing"
                  title={
                    <span className="flex items-center text-title-tab-setting">
                      <FastfoodIcon className="me-1 text-4" />
                      Email Marketing
                    </span>
                  }
                >
                  <div className="bg-F8F9FA">
                    <EmailMarketing ChangeSetting={ChangeSetting} />
                  </div>
                </Tab> */}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SettingInformation;
