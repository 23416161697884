import { useEffect, useState } from "react";
import { iconHotline } from "../../../../components/ImgExport";
import "./Styles.css";
import ModalCancelSubscription from "./component/ModalCancelSubscription";
import ModalOTPSubscriptionCancel from "../ModalOTPSubscriptionCancel/ModalOTPSubscriptionCancel";
import * as SubscriptionAPI from "../../../../api/apiOwner/Subscription";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ModalUpgragePage from "./component/ModalUpgragePage";
import ModalLoading from "../../../../components/ModalLoading";
import { formatPhoneNumber } from "../../../../utils/format";
import moment from "moment-timezone";
import ModalChangeCardSubcription from "../ModalChangeCardSubcription/ModalChangeCardSubcription";
import { getLanguageUI } from "../../../../Services/languageUI";
// interface SubscriotionCardItemsProps {
//   fill: string;
//   cardExpiration: string;
//   typeCard: string;
//   contentCard: string;
//   priceCard: number;
//   priceSaveCard: number;
//   numberSMS: number;
//   bytime: string;
//   logoSubsription: string;
//   statusText: string;
//   handleClick: () => void;
// }
// const SubscriotionCardItems = ({
//   bytime,
//   cardExpiration,
//   contentCard,
//   fill = "#0D7C46",
//   numberSMS,
//   priceCard,
//   priceSaveCard = 0,
//   typeCard,
//   logoSubsription,
//   statusText = "Upgrade",
//   handleClick,
// }: SubscriotionCardItemsProps) => {
//   const languageUI = getLanguageUI().subscriptionPage;
//   return (
//     <div className="box-subscription-card w-fit">
//       <CardSubscription fill={fill} />
//       <div className="content-card-subsctiption">
//         <div className="d-flex align-items-center gap-2">
//           <div className="box-img-subscription">
//             <img src={logoSubsription} alt="" className="img-subscription" />
//           </div>
//           <div>
//             <p className="text-name-card-subscription mb-0 text-white">
//               {cardExpiration}
//             </p>
//             <p className="text-type-card">{typeCard}</p>
//           </div>
//         </div>
//         <p className="mb-0 mt-2 text-content">{contentCard}</p>
//         <div className="d-flex gap-1 align-items-start mt-2">
//           <p className="text-price-subscription-card mb-0 mt-2">${priceCard}</p>
//           <img
//             src={arowRightPriceSubsription}
//             alt=""
//             className={`mt-2 ${priceSaveCard <= 0 ? "d-none" : ""} `}
//           />
//           <p
//             className={`text-price-hand ${priceSaveCard <= 0 ? "d-none" : ""}`}
//           >
//             {languageUI.saveTK} ${priceSaveCard}
//           </p>
//         </div>
//         <p className="mb-0 mt-2 text-what-included">
//           {languageUI.whatIncluded}
//         </p>
//         <div className="mt-2 d-flex align-items-center gap-2">
//           <img src={checkCircleSubscription} alt="" />
//           <p className="text-sms-have-in-buy mb-0">
//             {numberSMS} SMS / {bytime} {languageUI.month}
//           </p>
//         </div>
//         <div className="box-btn-upgrade" onClick={handleClick}>
//           <p className="text-upgrade mb-0">{statusText}</p>
//           <img src={starOutline} alt="" />
//         </div>
//       </div>
//     </div>
//   );
// };
const SubscriptionOwner = () => {
  const languageUI = getLanguageUI().subscriptionPage;
  const navigate = useNavigate();
  const [showModalCancelSubscription, setShowModalCancelSubscription] =
    useState(false);
  const [showModalCancelSubscriptionOTP, setShowModalCancelSubscriptionOTP] =
    useState(false);
  const UserIdStore = useSelector(userSlice.selectorUserId);

  // const [ListPacked, setListPacked] = useState<
  //   SubscriptionAPI.IListPackageData[]
  // >([]);
  const [MyPacked, setMyPacked] = useState<number>();
  // const [MyPackedName, setMyPackedName] = useState<string>("");
  // const [MyPackedTitle, setMyPackedTitle] = useState<any>("");

  const [MySMSLeft, setMySMSLeft] = useState<number>(0);
  const [MyTotalSMSLeft, setMyTotalSMSLeft] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Message, setMessage] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalChangeCard, setShowModalChangeCard] =
    useState<boolean>(false);

  const [modalPayment, setModalPayment] = useState(false);
  const [PackageIDchange, setPackageIDchange] = useState(0);
  const percenterSMS = ((MyTotalSMSLeft - MySMSLeft) / MyTotalSMSLeft) * 100;
  const [optionSMS, setOptionSMS] = useState<number>(1);
  const [phoneAdmin, setPhoneAdmin] = useState<string>("");
  const [dateRegister, setDateRegister] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");
  const [bankNumber, setBankNumber] = useState("");
  const [bankDateCard, setBankDateCard] = useState("");
  const [bankCVC, setBankCVC] = useState("");
  const [last4, setLast4] = useState("");

  const dismisModalCancelSubscription = () => {
    setShowModalCancelSubscription(false);
  };
  const dismisModalCancelSubscriptionOTP = async (status: boolean = false) => {
    if (status) {
      GetMyPacked();
    }
    setShowModalChangeCard(false);
    setShowModalCancelSubscriptionOTP(false);
    // await dispatchUser(tableSlice.clearTable());
    // await dispatchUser(staffTipSlice.clearStaffTip());
    // await dispatchUser(listFoodGroupSlice.clearFoodCategory());
    // await dispatchUser(menuFoodSlice.clearMenuFood());
    // await dispatchUser(floorSlice.clearFloor());
    // await dispatchUser(tipSlice.clearTipPercent());
    // await dispatchUser(ListDetailFoodSlice.clearInfoFood());
    // await dispatchUser(userSlice.clearUser());
    // navigate("/auth/login");
  };
  const handleRemoveSubscription = () => {
    setShowModalCancelSubscription(false);
    setShowModalCancelSubscriptionOTP(true);
  };

  // gListPacked.php
  const GetListPacked = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const ListPackage = await SubscriptionAPI.ListPackage();
      if (Number(ListPackage?.status) === 1) {
        setMessage("");
        // setListPacked(ListPackage?.data ? ListPackage?.data : []);
        setPhoneAdmin(ListPackage?.phoneNumber ?? "");
      } else {
        setMessage(
          "Currently the system does not have any packages. Please come back later"
        );
        // setListPacked([]);
      }
      setShowLoading(false);
    } else {
      console.log("Chưa đăng nhập");
      navigate("/auth/login");
    }
  };

  const GetMyPacked = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const MyPacked = await SubscriptionAPI.GetMyPacked(UserIdStore);
      if (Number(MyPacked?.status) === 1 && MyPacked?.data.length > 0) {
        setMyPacked(MyPacked?.data[0]?.id);
        setMySMSLeft(MyPacked?.data[0]?.cms);
        // setMyPackedTitle(MyPacked?.data[0]?.package_expiry);
        setMyTotalSMSLeft(MyPacked?.data[0]?.totalsms ?? 0);
        setBankNumber(MyPacked?.data[0]?.bank_number ?? "");
        setBankCVC(MyPacked?.data[0]?.bank_CVC ?? "");
        setLast4(MyPacked?.data[0]?.last4 ?? "");
        setBankDateCard(MyPacked?.data[0]?.bank_date_card ?? "");
        setDateRegister(
          MyPacked?.data[0]?.date_register
            ? moment(MyPacked?.data[0]?.date_register, "DD-MM-YYYY").format(
                "MM/DD/YYYY"
              )
            : ""
        );
        // setMyPackedName(
        //   MyPacked?.data[0]?.package_name
        //     ? MyPacked?.data[0]?.package_name +
        //         "/" +
        //         MyPacked?.data[0]?.typeCard
        //     : ""
        // );
        const d = moment(MyPacked?.data[0]?.date_register, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        );
        const currentDate = new Date(d); // Thay '2023-09-26' bằng ngày bạn muốn cộng

        if (Number(MyPacked?.data[0]?.package_expiry) === 1) {
          currentDate.setMonth(currentDate.getMonth() + 1);
        } else if (Number(MyPacked?.data[0]?.package_expiry) === 2) {
          currentDate.setFullYear(currentDate.getFullYear() + 1);
        } else if (Number(MyPacked?.data[0]?.package_expiry) === 3) {
          currentDate.setFullYear(currentDate.getFullYear() + 2);
        }

        // currentDate bây giờ chứa ngày sau khi cộng thêm 7 ngày
        setDateEnd(moment(currentDate).format("MM/DD/YYYY"));
      } else {
        setMyPacked(0);
        setMySMSLeft(0);
      }
      setShowLoading(false);
    } else {
      console.log("Chưa đăng nhập");
      navigate("/auth/login");
    }
  };

  const ReloadForm = () => {
    // setListPacked([]);
    setMyPacked(0);
    setMySMSLeft(0);
    setMessage("");
    setShowLoading(false);
    setOptionSMS(1);
  };

  const cancelPackage = async (owner_id: any, packed_id: any) => {
    setShowLoading(true);
    const cancel = await SubscriptionAPI.cancelPackage(owner_id, packed_id);
    if (Number(cancel?.status) === 1) {
      setShowLoading(false);
      dismisModalCancelSubscriptionOTP();
      Swal.fire({
        position: "center",
        icon: "success",
        title: languageUI.cancelPackageSuccess,
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        navigate("/auth/logout");
      }, 1500);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: cancel?.message,
        showConfirmButton: false,
        timer: 3000,
      });
      dismisModalCancelSubscriptionOTP();
      return;
    }
  };
  useEffect(() => {
    ReloadForm();
    GetListPacked();
    GetMyPacked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopup = (id: number, sms: number) => {
    setPackageIDchange(id);
    setModalPayment(true);
  };
  const handlePayment = async () => {
    setShowLoading(true);
    if (MyPacked === PackageIDchange) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: languageUI.thisYourPackage,
        showConfirmButton: false,
        timer: 3000,
      });
      setModalPayment(false);
      setShowLoading(false);

      return;
    }

    const Pay = await SubscriptionAPI.Payment(UserIdStore, PackageIDchange);
    if (Number(Pay?.status) === 1) {
      GetMyPacked();
      setShowLoading(false);

      Swal.fire({
        position: "center",
        icon: "success",
        title: languageUI.changePackageSuccess,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: Pay?.message,
        showConfirmButton: false,
        timer: 3000,
      });
      setModalPayment(false);
      setShowLoading(false);
      return;
    }
    setModalPayment(false);
  };
  return (
    <>
      {/* <div className="">
                <Topbar pageName="Subscription" />
            </div> */}
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="px-3 pt-118px  h-100vh pb-48px  w-100">
        <div className="bg-white  h-100 w-100 rounded-18px box-shadow-inventory position-relative">
          <div className="d-flex justify-content-between align-items-center py-7 px-6 border-bottom">
            <p className="mb-0 text-inventory">Subscription</p>
          </div>
          <div className="w-100 h-100 d-flex ">
            <div className="w-fit p-6 pb-1 w-30 border-right flex-shink-0 height-100-255px">
              <div className="d-flex flex-column align-items-center mb-6">
                <p className="text-your-subscription text-center mb-1">
                  {languageUI.yourSubcription}
                </p>
                {/* <div className="d-flex gap-1">
                  <p className="mb-0 text-content-you-are-using text-a5a5ba">
                    {languageUI.youAreUsing}
                  </p>
                  <p className="mb-0 text-content-you-are-using text-12B064 text-center">
                    {MyPackedName}
                  </p>
                </div> */}
              </div>
              <div>
                <p className="mb-1 text-center text-content-you-are-using">
                  {dateRegister} {languageUI.to} {dateEnd}
                </p>
              </div>

              <div className="box-range-sms ">
                <div className="d-flex gap-1 align-items-center">
                  <p className="text-this-month text-4A4A6A-owner mb-0">
                    {languageUI.thisMonthYouHave}
                  </p>
                  <p className="text-this-month text-FAA300 mb-0">
                    {MySMSLeft} {languageUI.sMSLeft}
                  </p>
                </div>
                <div className="range-box-subscription">
                  <div
                    className="range-slide-subscription"
                    style={{ width: `${percenterSMS}%` }}
                  ></div>
                </div>
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <p className="mb-0 text-number-slide-subscription">
                    {MyTotalSMSLeft - MySMSLeft}
                  </p>
                  <p className="mb-0 text-number-slide-subscription ">
                    {MyTotalSMSLeft}
                  </p>
                </div>
              </div>
              {/* <div className="mt-2 d-flex mb-1 align-items-center">
                                <p className=" mb-0 text-content-you-are-using">ạhd ákhạksdhá hdáh: </p>
                                <p className="mb-0 text-subsription-renew font-weight-600">(4242)</p>
                            </div> */}

              {/* <div
                className="box-car-visa mt-6"
                onClick={() => {
                  setShowModalChangeCard(true);
                }}
              >
                <p className="mb-0 text-your-card-subsription text-4A4A6A-owner d-flex">
                  {languageUI.yourCard} (
                  <p className="text-12B064 mb-0 text-your-card-subsription">
                    {last4 !== "" ? "VISA-" + last4 : ""}
                  </p>
                  )
                </p>
                <div className="d-flex gap-2 align-items-center">
                  <p className="text-update-card-visa mb-0">
                    {languageUI.update}
                  </p>
                  <img src={editTableInventoryGreen} alt="" />
                </div>
              </div> */}
              {/* <div className="mt-6 d-flex align-items-center justify-content-center w-100 gap-2">
                <img src={starBlink} alt="" />
                <p className="mb-0 text-subsription-renew">
                  {languageUI.subcriptionRenew}{" "}
                  {Number(MyPackedTitle) === 1
                    ? languageUI.month
                    : Number(MyPackedTitle) === 2
                    ? languageUI.year
                    : "2 " + languageUI.year}{" "}
                </p>
              </div>
              <div className="d-flex justify-content-center mt-2">
                <p
                  className="mb-0 text-cancel-subscription"
                  onClick={() => {
                    setShowModalCancelSubscription(true);
                  }}
                >
                  {languageUI.cancel}
                </p>
              </div> */}
              {/* <div className="">
                                <p className="text-note-subcription mb-1">Note:</p>
                                <p className="text-content-note-subcription">If you decide to switch to a higher package, you won't lose any money for the months you haven't used. Instead, the system will automatically adjust your payment for the new package.</p>
                            </div> */}
            </div>
            {/* <div className="w-100 p-6 height-card-subscription">
                            <div className="d-flex align-items-center justify-content-center gap-6 ">
                                <p className="mb-0 text-best-choice-subscription">{languageUI.pleaseChoosePackage}</p>
                                <div className="box-option-sms">
                                    <div className={` ${optionSMS === 1 ? 'option-sms-checked' : 'option-sms'}`} onClick={() => setOptionSMS(1)}>
                                        <p className={`text-option-sms mb-0 ${optionSMS === 1 ? 'text-12B064' : ''}`}>{languageUI.month}</p>
                                    </div>
                                    <div className={` ${optionSMS === 2 ? 'option-sms-checked' : 'option-sms'}`} onClick={() => setOptionSMS(2)}>
                                        <p className={`text-option-sms mb-0 ${optionSMS === 2 ? 'text-12B064' : ''}`}>{languageUI.year}</p>
                                    </div>
                                    <div className={` ${optionSMS === 3 ? 'option-sms-checked' : 'option-sms'}`} onClick={() => setOptionSMS(3)}>
                                        <p className={`text-option-sms mb-0 ${optionSMS === 3 ? 'text-12B064' : ''}`}>2 {languageUI.year}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`mt-6 d-flex gap-3  h-100 overflow-y-scroll optionSMS scroll-hidden ${optionSMS === 1 ? '' : 'd-none'}`}>
                                {ListPacked.map((item, i) => Number(item?.package_expiry) === 1 && (
                                    <SubscriotionCardItems key={`month${i}`} logoSubsription={item?.iconlink ? (targetImg + item?.iconlink) : logoMonthSubscripCardBasic} bytime="1" cardExpiration={item?.package_name} contentCard={item?.description ?? ''} fill={item?.corlor} numberSMS={item?.cms ?? 0} priceCard={item?.price ?? 0} priceSaveCard={item?.save ?? 0} typeCard={item?.typeCard} statusText={`${item?.id === MyPacked ? languageUI.current : languageUI.upgrade
                                        }`} handleClick={() => handlePopup(item?.id ?? 0, item?.cms ?? 0)} />
                                ))}


                            </div>
                            <div className={`mt-6 d-flex gap-3  h-100 overflow-y-scroll optionSMS  scroll-hidden ${optionSMS === 2 ? '' : 'd-none'}`}>
                                {ListPacked.map((item, i) => Number(item?.package_expiry) === 2 && (
                                    <SubscriotionCardItems key={`month${i}`} logoSubsription={item?.iconlink ? (targetImg + item?.iconlink) : logoMonthSubscripCardBasic} bytime="1" cardExpiration={item?.package_name} contentCard={item?.description ?? ''} fill={item?.corlor} numberSMS={item?.cms ?? 0} priceCard={item?.price ?? 0} priceSaveCard={item?.save ?? 0} typeCard={item?.typeCard} statusText={`${item?.id === MyPacked ? languageUI.current : languageUI.upgrade
                                        }`} handleClick={() => handlePopup(item?.id ?? 0, item?.cms ?? 0)} />
                                ))}
                            </div>
                            <div className={`mt-6 d-flex gap-3  h-100 overflow-y-scroll optionSMS scroll-hidden ${optionSMS === 3 ? '' : 'd-none'}`}>
                                {ListPacked.map((item, i) => Number(item?.package_expiry) === 3 && (
                                    <SubscriotionCardItems key={`month${i}`} logoSubsription={item?.iconlink ? (targetImg + item?.iconlink) : logoMonthSubscripCardBasic} bytime="1" cardExpiration={item?.package_name} contentCard={item?.description ?? ''} fill={item?.corlor} numberSMS={item?.cms ?? 0} priceCard={item?.price ?? 0} priceSaveCard={item?.save ?? 0} typeCard={item?.typeCard} statusText={`${item?.id === MyPacked ? languageUI.current : languageUI.upgrade
                                        }`} handleClick={() => handlePopup(item?.id ?? 0, item?.cms ?? 0)} />
                                ))}
                            </div>

                        </div> */}
          </div>
          <div className="box-hotline">
            <img src={iconHotline} alt="" />
            <div>
              <p className="mb-0 text-ultimate text-center mb-1">
                {languageUI.ultimatePackageCall}
              </p>
              <p className="mb-0 text-phonenumber-hotline text-center">
                1-{formatPhoneNumber(phoneAdmin)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ModalCancelSubscription
        handleClose={dismisModalCancelSubscription}
        show={showModalCancelSubscription}
        handleRemoveSubscription={handleRemoveSubscription}
      />
      <ModalUpgragePage
        handleClose={() => setModalPayment(false)}
        show={modalPayment}
        handlePayment={handlePayment}
      />
      <ModalOTPSubscriptionCancel
        handleClose={() => setShowModalCancelSubscriptionOTP(false)}
        show={showModalCancelSubscriptionOTP}
        HandleSubmit={() => cancelPackage(UserIdStore, MyPacked)}
        UserIdStore={UserIdStore}
      />
      <ModalChangeCardSubcription
        show={showModalChangeCard}
        handleClose={dismisModalCancelSubscriptionOTP}
        bank_CVC={bankCVC}
        bank_date_card={bankDateCard}
        bank_number={bankNumber}
        UserIdStore={UserIdStore ?? 0}
      />
    </>
  );
};
export default SubscriptionOwner;
