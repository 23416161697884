import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
// components
import PageTitle from "../../components/PageTitle";
import Table from "./components/tableGroupCustomer/Table";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Request from "../../api/Request";
import ModalLoading from "../../components/ModalLoading";
import * as GroupCustomerAdminAPI from "../../api/apiAdmin/GroupCustomerAdmin";
import Swal from "sweetalert2";
import { Switch } from "@mui/material";
import ModalResetAccount from "./components/ModalResetAccount/ModalResetAccount";
import React from "react";
import ModalSendEmailToGroup from "./components/ModalSendEmailToGroup/ModalSendEmailToGroup";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import { calender } from "../../components/ImgExport";
import Select from "react-select";
import ModalChangeLastMonth from "./components/ModalChangeLastMonth/ModalChangeLastMonth";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ModalChangeLastYear from "./components/ModalChangeLastYear/ModalChangeLastYear";
import { FormatDolla, FormatNumber } from "../../utils/format";
import moment from "moment-timezone";
import ModalChangeGoogleReview from "./components/ModalChangeGoogleReview/ModalChangeGoogleReview";

const GroupCustomerAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const languageUI = getLanguageCheckIn();
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [messSendAll, setMessSendAll] = useState("");
  const [Type, setType] = useState<number>(1);
  const [ListOwner, setListOwner] = useState<GroupCustomerAdminAPI.listOwner[]>(
    []
  );
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalSendEmail, setShowModalSendEmail] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState("allCustomer");
  const [modalSendAllMessage, setModalSendAllMessage] = useState(false);
  const [messSendOnlyOneOwner, setMessSendOnlyOneOwner] = useState("");

  const [modalSendMessage, setModalSendMessage] = useState(false);
  const [addSendMessage, setAddSendMessage] = useState(false);
  const [quantityAddSMS, setQuantityAddSMS] = useState(0);
  const [ownerId, setOwnerId] = useState<number>(0);
  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");
  const [checkErrorMessSendAll, setCheckErrorMessSendAll] = useState(false);
  const [showModalResetAccount, setShowModalResetAccount] = useState(false);

  const [errMessSendAll, setErMessSendAll] = useState("");
  const [checkErrorMessSendOneOwner, setCheckErrorMessSendOneOwner] =
    useState(false);
  const [errMessSendOneOwner, setErrMessSendOneOwner] = useState("");
  const typeRef = useRef<number>(0);
  const windowWidth = window.innerWidth;
  const [total_Owner, setTotal_Owner] = useState<number>(0);
  const [total_Owner_Active, setTotal_Owner_Active] = useState<number>(0);
  const [total_Owner_No_Active, setTotal_Owner_No_Active] = useState<number>(0);
  const [lenghtTable, setLenghtTable] = useState<number>(0);
  const [yearSearch, setYearSearch] = useState<number>(
    new Date().getFullYear()
  );
  const [nextYearSearch, setNextYearSearch] = useState<number>();
  const [targetIDCustomer, setTargetIDCustomer] = useState<number>();
  const [lastYearLastMonth, setLastYearLastMonth] = useState<number>();
  const [dateGoogleReview, setDateGoogeReview] = useState<string>();
  const [ratingGoogleReview, setRatingGoogleReview] = useState<number>();
  const [startsGoogleReview, setStartsGoogleReview] = useState<number>();

  const [showModalChangeLastYear, setShowModalChangeLastYear] =
    useState<boolean>(false);
  const [showModalChangeGoogleReview, setShowModalChangeGoogleReview] =
    useState<boolean>(false);
  const [showModalChangeLastMonth, setShowModalChangeLastMonth] =
    useState<boolean>(false);

  const dismisModalChangeLastYear = () => {
    setShowModalChangeLastYear(false);
  };
  const dismisModalChangeGoogleReview = () => {
    setShowModalChangeGoogleReview(false);
  };
  const dismisModalChangeLastMonth = () => {
    setShowModalChangeLastMonth(false);
  };

  useEffect(() => {
    setLenghtTable(ListOwner.length);
  }, [ListOwner]);
  const dismisModalResetAccount = () => {
    setShowModalResetAccount(false);
  };
  const dismisModalSendEmail = () => {
    setShowModalSendEmail(false);
  };

  const GetListOwner = async (
    years: number,
    nextYear: number = 0,
    typeCustomer: number = 0
  ) => {
    if (UserIdStore) {
      setShowLoading(true);

      const ListOwner = await GroupCustomerAdminAPI.ListOwner(
        UserIdStore,
        Type,
        typeCustomer,
        years,
        nextYear
      );
      if (Number(ListOwner.status) === 1) {
        setListOwner(ListOwner?.data ?? []);
        setShowLoading(false);
      } else {
        setListOwner([]);
        setShowLoading(false);
      }
    } else {
      setShowLoading(false);
      navigate("/web-admin/login");
    }
  };

  const countTotalOwwner = async (Type: any) => {
    if (UserIdStore) {
      // setShowLoading(true);
      const TotalOwner = await GroupCustomerAdminAPI.cTotalOwner(Type);
      // console.log(TotalOwner);
      if (Number(TotalOwner.status) === 1) {
        setTotal_Owner(
          TotalOwner?.data.total_owner ? TotalOwner?.data.total_owner : 0
        );
        setTotal_Owner_Active(
          TotalOwner?.data.total_owner_active
            ? TotalOwner?.data.total_owner_active
            : 0
        );
        setTotal_Owner_No_Active(
          TotalOwner?.data.total_owner_no_active
            ? TotalOwner?.data.total_owner_no_active
            : 0
        );
      } else {
      }
      // setShowLoading(false);
    } else {
      setShowLoading(false);
      navigate("/web-admin/login");
    }
  };

  const handleAddSMS = async (idOwner: number) => {
    if (Number(quantityAddSMS) === 0) {
      setCheckError(true);
      setErMess(languageUI.PleaseenteraddSMSnumber);
      return;
    }
    setCheckError(false);
    setErMess("");
    await Request.fetchAPI("/admin/addSMS.php", "POST", {
      admin_id: UserIdStore,
      owner_id: idOwner,
      cms: quantityAddSMS,
    })
      .then((res) => {
        if (Number(res.status) === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: languageUI.AddSmssuccess,
            showConfirmButton: false,
            timer: 3500,
          });
          setQuantityAddSMS(0);
          GetListOwner(yearSearch);
        }
      })
      .catch((err: any) => {
        console.log("err", err);
        Swal.fire({
          position: "center",
          icon: "error",
          title: languageUI.AddSmsErrortryagain,
          showConfirmButton: false,
          timer: 3500,
        });
      });
    setAddSendMessage(false);
  };

  const sendAllMessageForOwner = async () => {
    if (messSendAll === "") {
      setCheckErrorMessSendAll(true);
      setErMessSendAll(languageUI.PleaseenterMessage);
      return;
    }
    if (messSendAll.length >= 160) {
      setCheckErrorMessSendAll(true);
      setErMessSendAll(
        languageUI.Messagescannotexceed160charactersPleasereentermessages
      );
      return;
    }
    if (activeTab === "allCustomer") {
      typeRef.current = 1;
    } else if (activeTab === "basicMonthlyCustomer") {
      typeRef.current = 2;
    } else if (activeTab === "basicYearlyCustomer") {
      typeRef.current = 3;
    } else if (activeTab === "basic5YearsCustomer") {
      typeRef.current = 4;
    } else if (activeTab === "largeMonthlyCustomer") {
      typeRef.current = 5;
    } else if (activeTab === "largeYearlyCustomer") {
      typeRef.current = 6;
    } else if (activeTab === "large5YearsCustomer") {
      typeRef.current = 7;
    } else if (activeTab === "supperMonthlyCustomer") {
      typeRef.current = 8;
    } else if (activeTab === "supperYearlyCustomer") {
      typeRef.current = 9;
    } else if (activeTab === "supper5YearsCustomer") {
      typeRef.current = 10;
    }
    if (UserIdStore) {
      setShowLoading(true);
      const SendAllSMS = await GroupCustomerAdminAPI.SendAllSMSForOwner(
        UserIdStore,
        typeRef.current,
        messSendAll
      );
      if (Number(SendAllSMS.status) === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${languageUI.Sentmessagesuccess}: ${SendAllSMS?.success?.length} & ${languageUI.error}: ${SendAllSMS?.fail?.length}`,
          showConfirmButton: true,
        });
        setMessSendAll("");
        setModalSendAllMessage(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: languageUI.SomethingwentwrongPleasetryagainlater,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setShowLoading(false);
      setCheckErrorMessSendAll(false);
      setErMessSendAll("");
      setMessSendAll("");
      setModalSendAllMessage(false);
    }
  };

  const sendMessageForOneOwner = async () => {
    if (messSendOnlyOneOwner === "") {
      setCheckErrorMessSendOneOwner(true);
      setErrMessSendOneOwner(languageUI.PleaseenterMessage);
      return;
    }
    if (UserIdStore) {
      setShowLoading(true);
      const SendSMS = await GroupCustomerAdminAPI.SendSMSOwner(
        UserIdStore,
        ownerId,
        messSendOnlyOneOwner
      );
      if (Number(SendSMS.status) === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: languageUI.Sentmessagesuccess,
          showConfirmButton: false,
          timer: 1500,
        });
        setMessSendOnlyOneOwner("");
        setModalSendMessage(false);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          // title: languageUI.SomethingwentwrongPleasetryagainlater,
          title: SendSMS?.message
            ? SendSMS?.message
            : languageUI.SomethingwentwrongPleasetryagainlater,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setShowLoading(false);
      setCheckErrorMessSendOneOwner(false);
      setErrMessSendOneOwner("");
      setMessSendOnlyOneOwner("");
      setModalSendMessage(false);
    }
  };
  const handleShowSendEmail = () => {
    setShowModalSendEmail(true);
  };
  const handleShowMess = () => {
    setModalSendAllMessage(true);
  };

  interface NavTabItemProps {
    id: any;
    eventKey: string;
    navTitle: string;
  }

  const listNavTabItem = [
    {
      id: "1",
      eventKey: "allCustomer",
      name: languageUI.Allcustomer,
    },
    {
      id: "2",
      eventKey: "basicMonthlyCustomer",
      name: languageUI.Basicmonthlycustomer,
    },
    {
      id: "3",
      eventKey: "basicYearlyCustomer",
      name: languageUI.Basicyearlycustomer,
    },
    {
      id: "4",
      eventKey: "basic5YearsCustomer",
      name: languageUI.Basic2yearscustomer,
    },
    {
      id: "5",
      eventKey: "largeMonthlyCustomer",
      name: languageUI.Largemonthlycustomer,
    },
    {
      id: "6",
      eventKey: "largeYearlyCustomer",
      name: languageUI.Largeyearlycustomer,
    },
    {
      id: "7",
      eventKey: "large5YearsCustomer",
      name: languageUI.Large2yearscustomer,
    },
    {
      id: "8",
      eventKey: "supperMonthlyCustomer",
      name: languageUI.Suppermonthlycustomer,
    },
    {
      id: "9",
      eventKey: "supperYearlyCustomer",
      name: languageUI.Supperyearlycustomer,
    },
    {
      id: "10",
      eventKey: "supper5YearsCustomer",
      name: languageUI.Supper2yearscustomer,
    },
  ];

  const NavTabItem = ({ id, eventKey, navTitle }: NavTabItemProps) => {
    return (
      <>
        <Nav.Item as="li" className="mb-1">
          <Nav.Link
            className="cursor-pointer ms-0 px-1 fs-6 py-1"
            eventKey={eventKey}
            onClick={() => {
              setType(id);
              countTotalOwwner(id);
            }}
          >
            {navTitle}
          </Nav.Link>
        </Nav.Item>
      </>
    );
  };

  const changeValueLastYear = async (value: number) => {
    if (targetIDCustomer) {
      const req = await GroupCustomerAdminAPI.updateIncomeCustomer(
        targetIDCustomer,
        0,
        value
      );
      if (req?.status === 1) {
        dismisModalChangeLastYear();
        var data = ListOwner.map((item) => {
          return Number(item?.customer_id) === Number(targetIDCustomer)
            ? { ...item, income_lastyear: value }
            : item;
        });
        setListOwner(data);
        // GetListOwner(yearSearch);
      }
    }
  };

  const changeValueGoogle = async (
    date: string,
    rating: string,
    starts: string
  ) => {
    if (targetIDCustomer) {
      console.log(date);

      const req = await GroupCustomerAdminAPI.updateGoogleReview(
        targetIDCustomer,
        date,
        rating,
        starts
      );
      if (req?.status === 1) {
        dismisModalChangeGoogleReview();
        var data = ListOwner.map((item) => {
          return Number(item?.customer_id) === Number(targetIDCustomer)
            ? {
                ...item,
                google_review_date: date,
                google_review_rating: rating,
                google_review_stars: starts,
              }
            : item;
        });
        setListOwner(data);
        // GetListOwner(yearSearch);
      }
    }
  };

  const changeValueLastMonth = async (value: number) => {
    if (targetIDCustomer) {
      const req = await GroupCustomerAdminAPI.updateIncomeCustomer(
        targetIDCustomer,
        1,
        value
      );
      if (req?.status === 1) {
        dismisModalChangeLastMonth();
        // GetListOwner(yearSearch);
        var data = ListOwner.map((item) => {
          return Number(item?.customer_id) === Number(targetIDCustomer)
            ? { ...item, income_lastmonth: value }
            : item;
        });
        setListOwner(data);
      }
    }
  };

  const columns = [
    {
      Header: `#`,
      accessor: "index",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div>{Number(cell.row.index) + 1}</div>
        </>
      ),
    },
    {
      Header: languageUI.Customername,
      accessor: "customerName",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div>{cell.row.original?.customerName}</div>
          <div>{cell.row.original?.cellPhone}</div>
          <div>{cell.row.original?.email}</div>
        </>
      ),
    },

    {
      Header: languageUI.StoreInfo,
      accessor: "storeInfo",
      Footer: "hide",
      Cell: (cell: any) => (
        <>
          <div>{cell.row.original?.storeName}</div>
          <div>
            {cell.row.original?.address +
              (cell.row.original?.suit_of_unit ? ", " : "") +
              cell.row.original?.suit_of_unit +
              (cell.row.original?.store_city ? ", " : "") +
              cell.row.original?.store_city +
              (cell.row.original?.store_state ? ", " : "") +
              cell.row.original?.store_state +
              (cell.row.original?.zipcode ? ", " : "") +
              cell.row.original?.zipcode}
          </div>
          <div>{cell.row.original?.storePhone}</div>
          <div className="text-primary text-truncate w-200px">
            {cell.row.original?.googleID}
          </div>
        </>
      ),
      sort: false,
    },
    {
      Header: "suit_of_unit",
      accessor: "suit_of_unit",
      Footer: "hide",
      Cell: (cell: any) => (
        <>
          <div>{cell.row.original?.suit_of_unit}</div>
        </>
      ),
      sort: false,
    },
    {
      Header: languageUI.Datejoin,
      accessor: "pagageDateJoin",
      Footer: "hide",
      Cell: (cell: any) => (
        <>
          <div>
            {cell.row.original?.packageName} / {cell.row.original?.packageType}
          </div>
          <div className="text-primary text-truncate" style={{ width: 120 }}>
            {cell.row.original?.packageDateJoin}
          </div>
        </>
      ),
      sort: false,
    },
    {
      Header: languageUI.Dateexpire,
      accessor: "packageDateExpire",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => {
        const d = moment(
          cell.row.original?.packageDateJoin,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");
        const currentDate = new Date(d); // Thay '2023-09-26' bằng ngày bạn muốn cộng

        if (Number(cell.row.original?.package_expiry) === 1) {
          currentDate.setMonth(currentDate.getMonth() + 1);
        } else if (Number(cell.row.original?.package_expiry) === 2) {
          currentDate.setFullYear(currentDate.getFullYear() + 1);
        } else if (Number(cell.row.original?.package_expiry) === 3) {
          currentDate.setFullYear(currentDate.getFullYear() + 2);
        }

        return (
          <>
            <div>{moment(currentDate).format("MM/DD/YYYY")}</div>
          </>
        );
      },
    },
    {
      Header: languageUI.remain,
      accessor: "smsRemain",
      Footer: "hide",
      sort: false,
    },
    {
      Header: languageUI.Lastcard,
      accessor: "lastCard",
      Footer: "hide",
      sort: false,
    },
    // {
    //   Header: `Rating when joint`,
    //   accessor: "ratingWhenJoint",
    //   Footer: "hide",
    //   sort: false,
    // },
    // {
    //   Header: `Rating now`,
    //   accessor: "ratingNow",
    //   Footer: "hide",
    //   sort: false,
    // },
    {
      Header: languageUI.Active,
      accessor: "account",
      Footer: "hide",
      sort: false,

      Cell: (cell: any) => (
        <>
          <Switch
            className="z-index--99"
            checked={Number(cell.row.original?.status) === 0}
            onClick={() => {
              ActiveAccount(
                Number(cell.row.original?.customer_id),
                Number(cell.row.original?.status) === 0 ? 1 : 0
              );
            }}
          />
        </>
      ),
    },
    {
      Header: languageUI.View,
      accessor: "view",
      Footer: "hide",
      sort: false,
      align: "center",
      Cell: (cell: any) => (
        <>
          <div className="d-flex gap-3 justify-content-center">
            <button
              className="btn-function-group-customer flex-shrink-0"
              onClick={() => {
                setAddSendMessage(true);
                setOwnerId(cell?.row?.original?.customer_id);
              }}
            >
              {languageUI.AddSMS}
            </button>
            <button
              className="btn-function-group-customer"
              onClick={() => {
                setOwnerId(cell?.row?.original?.customer_id);
                setShowModalResetAccount(true);
              }}
            >
              {languageUI.Reset}
            </button>
            <a
              href={
                Request.URLWeb +
                "/web-owner/gotoowner/" +
                cell?.row?.original?.cryptor_id
              }
              target="_blank"
            >
              <button className="btn-function-group-customer">
                {languageUI.Access}
              </button>
            </a>
          </div>
        </>
      ),
    },
    {
      Header: "Last year",
      accessor: "lastyear",
      Footer: "hide",
      sort: false,

      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-between align-items-center gap-2">
            {FormatDolla(Number(cell.row.original?.income_lastyear))}
            <button
              className="btn-function-group-customer"
              onClick={() =>
                handleEditModalChangeLastYear(
                  cell.row.original?.customer_id,
                  Number(cell.row.original?.income_lastyear)
                )
              }
            >
              <CreateOutlinedIcon className="text-white" />
            </button>
          </div>
        </>
      ),
    },
    {
      Header: "Last month",
      accessor: "lastmonth",
      Footer: "hide",
      sort: false,

      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-between align-items-center gap-2">
            {FormatDolla(Number(cell.row.original?.income_lastmonth))}
            <button
              className="btn-function-group-customer"
              onClick={() =>
                handleEditModalChangeLastMonth(
                  cell.row.original?.customer_id,
                  Number(cell.row.original?.income_lastmonth)
                )
              }
            >
              <CreateOutlinedIcon className="text-white" />
            </button>
          </div>
        </>
      ),
    },
    {
      Header: "Google Review",
      accessor: "googleReview",
      Footer: "hide",
      sort: false,

      Cell: (cell: any) => (
        <>
          <div className="d-flex flex-shrink-0 justify-content-between align-items-center gap-2 w-100">
            <div className="flex-shrink-0 d-flex flex-column align-items-center justify-content-center">
              <div className="flex-shrink-0">
                {cell.row.original?.google_review_date ?? "--/--/----"}
              </div>
              <div className="d-flex">
                {cell.row.original?.google_review_rating
                  ? cell.row.original?.google_review_rating
                  : 0}{" "}
                (
                {cell.row.original?.google_review_stars
                  ? Number(cell.row.original?.google_review_stars)
                  : 0}
                )
              </div>
            </div>
            <button
              className="btn-function-group-customer"
              onClick={() =>
                handleEditModalChangeGoogleReview(
                  cell.row.original?.customer_id,
                  cell.row.original?.google_review_date,
                  cell.row.original?.google_review_rating,
                  cell.row.original?.google_review_stars
                )
              }
            >
              <CreateOutlinedIcon className="text-white" />
            </button>
          </div>
        </>
      ),
    },
    {
      Header: "Jan",
      accessor: "jan",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center align-items-center flex-column">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Jan.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Jan.countCustomer))}
            </p>
            {(Number(cell.row.original?.Jan.star_number) !== 0 ||
              Number(cell.row.original?.Jan.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Jan.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Jan.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Feb",
      accessor: "feb",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Feb.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Feb.countCustomer))}
            </p>
            {(Number(cell.row.original?.Feb.star_number) !== 0 ||
              Number(cell.row.original?.Feb.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Feb.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Feb.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Mar",
      accessor: "mar",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Mar.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Mar.countCustomer))}
            </p>
            {(Number(cell.row.original?.Mar.star_number) !== 0 ||
              Number(cell.row.original?.Mar.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Mar.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Mar.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Apr",
      accessor: "apr",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Apr.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Apr.countCustomer))}
            </p>
            {(Number(cell.row.original?.Apr.star_number) !== 0 ||
              Number(cell.row.original?.Apr.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Apr.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Apr.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "May",
      accessor: "may",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.May.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.May.countCustomer))}
            </p>
            {(Number(cell.row.original?.May.star_number) !== 0 ||
              Number(cell.row.original?.May.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.May.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.May.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Jun",
      accessor: "jun",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Jun.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Jun.countCustomer))}
            </p>
            {(Number(cell.row.original?.Jun.star_number) !== 0 ||
              Number(cell.row.original?.Jun.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Jun.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Jun.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "July",
      accessor: "july",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Jul.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Jul.countCustomer))}
            </p>
            {(Number(cell.row.original?.Jul.star_number) !== 0 ||
              Number(cell.row.original?.Jul.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Jul.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Jul.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Aug",
      accessor: "aug",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Aug.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Aug.countCustomer))}
            </p>
            {(Number(cell.row.original?.Aug.star_number) !== 0 ||
              Number(cell.row.original?.Aug.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Aug.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Aug.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Sep",
      accessor: "sep",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Sep.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Sep.countCustomer))}
            </p>
            {(Number(cell.row.original?.Sep.star_number) !== 0 ||
              Number(cell.row.original?.Sep.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Sep.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Sep.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Oct",
      accessor: "oct",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Oct.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Oct.countCustomer))}
            </p>
            {(Number(cell.row.original?.Oct.star_number) !== 0 ||
              Number(cell.row.original?.Oct.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Oct.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Oct.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Nov",
      accessor: "nov",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Nov.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Nov.countCustomer))}
            </p>
            {(Number(cell.row.original?.Nov.star_number) !== 0 ||
              Number(cell.row.original?.Nov.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Nov.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Nov.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Dec",
      accessor: "dec",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.Dec.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.Dec.countCustomer))}
            </p>
            {(Number(cell.row.original?.Dec.star_number) !== 0 ||
              Number(cell.row.original?.Dec.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.Dec.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.Dec.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "next_jan",
      accessor: "next_jan",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center align-items-center flex-column">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Jan.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Jan.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Jan.star_number) !== 0 ||
              Number(cell.row.original?.next_Jan.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Jan.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Jan.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Feb",
      accessor: "next_feb",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Feb.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Feb.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Feb.star_number) !== 0 ||
              Number(cell.row.original?.next_Feb.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Feb.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Feb.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Mar",
      accessor: "next_mar",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Mar.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Mar.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Mar.star_number) !== 0 ||
              Number(cell.row.original?.next_Mar.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Mar.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Mar.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Apr",
      accessor: "next_apr",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Apr.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Apr.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Apr.star_number) !== 0 ||
              Number(cell.row.original?.next_Apr.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Apr.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Apr.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "May",
      accessor: "next_may",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_May.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_May.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_May.star_number) !== 0 ||
              Number(cell.row.original?.next_May.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_May.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_May.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Jun",
      accessor: "next_jun",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Jun.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Jun.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Jun.star_number) !== 0 ||
              Number(cell.row.original?.next_Jun.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Jun.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Jun.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "July",
      accessor: "next_july",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Jul.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Jul.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Jul.star_number) !== 0 ||
              Number(cell.row.original?.next_Jul.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Jul.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Jul.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Aug",
      accessor: "next_aug",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Aug.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Aug.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Aug.star_number) !== 0 ||
              Number(cell.row.original?.next_Aug.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Aug.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Aug.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Sep",
      accessor: "next_sep",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Sep.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Sep.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Sep.star_number) !== 0 ||
              Number(cell.row.original?.next_Sep.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Sep.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Sep.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Oct",
      accessor: "next_oct",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Oct.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Oct.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Oct.star_number) !== 0 ||
              Number(cell.row.original?.next_Oct.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Oct.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Oct.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Nov",
      accessor: "next_nov",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Nov.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Nov.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Nov.star_number) !== 0 ||
              Number(cell.row.original?.next_Nov.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Nov.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Nov.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Dec",
      accessor: "next_dec",
      Footer: "hide",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div className="px-2 d-flex justify-content-center flex-column align-items-center">
            <p className="m-0 w-fit">
              {FormatDolla(Number(cell.row.original?.next_Dec.income))}
            </p>
            <p className="m-0 w-fit">
              {FormatNumber(Number(cell.row.original?.next_Dec.countCustomer))}
            </p>
            {(Number(cell.row.original?.next_Dec.star_number) !== 0 ||
              Number(cell.row.original?.next_Dec.user_ratings_total) !== 0) && (
              <p className="m-0 w-fit">
                {cell.row.original?.next_Dec.star_number +
                  "(" +
                  FormatNumber(
                    Number(cell.row.original?.next_Dec.user_ratings_total)
                  ) +
                  ")"}
              </p>
            )}
          </div>
        </>
      ),
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   Footer: "hide",
    //   sort: false,
    //   Cell: (cell: CellValue) => {
    //     return (
    //       <>
    //         <Dropdown>
    //           <Dropdown.Toggle
    //             variant="outline-primary"
    //             className="p-0 rounded-circle square-btn d-flex"
    //           >
    //             <i className="fe-more-horizontal m-auto"></i>
    //           </Dropdown.Toggle>

    //           <Dropdown.Menu>
    //             <Dropdown.Item
    //               href="#"
    //               onClick={() => {
    //                 setModalSendMessage(true);
    //                 setOwnerId(cell?.row?.original?.customer_id);
    //               }}
    //             >
    //               Send SMS
    //             </Dropdown.Item>
    //             <Dropdown.Item
    //               href="#"
    //               onClick={() => {
    //                 setAddSendMessage(true);
    //                 setOwnerId(cell?.row?.original?.customer_id);
    //               }}
    //             >
    //               Add SMS
    //             </Dropdown.Item>
    //             <Link to="/web-admin/account-staff" className="dropdown-item">
    //               <div>Start and stop service</div>
    //             </Link>
    //             <Link
    //               to={`/web-admin/edit-setting-customer-infor/${cell?.row?.original?.customer_id}`}
    //               className="dropdown-item"
    //             >
    //               <div>Setting function</div>
    //             </Link>
    //             <Link
    //               to={`/web-admin/qrcode/${cell?.row?.original?.customer_id}`}
    //               className="dropdown-item"
    //             >
    //               <div>View QR Code</div>
    //             </Link>
    //           </Dropdown.Menu>
    //         </Dropdown>
    //       </>
    //     );
    //   },
    // },
  ];

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "100",
      value: 100,
    },
  ];

  let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
  let menuItemElOwner = document?.querySelectorAll(".menu-owner");
  menuItemElAdmin.forEach((item, i) => {
    menuItemElAdmin[i]?.classList.remove("d-none");
  });
  menuItemElOwner.forEach((item, i) => {
    menuItemElOwner[i]?.classList.add("d-none");
  });

  const ActiveAccount = async (owner: number, status: number) => {
    Swal.fire({
      title: "Confirm",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setShowLoading(true);
        const req = await GroupCustomerAdminAPI.setActiveAccount(owner, status);
        if (Number(req?.status) === 1) {
          setListOwner(
            ListOwner.map((item) => {
              return Number(item?.customer_id) === owner
                ? { ...item, status: status }
                : item;
            })
          );
        }
        setShowLoading(false);
      }
    });
  };

  const ReloadFormMessage = () => {
    setCheckErrorMessSendAll(false);
    setErMessSendAll("");
    setMessSendAll("");
    setModalSendAllMessage(false);
  };

  const ReloadForm = () => {
    setListOwner([]);
    setType(1);
    setShowLoading(false);
  };

  const handleChangeYearOption = (value: number) => {
    setYearSearch(value);
    GetListOwner(value);
  };
  const handleChangeNextYearOption = (value: number) => {
    setNextYearSearch(value);
    GetListOwner(yearSearch, value, 0);
  };

  const handleEditModalChangeLastYear = (
    customer_id: number,
    value: number
  ) => {
    setShowModalChangeLastYear(true);
    setTargetIDCustomer(customer_id);
    setLastYearLastMonth(value);
  };

  const handleEditModalChangeLastMonth = (
    customer_id: number,
    value: number
  ) => {
    setShowModalChangeLastMonth(true);
    setTargetIDCustomer(customer_id);
    setLastYearLastMonth(value);
  };
  const handleEditModalChangeGoogleReview = (
    customer_id: number,
    date: string,
    rating: number,
    star_number: number
  ) => {
    setShowModalChangeGoogleReview(true);
    setTargetIDCustomer(customer_id);
    setDateGoogeReview(date);
    setRatingGoogleReview(rating);
    setStartsGoogleReview(star_number);
  };

  useEffect(() => {
    if (UserIdStore) {
      ReloadForm();
    }
  }, [location.pathname, UserIdStore]);

  useEffect(() => {
    if (UserIdStore) {
      GetListOwner(yearSearch);
      countTotalOwwner(Type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Type]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      {/* modal send all sms */}
      <Modal
        show={modalSendAllMessage}
        // eslint-disable-next-line no-sequences
        onHide={() => (setModalSendAllMessage(false), ReloadFormMessage())}
        centered
        className="modal-rounded-16px"
      >
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title>
            {languageUI.Message} ({lenghtTable})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <Form.Group>
            <Form.Control
              as="textarea"
              placeholder={languageUI.Messagescanonlycontain160characters}
              rows={3}
              maxLength={160}
              value={messSendAll}
              onChange={(e: any) => {
                setMessSendAll(e.target.value);
              }}
            />
            <p style={{ color: "#f1556c", textAlign: "center" }}>
              {checkErrorMessSendAll ? errMessSendAll : ""}
            </p>{" "}
          </Form.Group>
          <p className="text-align-end">{messSendAll.length}/160</p>
          {/* <label htmlFor="messLength">Number of message characters: </label>
          <input
            className="border border-dark rounded mx-2 text-center"
            style={{ width: "40px" }}
            type="text"
            name="messLength"
            value={messSendAll.length}
            readOnly
          /> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-end border-top">
          <Button variant="primary" onClick={sendAllMessageForOwner}>
            {languageUI.Send}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal send sms */}
      <Modal
        show={modalSendMessage}
        // eslint-disable-next-line no-sequences
        onHide={() => (setModalSendMessage(false), ReloadFormMessage())}
      >
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title>{languageUI.Message}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <Form.Group>
            <Form.Control
              as="textarea"
              placeholder={languageUI.Messagescanonlycontain160characters}
              rows={3}
              value={messSendOnlyOneOwner}
              onChange={(e: any) => {
                setMessSendOnlyOneOwner(e.target.value);
              }}
            />
            <p style={{ color: "#f1556c", textAlign: "center" }}>
              {checkErrorMessSendOneOwner ? errMessSendOneOwner : ""}
            </p>{" "}
          </Form.Group>
          <label htmlFor="messLength">
            {languageUI.Numberofmessagecharacters}:{" "}
          </label>
          <input
            className="border border-dark rounded mx-2 text-center"
            style={{ width: "40px" }}
            type="text"
            name="messLength"
            value={messSendOnlyOneOwner.length}
            readOnly
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-end border-top">
          <Button variant="primary" onClick={sendMessageForOneOwner}>
            {languageUI.Send}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal add SMS */}
      <Modal
        centered
        show={addSendMessage}
        onHide={() => setAddSendMessage(false)}
        className="modal-reset-account"
      >
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title className="text-20px text-414151f">
            {languageUI.AddSMS}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <Form.Group>
            <p className="text-16px m-0 text-414151f">
              {languageUI.EnterNumberSMS}:
            </p>
            <input
              className="w-100 text-16px text-black focus-visiable-none mt-2"
              style={{
                height: "40px",
                padding: "5px 15px",
                borderRadius: "5px",
                border: "1px solid #aaaaaa",
              }}
              type="number"
              placeholder={languageUI.addquantitysms}
              min="0"
              value={quantityAddSMS}
              onChange={(e: any) => {
                setQuantityAddSMS(e.target.value);
              }}
            />
            <p style={{ color: "#f1556c", marginTop: "5px" }}>
              {checkError ? errMess : ""}
            </p>{" "}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-end border-top">
          <Button
            variant="primary"
            className="font-bold rounded-3 text-white"
            onClick={() => handleAddSMS(ownerId)}
          >
            {languageUI.Add}
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalChangeLastMonth
        dismis={dismisModalChangeLastMonth}
        handleChangeLastMonth={changeValueLastMonth}
        show={showModalChangeLastMonth}
        value={lastYearLastMonth ?? 0}
      />
      <ModalChangeLastYear
        dismis={dismisModalChangeLastYear}
        handleChangeLastYear={changeValueLastYear}
        show={showModalChangeLastYear}
        value={lastYearLastMonth ?? 0}
      />
      <ModalChangeGoogleReview
        dismis={dismisModalChangeGoogleReview} //
        handleChangeGoogleReview={changeValueGoogle}
        show={showModalChangeGoogleReview}
        dateValue={dateGoogleReview ?? ""}
        ratingValue={ratingGoogleReview ?? undefined}
        startsValue={startsGoogleReview ?? undefined}
      />
      <div className="container-group-customer">
        <PageTitle
          breadCrumbItems={[
            { label: "Customer", path: "#" },
            { label: "Group customer", path: "#", active: true },
          ]}
          title={"Group customer"}
        />
        <div className="py-3 px-2 rounded-3 mb-3 bg-white shadow overflow-hidden">
          <Row className="mb-3">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <Nav
                  as="ul"
                  variant="pills"
                  className="navtab-bg"
                  defaultActiveKey={"allCustomer"}
                >
                  {listNavTabItem.map((item, index) => (
                    <NavTabItem
                      key={index}
                      navTitle={`${item.name}`}
                      eventKey={`${item.eventKey}`}
                      id={`${item.id}`}
                    />
                  ))}
                </Nav>
              </div>
            </Col>
          </Row>
          <Col className="mx-auto">
            <Row className="mb-4">
              <Col xs={12} sm={12} md={4} lg={4}>
                <div className="px-3 text-center">
                  <i className="fad fa-users text-primary fa-2x"></i>
                  <h2
                    className="text-primary fw-bold my-2 position-relative"
                    style={{ lineHeight: 1, top: 3 }}
                  >
                    {total_Owner}
                  </h2>
                  <h4 className="fw-700 mt-0">{languageUI.Totalowner}</h4>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div
                  className={`my-2 my-sm-2 my-md-0 my-lg-0 px-3 text-center ${
                    windowWidth > 575 ? "border-start border-end" : ""
                  }`}
                >
                  <i className="fad fa-check-circle text-primary fa-2x"></i>
                  <h2
                    className="text-primary fw-bold my-2 position-relative"
                    style={{ lineHeight: 1, top: 3 }}
                  >
                    {total_Owner_Active}
                  </h2>
                  <h4 className="fw-700 mt-0">{languageUI.Active}</h4>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div className="px-3 text-center">
                  <i className="fad fa-user-times text-primary fa-2x"></i>
                  <h2
                    className="text-primary fw-bold my-2 position-relative"
                    style={{ lineHeight: 1, top: 3 }}
                  >
                    {total_Owner_No_Active}
                  </h2>
                  <h4 className="fw-700 mt-0">{languageUI.Stopservice}</h4>
                </div>
              </Col>
            </Row>
          </Col>
          <div className="">
            <Table
              // overflowDropdown={overflowDropdown}
              columns={columns}
              data={ListOwner}
              pageSize={5}
              sizePerPageList={sizePerPageList}
              isSortable={true}
              pagination={true}
              isSearchable={true}
              // isSelectable={true}
              FilterCustomer={GetListOwner}
              handleShowMess={handleShowMess}
              handleShowSendEmail={handleShowSendEmail}
              yearSearch={yearSearch}
              nextYearSearch={nextYearSearch}
              handleChangeYearOption={handleChangeYearOption}
              handleChangeNextYearOption={handleChangeNextYearOption}
            />
          </div>
          {/* <Tab.Container
          defaultActiveKey={activeTab}
          onSelect={(tab: any) => setActiveTab(tab)}
        >
       
          <Tab.Content className="pt-0">
            <Tab.Pane eventKey="allCustomer"></Tab.Pane>
            <Tab.Pane eventKey="basicMonthlyCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  // isSelectable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="basicYearlyCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  // isSelectable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="basic5YearsCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                  // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="largeMonthlyCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                  // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="largeYearlyCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                  // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="large5YearsCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  // isSelectable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="supperMonthlyCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                  // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="supperYearlyCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                  // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="supper5YearsCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>
                    {languageUI.SendSMSAllCustomer}
                  </Button>
                </div>
                <Table
                  // overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListOwner}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                  FilterCustomer={GetListOwner}
                  handleShowMess={handleShowMess}
                  handleShowSendEmail={handleShowSendEmail}
                  // isSelectable={true}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container> */}
          <ModalSendEmailToGroup
            show={showModalSendEmail}
            handleClose={dismisModalSendEmail}
          />
          <ModalResetAccount
            handleClose={dismisModalResetAccount}
            show={showModalResetAccount}
            ownerId={ownerId}
          />
        </div>
      </div>
    </>
  );
};

export default GroupCustomerAdmin;
