import Topbar from "../../../../layouts/Topbar";
import Select from "react-select";
import { calender } from "../../../../components/ImgExport";
import "./Styles.css";
import React, { useEffect, useRef, useState } from "react";
import * as reportApi from "../../../../api/apiOwner/reportApi";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import ModalLoading from "../../../../components/ModalLoading";
import { LineChart } from "@mui/x-charts";
import EmailIcon from "@mui/icons-material/Email";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SouthEastIcon from "@mui/icons-material/South";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { useNavigate } from "react-router-dom";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { formatCurrencyVND, FormatDolla } from "../../../../utils/format";
import { format } from "date-fns";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getLanguageUI } from "../../../../Services/languageUI";
import Swal from "sweetalert2";
import { getLanguageUser } from "../../../../Services/languageUser";

const Report: React.FC = () => {
  const languageUI = getLanguageUI().reportPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [listDaySearch, setListDaySearch] = useState<
    { value: string; dayName: string }[]
  >([]);
  const [weeksInYear, setWeeksInYear] = useState<
    { start: string; end: string; label: string }[]
  >([]);
  const [weekStartValue, setWeekStartValue] = useState<string>("");
  const [MonthInYear, setMonthInYear] = useState<
    { label: string; value: number }[]
  >([]);
  const [monthSelected, setMonthSelected] = useState<number>(1);
  const [quarterId, setquarterId] = useState<number>(1);
  const [quartersInYear, setQuartersInYear] = useState<
    { id: Number; label: string; start: string; end: string }[]
  >([]);
  const [reportDay, setReportDay] = useState<reportApi.ReportResult>();
  const [reportWeek, setReportWeek] = useState<reportApi.ReportResult>();
  const [reportMonth, setReportMonth] = useState<reportApi.ReportResult>();
  const [reportQuarter, setReportQuarter] = useState<reportApi.ReportResult>();
  const [reportChart, setReportChart] = useState<reportApi.ReportResult[]>([]);
  const [listYearSelect, setListYearSelect] = useState<reportApi.ReportYear[]>(
    []
  );
  const [yearReport, setYearReport] = useState<number>(
    new Date().getFullYear()
  );
  const [reportByYear, setReportByYear] =
    useState<reportApi.ResultReportYear>();
  const DateNow = moment().format("YYYY-MM-DD");
  const navigate = useNavigate();
  const captureRef = useRef<HTMLDivElement>(null);

  const gInverToryCustomer = async (
    timestart: string = "",
    timeend: string = ""
  ) => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportInventory(
        UserIdStore,
        timestart,
        timeend
      );
      if (Number(req?.status) === 1) {
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  const greportDishes = async (
    timestart: string = "",
    timeend: string = "",
    sort: number = 0
  ) => {
    if (UserIdStore) {
      if (!ShowLoading) {
        setShowLoading(true);
      }
      const req = await reportApi.gReportDishis(
        UserIdStore,
        timestart,
        timeend,
        sort
      );
      if (Number(req?.status) === 1) {
        // setListDishis(req?.data ?? [])
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  useEffect(() => {
    gInverToryCustomer();
    greportDishes();
  }, []);

  const xLabels = [
    languageUI.jan,
    languageUI.feb,
    languageUI.mar,
    languageUI.april,
    languageUI.may,
    languageUI.jun,
    languageUI.jul,
    languageUI.aug,
    languageUI.sept,
    languageUI.october,
    languageUI.nov,
    languageUI.dec,
  ];

  //////////////////////////////////////////////////////////////////////////////////

  // lấy năm
  const gListYear = async () => {
    if (UserIdStore) {
      const res = await reportApi.gComboYearReport(UserIdStore);
      if (Number(res.status) === 1) {
        setListYearSelect(res.data);
      }
    }
  };
  //lấy 30 ngày
  const get30Day = () => {
    var today = new Date(
      moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)")
    );
    var listDay: { dayName: string; value: string }[] = [];
    for (let i = 0; i <= 30; i++) {
      var priorDate = new Date(
        new Date(
          moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)")
        ).setDate(today.getDate() - i)
      );
      if (i === 0) {
        let item = {
          dayName: languageUI.today,
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        // setDaySelected(moment(priorDate).format('YYYY-MM-DD'))
        gReportByDay(moment(priorDate).format("YYYY-MM-DD"));
        listDay.push(item);
      } else if (i === 1) {
        let item = {
          dayName: languageUI.yesterday,
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        listDay.push(item);
      } else {
        let item = {
          dayName: moment(priorDate).format("MM-DD-YYYY"),
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        listDay.push(item);
      }
    }
    setListDaySearch([...listDay]);
  };

  //lấy tuần của năm
  function getWeeksInYear() {
    const years = new Date(
      moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)")
    ).getFullYear(); // Năm bạn muốn hiển thị
    const firstDayOfYear = new Date(years, 0, 1); // Ngày đầu tiên của năm
    const lastDayOfYear = new Date(years, 12, 31); // Ngày cuối cùng của năm

    // Tính toán ngày thứ 2 đầu tiên
    // const dayOfWeek = firstDayOfYear.getDay();
    let firstMonday = firstDayOfYear;
    while (firstMonday.getDay() !== 1) {
      firstMonday.setDate(firstMonday.getDate() + 1);
    }
    const weeks = [];
    let currentWeekStart = firstMonday;

    while (currentWeekStart <= lastDayOfYear) {
      const currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekStart.getDate() + 6); // Set đến cuối tuần
      var dayStart = moment(new Date(currentWeekStart)).format("YYYY-MM-DD");
      var dayEnd = moment(new Date(currentWeekEnd)).format("YYYY-MM-DD");
      if (DateNow >= dayStart && DateNow <= dayEnd) {
        setWeekStartValue(dayStart);
        // setWeekEndValue(dayEnd)

        weeks.push({
          start: dayStart,
          end: dayEnd,
          label:
            format(new Date(dayStart), "MMM dd") + ` (${languageUI.thisWeek})`,
        });
        gReportByWeek(dayStart, dayEnd);
      } else {
        weeks.push({
          start: dayStart,
          end: dayEnd,
          label: format(new Date(dayStart), "MMM dd"),
        });
      }

      currentWeekStart = new Date(currentWeekEnd);
      currentWeekStart.setDate(currentWeekEnd.getDate() + 1); // Bắt đầu tuần mới
    }

    setWeeksInYear(weeks);

    // setTimeout(() => {
    //     scrollToElement()
    // }, 1000);
  }
  //lấy tháng của năm
  function getMonthInYear() {
    const monthNow = Number(moment().format("M"));

    const month = [
      {
        label: languageUI.january,
        value: 1,
      },
      {
        label: languageUI.february,
        value: 2,
      },
      {
        label: languageUI.march,
        value: 3,
      },
      {
        label: languageUI.april,
        value: 4,
      },
      {
        label: languageUI.may,
        value: 5,
      },
      {
        label: languageUI.june,
        value: 6,
      },
      {
        label: languageUI.july,
        value: 7,
      },
      {
        label: languageUI.august,
        value: 8,
      },
      {
        label: languageUI.september,
        value: 9,
      },
      {
        label: languageUI.october,
        value: 10,
      },
      {
        label: languageUI.november,
        value: 11,
      },
      {
        label: languageUI.december,
        value: 12,
      },
    ];
    setMonthSelected(monthNow);
    setMonthInYear(month);
    gReportBillbyMonth(monthNow);
  }
  //lấy quý của năm
  const GetQuarters = () => {
    const listQuy = [
      {
        id: 1,
        start: "01-01",
        end: "03-31",
        label: `${languageUI.jan} - ${languageUI.mar}`,
      },
      {
        id: 2,
        start: "04-01",
        end: "06-30",
        label: `${languageUI.apr} - ${languageUI.jun}`,
      },
      {
        id: 3,
        start: "07-01",
        end: "09-30",
        label: `${languageUI.jul} - ${languageUI.sept}`,
      },
      {
        id: 4,
        start: "10-01",
        end: "12-31",
        label: `${languageUI.oct} - ${languageUI.dec}`,
      },
    ];
    const month = moment().format("MM");
    let start = "";
    let end = "";
    if (Number(month) < 4) {
      setquarterId(1);
      start = "01-01";
      end = "03-31";
    } else if (Number(month) < 7) {
      setquarterId(2);
      start = "04-01";
      end = "06-31";
    } else if (Number(month) < 10) {
      setquarterId(3);
      start = "07-01";
      end = "09-31";
    } else {
      setquarterId(4);
      start = "10-01";
      end = "12-31";
    }
    gReportbyQuater(start, end);
    setQuartersInYear(listQuy);
  };

  const handleOnchageWeek = (e: any) => {
    setWeekStartValue(e?.value ?? "");
    // setWeekEndValue(e?.end ?? '')
    gReportByWeek(e?.value, e?.end);
  };

  const handleOnchageQuarter = (e: any) => {
    // setDateStart(e?.start ?? '')
    // setDateEnd(e?.end ?? '')

    setquarterId(e?.id ?? 0);
    gReportbyQuater(e?.start, e?.end);
  };

  //thay đổi search date
  const handleOnchageDate = (e: any) => {
    // setDaySelected(e?.value);
    gReportByDay(e?.value);
  };
  //thay đổi month
  const handleOnchageMonth = (e: any) => {
    setMonthSelected(e?.value ?? 1);
    gReportBillbyMonth(e?.value);
  };

  //lấy report theo ngày
  const gReportByDay = async (dayReport: string) => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportDetailbyDay(UserIdStore, dayReport);
      if (Number(req?.status) === 1) {
        setReportDay(req?.data);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  //lấy report theo tuần
  const gReportByWeek = async (day_start: string, day_end: string) => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportBillbyWeek(
        UserIdStore,
        day_start,
        day_end
      );
      if (Number(req?.status) === 1) {
        setReportWeek(req?.data);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  //lấy report theo tháng
  const gReportBillbyMonth = async (month: number) => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportBillbyMonth(UserIdStore, month);
      if (Number(req?.status) === 1) {
        setReportMonth(req?.data);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  //lấy report theo tháng
  const gReportChart = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportMoneyTimeChart(UserIdStore);
      if (Number(req?.status) === 1) {
        setReportChart(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  //lấy report theo quý
  const gReportbyQuater = async (day_start: string, day_end: string) => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportBillbyQuater(
        UserIdStore,
        day_start,
        day_end
      );
      if (Number(req?.status) === 1) {
        setReportQuarter(req?.data);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  // report theo năm
  const reportByYearSelect = async (yearReport: number) => {
    setYearReport(yearReport);
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.reportByYear(UserIdStore, yearReport);
      if (Number(req?.status) === 1) {
        setReportByYear(req.data);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  const [imgTest, setImgTest] = useState<File>();

  const DownloadAsImage = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const capture: any = document.querySelector(".export-dpf");
      const imgEmail = await html2canvas(capture).then(function (canvas) {
        return canvas.toDataURL().split(",")[1];
        // console.log(canvas);
      });
      const req = await reportApi.sendReportToMail(UserIdStore, imgEmail);
      if (Number(req?.status) === 1) {
        setShowLoading(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Send to mail success!",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setShowLoading(false);
        Swal.fire({
          position: "center",
          icon: "warning",
          title: req?.message ?? "Send to mail fail!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const downloadPDF = () => {
    const capture: any = document.querySelector(".export-dpf");
    if (capture) {
      html2canvas(capture).then((canvas) => {
        // const canvas = await html2canvas(elementRef.current);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("receipt.pdf");

        // const imgData = canvas.toDataURL("img/png");

        // const doc = new jsPDF({ orientation: "landscape" });
        // const componentWidth = doc.internal.pageSize.getWidth();
        // const componentHeight = doc.internal.pageSize.getHeight();
        // doc.addImage(imgData, "PNG", 0, 0);
        // doc.save("receipt.pdf");
      });
    }
  };

  const downloadReportYear = () => {
    if (captureRef.current) {
      html2canvas(captureRef.current)
        .then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "report-year.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error capturing the screenshot:", error);
        });
    }
  };
  useEffect(() => {
    gReportChart();
    getWeeksInYear();
    get30Day();
    getMonthInYear();
    GetQuarters();
    gListYear();
    reportByYearSelect(new Date().getFullYear());
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="p-4 mt-18 w-100 box-container-report">
        <div className="d-flex justify-content-between">
          <div className="w-48 bg-white rounded-5 p-4 ">
            <div className="d-flex align-items-center">
              <div className="w-100">
                <p className="text-title-chart-report">
                  {languageUI.moneyTime}
                </p>
                <LineChart
                  height={400}
                  legend={{
                    hidden: true,
                  }}
                  series={[
                    {
                      data: reportChart.map((item) => {
                        return item?.totalIncome;
                      }),
                      label: "Income",
                      color: "#4198d7",
                    },
                    // {
                    //   data: reportChart.map((item) => {
                    //     return item?.profit;
                    //   }),
                    //   label: "Profit",
                    //   color: "#f6c85f",
                    // },
                    {
                      data: reportChart.map((item) => {
                        return item?.totalSalary;
                      }),
                      label: "Salary",
                      color: "#6f4e7c",
                    },
                    // {
                    //   data: reportChart.map((item) => {
                    //     return item?.totalImport;
                    //   }),
                    //   label: "Import",
                    //   color: "#9dd866",
                    // },
                    {
                      data: reportChart.map((item) => {
                        return item?.totalCard;
                      }),
                      label: "Visa",
                      color: "#ca472f",
                    },
                    {
                      data: reportChart.map((item) => {
                        return item?.totalCash;
                      }),
                      label: "Cash",
                      color: "#ffa056",
                    },
                  ]}
                  xAxis={[{ scaleType: "point", data: xLabels }]}
                />
              </div>
              <div className="flex-shrink-0 d-flex gap-2 flex-column">
                <div className="d-flex align-items-center  gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#4198d7" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.income}</p>
                </div>
                {/* <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#f6c85f" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.profit}</p>
                </div> */}
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#6f4e7c" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.salary}</p>
                </div>
                {/* <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#9dd866" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.import}</p>
                </div> */}
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#ca472f" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.visa}</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#ffa056" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.cash}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-48  bg-white rounded-5 p-4">
            <div className="flex align-items-center  justify-content-between">
              <div className="w-100">
                <p className="text-title-chart-report">
                  {languageUI.customersOverTime}
                </p>
                <LineChart
                  height={400}
                  legend={{
                    hidden: true,
                  }}
                  series={[
                    // {
                    //   data: reportChart.map((item) => {
                    //     return item?.totalCustomer2Time;
                    //   }),
                    //   label: "Return Customer",
                    //   curve: "catmullRom",
                    //   color: "#6633CC",
                    // },
                    {
                      data: reportChart.map((item) => {
                        return item?.totalCustomerNew;
                      }),
                      label: "New Customer",
                      curve: "catmullRom",
                      color: "#E67300",
                    },
                    {
                      data: reportChart.map((item) => {
                        return item?.totalCoupon;
                      }),
                      label: "Coupons",
                      curve: "catmullRom",
                      color: "#8B0707",
                    },
                    // {
                    //   data: reportChart.map((item) => {
                    //     return item?.orderOnline;
                    //   }),
                    //   label: "Order",
                    //   curve: "catmullRom",
                    //   color: "#329262",
                    // },
                    // {
                    //   data: reportChart.map((item) => {
                    //     return item?.totalGood;
                    //   }),
                    //   label: "Good Review",
                    //   curve: "catmullRom",
                    //   color: "#5574A6",
                    // },
                    // {
                    //   data: reportChart.map((item) => {
                    //     return item?.totalBad;
                    //   }),
                    //   label: "Bad Review",
                    //   curve: "catmullRom",
                    //   color: "#651067",
                    // },
                  ]}
                  xAxis={[{ scaleType: "point", data: xLabels }]}
                />
              </div>
              <div className="flex-shrink-0 d-flex gap-2 flex-column">
                {/* <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#6633CC" }}
                  ></div>
                  <p className="text-lable-legend">
                    {languageUI.returnCustomer}
                  </p>
                </div> */}
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#E67300" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.newCustomer}</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#8B0707" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.coupons}</p>
                </div>
                {/* <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#329262" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.order}</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#5574A6" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.goodReview}</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div
                    className="box-lable-chart-line"
                    style={{ backgroundColor: "#651067" }}
                  ></div>
                  <p className="text-lable-legend">{languageUI.badReview}</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 rounded-5 bg-white mt-3 p-4">
          <div className="w-100 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button
                className="btn-email-download-report"
                onClick={() => {
                  navigate("/web-owner/report/incomedetail");
                }}
              >
                <QueryStatsIcon className="me-1" />
                {languageUI.detailIncome}
              </button>
              <button
                className="btn-email-download-report"
                onClick={DownloadAsImage}
              >
                <EmailIcon className="me-1" />
                Email
              </button>
              {/* <button
                className="btn-email-download-report"
                onClick={downloadPDF}
              >
                <FileDownloadIcon className="me-1" />
                {languageUI.download}
              </button> */}
            </div>
          </div>
          <div className="d-flex box-table-report mt-3 rounded-5 export-dpf">
            <div className="column-box-report p-4">
              <p className="text-title-table-report">{languageUI.date}</p>
              <div className="d-flex align-items-center border  px-2  rounded-8px h-48px w-fit">
                <img src={calender} alt="" />
                <Select
                  className="react-select react-select-container w-180px  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.today}
                  options={listDaySearch.map((item) => {
                    return {
                      value: item.value,
                      label: item.dayName,
                    };
                  })}
                  onChange={(e: any) => handleOnchageDate(e)}
                />
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.income}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportDay?.totalIncome
                      ? formatCurrencyVND(reportDay?.totalIncome)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.import}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportDay?.totalImport
                      ? FormatDolla(reportDay?.totalImport)
                      : 0}
                  </p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.salary}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportDay?.totalSalary
                      ? FormatDolla(reportDay?.totalSalary)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.saleTax}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportDay?.totalTax ? formatCurrencyVND(reportDay?.totalTax) : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.profit}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    ${reportDay?.profit ? FormatDolla(reportDay?.profit) : 0}
                  </p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.coupons}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportDay?.totalCoupon
                      ? FormatDolla(reportDay?.totalCoupon)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Tip</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportDay?.totalTip ? formatCurrencyVND(reportDay?.totalTip) : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlineToGo}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlinePickUp}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.cash}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportDay?.totalCash
                      ? formatCurrencyVND(reportDay?.totalCash)
                      : 0}{" "}
                    ({reportDay?.cashPercen}%)
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Credit card</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportDay?.totalCard
                      ? formatCurrencyVND(reportDay?.totalCard)
                      : 0}{" "}
                    ({reportDay?.cardPercen}%)
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.googlePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.applePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.totalCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportDay?.totalAllCustomer
                        ? FormatDolla(reportDay?.totalAllCustomer)
                        : 0
                    )}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.newCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportDay?.totalCustomerNew
                        ? formatCurrencyVND(reportDay?.totalCustomerNew)
                        : 0
                    )}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.returnCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportDay?.totalCustomer2Time
                      ? FormatDolla(reportDay?.totalCustomer2Time)
                      : 0}
                  </p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.goodReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportDay?.totalGood
                      ? FormatDolla(reportDay?.totalGood)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.badReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportDay?.totalBad ? FormatDolla(reportDay?.totalBad) : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.reseveTable}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
            </div>
            <div className="column-box-report p-4">
              <p className="text-title-table-report">{languageUI.week}</p>
              <div className="d-flex align-items-center border  px-2  rounded-8px h-48px w-fit">
                <img src={calender} alt="" />
                <Select
                  className="react-select react-select-container w-180px  border-select-none"
                  classNamePrefix="react-select"
                  value={
                    weeksInYear
                      .map((item) => {
                        return {
                          value: item.start,
                          label: item.label,
                        };
                      })
                      .filter((dv) => dv.value === weekStartValue)[0]
                  }
                  placeholder={languageUI.thisWeek}
                  options={weeksInYear.map((item) => {
                    return {
                      value: item.start,
                      label: item.label,
                      end: item.end,
                    };
                  })}
                  onChange={(e: any) => handleOnchageWeek(e)}
                />
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.income}
                </div>
                <div className="w-50 text-box-content-report d-flex gap-2">
                  <p className="mb-0">

                    {reportWeek?.totalIncome
                      ? formatCurrencyVND(reportWeek?.totalIncome)
                      : 0}
                  </p>
                  <div
                    className="d-flex align-items-center text-box-content-report"
                    style={{
                      color: `${Number(reportWeek?.percentIncome) > 0
                          ? "#0B2F88"
                          : "red"
                        }`,
                    }}
                  >
                    <p className="mb-0">
                      {Number(reportWeek?.percentIncome) > 0
                        ? Number(reportWeek?.percentIncome)
                        : Number(reportWeek?.percentIncome) * -1}
                      %
                    </p>
                    {Number(reportWeek?.percentIncome) > 0 ? (
                      <ArrowUpwardRoundedIcon className="icon-report-up" />
                    ) : (
                      <SouthEastIcon className="icon-report-up-down" />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.import}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportWeek?.totalImport
                      ? FormatDolla(reportWeek?.totalImport)
                      : 0}
                  </p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.salary}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportWeek?.totalSalary
                      ? FormatDolla(reportWeek?.totalSalary)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.saleTax}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportWeek?.totalTax
                      ? formatCurrencyVND(reportWeek?.totalTax)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.profit}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    ${reportWeek?.profit ? FormatDolla(reportWeek?.profit) : 0}
                  </p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.coupons}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportWeek?.totalCoupon
                      ? FormatDolla(reportWeek?.totalCoupon)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Tip</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportWeek?.totalTip
                      ? formatCurrencyVND(reportWeek?.totalTip)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlineToGo}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlinePickUp}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.cash}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportWeek?.totalCash
                      ? formatCurrencyVND(reportWeek?.totalCash)
                      : 0}{" "}
                    ({reportWeek?.cashPercen}%)
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Credit card</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportWeek?.totalCard
                      ? formatCurrencyVND(reportWeek?.totalCard)
                      : 0}{" "}
                    ({reportWeek?.cardPercen}%)
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.googlePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.applePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.totalCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportWeek?.totalAllCustomer
                        ? FormatDolla(reportWeek?.totalAllCustomer)
                        : 0
                    )}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.newCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportWeek?.totalCustomerNew
                        ? formatCurrencyVND(reportWeek?.totalCustomerNew)
                        : 0
                    )}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.returnCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportWeek?.totalCustomer2Time
                      ? FormatDolla(reportWeek?.totalCustomer2Time)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.goodReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportWeek?.totalGood
                      ? FormatDolla(reportWeek?.totalGood)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.badReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportWeek?.totalBad
                      ? FormatDolla(reportWeek?.totalBad)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.reseveTable}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
            </div>
            <div className="column-box-report p-4">
              <p className="text-title-table-report">{languageUI.month}</p>
              <div className="d-flex align-items-center border  px-2  rounded-8px h-48px w-fit">
                <img src={calender} alt="" />
                <Select
                  className="react-select react-select-container w-180px  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder="Month"
                  isClearable={true}
                  options={MonthInYear}
                  value={
                    MonthInYear.filter((dv) => dv.value === monthSelected)[0]
                  }
                  onChange={(e: any) => handleOnchageMonth(e)}
                />
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.income}
                </div>
                <div className="w-50 text-box-content-report d-flex gap-2">
                  <p className="mb-0">

                    {reportMonth?.totalIncome
                      ? formatCurrencyVND(reportMonth?.totalIncome)
                      : 0}
                  </p>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      color: `${Number(reportMonth?.percentIncome) > 0
                          ? "#0B2F88"
                          : "red"
                        }`,
                    }}
                  >
                    <p className="mb-0">
                      {Number(reportMonth?.percentIncome) > 0
                        ? Number(reportMonth?.percentIncome)
                        : Number(reportMonth?.percentIncome) * -1}
                      %
                    </p>
                    {Number(reportMonth?.percentIncome) > 0 ? (
                      <ArrowUpwardRoundedIcon className="icon-report-up" />
                    ) : (
                      <SouthEastIcon className="icon-report-up-down" />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.import}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportMonth?.totalImport
                      ? FormatDolla(reportMonth?.totalImport)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.salary}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportMonth?.totalSalary
                      ? formatCurrencyVND(reportMonth?.totalSalary)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.saleTax}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportMonth?.totalTax
                      ? formatCurrencyVND(reportMonth?.totalTax)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.profit}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportMonth?.profit ? FormatDolla(reportMonth?.profit) : 0}
                  </p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.coupons}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportMonth?.totalCoupon
                      ? FormatDolla(reportMonth?.totalCoupon)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Tip</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportMonth?.totalTip
                      ? formatCurrencyVND(reportMonth?.totalTip)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlineToGo}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlinePickUp}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.cash}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportMonth?.totalCash
                      ? formatCurrencyVND(reportMonth?.totalCash)
                      : 0}{" "}
                    ({reportMonth?.cashPercen}%)
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Credit card</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportMonth?.totalCard
                      ? formatCurrencyVND(reportMonth?.totalCard)
                      : 0}{" "}
                    ({reportMonth?.cardPercen}%)
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.googlePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.applePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.totalCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportMonth?.totalAllCustomer
                        ? FormatDolla(reportMonth?.totalAllCustomer)
                        : 0
                    )}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.newCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportMonth?.totalCustomerNew
                        ? formatCurrencyVND(reportMonth?.totalCustomerNew)
                        : 0
                    )}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.returnCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportMonth?.totalCustomer2Time
                      ? FormatDolla(reportMonth?.totalCustomer2Time)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.goodReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportMonth?.totalGood
                      ? FormatDolla(reportMonth?.totalGood)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.badReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportMonth?.totalBad
                      ? FormatDolla(reportMonth?.totalBad)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">
                {languageUI.reseveTable}
              </div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">$1,00</p>
              </div>
              </div> */}
            </div>
            <div className="column-box-report p-4">
              <p className="text-title-table-report">{languageUI.quarter}</p>
              <div className="d-flex align-items-center border  px-2  rounded-8px h-48px w-fit">
                <img src={calender} alt="" />
                <Select
                  className="react-select react-select-container w-180px  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder="Quarter"
                  isClearable={true}
                  options={quartersInYear}
                  value={quartersInYear.filter((dv) => dv.id === quarterId)[0]}
                  onChange={(e: any) => handleOnchageQuarter(e)}
                />
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.income}
                </div>
                <div className="w-50 text-box-content-report d-flex gap-2">
                  <p className="mb-0">

                    {reportQuarter?.totalIncome
                      ? formatCurrencyVND(reportQuarter?.totalIncome)
                      : 0}
                  </p>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      color: `${Number(reportQuarter?.percentIncome) > 0
                          ? "#0B2F88"
                          : "red"
                        }`,
                    }}
                  >
                    <p className="mb-0">
                      {Number(reportQuarter?.percentIncome) > 0
                        ? Number(reportQuarter?.percentIncome)
                        : Number(reportQuarter?.percentIncome) * -1}
                      %
                    </p>
                    {Number(reportQuarter?.percentIncome) > 0 ? (
                      <ArrowUpwardRoundedIcon className="icon-report-up" />
                    ) : (
                      <SouthEastIcon className="icon-report-up-down" />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.import}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportQuarter?.totalImport
                      ? FormatDolla(reportQuarter?.totalImport)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.salary}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportQuarter?.totalSalary
                      ? formatCurrencyVND(reportQuarter?.totalSalary)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.saleTax}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportQuarter?.totalTax
                      ? formatCurrencyVND(reportQuarter?.totalTax)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.profit}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportQuarter?.profit
                      ? FormatDolla(reportQuarter?.profit)
                      : 0}
                  </p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.coupons}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportQuarter?.totalCoupon
                      ? FormatDolla(reportQuarter?.totalCoupon)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Tip</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportQuarter?.totalTip
                      ? formatCurrencyVND(reportQuarter?.totalTip)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlineToGo}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlinePickUp}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.cash}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportQuarter?.totalCash
                      ? (reportQuarter?.totalCash)
                      : 0}{" "}
                    ({reportQuarter?.cashPercen}%)
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Credit card</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportQuarter?.totalCard
                      ? (reportQuarter?.totalCard)
                      : 0}{" "}
                    ({reportQuarter?.cardPercen}%)
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.googlePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.applePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.totalCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportQuarter?.totalAllCustomer
                        ? FormatDolla(reportQuarter?.totalAllCustomer)
                        : 0
                    )}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.newCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportQuarter?.totalCustomerNew
                        ? formatCurrencyVND(reportQuarter?.totalCustomerNew)
                        : 0
                    )}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.returnCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportQuarter?.totalCustomer2Time
                      ? FormatDolla(reportQuarter?.totalCustomer2Time)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.goodReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportQuarter?.totalGood
                      ? FormatDolla(reportQuarter?.totalGood)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.badReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportQuarter?.totalBad
                      ? FormatDolla(reportQuarter?.totalBad)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">
                {languageUI.reseveTable}
              </div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">$1,00</p>
              </div>
              </div> */}
            </div>
            <div className="column-box-report p-4">
              <button className="btn-down-img" onClick={downloadReportYear}>
                {languageUI.download}
              </button>
              <p className="text-title-table-report">{languageUI.year}</p>
              <div className="d-flex align-items-center border  px-2  rounded-8px h-48px w-fit">
                <img src={calender} alt="" />
                <Select
                  className="react-select react-select-container w-180px  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.year}
                  isClearable={true}
                  options={listYearSelect.map((item) => {
                    return {
                      value: item.value,
                      label: item.year,
                    };
                  })}
                  onChange={(e: any) => reportByYearSelect(e.value)}
                />
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.income}
                </div>
                <div className="w-50 text-box-content-report d-flex gap-2">
                  <p className="mb-0">

                    {reportByYear?.totalIncome
                      ? formatCurrencyVND(reportByYear?.totalIncome)
                      : 0}
                  </p>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      color: `${Number(reportByYear?.percentIncome) > 0
                          ? "#0B2F88"
                          : "red"
                        }`,
                    }}
                  >
                    <p className="mb-0">
                      {reportByYear?.percentIncome
                        ? Number(reportByYear?.percentIncome) > 0
                          ? Number(reportByYear?.percentIncome)
                          : Number(reportByYear?.percentIncome) * -1
                        : "0"}
                      %
                    </p>
                    {Number(reportByYear?.percentIncome) > 0 ? (
                      <ArrowUpwardRoundedIcon className="icon-report-up" />
                    ) : (
                      <SouthEastIcon className="icon-report-up-down" />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.import}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportByYear?.totalImport
                      ? FormatDolla(reportByYear?.totalImport)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.salary}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportByYear?.totalSalary
                      ? formatCurrencyVND(reportByYear?.totalSalary)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.saleTax}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportByYear?.totalTax
                      ? formatCurrencyVND(reportByYear?.totalTax)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.profit}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportByYear?.profit
                      ? FormatDolla(reportByYear?.profit)
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.coupons}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    $
                    {reportByYear?.totalCoupon
                      ? FormatDolla(reportByYear?.totalCoupon)
                      : 0}
                  </p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Tip</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportByYear?.totalTip
                      ? formatCurrencyVND(reportByYear?.totalTip)
                      : 0}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlineToGo}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$0</p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.orderOnlinePickUp}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.cash}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportByYear?.totalCash
                      ? formatCurrencyVND(reportByYear?.totalCash)
                      : 0}{" "}
                    ({reportByYear?.cashPercen}%)
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">Credit card</div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">

                    {reportByYear?.totalCard
                      ? formatCurrencyVND(reportByYear?.totalCard)
                      : 0}{" "}
                    ({reportByYear?.cardPercen}%)
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.googlePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.applePay}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">$1,00</p>
                </div>
              </div> */}
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.totalCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportByYear?.totalAllCustomer
                        ? reportByYear?.totalAllCustomer
                        : 0
                    )}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.newCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {Number(
                      reportByYear?.totalCustomerNew
                        ? reportByYear?.totalCustomerNew
                        : 0
                    )}
                  </p>
                </div>
              </div>
              {/* <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.returnCustomer}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportByYear?.totalCustomer2Time
                      ? reportByYear?.totalCustomer2Time
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.goodReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportByYear?.totalGood ? reportByYear?.totalGood : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.badReview}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportByYear?.totalNuetral
                      ? reportByYear?.totalNuetral
                      : 0}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex mt-2 gap-2">
                <div className="w-50  text-box-content-report">
                  {languageUI.reseveTable}
                </div>
                <div className="w-50 text-box-content-report">
                  <p className="mb-0">
                    {reportByYear?.totalBad ? reportByYear?.totalBad : 0}
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------- */}
      {/* <div className=" bg-white">
                <div className=" mt-18 ">
                    <div className="d-flex justify-between  ml-12 pt-3">
                        <div className="w-100 flex items-center py-12px">
                            <span className="text-8 fw-600 font-urbansist mr-6 text-212134">Filter</span>
                            {selectBtn ? (
                                <div className="flex ">
                                    <div className="d-flex align-items-center border px-2 rounded-8px h-42px mr-6">
                                        <img src={calender} alt="" />
                                        <div className="w-100">
                                            <div className="form-group">
                                                <DateRangePicker

                                                    initialSettings={{
                                                        // startDate: new Date(),
                                                        showDropdowns: true,
                                                        autoUpdateInput: false,
                                                        locale: {
                                                            cancelLabel: 'Reset',
                                                            applyLabel: "Apply"
                                                        }
                                                    }}
                                                    onApply={handleApplyDishis}
                                                    onCancel={handleCancelDishis}
                                                >
                                                    <input type="text" className="form-control input-daterange-timepicker border-none w-195px" placeholder="Today" value={searchstartDishis != "" && searchendDishis != "" ? (moment(searchstartDishis).format('DD/MM/YYYY') + "-" + moment(searchendDishis).format('DD/MM/YYYY')) : ""} readOnly={true} />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center border  px-2  rounded-8px h-42px">
                                        <img src={calender} alt="" />
                                        <Select
                                            className="react-select react-select-container w-250px  border-select-none"
                                            classNamePrefix="react-select"
                                            defaultValue={[""]}
                                            placeholder="View by total"
                                            isClearable={true}
                                            options={Array2.map((item) => {
                                                return {
                                                    value: item.value,
                                                    label: item.dayName,
                                                };
                                            })}
                                            onChange={(e) => changeSort(e)}
                                        />
                                    </div>
                                </div>

                            ) : (
                                <div className="d-flex align-items-center border px-2 rounded-8px h-42px mr-6">
                                    <img src={calender} alt="" />
                                    <div className="w-100">
                                        <div className="form-group">
                                            <DateRangePicker

                                                initialSettings={{
                                                    // startDate: new Date(),
                                                    showDropdowns: true,
                                                    autoUpdateInput: false,
                                                    locale: {
                                                        cancelLabel: 'Đặt lại',
                                                        applyLabel: "Đồng ý"
                                                    }
                                                }}
                                                onApply={handleApply}
                                                onCancel={handleCancel}
                                            >
                                                <input type="text" className="form-control input-daterange-timepicker border-none w-195px" placeholder="Today" value={searchstart != "" && searchend != "" ? (moment(searchstart).format('DD/MM/YYYY') + "-" + moment(searchend).format('DD/MM/YYYY')) : ""} readOnly={true} />
                                            </DateRangePicker>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                        <div className="flex bg-#EFF3F1 custom-radius-table p-1 bg-EFF3F1 mr-12">
                            <div className={`pr-2 ${selectBtn == 0 ? "shadow-btn-group bg-white radius-2" : ""}`} onClick={() => handleSelectBtn(0)}>
                                <button className={`h-100 flex items-center  border-none  fw-600 text-18px px-2 ${selectBtn == 0 ? 'text-0FA54A bg-white radius-2' : 'text-4A4A6A'}`}>
                                    <InsertChartOutlinedIcon className="mr-1" />
                                    Overal
                                </button>
                            </div>
                            <div onClick={() => handleSelectBtn(1)} className={`${selectBtn == 1 ? "shadow-btn-group bg-white radius-2" : ""}`}>
                                <button className={`h-100 flex items-center  border-none  fw-600 text-18px px-2 ${selectBtn == 1 ? 'text-0FA54A bg-white radius-2 shadow-btn-group' : 'text-4A4A6A'}`}>
                                    {selectBtn ? (<img src={dishesGreen} className="mr-1"></img>) : (<img src={dishes} alt="" className="mr-1" />)}
                                    Dishes
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-y-scroll body-content-scroll">
                {selectBtn == 0 ?
                    <Overal IncomeValue={IncomeValue} chartCustomer={chartCustomer} chartPayment={chartPayment} totalAllCustomer={totalAllCustomer} totalCustomer2Time={totalCustomer2Time} totalCustomerNew={totalCustomerNew} totalIncome={totalIncome} valueMonthName={valueMonthName} />
                    :
                    <Dishes listDishis={listDishis} />
                }
            </div> */}

      <div className="hidden-print">
        <div className="">
          <div className="img-year-report" ref={captureRef}>
            <p className="text-title-table-report text-center">
              Year {yearReport}
            </p>
            <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">
                {languageUI.income}
              </div>
              <div className="w-50 text-box-content-report d-flex gap-2">
                <p className="mb-0">

                  {reportByYear?.totalIncome
                    ? formatCurrencyVND(reportByYear?.totalIncome)
                    : 0}
                </p>
                <div
                  className="d-flex align-items-center"
                  style={{
                    color: `${Number(reportByYear?.percentIncome) > 0
                        ? "#0B2F88"
                        : "red"
                      }`,
                  }}
                >
                  <p className="mb-0">
                    {reportByYear?.percentIncome
                      ? Number(reportByYear?.percentIncome) > 0
                        ? Number(reportByYear?.percentIncome)
                        : Number(reportByYear?.percentIncome) * -1
                      : "0"}
                    %
                  </p>
                  {Number(reportByYear?.percentIncome) > 0 ? (
                    <ArrowUpwardRoundedIcon className="icon-report-up" />
                  ) : (
                    <SouthEastIcon className="icon-report-up-down" />
                  )}
                </div>
              </div>
            </div>
            <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">
                {languageUI.salary}
              </div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">

                  {reportByYear?.totalSalary
                    ? formatCurrencyVND(reportByYear?.totalSalary)
                    : 0}
                </p>
              </div>
            </div>
            <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">
                {languageUI.saleTax}
              </div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">

                  {reportByYear?.totalTax
                    ? formatCurrencyVND(reportByYear?.totalTax)
                    : 0}
                </p>
              </div>
            </div>
            <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">Tip</div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">

                  {reportByYear?.totalTip
                    ? formatCurrencyVND(reportByYear?.totalTip)
                    : 0}
                </p>
              </div>
            </div>
            <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">
                {languageUI.cash}
              </div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">

                  {reportByYear?.totalCash
                    ? formatCurrencyVND(reportByYear?.totalCash)
                    : 0}{" "}
                  ({reportByYear?.cashPercen}%)
                </p>
              </div>
            </div>
            <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">Credit card</div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">

                  {reportByYear?.totalCard
                    ? formatCurrencyVND(reportByYear?.totalCard)
                    : 0}{" "}
                  ({reportByYear?.cardPercen}%)
                </p>
              </div>
            </div>
            <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">
                {languageUI.totalCustomer}
              </div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">
                  {Number(
                    reportByYear?.totalAllCustomer
                      ? reportByYear?.totalAllCustomer
                      : 0
                  )}
                </p>
              </div>
            </div>
            <div className="w-100 d-flex mt-2 gap-2">
              <div className="w-50  text-box-content-report">
                {languageUI.newCustomer}
              </div>
              <div className="w-50 text-box-content-report">
                <p className="mb-0">
                  {Number(
                    reportByYear?.totalCustomerNew
                      ? reportByYear?.totalCustomerNew
                      : 0
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Report;
