import * as Request from "../Request";

export interface Result<T> {
  status: number;
  message?: string;
  data: T;
  secret: string;
  paymentIntents: string;
  tipAmount: number;
}
//đăng ký và kết nói stripe
export const CreateLocationReader = async (
  owner_id: number,
  display_name: string,
  line1: string,
  city: string,
  country: string,
  postal_code: string,
  state: string,
  registration_code: string,
  label: string,
  pos_id: number
) => {
  return await Request.fetchAPI(`stripe/create_reader.php`, "POST", {
    owner_id,
    display_name,
    line1,
    city,
    country,
    postal_code,
    state,
    registration_code,
    label,
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
      };
    });
};
export const connectStripe = async (owner_id: number) => {
  return await Request.fetchAPI(`stripe/register_reader.php`, "POST", {
    owner_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
      };
    });
};
//xóa máy terminal và location
export const removeTerminalStripe = async (owner_id: number) => {
  return await Request.fetchAPI(`stripe/removeTerminal.php`, "POST", {
    owner_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
      };
    });
};
//tạo hóa đơn thanh toán
export const CreatePaymentIntent = async (
  owner_id: number,
  amount: number,
  pos_id: number
) => {
  return await Request.fetchAPI(`stripe/Create_paymentIntents.php`, "POST", {
    owner_id,
    amount,
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
        paymentIntents: "",
      };
    });
};
//tiến hành thanh toán
export const PaymentIntentStripe = async (
  owner_id: number,
  paymentIntents: string,
  pos_id: number
) => {
  return await Request.fetchAPI(`stripe/paymentWithReader.php`, "POST", {
    owner_id,
    paymentIntents,
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
        tipAmount: 0,
      };
    });
};
//chụp màn hình thanh toán
export const CapturePaymentIntent = async (
  owner_id: number,
  paymentIntentId: string
) => {
  return await Request.fetchAPI(`stripe/capture_payment_intent.php`, "POST", {
    owner_id,
    paymentIntentId,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
      };
    });
};
//huy thanh toán
export const CancelPaymentIntent = async (
  owner_id: number,
  paymentIntents: string,
  pos_id: number
) => {
  return await Request.fetchAPI(`stripe/cancel_payment_stripe.php`, "POST", {
    owner_id,
    paymentIntents,
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
      };
    });
};
//name reader
export const ChangeNameReaderStripe = async (
  owner_id: number,
  pos_id: number
) => {
  return await Request.fetchAPI(`stripe/gTerminalReaderOwner.php`, "POST", {
    owner_id,
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
      };
    });
};
