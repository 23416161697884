import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import "./Styles.css"
import ContentTableReview from './ContentTableReview';
import { IListReviewData } from '../../../../../api/apiOwner/ReviewOwner';
import { CustomerSelected } from '../../../../../api/apiOwner/customerApi';
import { ComboVoucher } from '../../../../../api/apiOwner/comboApi';
import { getLanguageUI } from '../../../../../Services/languageUI';

interface TableReviewProp {
    ListReview: IListReviewData[],
    handleCheckedAll: (e: boolean) => void;
    isCheckAll: boolean;
    handleChecked?: (e: boolean, customer: string, phone: string, name: string) => void;
    listCustomerSelected: CustomerSelected[]
    comboVoucher?: ComboVoucher[]
}

const TableReview: React.FC<TableReviewProp> = ({ ListReview = [], listCustomerSelected = [], comboVoucher = [], isCheckAll = false, handleCheckedAll, handleChecked }) => {
    const languageUI = getLanguageUI().reviewPage
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='w-10'><Checkbox checked={isCheckAll} disabled={ListReview.length === 0} onClick={(e: any) => handleCheckedAll(e.target.checked)} /></TableCell>
                            <TableCell className="text-12B064 text-4 fw-600 font-urbansist w-10">Points</TableCell>
                            <TableCell align="left" className="text-12B064 text-4 fw-600 font-urbansist w-16">{languageUI.customerName}</TableCell>
                            <TableCell align="left" className="text-12B064 text-4 fw-600 font-urbansist w-16">{languageUI.orderID}</TableCell>
                            <TableCell align="left" className="text-12B064 text-4 fw-600 font-urbansist w-16">{languageUI.review}</TableCell>
                            <TableCell align="left" className="text-12B064 text-4 fw-600 font-urbansist w-16">{languageUI.messageReview}</TableCell>
                            <TableCell align="left" className="text-12B064 text-4 fw-600 font-urbansist w-16">{languageUI.phone}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className='body-table-reivew'>
                        {ListReview.map((item, i) => (
                            <ContentTableReview message={item?.message} name={item?.username} orderId={item?.id} phone={item?.phone} comboVoucher={comboVoucher} point={item?.points} reviewName={item?.name} icon={item?.icon} reviewColor={item?.corlor} key={`rv${i}`} customer_id={item?.customer_id} checked={listCustomerSelected.some((row) => row?.customer_id === item?.customer_id)} handleChecked={handleChecked} />
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
export default TableReview