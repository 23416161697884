
import Modal from 'react-bootstrap/Modal';
import "./Styles.css"

import { thanksOrder } from '../../../../components/ImgExport';

interface SeeYourOrderProps {
    show: boolean;
    handleClose: () => void;
    HandleSeeOrder: () => void;
}
const SeeYourOrder = ({
    show,
    handleClose,
    HandleSeeOrder,
}: SeeYourOrderProps) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-see-your-order"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className='w-100 d-flex flex-column align-items-center'>
                    <p className='thanks-see-your-order'>Thank You!</p>
                    <p className='see-your-order-successfull'>Your order is successfull</p>
                    <img src={thanksOrder} alt="" />
                    <div className='btn-see-your-order mt-3' onClick={HandleSeeOrder}>
                        See your order
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default SeeYourOrder;