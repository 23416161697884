// import axios from "axios";
import axios from "axios";
const URL = "https://api.bpos.bdata.cloud/";
// const URL = "http://localhost/";
const URLWeb = "https://bpos.bdata.cloud";
// const URL = "http://192.168.1.88/";

const URL_QRCode = URLWeb + "/webcheckin/sendmenu";
const targetFood = URL + "owner/image/food/";
const targetImgage = URL + "owner/image/";
const targetImgadmin = URL + "admin/image/";
// const URL_SOCKET = "http://192.168.0.95:5006/";
// const URL_SOCKET = "https://socket.brijackposadmin.com";
const URL_SOCKET = "https://socket.bpos.bdata.cloud/";
const KeyGoogleMap = "AIzaSyDEg6BghG-ECBQmAxPblLiP4ZUg2evKSew";
const secretKeyTin = 'jsdfhiojsdghDucDaiBangfiuosghfkbnmdviuozhgwkeTinQuaDepTrairnmfkljHSHCFiuFJnbcBNhanGaQuaHIFNMdbKBDFIASDFvkjf';//key này dùng để mã hóa các chuỗi của tín
//

interface Option {
  router?: string;
}
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

export const fetchAPI = async (
  urlquest: string,
  method: string,
  params?: any
) => {
  if (method.toUpperCase() === "GET") {
    const response = await fetch(URL + urlquest, {
      method: method,
    });
    return response.json();
  } else if (method.toUpperCase() === "POST") {
    var bodyFormData = new FormData();
    for (let key in params) {
      bodyFormData.append(key, params[key]);
    }

    const response = await fetch(URL + urlquest, {
      method: method,
      body: bodyFormData,

    }).then((res) => res.json());
    return response;
  }
};

const API = (
  urlquest: string,
  method: string,
  params?: any,
  authorizationToken?: any
) => {
  var data: any = [];
  if (method.toUpperCase() === "GET") {
    return axios({
      url: URL + urlquest,
      method: "get",
      params: params,
    }).then((response: any) => {
      return response.data;
    });
  } else if (method.toUpperCase() === "POST") {

    return axios
      .post(URL + urlquest, { params })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => { });
  } else {
    return data;
  }
};

//chuyển text sang HTML
const ConvertHTML = (item: any) => {
  return {
    __html: item,
  };
};

export interface Response<T> {
  status?: number;
  message?: string;
  data?: T;
}

export {
  // get,
  // post,
  API,
  ConvertHTML,
};

export {
  URL_QRCode,
  targetFood,
  targetImgage,
  URL_SOCKET,
  KeyGoogleMap,
  URLWeb,
  targetImgadmin,
  secretKeyTin
};
