import { Form, Modal } from "react-bootstrap";
import "./styles.css";
import { useEffect, useState } from "react";
import { leaf1, leaf2 } from "../../../../components/ImgExport";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import { getLanguageUI } from "../../../../Services/languageUI";
import React from "react";
import * as accountHandheldApi from "../../../../api/apiOwner/accountHandheldApi";

interface ModalEditAccountHandheldProps {
  show: boolean;
  merchanIdEdit: string;
  authorizationEdit: string;
  ha_id: number;
  handleClose: () => void;
  setShowLoading: (load: boolean) => void;
  handleSuccess: () => void;
}

const ModalEditAccountHandheld = ({
  show,
  authorizationEdit,
  merchanIdEdit,
  ha_id,
  handleClose,
  setShowLoading,
  handleSuccess,
}: ModalEditAccountHandheldProps) => {
  const owner_id = useSelector(userSlice.selectorUserId);
  const languageUI = getLanguageUI().giftCard;
  const languageUI2 = getLanguageUI().handheldPOS;
  const [merchantId, setMerchantId] = useState("");
  const [authorization, setAuthorization] = useState("");
  const [messageError, setMessageError] = useState<string>("");

  const submitAddAccount = async () => {
    if (merchantId.trim() === "") {
      setMessageError(languageUI2.pleaseEnterMerchanId);
      return;
    }
    if (authorization.trim() === "") {
      setMessageError(languageUI2.pleaseEnterAuthorization);
      return;
    }
    if (owner_id) {
      setShowLoading(true);
      const req = await accountHandheldApi.editAcountUATHandheld(
        owner_id,
        merchantId,
        authorization,
        ha_id
      );
      if (Number(req?.status) === 1) {
        setShowLoading(false);
        handleSuccess();
      } else {
        setShowLoading(false);
        setMessageError(req?.message ?? "Bad request");
      }
    }
  };
  useEffect(() => {
    if (show) {
      setMessageError("");

      setMerchantId(merchanIdEdit);
      setAuthorization(authorizationEdit);
    }
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-import-inventory"
      >
        <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
          <Modal.Body>
            <div className="px-48px">
              <p className="text-delete-modal-customer mb-0 text-center">
                {languageUI2.editAccountHandheld}
              </p>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">MerchantId:</p>
                <div className="input-add-table">
                  <input
                    type="text"
                    className="border-none focus-visiable-none text-input-add-table px-2 w-100"
                    placeholder={"MerchantId"}
                    value={merchantId}
                    onChange={(e) => setMerchantId(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">Authorization:</p>
                <div className="input-add-table">
                  <input
                    type="text"
                    className="border-none focus-visiable-none text-input-add-table px-2 w-100"
                    placeholder={"Authorization"}
                    value={authorization}
                    onChange={(e) => setAuthorization(e.target.value)}
                  />
                </div>
              </div>
              {messageError && (
                <div className="text-center text-danger text-italic">
                  {messageError}
                </div>
              )}
              <div className="d-flex justify-content-between w-100 mt-6 gap-3">
                <div
                  className="btn-import-inventory-footer "
                  onClick={handleClose}
                >
                  {languageUI.back}
                </div>
                <div
                  className="btn-import-inventory-footer border-12B064 text-white bg-0B2F88"
                  onClick={submitAddAccount}
                >
                  {languageUI.save}
                </div>
              </div>
            </div>
            <img src={leaf1} alt="" className="position-leaf1-voucher" />
            <img src={leaf2} alt="" className="position-leaf2-voucher" />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalEditAccountHandheld;
