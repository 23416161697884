import { Accordion, Form, Modal } from "react-bootstrap";
import "../Styles.css"
import { calender, leaf1, leaf2, minusGreen, pencilEdit, plusGreen, searchGreen, strashRed, trashBlack, trashGray, trashRed, trashRedRemoveFloor } from "../../../../../components/ImgExport";
import Select from "react-select";
import { ComboUnit } from "../../../../../api/apiOwner/comboApi";
import * as InventoryApi from "../../../../../api/apiOwner/InventoryApi";
import { useEffect, useState } from "react";
import React from "react";
import { getLanguageUI } from "../../../../../Services/languageUI";




interface ModalCreateMatrialProps {
    show: boolean;
    comboUnit: ComboUnit[];
    setShowLoading: (e: boolean) => void;
    UserIdStore?: number | null;
    handleClose: () => void;
    handleSuccess: (mess: string) => void;
}

const ModalCreateMatrial = ({
    show, comboUnit = [], UserIdStore = 0, setShowLoading, handleSuccess,
    handleClose
}: ModalCreateMatrialProps) => {
    const languageUI = getLanguageUI().inventoryPage
    const [materialName, setMaterialName] = useState<string>('')
    const [unit_id, setUnit_id] = useState<string>('')
    const [messageError, setMessageError] = useState('')
    const handleOnchageUnit = (unitid: string) => {
        setUnit_id(unitid)
    }

    const SubmitAdd = async () => {
        if (UserIdStore) {
            if (materialName.trim() === '') {
                setMessageError(languageUI.materialNameRequired)
            } else if (!unit_id || unit_id === '') {
                setMessageError(languageUI.unitRequired);
            } else {
                setMessageError('')
                setShowLoading(true)
                const req = await InventoryApi.addMaterialByOwner(UserIdStore, materialName, unit_id);
                if (Number(req?.status) === 1) {
                    setShowLoading(false)
                    handleSuccess(languageUI.successAddedMaterial)
                } else {
                    setMessageError(req?.message ?? '')
                    setShowLoading(false)
                }
            }

        } else {
            setMessageError(languageUI.ownerNotFound)
        }

    }
    useEffect(() => {
        if (!show) {
            setMaterialName('')
            setUnit_id('')
            setMessageError('')
        }

    }, [show])
    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="modal-delete-customer">
                <Modal.Dialog>
                    <Modal.Body>
                        <div className="position-relative px-3 w-466px">
                            <div className="w-100 z-10">
                                <p className="text-delete-modal-customer mb-0 text-center">{languageUI.createNewMaterial}</p>
                                <div className={`mt-2`}>
                                    <p className="text-add-table-name mb-1">{languageUI.materialName}</p>
                                    <div className="input-add-table">

                                        <input type="text" className="border-none focus-visiable-none text-input-add-table px-2 w-100" placeholder={languageUI.enterMaterialName} value={materialName} onChange={(e) => setMaterialName(e.target.value)} />
                                    </div>
                                    <p className="text-add-table-name mt-3 mb-1">{languageUI.unit}</p>
                                    <div className="input-add-table px-1">

                                        <Select
                                            className="react-select react-select-container w-100 select-unit-new-material border-select-none"
                                            classNamePrefix="react-select"
                                            defaultValue={[""]}
                                            placeholder={languageUI.selectUnit}
                                            isClearable={true}
                                            value={comboUnit.filter(item => item?.id === unit_id).map(row => { return { value: row.id, label: row.unit_name } })}
                                            options={comboUnit.map((item) => {
                                                return {
                                                    value: item.id,
                                                    label: item.unit_name,
                                                };
                                            })}
                                            onChange={(e) => handleOnchageUnit(e?.value)}
                                        />
                                    </div>
                                    <div className="text-danger text-center">{messageError}</div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>
                                            {languageUI.cancel}
                                        </div>
                                        <div className="btn-save-cancel-floor text-white bg-0FA54A border-0FA54A" onClick={SubmitAdd}>
                                            {languageUI.save}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <img src={leaf1} alt="" className="img-leaf1-setup-table" />
                            <img src={leaf2} alt="" className="img-leaf2" />
                        </div>
                    </Modal.Body>
                </Modal.Dialog>

            </Modal>
        </>

    );
};

export default ModalCreateMatrial;
