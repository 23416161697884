import { useEffect, useState } from 'react';
import { ListFoodWaiting } from '../../../../api/apiOwner/ketchenApi';
import { arrowLeft, arrowLeftPage, cartBag, chervonRightPrimary, chevronLeftGreen, clockBlue, hourglass, store } from '../../../../components/ImgExport'
import './Styles.css'
import moment from 'moment-timezone';
import { getLanguageUI } from '../../../../Services/languageUI';
interface BillORderCounterFoodItemsHitoryProps {
    foodName: string;
    quantityFood: number;
    note: string;
    optionFood: string;
    eatStatus: boolean;
    accompanyingFood: string;

}
const BillORderCounterFoodItemsHitory = ({ foodName, accompanyingFood, note, optionFood, quantityFood, eatStatus }: BillORderCounterFoodItemsHitoryProps) => {
    const languageUI = getLanguageUI().kitchenCounter
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mt-2'>
                <p className='name-food-counter mb-0'>{foodName}</p>
                <div className='d-flex'>
                    <p className='mb-0 text-x-counter'>x</p>
                    <p className='mb-0 text-number-food-counter'>{quantityFood}</p>
                </div>
            </div>
            <div className='d-flex align-items-center gap-2 mt-2'>
                {optionFood && (
                    <div className='box-option-food-counter'>{optionFood}</div>
                )}
                <div className='box-status-eat-counter gap-1'>
                    {eatStatus ? <img src={store} alt="" /> : <img src={cartBag} alt="" />}
                    {eatStatus ? languageUI.dineIn : languageUI.takeAway}


                </div>
            </div>
            {accompanyingFood && (
                <div className='d-flex align-items-center gap-1 mt-2'>
                    <p className='mb-0 text-accompanying-food '>+ {accompanyingFood}</p>
                    <p className='mb-0 text-accompanying-food text-12B064 flex-shink-0'>(Free x1)</p>
                </div>
            )}
            <p className='mb-0 mt-1 text-note-food-counter'>{note}</p>
        </div>
    )
}
interface BillORderCounterHistoryProps {
    tableName: string,
    timeOrder: string,
    orderId: string,
    listFood: ListFoodWaiting[],
    RestoreOrder: (orderid: string) => void
}
const BillORderCounterHistory = ({ tableName = '', listFood = [], orderId = '', timeOrder = '', RestoreOrder }: BillORderCounterHistoryProps) => {
    const languageUI = getLanguageUI().kitchenCounter
    const [timeWaiting, setTimeWaiting] = useState<string>('')
    const calculateTimeDifference = () => {
        const timestamp1: any = new Date(timeOrder);
        const timestamp2: any = new Date(new Date(moment().format('YYYY-MM-DD HH:mm:ss')));

        // Tính khoảng cách thời gian
        const timeDifference = Math.abs(timestamp2 - timestamp1);

        // Chuyển khoảng cách thời gian thành giờ, phút và giây
        const hours = Math.floor(timeDifference / 3600000);
        const minutes = Math.floor((timeDifference % 3600000) / 60000);
        // const seconds = Math.floor((timeDifference % 60000) / 1000);                              
        setTimeWaiting((hours < 10 ? ('0' + hours) : hours) + ":" + (minutes < 10 ? ('0' + minutes) : minutes))
    }


    useEffect(() => {
        if (timeOrder) {
            calculateTimeDifference()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId])
    return (
        <div className="box-bill-counter mb-3">
            <div className="d-flex justify-content-between align-items-center">
                <div className="complete-table-counter" onClick={() => RestoreOrder(orderId)}>
                    <img src={chevronLeftGreen} alt="" className='' />
                    {languageUI.restore}
                </div>
                <div className="d-flex gap-2 align-items-center">
                    {tableName && (
                        <div className="name-table-counter">
                            {tableName}
                        </div>
                    )}
                    <div className="time-table-counter">
                        <img src={clockBlue} alt="" />
                        {timeWaiting}
                    </div>
                </div>
            </div>
            <p className='mb-0 text-name-order-bill mt-2'>Order #{orderId}</p>
            {listFood.map((item, i) => (
                <BillORderCounterFoodItemsHitory key={`food${i}`} accompanyingFood={item?.side_name} eatStatus={Number(item?.form_order) === 1 ? true : false} foodName={item?.name} note={item?.note} optionFood={item?.notefood_name} quantityFood={Number(item?.quantity)} />
            ))}

            <div className='d-flex justify-content-center align-items-center position-relative'>
                <hr className='hr-dashed w-100' />
                <div className='text-dish-ready position-absolute'>{languageUI.dishReadyAt} {moment(timeOrder).format("DD/MM/YYYY HH:mm")}</div>
            </div>
        </div>
    )
}
export default BillORderCounterHistory