import { useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { records as data } from "./components/fakeDataTableAccountStaff";
// components
import Select from "react-select";
import { CellValue } from "react-table";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";
import * as AccountStaffAPI from "../../api/apiAdmin/AccountStaff";
import { withSwal } from "react-sweetalert2";
import MaskedInput from "react-text-mask";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

const AccountStaff = withSwal((props: any) => {
  const languageUI = getLanguageCheckIn();
  const navigate = useNavigate();

  const UserIdStore = useSelector(userSlice.selectorUserId);
  //
  const [overflowDropdown, setOverflowDropdown] = useState(false);
  const [modalAddStaff, setModalAddStaff] = useState(false);
  const [modalEditStaff, setModalEditStaff] = useState(false);
  // Datepicker

  const [ListStaff, setListStaff] =
    useState<AccountStaffAPI.IListAccountStaff[]>();
  const [Message, setMessage] = useState<any>("");
  const [MessageToast, setMessageToast] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState(false);
  const [MessageToastSuccess, setMessageToastSuccess] = useState<any>("");
  const [EmailAdd, setEmailAdd] = useState<any>("");
  const [PasswordAdd, setPasswordAdd] = useState<any>("");
  const [NameAdd, setNameAdd] = useState<any>("");
  const [PhoneAdd, setPhoneAdd] = useState<any>("");

  const [EmailEdit, setEmailEdit] = useState<any>("");
  const [NameEdit, setNameEdit] = useState<any>("");
  const [PhoneEdit, setPhoneEdit] = useState<any>(0);
  const [permissionId, setPermissionId] = useState<number>(0);
  const [permissionIdEdit, setPermissionIdEdit] = useState<number>(0);
  const [comboPermission, setComboPermission] = useState<AccountStaffAPI.PermissionAdmin[]>([])
  const idStaffEditRef = useRef<any>(0);
  const permissionAddEditRef = useRef<any>([]);

  const location = useLocation()

  // const handleDeleteFood = (idFood: any) => {
  //     swal
  //         .fire({
  //             title: 'Do you want to change static for this user ?',
  //             text: "",
  //             icon: 'question',
  //             showCancelButton: true,
  //             confirmButtonColor: '#28bb4b',
  //             cancelButtonColor: '#f34e4e',
  //             confirmButtonText: 'Yes',
  //             cancelButtonText: 'No',
  //         }).then(function (result: { value: any; dismiss: any }) {
  //             if (result.value) {
  //                 // delFood(idFood);
  //                 swal.fire({
  //                     title: 'Deleted!',
  //                     text: 'This user has been change static.',
  //                     icon: 'success',
  //                     confirmButtonColor: '#4a4fea',
  //                 });
  //             } else if (
  //                 // Read more about handling dismissals
  //                 result.dismiss == swal.DismissReason.cancel
  //             ) {
  //                 swal.fire({
  //                     title: 'Cancelled',
  //                     text: "This user hasn't been change static",
  //                     icon: 'error',
  //                     confirmButtonColor: '#4a4fea',
  //                 });
  //             }
  //         })
  // }

  // Datatable setting
  // Datatable config
  const columns = [
    {
      Header: "User ID",
      accessor: "STT",
      sort: false,
    },
    {
      Header: "Email",
      accessor: "email",
      sort: false,
    },
    {
      Header: languageUI.Name,
      accessor: "name",
      sort: false,
    },
    {
      Header: languageUI.Phone,
      accessor: "phone",
      sort: false,
    },
    {
      Header: languageUI.Status,
      accessor: "Static",
      sort: false,
      Cell: (cell: CellValue) => {
        return (
          <Form>
            <Form.Check
              defaultChecked={Number(cell.row.original.status) === 0 ? true : false}
              type="switch"
              id="custom-switch"
              onClick={(e) => {
                ChangeStatic(UserIdStore, cell.row.original.userID);
              }}
            // onChange={(e)=>{console.log(e.target.value); cell.row.original.status = 1; handleDeleteFood(cell.row.original.id)}}
            />
          </Form>
          // <></>
        );
      },
    },
    {
      Header: languageUI.Action,
      accessor: "action",
      sort: false,
      Cell: (cell: CellValue) => {
        return (
          <>
            <Button
              variant="outline-primary"
              className="square-btn p-0 d-flex rounded-circle"
              onClick={() => {
                getInfoStaff(cell?.row?.original?.userID);
                setModalEditStaff(true);
                setMessageToast("");
                setMessageToastSuccess("");
              }}
            >
              <i className="fe-edit-2 m-auto"></i>
            </Button>
          </>
        );
      },
    },
  ];

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: languageUI.All,
      value: data.length,
    },
  ];

  const createNewStaff = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      // var permissionString = "";
      // for (
      //   let index = 0;
      //   index < permissionAddEditRef.current.length;
      //   index++
      // ) {
      //   const element = permissionAddEditRef.current[index];
      //   if (index == 0) {
      //     permissionString = element.value;
      //   } else {
      //     permissionString = permissionString + "," + element.value;
      //   }
      // }
      const AddStaff = await AccountStaffAPI.AddStaff(
        UserIdStore,
        EmailAdd,
        PasswordAdd,
        NameAdd,
        PhoneAdd,
        permissionId
      );
      if (Number(AddStaff.status) === 1) {
        setMessageToast("");
        setMessageToastSuccess(languageUI.Addstaffsuccess);
        GetListStaff();
        setModalAddStaff(false);
        setShowLoading(false);
      } else {
        setMessageToastSuccess("");
        setMessageToast(
          AddStaff?.message
            ? AddStaff?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      }
      setShowLoading(false);
    } else {
      setShowLoading(false);
      navigate("/web-admin/login");
    }
  };

  const editStaff = async () => {
    if (UserIdStore) {

      // var permissionString = "";
      // for (
      //   let index = 0;
      //   index < permissionAddEditRef.current.length;
      //   index++
      // ) {
      //   const element = permissionAddEditRef.current[index];
      //   if (index == 0) {
      //     permissionString = element.value;
      //   } else {
      //     permissionString = permissionString + "," + element.value;
      //   }
      // }
      if (!permissionIdEdit) {
        setMessageToastSuccess("");
        setMessageToast(
          languageUI.PleaseenterPermission
        );
      } else {
        setShowLoading(true);
        const EditStaff = await AccountStaffAPI.EditStaff(
          UserIdStore,
          idStaffEditRef.current,
          NameEdit,
          PhoneEdit,
          permissionIdEdit
        );
        if (Number(EditStaff.status) === 1) {
          setMessageToast("");
          setMessageToastSuccess(languageUI.Editstaffsuccess);
          GetListStaff();
          setModalEditStaff(false);
          setShowLoading(false);

          // setTimeout(() => {
          //     setShowLoading(false);
          //     window.location.reload();
          // }, 2000);
        } else {
          setMessageToastSuccess("");
          setMessageToast(
            EditStaff?.message
              ? EditStaff?.message
              : languageUI.SomethingwentwrongPleasetryagainlater
          );
        }
        setShowLoading(false);
      }

    } else {
      navigate("/web-admin/login");
    }
  };

  const deleteStaff = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const DeleteStaff = await AccountStaffAPI.DeleteStaff(
        idStaffEditRef.current
      );
      if (Number(DeleteStaff.status) === 1) {
        setMessageToast("");
        setMessageToastSuccess(languageUI.Deletestaffsuccess);
        GetListStaff();
        setModalEditStaff(false);
        setShowLoading(false);
        // setTimeout(() => {
        //     setShowLoading(false);
        //     window.location.reload();
        // }, 2000);
      } else {
        setMessageToastSuccess("");
        setMessageToast(
          DeleteStaff?.message
            ? DeleteStaff?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const getInfoStaff = async (id: any) => {
    idStaffEditRef.current = id;
    permissionAddEditRef.current = [];
    if (UserIdStore) {
      setShowLoading(true);
      const staffInfo = await AccountStaffAPI.InfoStaff(id);
      if (Number(staffInfo.status) === 1) {
        var permission: any = staffInfo.data?.permission;
        if (staffInfo.data?.permission === null) {
          permissionAddEditRef.current = [];
        } else {
          var permissionArray = permission.split(",");
          for (let index = 0; index < permissionArray.length; index++) {
            const element = permissionArray[index];
            if (Number(element) === 1) {
              permissionAddEditRef.current.push({
                value: "1",
                label: "Manage Staff",
              });
            } else if (Number(element) === 2) {
              permissionAddEditRef.current.push({
                value: "2",
                label: "Manage Owner",
              });
            } else if (Number(element) === 3) {
              permissionAddEditRef.current.push({
                value: "3",
                label: "Manage Package",
              });
            }
          }
        }
        setPermissionIdEdit(Number(staffInfo?.data?.permission_id))
        setEmailEdit(staffInfo?.data?.email);
        setNameEdit(staffInfo?.data?.name);
        setPhoneEdit(staffInfo?.data?.phone);

        setMessageToast("");
      } else {
        setEmailEdit("");
        setNameEdit("");
        setPhoneEdit("");
        setPermissionIdEdit(0);
        setMessageToast(
          staffInfo?.message
            ? staffInfo?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const GetListStaff = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const ListStaff = await AccountStaffAPI.ListStaff();
      if (Number(ListStaff.status) === 1) {
        setListStaff(ListStaff?.data);
        setMessage("");
        setMessageToast("");
      } else {
        setListStaff([]);
        setMessage(
          ListStaff?.message
            ? ListStaff?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const ChangeStatic = async (UserId: any, idOwner: any) => {
    if (UserIdStore) {
      setShowLoading(true);
      const ChangeStaticStaff = await AccountStaffAPI.ChangeStatic(
        UserId,
        idOwner
      );
      if (Number(ChangeStaticStaff.status) === 1) {
        GetListStaff();
        // setChangeStaticStaff(ChangeStaticStaff?.data);
        setMessage("");
        setMessageToast("");
      } else {
        // setChangeStaticStaff([]);
        setMessage(
          ChangeStaticStaff?.message
            ? ChangeStaticStaff?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const ReloadForm = () => {
    setListStaff([]);
    setMessage("");
    setShowLoading(false);
  };

  const ReloadFormModalAdd = () => {
    setMessageToast("");
    setEmailAdd("");
    // setPasswordAdd("");
    setNameAdd("");
    setPhoneAdd("");
    setPermissionId(0)
  };

  const onchagePhoneNumber = (e: any) => {
    let phone = e?.target.value;
    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setPhoneAdd(phoneReplace);
  };

  const onchagePhoneNumberEdit = (e: any) => {
    let phone = e?.target.value;
    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setPhoneEdit(phoneReplace);
  };

  const gComboPermission = async () => {
    const req = await AccountStaffAPI.gComboPermissionAdmin();
    if (Number(req?.status) === 1) {
      setComboPermission(req?.data ?? []);
    }
  }

  useEffect(() => {
    ReloadForm();
    ReloadFormModalAdd();
    gComboPermission()
    GetListStaff();
  }, [location.pathname]);

  useEffect(() => {
    ReloadFormModalAdd();
  }, [modalAddStaff]);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: languageUI.WebAdmin, path: "#" },
          { label: languageUI.Liststaff, path: "#", active: true },
        ]}
        title={languageUI.Liststaff}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal
        show={modalAddStaff}
        onHide={() => {
          setModalAddStaff(false);
        }}
      >
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title>{languageUI.Adduser}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          {/* <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="UserID" inputMode='numeric' />
                    </Form.Group> */}
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">
                Email:<span className="text-danger">*</span>
              </Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={EmailAdd}
                  type="email"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Email"
                  onChange={(e: any) => {
                    setEmailAdd(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">
                {languageUI.Password}:<span className="text-danger">*</span>
              </Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={PasswordAdd}
                  type="password"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder={languageUI.Password}
                  onChange={(e: any) => {
                    setPasswordAdd(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">
                {languageUI.Name}:<span className="text-danger">*</span>
              </Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={NameAdd}
                  type="name"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder={languageUI.Name}
                  onChange={(e: any) => {
                    setNameAdd(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">
                {languageUI.Phone}:<span className="text-danger">*</span>
              </Form.Label>
            </div>
            <div className="col-10">
              {/* <InputGroup className="mb-3">
                                <Form.Control
                                    value={PhoneAdd}
                                    type="phone"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    placeholder='Phone'
                                    onChange={(e: any) => { setPhoneAdd(e.target.value) }}
                                />
                            </InputGroup> */}
              <MaskedInput
                mask={[
                  "(",
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="(___) ___-____"
                className="form-control mb-3"
                value={PhoneAdd}
                onChange={onchagePhoneNumber}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">
                {languageUI.Role}:<span className="text-danger">*</span>
              </Form.Label>
            </div>
            <div className="col-10">
              <Select
                className="react-select react-select-container"
                classNamePrefix="react-select"
                // defaultValue={[""]}
                placeholder={languageUI.ChooseRole}
                value={comboPermission.filter(item => Number(item.permission_id) === permissionId)[0]}
                isClearable={true}
                options={comboPermission}
                onChange={(e: any) => {
                  // if (e.length == 0) {
                  //   permissionAddEditRef.current = [];
                  // } else {
                  //   permissionAddEditRef.current = e;
                  //   // AddRole();
                  // }
                  setPermissionId(Number(e?.permission_id))
                }}
                isMulti={false}
              ></Select>
            </div>
          </div>
        </Modal.Body>
        <div className="text-center text-success">{MessageToastSuccess}</div>
        <div className="text-center text-danger">{MessageToast}</div>
        <Modal.Footer className="justify-content-end border-top ">
          <Button variant="primary" onClick={createNewStaff}>
            {languageUI.Create}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalEditStaff}
        onHide={() => {
          setModalEditStaff(false);
          idStaffEditRef.current = 0;
        }}
      >
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title>{languageUI.Edituser}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">Email:</Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={EmailEdit}
                  type="email"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Email"
                  readOnly
                />
              </InputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">{languageUI.Name}:</Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={NameEdit}
                  type="name"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder={languageUI.Name}
                  onChange={(e: any) => {
                    setNameEdit(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">{languageUI.Phone}:</Form.Label>
            </div>
            <div className="col-10">
              {/* <InputGroup className="mb-3">
                                <Form.Control
                                    value={PhoneEdit}
                                    type="phone"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    placeholder='Phone'
                                    onChange={(e: any) => { setPhoneEdit(e.target.value) }}
                                />
                            </InputGroup> */}
              <MaskedInput
                mask={[
                  "(",
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="(___) ___-____"
                className="form-control mb-3"
                // value={PhoneEdit ? PhoneEdit.slice(0,0) + "(" + PhoneEdit.slice(0,3) + ") " + PhoneEdit.slice(3,6) + "-" + PhoneEdit.slice(6,10) : ""}
                value={PhoneEdit}
                onChange={onchagePhoneNumberEdit}
              />
            </div>
          </div>
          {/* <Form.Group className="mb-3">
                        <Form.Control type="password" placeholder="Password" value={"1234"} readOnly/>
                    </Form.Group> */}
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">{languageUI.Role}:</Form.Label>
            </div>
            <div className="col-10">
              <Select
                className="react-select react-select-container"
                classNamePrefix="react-select"
                defaultValue={permissionAddEditRef.current}
                placeholder={languageUI.ChooseRole}
                value={comboPermission.filter(item => Number(item.permission_id) === permissionIdEdit)[0]}
                isClearable={true}
                options={comboPermission}
                onChange={(e: any) => {
                  setPermissionIdEdit(Number(e?.permission_id))
                }}
                isMulti={false}
              ></Select>
            </div>
          </div>
        </Modal.Body>
        <div className="text-center text-success">{MessageToastSuccess}</div>
        <div className="text-center text-danger">{MessageToast}</div>
        <Modal.Footer className="justify-content-end border-top">
          <Button variant="light" onClick={deleteStaff}>
            {languageUI.Deleteuser}
          </Button>
          <Button variant="primary" className="me-0" onClick={editStaff}>
            {languageUI.Save}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="py-3 px-2 rounded-3 mb-3 bg-white shadow position-relative">
        <div className="position-relative">
          <div className="text-end position-absolute end-0 top-0 btn-sm-relative">
            <Button
              variant="outline-primary"
              className="w-xs-100 mt-2 mt-sm-2 mt-md-0 mt-lg-0"
              onClick={() => {
                setModalAddStaff(true);
                setMessageToast("");
                setMessageToastSuccess("");
                permissionAddEditRef.current = [];
              }}
            >
              <i className="fe-plus me-2"></i>{languageUI.Addnew}
            </Button>
          </div>
          <Table
            overflowDropdown={overflowDropdown}
            columns={columns}
            data={ListStaff || []}
            pageSize={5}
            sizePerPageList={sizePerPageList}
            isSortable={true}
            pagination={true}
            isSearchable={true}
          // onChangeStatic={()=>ChangeStatic()}
          />
        </div>
      </div>
    </>
  );
});

export default AccountStaff;