import { Drawer } from "@mui/material";
import {
  AccessTimeIcon,
  dollarGreen,
  tip,
  clock,
} from "../../../../../../components/Image";
import "./Styles.css";
import dayjs from "dayjs";
import ContentTableSalaryDetail from "../ContentTable/ContentTableSalary/ContentTableSalaryDetail";
import React, { useEffect, useState } from "react";
import DrawerSumary from "./DrawerSumary";
import {
  DetailTimeSheet,
  editClockOut,
  editClockin,
} from "../../../../../../api/apiOwner/StaffApi";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ModalLoading from "../../../../../../components/ModalLoading";
import ModalToast from "../../../ModalToast/ModalToastErr";
import { formatDate } from "../../../../../../utils/format";
import { getLanguageUI } from "../../../../../../Services/languageUI";
import { ListPrinterByCatalog } from "../../../../../../api/apiOwner/SettingPrinterApi";
interface drawerSalaryDetail {
  showDrawerSalaryDetail?: boolean;
  dismistDrawerSalaryDetail?: () => void;
  detailTimeSheet?: DetailTimeSheet;
  listPrinterName?: ListPrinterByCatalog[];
  ReloadWorking?: () => void;
}

const DrawerSalaryDetail: React.FC<drawerSalaryDetail> = ({
  showDrawerSalaryDetail,
  detailTimeSheet = {},
  ReloadWorking,
  listPrinterName = [],
  dismistDrawerSalaryDetail,
}) => {
  const languageUI = getLanguageUI().staffPage;
  const languagePrinterUI = getLanguageUI().printPage;
  const [showDrawerSumary, setShowDrawerSumary] =
    React.useState<boolean>(false);
  const [valueClockin, setValueClockin] = React.useState<
    dayjs.Dayjs | null | any
  >();
  const [valueClockout, setValueClockout] = React.useState<
    dayjs.Dayjs | null | any
  >();
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [showToast, setShowToas] = useState<boolean>(false);
  const [isChangeClockIn, setIsChangeClockIn] = useState(0);
  const [isChangeClockOut, setIsChangeClockOut] = useState(0);

  const changeClockIn = async () => {
    if (isNaN(valueClockin?.$d)) {
      setMessage("Invalid Date");
      return;
    }
    if (valueClockout) {
      if (
        !isNaN(valueClockout?.$d) &&
        formatDate(valueClockin?.$d) > formatDate(valueClockout?.$d)
      ) {
        setMessage("Clock in malformed");
        return;
      }
    }
    setMessage("");
    if (detailTimeSheet?.id) {
      setShowLoading(true);
      const clockinTime = formatDate(valueClockin?.$d);
      const req = await editClockin(detailTimeSheet?.id, clockinTime);
      if (Number(req?.status) === 1) {
        setShowLoading(false);

        setMessageSuccess("Update clock in successful");
        setShowToas(true);
        ReloadWorking?.();
      } else {
        setShowLoading(false);
        setMessage(" Clock in Update failed");
      }
    }
  };
  useEffect(() => {
    if (isChangeClockOut > 0) changeClockOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeClockOut]);
  useEffect(() => {
    if (isChangeClockIn > 0) changeClockIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeClockIn]);
  const changeClockOut = async () => {
    if (isNaN(valueClockout?.$d)) {
      setMessage("Invalid Date");
      return;
    }
    if (
      !isNaN(valueClockout?.$d) &&
      formatDate(valueClockin?.$d) > formatDate(valueClockout?.$d)
    ) {
      setMessage("Clock out malformed");
      return;
    }
    setMessage("");
    if (detailTimeSheet?.id) {
      setShowLoading(true);
      const clockoutTime = formatDate(valueClockout?.$d);
      const req = await editClockOut(detailTimeSheet?.id, clockoutTime);
      if (Number(req?.status) === 1) {
        setShowLoading(false);

        setMessageSuccess("Update clock out successful");
        setShowToas(true);
        ReloadWorking?.();
      } else {
        setShowLoading(false);
        setMessage(" Clock out Update failed");
      }
    }
  };
  useEffect(() => {
    if (showDrawerSalaryDetail) {
      setValueClockin(dayjs(detailTimeSheet?.time_clock_in));
      if (detailTimeSheet?.time_clock_out) {
        setValueClockout(dayjs(detailTimeSheet?.time_clock_out));
      } else {
        setValueClockout(null);
      }
    }
  }, [showDrawerSalaryDetail, detailTimeSheet]);
  return (
    <>
      <ModalToast
        show={showToast}
        handleClose={() => setShowToas(false)}
        content={messageSuccess}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <DrawerSumary
        openDrawerSumary={showDrawerSumary}
        dismisDrawer={() => {
          setShowDrawerSumary(!showDrawerSumary);
        }}
        detailTimeSheet={detailTimeSheet}
        listPrinterName={listPrinterName}
      />
      <Drawer
        className="custom-btn-close "
        anchor="right"
        onClose={dismistDrawerSalaryDetail}
        open={showDrawerSalaryDetail}
      >
        <div className="padding-drawer-History h-100vh width-drawer-1000">
          <div className="flex justify-between position-relative">
            <div className="flex">
              <span className="text-8 fw-600 font-urbansist text-212134 mb-4 mr-8">
                {detailTimeSheet?.name}
              </span>
              <div className="flex flex-column ">
                {/* <div className="flex items-center mb-4">
                                    <span className="text-4 fw-600 font-urbansist text-666687 flex items-center ">Name</span>
                                    <span className="text-4 fw-600 font-urbansist text-4A4A6A ml-6">{detailTimeSheet?.name}</span>
                                </div> */}
                <div className="flex items-center mb-4">
                  <span className="text-4 fw-600 font-urbansist text-666687 flex items-center ">
                    {languageUI.salary}
                  </span>
                  <span className="text-4 fw-600 font-urbansist text-FAA300 ml-6">
                    ${detailTimeSheet?.salary ? detailTimeSheet.salary : 0}
                  </span>
                </div>
                <div className="flex items-center mb-4">
                  <span className="text-4 fw-600 font-urbansist text-666687 flex items-center ">
                    TIP
                  </span>
                  <span className="text-4 fw-600 font-urbansist text-FAA300 ml-46px">
                    ${detailTimeSheet?.tip ? detailTimeSheet?.tip : 0}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-4 fw-600 font-urbansist text-666687 flex items-center">
                    {languageUI.date}
                  </span>
                  <span className="text-4 fw-600 font-urbansist text-4A4A6A ml-8">
                    {detailTimeSheet?.datesheet}
                  </span>
                </div>
              </div>
              <div className="ml-22">
                <div className="flex items-center w-280px mb-3 gap-4 justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <AccessTimeIcon className="text-666687" />
                    <span className="text-4 font-urbansist text-666687">
                      {languageUI.clockIn}
                    </span>
                  </div>
                  <div className="">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DateTimePicker", "DateTimePicker"]}
                      >
                        <DateTimePicker
                          // readOnly
                          className="date-picker-clockin"
                          views={["hours", "minutes"]}
                          value={valueClockin}
                          onChange={(newValue) => {
                            setValueClockin(newValue);
                          }}
                          onAccept={() =>
                            setIsChangeClockIn(isChangeClockIn + 1)
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {/* <DateTimePicker onChange={setValueClockin} value={valueClockin} />
                                        <RedditTextField
                                            className=" custom-w-edit custom-input"
                                            // defaultValue="01:09 pm"
                                            id="01:09 pm"
                                            variant="filled"
                                            value={detailTimeSheet?.clock_in}

                                        /> */}
                  </div>
                </div>

                <div className="flex items-center w-280px gap-4 justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <AccessTimeIcon className="text-666687 " />
                    <span className="text-4 font-urbansist text-666687">
                      {languageUI.clockOut}
                    </span>
                  </div>
                  <div className="">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DateTimePicker", "DateTimePicker"]}
                      >
                        <DateTimePicker
                          // readOnly
                          className="date-picker-clockout"
                          views={["hours", "minutes"]}
                          value={valueClockout}
                          // ampm={false}
                          onChange={(newValue) => {
                            setValueClockout(newValue);
                          }}
                          onAccept={() =>
                            setIsChangeClockOut(isChangeClockOut + 1)
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={() => setShowDrawerSumary(!showDrawerSumary)}
              className="border-none p-btn-print-sumary text-white radius-2 bg-0FA54A h-fit-content position-absolute right-0 bottom-0"
            >
              {languageUI.print}
            </button>
          </div>

          <div className="text-center text-danger text-italic">{message}</div>
          <div className="radius-4 border mt-8">
            <table className="table custom-m-b">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-20 text-18px text-12B064 fw-600 font-urbansist"
                  >
                    {languageUI.table}
                  </th>
                  <th scope="col" className="w-20">
                    <div className="flex items-center">
                      <span className="mr-2 text-18px text-12B064 fw-600 font-urbansist">
                        {languageUI.order}
                      </span>
                    </div>
                  </th>

                  <th
                    scope="col"
                    className="w-20 text-18px text-12B064 fw-600 font-urbansist"
                  >
                    <div className="flex  items-center">
                      <img src={dollarGreen} alt="" className="mr-3" />
                      <span className="mr-2 text-18px text-12B064 fw-600 font-urbansist">
                        {languageUI.bill}
                      </span>
                    </div>
                  </th>

                  <th scope="col" className="w-20">
                    <div className="flex  items-center">
                      <img src={tip} alt="" className="mr-3" />
                      <span className="mr-2 text-18px text-12B064 fw-600 font-urbansist">
                        TIP
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="w-20">
                    <div className="flex  items-center">
                      <img src={clock} alt="" className="mr-3" />
                      <span className="mr-2 text-18px text-12B064 fw-600 font-urbansist">
                        {languageUI.timePrinter}
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="t-body-salary-detail">
                {detailTimeSheet?.list_order &&
                  detailTimeSheet?.list_order.map((item, i) => (
                    <ContentTableSalaryDetail
                      table={item?.table_name}
                      order={`#${item?.id}`}
                      bill={item?.price}
                      tip={item?.tip_amount}
                      timePoint={item?.timeend}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerSalaryDetail;
