import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import '../../Styles.css'
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ModalAddFoodUpSale from '../ModalAddFoodUpSale/ModalAddFoodUpSale';
import { useEffect, useState } from 'react';
import { InfoComboFood, addUpsaleDrinks, addUpsaleFoods, gComboDrinksUpSale, gComboFoodsUpSale, gConfigUpsale, upConfigUpsale } from '../../../../api/apiOwner/UpSale';
import ModalLoading from '../../../../components/ModalLoading';
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import { targetFood } from '../../../../api/Request';
import { default_product } from '../../../../components/Image';
import { getLanguageUI } from '../../../../Services/languageUI';

const UpSale = () => {
  const languageUI = getLanguageUI().setting
  const [isAddFoodUpsale, setIsAddFoodUpsale] = useState(false)
  const [showModalLoading, setShowModalLoading] = useState(false)
  const [comboFoodUpSale, setComboFoodUpSale] = useState<InfoComboFood[]>([])
  const [comboDrinksUpSale, setComboDrinksUpSale] = useState<InfoComboFood[]>([])
  const [listComboActive, setListComboActive] = useState<InfoComboFood[]>([])
  const [statusConfig, setStatusConfig] = useState<boolean>(false)
  const [statusOpenModal, setStatusOpenModal] = useState<boolean>()
  // const [foodsUpsaleAcitve, setFoodsUpsaleActive] = useState<InfoComboFood[]>()
  // const [drinksUpsaleAcitve, setDrinksUpsaleActive] = useState<InfoComboFood[]>()



  const UserIdStore = useSelector(userSlice.selectorUserId);

  const showModalAddFoodUpSale = (listFood: InfoComboFood[]) => {
    setListComboActive(listFood)
    setIsAddFoodUpsale(true)
  }

  const dissmisAddFood = () => {
    setIsAddFoodUpsale(false)
  }
  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  const glistFoodsUpSale = async () => {
    setShowModalLoading(true)
    if (UserIdStore) {
      const res = await gComboFoodsUpSale(UserIdStore.toString())
      if (Number(res.status) === 1) {
        setComboFoodUpSale(res.data ?? [])

        setShowModalLoading(false)
      }else{
        setShowModalLoading(false)
      }
    }
  }
  const glistDrinksUpSale = async () => {
    
    if (UserIdStore) {
      setShowModalLoading(true)
      const res = await gComboDrinksUpSale(UserIdStore.toString())
      if (Number(res.status) === 1) {
        setComboDrinksUpSale(res.data ?? [])
        setShowModalLoading(false)
      }else{
        setShowModalLoading(false)
      }
    }
  }
  const getConfigUpsale = async () => {
    

    if (UserIdStore) {
      setShowModalLoading(true)
      const res = await gConfigUpsale(UserIdStore.toString())
      if (Number(res.status) === 1) {
        setStatusConfig(Number(res.data?.is_show_upsale) === 1 ? true : false)
        setShowModalLoading(false)
      }else{
        setShowModalLoading(false)
      }
    }
  }
  const updateConfigUpsale = async (isUpdate: boolean) => {
    
    setStatusConfig(isUpdate)
    if (UserIdStore) {
      setShowModalLoading(true)
      const req = await upConfigUpsale(UserIdStore.toString(), isUpdate ? 1 : 0)
      if (Number(req.status) === 1) {
        setShowModalLoading(false)
      }else{
        setShowModalLoading(false)
      }
    }
  }
  const handleAddUpsaleDrinks = async (listItem: InfoComboFood[]) => {
    
    let ListActive = listItem.filter(status => Number(status.checked) === 1).map(item => {
      return item.id
    })
    if (UserIdStore) {
      setShowModalLoading(true)
      if (statusOpenModal) {
        const req = await addUpsaleFoods(UserIdStore, ListActive)
        if (Number(req.status) === 1) {
          setIsAddFoodUpsale(false)
          setShowModalLoading(false)
          glistFoodsUpSale()

        }else{
          setShowModalLoading(false)
        }
      } else {
        const req = await addUpsaleDrinks(UserIdStore, ListActive)
        if (Number(req.status) === 1) {
          setIsAddFoodUpsale(false)
          setShowModalLoading(false)
          glistDrinksUpSale()

        }else{
          setShowModalLoading(false)
        }
      }
    }
  }
  useEffect(() => {
    getConfigUpsale()
    glistDrinksUpSale()
    glistFoodsUpSale()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <ModalLoading onHide={() => setShowModalLoading(false)} show={showModalLoading} />
      <div className='box-connect-stripe px-5 pb-4 bg-white h-100vh-145px'>
        <div className='w-fit'>
          <FormGroup>
            <FormControlLabel
              className='font-bold'
              labelPlacement='start'
              label={<div className='font-medium text-16px'>{languageUI.statusUpSale}</div>}
              control={<Android12Switch
                onClick={(e: any) => updateConfigUpsale(e.target.checked)}
                checked={statusConfig}
              />}
            />
          </FormGroup>
        </div>
        <div className='px-3'>
          <div className=' d-flex gap-2 align-items-end'>
            <p className='mb-0 font-medium text-16px'>{languageUI.chooseNumberFood}</p>
          </div>
          <div className='mt-2 d-flex flex-wrap  align-items-center gap-2'>
            {comboFoodUpSale ? (
              comboFoodUpSale.filter(item => Number(item.checked) === 1).map(item => (
                <div className='box-food-upsale shadow-button-default bg-white'>
                  <p className='m-0 text-orange font-bold'>$ {item.price}</p>
                  <div className='img-food-upsale border'>
                    <img src={targetFood + item.imageCourse} alt="" className='h-100 w-100 object-fit-cover rounded-8px' onError={(e) => {
                      e.currentTarget.src = default_product;
                    }} />
                  </div>
                  <p className='m-0 text-two-line-name text-11151f mt-1 font-bold'>{item.name}</p>
                </div>
              ))
            ) : ''}

            <div className='border-primary  w-100px rounded-12px shadow-button-default position-relative d-flex justify-content-center align-items-center bg-white' onClick={() => { showModalAddFoodUpSale(comboFoodUpSale); setStatusOpenModal(true); glistFoodsUpSale() }}>
              <LocalPrintshopIcon className='icon-add-printer opacity-0' />
              <AddRoundedIcon className='icon-printer text-primary position-absolute' />
            </div>
          </div>
        </div>
        <div className='px-3 mt-3'>
          <div className=' d-flex gap-2 align-items-end'>
            <p className='mb-0 font-medium text-16px'>{languageUI.chooseNumberDrink}</p>
          </div>
          <div className='mt-2 d-flex align-items-center gap-2'>
            {comboDrinksUpSale ? (
              comboDrinksUpSale.filter(item => Number(item.checked) === 1).map(item => (
                <div className='box-food-upsale shadow-button-default bg-white'>
                  <p className='m-0 text-orange font-bold'>$ {item.price}</p>
                  <div className='img-food-upsale border'>
                    <img src={targetFood + item.imageCourse} alt="" className='h-100 w-100 object-fit-cover rounded-8px' onError={(e) => {
                      e.currentTarget.src = default_product;
                    }} />
                  </div>
                  <p className='m-0 text-two-line-name text-11151f mt-1 font-bold'>{item.name}</p>
                </div>
              ))
            ) : ''}

            <div className='border-primary  w-100px rounded-12px shadow-button-default position-relative d-flex justify-content-center align-items-center bg-white' onClick={() => { showModalAddFoodUpSale(comboDrinksUpSale); setStatusOpenModal(false); glistDrinksUpSale() }}>
              <LocalPrintshopIcon className='icon-add-printer opacity-0' />
              <AddRoundedIcon className='icon-printer text-primary position-absolute' />
            </div>
          </div>
        </div>
      </div>
      <ModalAddFoodUpSale ListFood={listComboActive} show={isAddFoodUpsale} handleClose={dissmisAddFood} handleSuscces={handleAddUpsaleDrinks} />
    </div>
  )
}
export default UpSale