import * as Request from "../Request";
export interface ConfigSettingProps {
    landingpage?: string;
    returnUrl?: string;
}

export interface Result<T> {
    status: number,
    message?: string,
    data?: T
}
export interface Config {
    status: number,
    message: string,
    secretKey: string,
    publishKey: string,
}
export interface Device {
    id: string,
    title: string,
    description: string,
    price: string,
    imgurl: string,
    timestamp: string
}
export interface ListDevice {
    status: number,
    message?: string,
    data?: Device[]
}
export interface InfoDevice {
    status: number,
    message: string,
    data: Device
}
export const ConfigSetting = async () => {
    return await Request.fetchAPI(`admin/gconfigSetting.php`, "GET")
        .then((res: Result<ConfigSettingProps>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};
export const EditReturnUrl = async (returnUrl: string) => {
    return await Request.fetchAPI(`admin/updateReturnUrl.php`, "POST", { returnUrl })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};
export const gConfigStripeAdmin = async () => {
    return await Request.fetchAPI(`admin/gConfigStripe.php`, "POST", {})
        .then((res: Config) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                secretKey: '',
                publishKey: '',
            };
        });
};

// Lấy danh sách thiết bị Equipment

export const gComboDevicePos = async () => {
    return await Request.fetchAPI(`admin/gComboDevicePos.php`, "POST", {})
        .then((res: ListDevice) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined
            };
        });
};


// Thêm thiết bị Equipment
export const addDevicePos = async (title: string, description: string, price: number, image?: File) => {
    return await Request.fetchAPI(`admin/addDevicePos.php`, "POST", { image, title, description, price })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: ''
            };
        });
};


// Lấy thông tin thiết bị
export const gDevicePosById = async (id: string) => {
    return await Request.fetchAPI(`admin/gDevicePosById.php`, "POST", { id })
        .then((res: InfoDevice) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined
            };
        });
};

// Sửa thiết bị
export const editDevicePos = async (id: string, title: string, description: string, price: number, image?: File) => {
    return await Request.fetchAPI(`admin/editDevicePos.php`, "POST", { id, title, description, price, image })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: ''
            };
        });
};

// Xóa thiết bị
export const deleteDevicePos = async (id: string) => {
    return await Request.fetchAPI(`admin/deleteDevicePos.php`, "POST", { id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: ''
            };
        });
};
