import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { plusWhite } from "../../components/ImgExport";
import ModalLoading from "../../components/ModalLoading";
import "./Styles.css";
import ModalCreateGiftCard from "./components/GiftCard/ModalAddGiftCard/ModalAddGiftCard";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import * as giftCodeVoucher from "../../api/apiOwner/giftCodeVoucher";
import ContentTableGiftVoucher from "./components/GiftCard/ContentTableVoucher";
import { getLanguageUI } from "../../Services/languageUI";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
const GiftCard: React.FC = () => {
  const navigate = useNavigate();
  const languageUI = getLanguageUI().giftCard;
  const owner_id = useSelector(userSlice.selectorUserId);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalAddGiftCard, setShowModalAddGiftCard] =
    useState<boolean>(false);
  const [listGiftVoucher, setListGiftVoucher] = useState<
    giftCodeVoucher.GiftVoucher[]
  >([]);
  const dismisModalAddGiftCard = () => {
    setShowModalAddGiftCard(false);
  };

  const gListGiftVoucher = async () => {
    if (owner_id) {
      setShowLoading(true);
      const req = await giftCodeVoucher.gListGiftVoucher(owner_id);
      if (Number(req?.status) === 1) {
        setListGiftVoucher(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setListGiftVoucher([]);
      }
    }
  };
  useEffect(() => {
    gListGiftVoucher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="px-3 pt-90px  h-100vh pb-5  w-100">
        <div className="bg-white  h-100 w-100 rounded-18px box-shadow-inventory ">
          <div className="d-flex justify-content-between align-items-center py-7 px-6">
            <div className="d-flex gap-4 align-items-center">
              <p className="mb-0 text-inventory">{languageUI.giftCard}</p>
              <div
                className="btn-create-voucher"
                onClick={() => {
                  navigate("/web-owner/giftCardHistory");
                }}
              >
                {languageUI.giftCardHistory}
                <ArrowForwardIosIcon className="text-white" />
              </div>
            </div>
            <div
              className="btn-create-voucher"
              onClick={() => setShowModalAddGiftCard(true)}
            >
              <img src={plusWhite} alt="" />
              {languageUI.createGiftCardProgram}
            </div>
          </div>
          <div>
            <TableContainer className="border-top">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-12B064 text-4 fw-600 font-urbansist w-4">
                      {languageUI.noNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-8"
                    >
                      {languageUI.enable}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-12"
                    >
                      {languageUI.dateAdd}
                    </TableCell>

                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-12"
                    >
                      {languageUI.expiryDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-16"
                    >
                      {languageUI.giftCardName}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-8"
                    >
                      {languageUI.personSell}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-8"
                    >
                      {languageUI.sellTo}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="text-12B064 text-4 fw-600 font-urbansist w-6"
                    >
                      {languageUI.Remain}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="text-12B064 text-4 fw-600 font-urbansist w-8"
                    >
                      {languageUI.giftCardValue}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="text-12B064 text-4 fw-600 font-urbansist w-6"
                    >
                      {languageUI.quantity}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="text-12B064 text-4 fw-600 font-urbansist w-6"
                    >
                      {languageUI.used}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="text-12B064 text-4 fw-600 font-urbansist w-6"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="body-table-gift-card">
                  {listGiftVoucher.map((item, i) => (
                    <ContentTableGiftVoucher
                      key={item?.id}
                      no={i + 1}
                      voucherID={item?.id}
                      voucherName={item?.voucher_name}
                      discountValue={item?.discount_value}
                      enable={Number(item?.enable)}
                      startdate={item?.startdate}
                      enddate={item?.enddate}
                      type={Number(item?.type)}
                      quantity={Number(item?.quantity)}
                      quantity_used={Number(item?.quantity_used)}
                      is_never_expire={Number(item?.is_never_expire)}
                      remain={Number(item?.remain)}
                      send_to={item?.send_to}
                      staff_name={item?.staff_name}
                      create_at={item?.createat}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <ModalCreateGiftCard
        handleClose={dismisModalAddGiftCard}
        show={showModalAddGiftCard}
        setShowLoading={setShowLoading}
        HandleCreatSuccess={() => {
          dismisModalAddGiftCard();
          gListGiftVoucher();
        }}
      />
    </>
  );
};

export default GiftCard;
