import { Modal } from "react-bootstrap";
import "./Styles.css";
import { handClick, leaf1, leaf2 } from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useCallback, useEffect, useState } from "react";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";
import ModalLoading from "../../../../components/ModalLoading";
import $ from "jquery";
import { getLanguageUI } from "../../../../Services/languageUI";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { formatCurrencyVND, formatDate, parseDolla } from "../../../../utils/format";
import {
  DateTimePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import * as staffJointSlice from "../../../../store/slices/staffJointSlice";
import {
  addGiftCardByPos,
  PaymentIntentGift,
} from "../../../../api/apiOwner/voucherApi";
import * as userSlice from "../../../../store/slices/userSlice";
import React from "react";
import {
  CancelPaymentIntent,
  CreatePaymentIntent,
} from "../../../../api/apiOwner/stripeApi";
import ModalReader from "../ModalReader";
import moment from "moment-timezone";
import {
  connectCardPoint,
  disConnectCardPoint,
  paymentWithCardPoint,
} from "../../../../api/apiOwner/cardPointUATApi";
import ModalQrcodeVnpay from "../ModalQrcodeVnpay";
import { vnpayCreatePayment } from "../../../../api/apiOwner/vpnPayApi";
import socket from "../../../../utils/socket";
interface ModalBuyGiftCardProps {
  show: boolean;
  handleClose: () => void;
  setShowLoading: (status: boolean) => void;
}

const ModalBuyGiftCard = ({
  show,
  handleClose,
  setShowLoading,
}: // setIsOwner
  ModalBuyGiftCardProps) => {
  const languageUI = getLanguageUI().giftCard;
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const handheldID = useSelector(userSlice.selectorHsnDefault) ?? 0;
  const [sendToValue, setSendToValue] = useState<string>("1");
  const [deliver, setDeliver] = useState<string>("1");
  const [amountValue, setAmountValue] = useState<number | string>();
  const [valueTimeSend, setValueTimeSend] = useState<
    dayjs.Dayjs | null | any
  >();
  const [sendFrom, setSendFrom] = useState<string>();
  const [sendTo, setSendTo] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [note, setNote] = useState<string>();
  const today = dayjs();
  const [messageError, setMessageError] = useState<string>("");
  const staffJointId = useSelector(staffJointSlice.selectorStaffId) ?? 0;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [paymentIntentId, setpaymentIntentId] = useState("");
  const [showModalReader, setShowModalReader] = useState(false);
  const [sessionKeyHandheld, setSessionKeyHandheld] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const [showModalQRCODEVnPay, setShowModalQRCODEVnPay] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [orderRef, setOrderRef] = useState('')
  const [refTxn, setRefTxn] = useState('')

  const handleChangeSendToValue = (value: string) => {
    setSendToValue(value);
  };
  const handleChangeDeliver = (value: string) => {
    setDeliver(value);
  };
  const dismisModalReader = () => {
    setShowModalReader(false);
  };

  const clearForm = async () => {
    setAmountValue("");
    setSendFrom("");
    setSendTo("");
    setPhoneNumber("");
    setEmail("");
    setSendToValue("1");
    setDeliver("1");
    setValueTimeSend(null);
    setNote("");
    setMessageError("");
    setOrderRef('')
  };

  const submitAddgift = async (method: number) => {
    if (!amountValue || Number(amountValue) <= 0) {
      setMessageError("Please enter amount!");
      return;
    }
    if (!sendFrom || sendFrom === "") {
      setMessageError("Please enter send from!");
      return;
    }
    if (!sendTo || sendTo === "") {
      setMessageError("Please enter send to!");
      return;
    }
    if (sendToValue === "2" && email === "") {
      setMessageError("Please enter email!");
      return;
    }
    if (
      sendToValue === "1" &&
      (phoneNumber === "" || phoneNumber?.length !== 10)
    ) {
      setMessageError("Please enter phone!");
      return;
    }
    if (deliver === "2" && !valueTimeSend) {
      setMessageError("Please enter Schedule!");
      return;
    }
    if (!note || note === "") {
      setMessageError("Please enter message (optional)!");
      return;
    }
    const clockIntime = valueTimeSend
      ? moment(formatDate(valueTimeSend?.$d)).format("YYYY-MM-DD HH:mm:ss")
      : "";

    if (UserIdStore) {
      setShowLoading(true);
      if (method === 1) {
        const req = await addGiftCardByPos(
          UserIdStore,
          note,
          Number(amountValue),
          sendFrom,
          sendTo,
          phoneNumber ?? "",
          email ?? "",
          clockIntime,
          staffJointId,
          Number(deliver)
        );
        if (Number(req?.status) === 1) {
          setShowLoading(false);
          handleClose();
        } else {
          setShowLoading(false);
          setMessageError(req?.message ?? "Bad Request");
          return;
        }
      } else if (method === 3) {
        handlePaymentVnPay()
      } else {

        connectHandheld();
      }
    }
  };
  //connect handheld
  const connectHandheld = async () => {
    if (UserIdStore) {
      if (!handheldID || handheldID === 0) {
        setMessageError("Please connect Handheld");
      } else {
        setShowLoading(true);
        const res = await connectCardPoint(UserIdStore, handheldID);
        if (res?.status === 1 && res?.data) {
          setShowLoading(false);
          setShowModalReader(true);
          setSessionKeyHandheld(res?.data);
          paymentWithHandheld(res?.data);
        } else {
          setShowLoading(false);
          setMessageError(res?.message ?? "Bad request");
        }
      }
    }
  };
  const pushPaymentVnpay = useCallback(async (data: any) => {
    var pathName = window.location.pathname;
    if (pathName.search("/web-owner") > -1) {
      setRefTxn(data?.vnp_TxnRef);
      setIsPay(true)
    }
  }, [socket]);
  //tạo hóa đơn qrcode vnpay
  const handlePaymentVnPay = async () => {
    if (UserIdStore) {
      try {
        var pricePay = Number(amountValue);
        const vnp_TxnRef = UserIdStore + '' + pos_id + '' + moment(new Date()).format('YYYYMMDDHHmmss');

        const data = await vnpayCreatePayment(UserIdStore, pricePay, vnp_TxnRef);
        if (data.status === 1) {
          setOrderRef(vnp_TxnRef);
          setQrUrl(data.data);
          setShowModalQRCODEVnPay(true);
          setShowLoading(false);
          // window.location.href = data.data; // Chuyển hướng người dùng đến trang thanh toán của VNPay
        } else {
          setMessageError("Có lỗi xảy ra khi tạo yêu cầu thanh toán!");
          setShowModalQRCODEVnPay(false)
          setShowLoading(false);
        }
      } catch (error) {
        console.error("Thanh toán thất bại", error);
        setMessageError("Thanh toán thất bại");
        setShowModalQRCODEVnPay(false);
        setShowLoading(false);
      }
    }
  };
  const PaymentVnPaySucccess = async () => {
    setShowModalQRCODEVnPay(false);
    if (!UserIdStore) {
      return;
    }
    const req = await addGiftCardByPos(
      UserIdStore,
      note ?? "",
      Number(amountValue),
      sendFrom ?? "",
      sendTo ?? "",
      phoneNumber ?? "",
      email ?? "",
      valueTimeSend,
      staffJointId,
      Number(deliver)
    );
    if (Number(req?.status) === 1) {
      setShowLoading(false);
      setIsPay(false);
      setRefTxn('');
      setOrderRef('')
      handleClose();
      dismisModalReader();
    } else {
      setShowLoading(false);
    }
  }
  //tiến hành chờ wuetj thẻ cảdpoint
  const paymentWithHandheld = async (sessionKey: string) => {
    if (!UserIdStore) {
      return;
    }
    var pricePay = ((Number(amountValue)));
    const req = await paymentWithCardPoint(
      UserIdStore ?? 0,
      sessionKey,
      0,
      pricePay,
      handheldID
    );
    if (Number(req?.status) === 1) {
      setShowModalReader(false);
      const req = await addGiftCardByPos(
        UserIdStore,
        note ?? "",
        Number(amountValue),
        sendFrom ?? "",
        sendTo ?? "",
        phoneNumber ?? "",
        email ?? "",
        valueTimeSend,
        staffJointId,
        Number(deliver)
      );
      if (Number(req?.status) === 1) {
        setShowLoading(false);
        handleClose();
        dismisModalReader();
      } else {
        setShowLoading(false);
      }
    } else {
      setShowModalReader(false);
      if (!req?.message?.includes("canceled")) {
        setMessageError(req.message ?? "");
      }
      return "";
    }
  };
  const CancelPayment = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const res = await disConnectCardPoint(
        UserIdStore,
        handheldID,
        sessionKeyHandheld
      );
      if (res?.status === 1) {
        setShowLoading(false);
        setSessionKeyHandheld("");
        dismisModalReader();
      } else {
        setShowLoading(false);
        setMessageError(res?.message ?? "Bad request");
      }
    }
  };
  useEffect(() => {
    if (isPay && refTxn === orderRef) {
      setShowModalQRCODEVnPay(false)
      PaymentVnPaySucccess()
    }
  }, [isPay, refTxn])
  useEffect(() => {
    clearForm();
    if (!UserIdStore) {
      return;
    }
    const eventPayment = "PushPaymentVnpayQuick" + UserIdStore;

    socket.on(eventPayment, pushPaymentVnpay);

    return () => {
      socket.off(eventPayment, pushPaymentVnpay);
    };
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-byt-gift-card border-modal-16px "
      >
        <Modal.Dialog className="w-100 my-2">
          <Modal.Body className="p-0 ">
            <div className="position-relative px-4 ">
              <div className="  w-100">
                <h2 className="text-center text-[#4a4a6a]">
                  {languageUI.giftCard}
                </h2>
                <p className="step-number ">
                  {languageUI.step1}: {languageUI.amount}
                </p>
                <div className="d-flex align-items-center gap-3 px-4 mt-2">
                  <input
                    type="number"
                    className="rounded-3 border-999999 w-150px px-3 h-30px"
                    inputMode="numeric"
                    value={String(amountValue)}
                    onChange={(e) => {
                      setAmountValue(e.target.value);
                    }}
                  />
                  <p className="m-0 font-medium">
                    {formatCurrencyVND(
                      amountValue === undefined ? 0 : Number(amountValue)
                    )}
                  </p>
                </div>
                <p className="step-number">
                  {languageUI.step2}: {languageUI.info}
                </p>
                <div className="d-flex px-4 align-items-center">
                  <p className="text-send-gift-card w-150px">
                    {languageUI.sendfrom}:
                  </p>
                  <input
                    value={sendFrom}
                    type="text"
                    className="border-999999 rounded-3  w-250px px-3 h-30px text-4a4a6a"
                    onChange={(e) => setSendFrom(e.target.value)}
                  />
                </div>
                <div className="d-flex px-4 align-items-center mt-2">
                  <p className="text-send-gift-card w-150px">
                    {languageUI.sendto}:
                  </p>
                  <input
                    value={sendTo}
                    type="text"
                    className="border-999999 rounded-3  w-250px px-3 h-30px text-4a4a6a"
                    onChange={(e) => setSendTo(e.target.value)}
                  />
                </div>
                <p className="step-number">
                  {languageUI.step3}: {languageUI.sendto}
                </p>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={sendToValue}
                  name="choose-food-group flex-shink-0"
                  className=""
                >
                  <div className="d-flex px-4">
                    <div className="w-150px d-flex justify-content-start">
                      <FormControlLabel
                        value={"1"}
                        control={
                          <Radio
                            sx={{
                              color: "#0B2F88",
                              "&.Mui-checked": {
                                color: "#0B2F88",
                              },
                            }}
                            onChange={(e) => {
                              handleChangeSendToValue(e.target.value);
                            }}
                          />
                        }
                        label={<p className="m-0">{languageUI.phone}:</p>}
                      />
                    </div>
                    <input
                      type="text"
                      inputMode="numeric"
                      value={phoneNumber}
                      maxLength={10}
                      className="border-999999 rounded-3  w-250px px-3 h-30px text-4a4a6a"
                      disabled={sendToValue !== "1"}
                      onChange={(e) => {
                        if (phoneNumber && phoneNumber?.length > 10) {
                          return;
                        } else {
                          setPhoneNumber(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex px-4">
                    <div className="w-150px d-flex justify-content-start">
                      <FormControlLabel
                        value={"2"}
                        control={
                          <Radio
                            sx={{
                              color: "#0B2F88",
                              "&.Mui-checked": {
                                color: "#0B2F88",
                              },
                            }}
                            onChange={(e) => {
                              handleChangeSendToValue(e.target.value);
                            }}
                          />
                        }
                        label={<p className="m-0">Email:</p>}
                      />
                    </div>
                    <input
                      type="text"
                      value={email}
                      className="border-999999 rounded-3  w-250px px-3 h-30px text-4a4a6a"
                      disabled={sendToValue !== "2"}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </RadioGroup>

                <p className="step-number">
                  {languageUI.step4}: {languageUI.deliver}
                </p>
                <RadioGroup
                  aria-labelledby="deliver-gift-card"
                  value={deliver}
                  name="deliver-gift-card flex-shink-0"
                  className=""
                >
                  <div className="d-flex px-4">
                    <div className="w-150px d-flex justify-content-start">
                      <FormControlLabel
                        value={"1"}
                        control={
                          <Radio
                            sx={{
                              color: "#0B2F88",
                              "&.Mui-checked": {
                                color: "#0B2F88",
                              },
                            }}
                            onChange={(e) => {
                              handleChangeDeliver(e.target.value);
                            }}
                          />
                        }
                        label={<p className="m-0">{languageUI.now}:</p>}
                      />
                    </div>
                  </div>
                  <div className="d-flex px-4">
                    <div className="w-150px d-flex justify-content-start">
                      <FormControlLabel
                        value={"2"}
                        control={
                          <Radio
                            sx={{
                              color: "#0B2F88",
                              "&.Mui-checked": {
                                color: "#0B2F88",
                              },
                            }}
                            onChange={(e) => {
                              handleChangeDeliver(e.target.value);
                            }}
                          />
                        }
                        label={<p className="m-0">{languageUI.schedule}:</p>}
                      />
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateTimePicker"]}>
                        <DesktopDateTimePicker
                          value={valueTimeSend}
                          onChange={(e) => setValueTimeSend(e)}
                          disabled={deliver !== "2"}
                          minDate={today}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </RadioGroup>
                <p className="step-number">
                  {languageUI.step4}: {languageUI.Messageoptional}
                </p>
                <div className="px-4">
                  <textarea
                    className="form-control text-4a4a6a mt-2"
                    id="example-textarea"
                    rows={3}
                    value={note}
                    placeholder={languageUI.writeSomeNote}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              {messageError && (
                <div className="text-danger text-center">{messageError}</div>
              )}

              <div className="d-flex justify-content-between mt-3">
                <button className="btn-submit-gift-card" onClick={handleClose}>
                  {languageUI.cancel}
                </button>
                <button
                  className="btn-submit-gift-card"
                  onClick={() => submitAddgift(1)}
                >
                  {languageUI.payWithCash}
                </button>
                <button
                  className="btn-submit-gift-card"
                  onClick={() => submitAddgift(3)}
                >
                  VNPAY-QR
                </button>
                <button
                  className="btn-submit-gift-card"
                  onClick={() => submitAddgift(2)}
                >
                  {languageUI.payWithCard}
                </button>
              </div>
              {/* <img src={leaf1} alt="" className="img-leaf1-otp-owner" />
              <img src={leaf2} alt="" className="img-leaf2-otp-owner" /> */}
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
      <ModalReader
        show={showModalReader}
        handleClose={CancelPayment}
        amount={((Number(amountValue)))}
      />
      <ModalQrcodeVnpay
        show={showModalQRCODEVnPay}
        handleClose={() => setShowModalQRCODEVnPay(false)}
        qrUrl={qrUrl}
        amount={((Number(amountValue)))}
      />
    </>
  );
};

export default ModalBuyGiftCard;
