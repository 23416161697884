import { number } from "yup";
import * as Request from "../Request";
export interface InfoCustomer {
  id_customer: string;
  name: string;
  point: string;
  phone: string;
  lastVisit: string;
  timeVisit: string;
  total_amount: string;
}
export interface CustomerSelected {
  customer_id: string;
  phone: string;
  name: string;
}
export interface InfoCustomer {
  id: string;
  name: string;
  phone: string;
  gender: string;
  birthday: string;
  points: string;
  timeVisit: string;
}
export interface HistoreOrderStaff {
  id: string;
  timeOrder: string;
  table_id: string;
  staff_name: string;
  table_name: string;
  sell_card: number;
}
export interface HistoryOrder {
  id: string;
  owner_id: string;
  custommer_id: string;
  table_id: string;
  table_name: string;
  tip_amount: string;
  tip_staff_id: string;
  staff_name: string;
  price: string;
  timeorder: string;
  dateorder: string;
  total_order: string;
  vat_amount: string;
}
export interface Result<T> {
  status: number;
  message?: string;
  data?: T;
  total?: number;
  totalPage?: number;
  info?: InfoCustomer;
}

export interface InfoOrder {
  custommer_id: string;
  dateorder: string;
  id: string;
  owner_id: string;
  price: string;
  staff_name: string;
  table_id: string;
  table_name: string;
  timeorder: string;
  tip_amount: string;
  tip_staff_id: string;
  total_order: string;
  vat_amount: string;
  customer_name: string;
  phone: string;
  totalItem: string;
  order_method: string;
  order_method_name: string;
}
export interface successSentSms {
  status: number;
  message: string;
  data?: string[];
  phone: string;
}

export interface ResultSentSMS<T> {
  status: number;
  message: string;
  success: T;
  fail: T;
}
export const getAllCustomer = async (
  admin_id: number,
  date_start: string,
  date_end: string,
  type: number,
  page?: number,
  limit?: number,
  monthCheck?: number,
  id_review?: number,
  searchPhone: string = ""
) => {
  return await Request.fetchAPI(`owner/gAllCustomer_v3.php`, "POST", {
    admin_id,
    date_start,
    date_end,
    type,
    page,
    limit,
    monthCheck,
    id_review,
    searchPhone,
  })
    .then((res: Result<InfoCustomer[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        total: 0,
        totalPage: 1,
      };
    });
};

export const sentSMSToCustomer = async (
  admin_id: number,
  contentMessage: string,
  listCustomerSelected: CustomerSelected[],
  voucherId: number = 0
) => {
  return await Request.fetchAPI(`owner/sentSMSToMultilCustomer.php`, "POST", {
    admin_id,
    contentMessage,
    listCustomerSelected: JSON.stringify(listCustomerSelected),
    voucherId,
  })
    .then((res: ResultSentSMS<successSentSms>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gHistoryOrderCustomer = async (
  owner_id: number,
  customer_id: string
) => {
  return await Request.fetchAPI(`owner/gInfoAndOrderByCustomer.php`, "POST", {
    owner_id,
    customer_id,
  })
    .then((res: Result<HistoryOrder[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        info: undefined,
      };
    });
};

export const gHistoryOrder = async (
  owner_id: number,
  page?: number,
  limit?: number
) => {
  return await Request.fetchAPI(`owner/historyOrder.php`, "POST", {
    owner_id,
    page,
    limit,
  })
    .then((res: Result<InfoOrder[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        total: 0,
        totalPage: 1,
      };
    });
};
export const gDeleteBillOrder = async (owner_id: number, order_id: number) => {
  return await Request.fetchAPI(`owner/delete-order.php`, "POST", {
    owner_id,
    order_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        data: [],
        status: number,
      };
    });
};
export const gHistoryOrderByStaff = async (
  owner_id: number,
  page?: number,
  limit?: number
) => {
  return await Request.fetchAPI(`owner/gListHistoryOrderByStaff.php`, "POST", {
    owner_id,
    page,
    limit,
  })
    .then((res: Result<HistoreOrderStaff[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        total: 0,
        totalPage: 1,
      };
    });
};
