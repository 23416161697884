import { Accordion } from "react-bootstrap";
import "./Styles.css";
import { useEffect, useState } from "react";
import ModalAlert from "../../components/ModalAlert/ModalAlert";
import * as orderConfilmApi from "../../api/apiCheckin/orderConfilmApi";
import * as paymentApi from "../../api/apiCheckin/paymentApi";
import { formatCurrencyVND, FormatDolla } from "../../utils/format";
import socket from "../../utils/socket";
import OrderListITem from "./components/OrderListITem/OrderListITem";
import {
  arrowLeft,
  chevronDown,
  dollarGray,
  percent,
  plusOrange,
  wallet,
} from "../../components/ImgExport";
import MethodPayment from "./components/MethodPayment/MethodPayment";
import ModalSelectEmployeeTip from "./components/ModalSelectEmployeeTip/ModalSelectEmployeeTip";
import ModalPaymentCash from "./components/MethodPayment/Component/ModalPaymentCash";
import ModalCallStaff from "./components/MethodPayment/Component/ModalCallStaff";
import ModalAddVisaCard from "./components/ModalAddVisaCard/ModalAddVisaCard";
import ModalLoading from "../../components/ModalLoading";
import PaymentStatus from "./PaymentStatus";
import { useNavigate } from "react-router-dom";
import ModalCustomTip from "./components/ModalCustomTip/ModalCustomTip";
import React from "react";
import { clearTokenCustomer } from "../../api/apiCheckin/checkinApi";
import { PaymentMethod, Token } from "@stripe/stripe-js";
import { checkedAddReader } from "../../components/Image";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import ModalApplePay from "./components/ModalPaymentApple/ModalApplePay";
import moment from "moment";
import { vnpayCreatePayment } from "../../api/apiOwner/vpnPayApi";

const Payment = () => {
  const navigate = useNavigate();
  const languageUI = getLanguageCheckIn();
  const [valueTip, setValueTip] = useState<number>(0); //id tip đang chọn
  const [TipPrice, setTipPrice] = useState<number>(0);
  const [valueTipCustom, setValueTipCustom] = useState<number | string>();

  const custommerId = localStorage.getItem("custommerId");
  const table_id = localStorage.getItem("table_id");
  const ownerID = Number(localStorage.getItem("ownerID"));
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [listStaff, setListStaff] = useState<paymentApi.TipStaff[]>([]);
  const [staffId, setStaffId] = useState<string>(""); //staff dc chọn nhận tip
  const [staffName, setStaffName] = useState<string>(""); //tên staff dc chọn nhận tip
  const [messageError, setMessageError] = useState<string>("");
  const [showModalAlertFail, setshowModalAlertFail] = useState(false);
  const [orderInfo, setOrrderInfo] = useState<orderConfilmApi.InfoOrder[]>([]);
  const [tipList, setTipList] = useState<paymentApi.Tip[]>([]);

  const [showMethodPayment, setShowMethodPayment] = useState(false);
  const [showModalChooseNameTip, setShowModalChooseNameTip] = useState(false);
  const [showModalPaymentCash, setShowModalPaymentCash] = useState(false);
  const [showModalCallStaff, setShowModalCallStaff] = useState(false);
  const [showModalAddVisaCard, setShowModalAddVisaCard] = useState(false);
  const [showModalApplePay, setShowModalApplePay] = useState(false);
  const [valuePaymentNumberMethod, setValueNumberMethod] = useState();
  const [paymentMethodName, setPaymentMethodName] = useState("");
  const [totalFood, setTotalFood] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [feeStripe, setFeeStripe] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<number | string>("");
  const [expiryDate, setexpiryDate] = useState<string>();
  const [CVVNumber, setCVVNumber] = useState<number>();
  const [showDateYear, setShowDateYear] = useState(false);
  const [showPaymentStatus, setShowPaymentStatus] = useState(false);
  const [statusPayVisa, setStatusPayVisa] = useState<boolean>(false);
  const [showCustomTip, setShowCustomTip] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherId, setVoucherId] = useState("");
  const [infoVoucher, setinfoVoucher] = useState<paymentApi.VoucherUsed>();
  const [discount, setDiscount] = useState<number>(0);
  const [messageErrorVoucher, setMessageErrorVoucher] = useState<string>("");
  const [order_id, setOrder_id] = useState<string>();
  const [isVoucher, setIsVoucher] = useState(false);
  const [tokenCard, setTokenCard] = useState<Token>();
  const [openToolTip, setOpenToolTip] = React.useState(false);
  const [listSide, setListSide] = useState<orderConfilmApi.SideList[]>([]);
  const [paymentMethodNameShowBill, setPaymentMethodNameShowBill] =
    useState<string>(""); //hiển thị ở bill

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const dismisModalCustomTip = () => {
    setShowCustomTip(false);
  };

  const dismisPaymentStatus = () => {
    setShowPaymentStatus(false);
  };
  const handleCloseshowDateYear = () => {
    setShowDateYear(false);
  };
  const handleShowDateYear = () => {
    setShowDateYear(true);
  };

  function handleChangeInput(tip?: number) {
    if (tip) {
      setValueTipCustom(Number(tip));
      setValueTip(0);
      setTipPrice(Number(tip));
    } else {
      setValueTipCustom("");
      setValueTip(0);
      setTipPrice(0);
    }
  }

  const dismisModalAlert = () => {
    setshowModalAlertFail(false);
  };

  const dismisModalAddVisaCard = () => {
    setShowModalAddVisaCard(false);
  };

  const dismisModalApplePay = () => {
    setShowModalApplePay(false);
  };

  const dismisModalCallStaff = () => {
    setShowModalCallStaff(false);
  };

  const dismisModalChooseNameTip = () => {
    setShowModalChooseNameTip(false);
  };

  const dismisModalMethodPayment = () => {
    setShowMethodPayment(false);
  };
  const handleCashSuccess = () => {
    setShowModalPaymentCash(false);
    navigate("/webcheckin/choosemenu/" + custommerId);
  };
  const CheckVouchePass = async () => {
    if (ownerID && custommerId) {
      const req = await paymentApi.checkExistVoucher(
        ownerID,
        custommerId,
        voucherCode
      );
      if (Number(req?.status) === 1) {
        setinfoVoucher(req?.data ?? {});
        setMessageErrorVoucher("");
        setVoucherId(req?.data?.id ?? "");
        const Info = req?.data;
        if (Number(Info?.type) === 1) {
          setDiscount((totalPrice * Number(Info?.discount_value ?? 0)) / 100);
        } else {
          setDiscount(
            Number(Info?.discount_value) > totalPrice
              ? totalPrice
              : Number(Info?.discount_value)
          );
        }
        setIsVoucher(true);
      } else {
        setDiscount(0);
        setinfoVoucher({});
        setVoucherId("");
        setMessageErrorVoucher(req?.message ?? "");
      }
    }
  };
  const handelPayment = () => {
    // console.log(11122, valuePaymentNumberMethod);

    // if (valuePaymentNumberMethod == 1) {
    //   setShowMethodPayment(false)
    //   setPaymentMethodName('Cash')
    //   // setShowModalPaymentCash(true)
    // }
    // else
    if (valuePaymentNumberMethod == 2) {
      setPaymentMethodName("visa");
      setShowMethodPayment(false);
      setShowModalAddVisaCard(true);
    }
    // else if (valuePaymentNumberMethod == 3) {
    //   setShowMethodPayment(false)
    //   setPaymentMethodName('Google Pay')
    // }
    else if (valuePaymentNumberMethod == 4) {
      setShowMethodPayment(false);
      setTokenCard(undefined);
      setPaymentMethodName("Apple Pay");
      setShowModalApplePay(true);
    }
  };
  useEffect(() => {
    if (cardNumber) {
      setPaymentMethodName("VISA-" + cardNumber);
    }
  }, [cardNumber]);
  const handelCallStaff = async () => {
    setShowModalCallStaff(true);
    setShowMethodPayment(false);
    if (order_id) {
      const callStaffapi = await paymentApi.CallStaff(order_id);
      if (Number(callStaffapi.status) === 1) {
        socket.emit("PushNotificationCallStaff", {
          table_id,
          ownerID,
          custommerId,
          message: callStaffapi?.message ?? "Help",
        });
      }
    }
  };
  const gListStaffTip = async () => {
    if (ownerID) {
      const StaffApi = await paymentApi.getListTipStaff(ownerID);
      if (Number(StaffApi.status) === 1) {
        setListStaff(StaffApi.data ?? []);
      }
    }
  };

  const gListTip = async () => {
    if (ownerID) {
      const Tip = await paymentApi.getListTip(ownerID);
      if (Number(Tip.status) === 1) {
        setTipList(Tip?.data ?? []);
      }
    }
  };
  const gInfoOrderConfilm = async () => {
    if (custommerId && ownerID && table_id) {
      setShowLoading(true);
      const Info = await orderConfilmApi.gOrderConfilmPay(
        custommerId,
        ownerID,
        table_id
      );
      if (Number(Info.status) === 1) {
        setOrrderInfo(Info?.data ?? []);
        setOrder_id(Info?.order_id);
        setTotalFood(Number(Info?.totalFood));
        setTotalPrice(Number(Info?.totalPrice));
        setValueTipCustom(
          Number(Info?.tip_amount) ? Number(Info?.tip_amount) : ""
        );
        setStaffId(Info?.tip_staff_id ? Info?.tip_staff_id : "");
        setStaffName(Info?.staff_name ? Info?.staff_name : "");
        setTipPrice(Number(Info?.tip_amount) ? Number(Info?.tip_amount) : 0);
        setVoucherCode(Info?.voucher_code ?? "");
        setDiscount(Number(Info?.discount));
        setVoucherId(Info?.voucher_id ?? "");
        setListSide(Info?.SideList ?? []);
        if (Number(Info?.voucher_id) !== 0) {
          setIsVoucher(true);
        }
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  const gConfig = async () => {
    const config = await paymentApi.getConfig(ownerID);
    if (Number(config.status) === 1) {
      setTax(Number(config?.data?.tax) ?? 0);
      setFeeStripe(Number(config?.data?.fee_stripe) === 1 ? true : false);
    }
  };

  const PaymentOrder = () => {

    if (TipPrice < 0) {
      setMessageError(languageUI.Tippingmustbegreaterthan);
      setshowModalAlertFail(true);
    } else {
      //chuyển qua vnpay
      // if (Number(valuePaymentNumberMethod) === 1) {
      //   PaymentByCash();
      // } else if (Number(valuePaymentNumberMethod) === 2) {
      //   // setShowModalPayment2(true)
      //   PaymentByVisa();
      // } else if (Number(valuePaymentNumberMethod) === 4) {
      //   setShowModalApplePay(true);
      // }
      PaymentByVNPay()
    }

  };
  //thanh toán vnpay
  const PaymentByVNPay = async () => {
    if (custommerId && ownerID && table_id && order_id) {
      setShowLoading(true);
      var tax_price = tax > 0 ? (totalPrice * tax) / 100 : 0;
      var tip_amount = TipPrice > 0 ? TipPrice : 0;
      var fee = feeStripe ? ((totalPrice + TipPrice) * 3) / 100 : 0;

      var TotalPay = Math.round(Number(
        (
          totalPrice -
          discount +
          TipPrice +
          (totalPrice * tax) / 100 +
          fee
        )
      ));

      const Payment = await paymentApi.PaymentVNPay(
        Number(custommerId), ownerID, TotalPay, tax_price, fee, Number(order_id), Number(staffId), tip_amount, discount, voucherCode, Number(voucherId)
      );
      if (Number(Payment.status) === 1) {
        // window.location.href = Payment?.data;
        // socket.emit("PushNotificationPayMent", {
        //   table_id,
        //   ownerID,
        //   custommerId,
        //   message: Payment?.message,
        //   order_id: Payment?.order_id,
        // });
        CreateTransaction()
        setShowLoading(false);
      } else {
        setMessageError(Payment?.message ?? "");
        setshowModalAlertFail(true);
        setShowLoading(false);
      }
    }
  };

  //tiến hàng thanh toán vnpay
  const CreateTransaction = async () => {
    var tax_price = tax > 0 ? (totalPrice * tax) / 100 : 0;
    var tip_amount = TipPrice > 0 ? TipPrice : 0;
    var fee = feeStripe ? ((totalPrice + TipPrice) * 3) / 100 : 0;

    var TotalPay = Math.round(Number(
      (
        totalPrice -
        discount +
        TipPrice +
        (totalPrice * tax) / 100 +
        fee
      ))
    );

    const vnp_TxnRef = ownerID + '' + moment(new Date()).format('YYYYMMDDHHmmss');

    const data = await vnpayCreatePayment(ownerID, TotalPay, vnp_TxnRef, order_id);
    if (data.status === 1 && data?.data) {
      setShowLoading(false);
      window.location.href = data?.data; // Chuyển hướng người dùng đến trang thanh toán của VNPay
    } else {
      setMessageError("Có lỗi xảy ra khi tạo yêu cầu thanh toán!");
      setShowLoading(false);
    }
  }
  //thanh toán bằng tiền mặt
  const PaymentByCash = async () => {
    if (custommerId && ownerID && table_id && order_id) {
      setShowLoading(true);
      const Payment = await paymentApi.PaymentCash(
        staffId,
        TipPrice,
        table_id,
        custommerId,
        ownerID,
        order_id,
        voucherCode,
        voucherId,
        discount
      );
      if (Number(Payment.status) === 1) {
        socket.emit("PushNotificationPayMent", {
          table_id,
          ownerID,
          custommerId,
          message: Payment?.message,
          order_id: Payment?.order_id,
        });
        setShowLoading(false);
        setShowModalPaymentCash(true);
      } else {
        setMessageError(Payment?.message ?? "");
        setshowModalAlertFail(true);
        setShowLoading(false);
      }
    }
  };
  //thanh toán visa
  const PaymentByVisa = async () => {
    if (custommerId && ownerID && table_id && order_id && tokenCard?.id) {
      setShowLoading(true);
      var tax_price = tax > 0 ? (totalPrice * tax) / 100 : 0;
      var tip_amount = TipPrice > 0 ? TipPrice : 0;
      var fee = feeStripe ? ((totalPrice + TipPrice) * 3) / 100 : 0;

      var TotalPay = Number(
        (
          totalPrice -
          discount +
          TipPrice +
          (totalPrice * tax) / 100 +
          fee
        ).toFixed(2)
      );

      const Payment = await paymentApi.PaymentVisa(
        order_id,
        ownerID,
        custommerId,
        TotalPay,
        tax_price,
        TotalPay,
        fee,
        tokenCard?.id,
        staffId,
        tip_amount,
        voucherCode,
        voucherId,
        discount
      );
      if (Number(Payment.status) === 1) {
        socket.emit("PushNotificationPayMent", {
          table_id,
          ownerID,
          customer_name: Payment?.customer_name,
          custommerId,
          message: Payment?.message,
          order_id: Payment?.order_id,
        });
        clearToken();
        setPaymentMethodNameShowBill("VISA-" + cardNumber);
        setShowLoading(false);

        setStatusPayVisa(true);
        setShowPaymentStatus(true);
      } else {
        setMessageError(Payment?.message ?? "");
        setStatusPayVisa(false);
        setShowLoading(false);
        setShowPaymentStatus(true);
      }
    }
  };
  const clearToken = async () => {
    const token_custommer = localStorage.getItem("token_custommer");
    if (token_custommer) {
      await clearTokenCustomer(token_custommer);
    }
  };
  const BackHomeHandle = () => {
    navigate(`/webcheckin/choosemenu/${custommerId}`);
  };
  const NextMyBill = () => {
    var tax_price = tax > 0 ? (totalPrice * tax) / 100 : 0;
    var tip_amount = TipPrice > 0 ? TipPrice : 0;
    var TotalPay = Number(
      (
        totalPrice +
        TipPrice +
        (totalPrice * tax) / 100 +
        (feeStripe ? ((totalPrice + TipPrice) * 3) / 100 : 0)
      ).toFixed(2)
    );
    navigate("/webcheckin/mybill", {
      state: {
        order_id,
        ownerID,
        custommerId,
        totalPrice,
        tax_price,
        TotalPay,
        cardNumber,
        expiryDate,
        CVVNumber,
        staffId,
        tip_amount,
        orderInfo: orderInfo,
        discount,
        paymentMethodNameShowBill,
      },
    });
  };
  useEffect(() => {
    gConfig();
    gListTip();
    gInfoOrderConfilm();
    gListStaffTip();
  }, []);
  return (
    <div className="bg-white w-100 min-h-100vh">
      <header className="header-imformation-order px-3 d-flex justify-content-between align-items-start">
        <div>
          <p className="mb-0 text-review-imformation">{languageUI.Review}</p>
          <p className="mb-0 text-number-order">
            {languageUI.yourorder} ({totalFood})
          </p>
        </div>
        <div className="d-flex align-items-center gap-1">
          <img src={plusOrange} alt="" />
          <div
            onClick={() => navigate(`/webcheckin/choosemenu/${custommerId}`)}
          >
            <p className="mb-0 text-order-more">{languageUI.Ordermore}</p>
          </div>
        </div>
      </header>
      <div className="padding-top-header-information">
        <div className="pt-3 px-3">
          <ModalLoading
            show={ShowLoading}
            onHide={() => setShowLoading(false)}
          />
          <div className={`transition-0.5`}>
            <p className="text-number-order">
              {languageUI.YouhavediscountcodeApplynow}
            </p>
            <div className="box-input-voucher">
              <div className="d-flex gap-2">
                {infoVoucher?.type && Number(infoVoucher?.type) === 2 ? (
                  <img src={dollarGray} alt="" />
                ) : (
                  <img src={percent} alt="" />
                )}

                <input
                  type="text"
                  placeholder="Discountcode "
                  className="input-voucher focus-visible-none"
                  value={voucherCode}
                  onChange={(e) => setVoucherCode(e.target.value)}
                  readOnly={isVoucher}
                />
              </div>
              <div>
                {isVoucher && discount > 0 && (
                  <img
                    src={checkedAddReader}
                    alt=""
                    className="h-50px w-35px me-3"
                  />
                )}

                <button
                  className="btn-apply-voucher"
                  onClick={CheckVouchePass}
                  disabled={isVoucher}
                >
                  {languageUI.Apply}
                </button>
              </div>
            </div>
            <div className="text-danger text-italic text-center">
              {messageErrorVoucher}
            </div>
            <div className="mt-2">
              <p className="text-content-title">{languageUI.Addtip}</p>
              <div className="d-flex justify-content-between">
                {tipList.length > 0 &&
                  tipList.map((items, i) => (
                    <div
                      key={i}
                      className={`d-flex justify-content-center align-items-center w-30  ${valueTip === Number(items.id)
                        ? "custom-choose-tip-2"
                        : "custom-choose-tip"
                        }`}
                      onClick={() => {
                        setValueTip(Number(items.id));
                        setTipPrice(
                          (totalPrice * Number(items?.percent)) / 100
                        );
                        setValueTipCustom("");
                        staffName === ""
                          ? setShowModalChooseNameTip(true)
                          : setShowModalChooseNameTip(false);
                      }}
                    >
                      <p
                        className={`custom-text-choose-categorie text-choose-tip text-center mb-0 ${valueTip === Number(items.id)
                          ? "text-ffffff"
                          : "text-8E8EA9 "
                          }`}
                      >
                        {items.percent} % <br /> (
                        {formatCurrencyVND(
                          (totalPrice * Number(items?.percent)) / 100
                        )}
                        )
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
              <input
                type="number"
                value={valueTipCustom}
                // onChange={handleChangeInput}
                readOnly
                onClick={() => {
                  setShowCustomTip(true);
                  staffName === ""
                    ? setShowModalChooseNameTip(true)
                    : setShowModalChooseNameTip(false);
                }}
                className="input-voucher focus-visible-none w-100"
                placeholder={languageUI.CustomTipAmount}
              />
              <p className="mb-0 text-other mr-6px">$</p>
            </div>
            <div
              className={`d-flex align-items-center gap-1 mt-2 ${staffName === "" ? "d-none" : ""
                }`}
              onClick={() => setShowModalChooseNameTip(true)}
            >
              <div className="input-voucher flex-shink-0">
                {languageUI.Employeesaretipped}:
              </div>
              <div className="input-voucher text-12B064 text-truncate">{`${staffName != "" ? staffName : languageUI.Employeewanttotip
                } `}</div>
            </div>
            <div className={`mt-2`}>
              <p className="text-content-title">{languageUI.PaymentMethod}</p>
              <div
                className="d-flex justify-content-between border-1px-gray rounded-16px mt-2"
                onClick={() => {
                  setShowMethodPayment(true);
                }}
              >
                {/* <input type="text" className="input-voucher focus-visible-none" placeholder="Other" /> */}
                <div className="d-flex align-items-center gap-2px ">
                  <p className="input-voucher mb-0">
                    {languageUI.Yourpaymentmethod}
                  </p>
                  <p className="payment-method mb-0">{`${paymentMethodName ? "(" + paymentMethodName + ")" : ""
                    }`}</p>
                </div>
                <img src={chevronDown} alt="" />
              </div>
            </div>
          </div>
          <Accordion>
            <Accordion.Item
              eventKey="0"
              className="border-none focus-visible-none mt-2"
            >
              <Accordion.Header className="text-order-list">
                {languageUI.Orderlistandprices}
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-column gap-2">
                  <OrderListITem
                    orderInfo={orderInfo}
                    status={true}
                    textStatus="Well done"
                  />
                </div>
                <div>
                  {listSide.length > 0 && (
                    <>
                      <p className="choose-a-side mb-0">
                        {languageUI.SideDish}
                      </p>
                      {listSide.map((item, index) => (
                        <div>
                          <p className="text-side-dish mb-0">
                            {index + 1}. {item.name} (x{item.quantity})
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="my-2 py-2 border-top d-flex flex-column gap-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="name-title-price">
                      {languageUI.ItemsTotal}
                    </div>
                    <div className="d-flex gap-2px">
                      <div className="text-price-food-3">
                        {formatCurrencyVND(totalPrice)}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="name-title-price">Tip</div>
                    <div className="d-flex gap-2px">
                      {/* <div className="text-dollar-3">$</div> */}
                      <div className="text-price-food-3">
                        {TipPrice > 0 ? formatCurrencyVND(TipPrice) : 0}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="name-title-price">Tax</div>
                    <div className="d-flex gap-2px">
                      {/* <div className="text-dollar-3">$</div> */}
                      <div className="text-price-food-3">
                        {tax > 0 ? formatCurrencyVND((totalPrice * tax) / 100) : 0}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="name-title-price">
                      {languageUI.Discount}
                    </div>
                    <div className="d-flex gap-2px">
                      {/* <div className="text-dollar-3">$</div> */}
                      <div className="text-price-food-3">
                        {discount > 0 ? formatCurrencyVND(discount) : 0}
                      </div>
                    </div>
                  </div>
                  {feeStripe && (
                    <div className="d-flex justify-content-between align-items-center">
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div className="tooltip-payment-web-checkin">
                          <Tooltip
                            className=""
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={openToolTip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="right"
                            title={
                              languageUI.TheconveniencefeeischargedpertransactionbyathirdpartyvendorWedonotretainanyportionofthisfee
                            }
                          >
                            <div
                              className="d-flex gap-1 align-items-end w-fit"
                              onClick={() => setOpenToolTip(true)}
                            >
                              <div className="name-title-price">
                                {languageUI.Conveniencefee}
                              </div>
                              <HelpOutlineSharpIcon className="" />
                            </div>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                      <div className="d-flex gap-2px">
                        {/* <div className="text-dollar-3">$</div> */}
                        <div className="text-price-food-3">
                          {formatCurrencyVND(((totalPrice + TipPrice) * 3) / 100)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="footer-choose-menu py-3 w-100 bg-white px-3 border-top">
        <div className="d-flex justify-content-between ">
          <p className="text-total-information-card">{languageUI.Total}</p>
          <div className="d-flex gap-2px">
            <div className="text-price-information-card">
              {formatCurrencyVND(
                totalPrice -
                discount +
                TipPrice +
                (feeStripe ? ((totalPrice + TipPrice) * 3) / 100 : 0) +
                (totalPrice * tax) / 100
              )}
            </div>
            {/* <div className="text-dollar">$</div> */}
          </div>
        </div>
        <div className=" d-flex gap-2 align-items-center">
          <div
            className="position-relative"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={arrowLeft} alt="" />
          </div>
          {/* <a href="/webcheckin/cardInformation" className="w-100"> */}

          <button
            className="custom-filter-btn border-none w-100 text-ffffff"
            onClick={PaymentOrder}
          >
            <img src={wallet} alt="" />
            {languageUI.Paynow}
          </button>
          {/* </a> */}
        </div>
      </div>
      <ModalCustomTip
        handleClose={dismisModalCustomTip}
        show={showCustomTip}
        valueTipCustom={valueTipCustom}
        handleChangeInput={handleChangeInput}
      />
      <MethodPayment
        setValueNumberMethod={setValueNumberMethod}
        show={showMethodPayment}
        handleClose={dismisModalMethodPayment}
        handelSubmit={handelPayment}
        handelCallStaff={handelCallStaff}
        Amount={Number(
          (totalPrice - discount + TipPrice + (totalPrice * tax) / 100).toFixed(
            2
          )
        )}
      />
      <ModalAlert
        handleClose={dismisModalAlert}
        dismisImg={true}
        img={"#"}
        show={showModalAlertFail}
        title={messageError}
      ></ModalAlert>
      <ModalPaymentCash
        handleClose={handleCashSuccess}
        show={showModalPaymentCash}
      />
      <ModalCallStaff
        handleClose={dismisModalCallStaff}
        show={showModalCallStaff}
      />
      <ModalSelectEmployeeTip
        heightModal={800}
        listStaff={listStaff}
        staffId={staffId}
        setStaffId={(id: string) => setStaffId(id)}
        setStaffName={(name: string) => setStaffName(name)}
        handleClose={dismisModalChooseNameTip}
        show={listStaff.length > 0 ? showModalChooseNameTip : false}
      />
      <ModalAddVisaCard
        handleClose={dismisModalAddVisaCard}
        show={showModalAddVisaCard}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
        setexpiryDate={setexpiryDate}
        setCVVNumber={setCVVNumber}
        expiryDate={expiryDate}
        CVVNumber={CVVNumber}
        handleCloseshowDateYear={handleCloseshowDateYear}
        handleShowDateYear={handleShowDateYear}
        showDateYear={showDateYear}
        setTokenCard={setTokenCard}
      />
      <ModalApplePay
        handleClose={dismisModalApplePay}
        show={showModalApplePay}
        custommerId={custommerId}
        table_id={table_id}
        tax={tax}
        TipPrice={TipPrice}
        feeStripe={feeStripe}
        setShowLoading={setShowLoading}
        staffId={staffId}
        voucherCode={voucherCode}
        voucherId={voucherId}
        discount={discount}
        order_id={order_id}
        totalAmount={Number(
          (
            totalPrice -
            discount +
            TipPrice +
            (feeStripe ? ((totalPrice + TipPrice) * 3) / 100 : 0) +
            (totalPrice * tax) / 100
          )
        )}
        clearToken={clearToken}
        setStatusPayVisa={setStatusPayVisa}
        setShowPaymentStatus={setShowPaymentStatus}
        setPaymentMethodNameShowBill={setPaymentMethodNameShowBill}
      />
      <PaymentStatus
        handelClose={dismisPaymentStatus}
        show={showPaymentStatus}
        status={statusPayVisa}
        handelCallStaff={handelCallStaff}
        NextMyBill={NextMyBill}
        BackHomeHandle={BackHomeHandle}
      />
    </div>
  );
};

export default Payment;
