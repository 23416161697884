import { Modal } from "react-bootstrap";
import './styles.css'
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface ModalConfirmDeleteProps {
  show: boolean;
  printerName: string;
  messageError: string;
  handleClose: () => void;
  submitConfilm: () => void
}
const ModalConfirmDelete = ({ handleClose, show, printerName, messageError = '', submitConfilm }: ModalConfirmDeleteProps) => {
  const languageUI = getLanguageUI().setting
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className='modal-add-printer'
    >
      <Modal.Body>
        <h1 className="text-center">{languageUI.wantDeletePrinter} {printerName}?</h1>
        {messageError !== '' && (
          <div className="text-danger text-center ">{messageError}</div>
        )}
        <div className="mt-2 flex justify-content-between mt-4">
          <div className="text-white font-bold h-40px w-48 bg-primary w-fit d-flex justify-content-center align-items-center rounded-3" onClick={() => handleClose()}>{languageUI.cancel}</div>
          <div className="text-white font-bold h-40px w-48  w-fit d-flex justify-content-center align-items-center rounded-3 bg-delete" onClick={submitConfilm}>Ok</div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ModalConfirmDelete