import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import HyperDatepicker from "../../components/Datepicker";
// components
import Select from "react-select";
import { usFlag } from "../../components/Image";
import PageTitle from "../../components/PageTitle";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../components/ModalLoading";
import * as AccountInformation from "../../api/apiOwner/AccountInfor";
import Swal from "sweetalert2";
import moment from "moment-timezone";

const AccountInfor = () => {

  const UserIdStore = useSelector(userSlice.selectorUserId);

  //   const [ListPackage, setListPackage] =
  //     useState<CreateCustomerAdminAPI.IListPackage[]>();
  const [ContactPerson, setContactPerson] = useState<any>("");
  const [StoreName, setStoreName] = useState<any>("");
  const [CellPhone, setCellPhone] = useState<any>("");
  const [Address, setAddress] = useState<any>("");
  const [EmailAddress, setEmailAddress] = useState<any>("");
  const [SuitOfUnit, setSuitOfUnit] = useState<any>("");
  const [City, setCity] = useState<any>("");
  const [BusinessType, setBusinessType] = useState<number>(0);
  const [States, setStates] = useState<any>("");
  const [IdCard, setIdCard] = useState<any>("");
  const [CVC, setCVC] = useState<any>(0);

  const newday = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
  const monthNow =
    Number(newday.getMonth()) + 1 < 10
      ? "0" + (Number(newday.getMonth()) + 1)
      : newday.getMonth() + 1;
  const yearNow = newday.getFullYear();
  const startDateFormatRef = useRef<any>(yearNow + "-" + monthNow);
  const [ShowLoading, setShowLoading] = useState(false);

  //
  // Datepicker
  const [selectedStartDate, setSelectedStartDate] = useState<any>(new Date());

  const onDateChangeStart = (date: Date) => {
    if (date) {
      setSelectedStartDate(date);
      var month =
        Number(date.getMonth()) + 1 < 10
          ? "0" + (Number(date.getMonth()) + 1)
          : date.getMonth() + 1;
      var year = date.getFullYear();
      startDateFormatRef.current = month + "-" + year;
      console.log("startDateFormatRef.current", startDateFormatRef.current);
    }
  };

  // let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
  // let menuItemElOwner = document?.querySelectorAll(".menu-owner");
  // menuItemElAdmin.forEach((item, i) => {
  //   menuItemElAdmin[i]?.classList.remove("d-none");
  // });
  // menuItemElOwner.forEach((item, i) => {
  //   menuItemElOwner[i]?.classList.add("d-none");
  // });

  const GetAccountInfor = async () => {
    const AccountInfor = await AccountInformation.GetAccountInfor(UserIdStore);
    if (Number(AccountInfor.status) === 1) {
      setContactPerson(AccountInfor?.data?.contact_person);
      setStoreName(AccountInfor?.data.name);
      setCellPhone(AccountInfor?.data?.phone);
      setAddress(AccountInfor?.data?.address);
      setEmailAddress(AccountInfor?.data?.email);
      setCity(AccountInfor?.data?.store_city);
      setStates(AccountInfor?.data?.store_state);
      setCVC(AccountInfor?.data.bank_CVC);
      setIdCard(AccountInfor?.data.bank_number);
      if (AccountInfor?.data?.bank_date_card !== "") {
        setSelectedStartDate(
          new Date("01/" + AccountInfor?.data?.bank_date_card)
        );
      }
      startDateFormatRef.current = AccountInfor?.data?.bank_date_card;
      setSuitOfUnit(AccountInfor?.data.suit_of_unit);
    }
  };
  useEffect(() => {
    GetAccountInfor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const EditAccountInfor = async () => {
    const AccountInfor = await AccountInformation.updateAccountInfor(
      UserIdStore,
      ContactPerson,
      StoreName,
      Address,
      EmailAddress,
      City,
      SuitOfUnit,
      BusinessType,
      States,
      IdCard,
      CVC,
      startDateFormatRef.current
    );
    if (Number(AccountInfor.status) === 1) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Update success!",
        showConfirmButton: false,
        timer: 3500,
      });
    } else if (
      AccountInfor.message === "Email already exists on the system !"
    ) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Update Error, Email already exists on the system !",
        showConfirmButton: false,
        timer: 3500,
      });
    } else {
      console.log(AccountInfor);

      Swal.fire({
        position: "center",
        icon: "error",
        title: "Update Error, Please try again !",
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Customer", path: "#" },
          { label: "Create customer", path: "#", active: true },
        ]}
        title={"Create customer"}
      />

      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="p-3 rounded-3 mb-3 bg-white shadow">
        <h4 className="header-title fs-5 mb-3">Store detail</h4>
        <Row className="mx-n2">
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Contact person:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={ContactPerson}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Contact person"
                    onChange={(e: any) => {
                      setContactPerson(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Store name:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={StoreName}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Store name"
                    onChange={(e: any) => {
                      setStoreName(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Cell phone:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <InputGroup.Text className="border-end-0 bg-white">
                    <img src={usFlag} height={21} alt='' />
                  </InputGroup.Text>
                  <Form.Control
                    value={CellPhone}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    className="border-start-0 ps-0"
                    placeholder="(xxx) xxx-xxxx"
                    readOnly
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Address:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={Address}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Address"
                    onChange={(e: any) => {
                      setAddress(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Email address:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={EmailAddress}
                    type="email"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Email address"
                    onChange={(e: any) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Suit of unit:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={SuitOfUnit}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Suit of unit"
                    onChange={(e: any) => {
                      setSuitOfUnit(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">City:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={City}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="City"
                    onChange={(e: any) => {
                      setCity(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Business type:</Form.Label>
              </div>
              <div className="col-10">
                <Select
                  className="react-select react-select-container mb-3"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder="Business type"
                  isClearable={true}
                  options={[{ value: "1", label: "Nail/Spa" }]}
                  onChange={(e: any) => {
                    if (e == null) {
                      setBusinessType(0);
                    } else {
                      setBusinessType(e.value);
                    }
                  }}
                ></Select>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">States:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={States}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="States"
                    onChange={(e: any) => {
                      setStates(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>

        <div className="dropdown-divider mt-0"></div>
        { }
        <h4 className="header-title fs-5 mb-3 mt-2">Card information</h4>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Id Card:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={IdCard}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Id Card"
                    onChange={(e: any) => {
                      setIdCard(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Date of Card:</Form.Label>
              </div>
              <div className="col-10">
                <HyperDatepicker
                  hideAddon={true}
                  value={selectedStartDate}
                  dateFormat="MMMM yyyy"
                  onChange={(date) => {
                    console.log("date", date);

                    onDateChangeStart(date);
                  }}
                  showMonthYearPicker
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">CVC:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={CVC}
                    type="number"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="CVC"
                    onChange={(e: any) => {
                      setCVC(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>
        <div className="text-end">
          <Button
            variant="primary"
            className="bg-opacity-25"
            onClick={() => {
              EditAccountInfor();
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </>
  );
};

export default AccountInfor;
