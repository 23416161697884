import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "./styles.css";
import { useEffect, useState } from "react";
import {
  leaf1,
  leaf2,
  minusGreen,
  plusGreen,
} from "../../../../../components/ImgExport";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../../store/slices/userSlice";
import moment from "moment-timezone";
import { addGiftVoucher } from "../../../../../api/apiOwner/giftCodeVoucher";
import { getLanguageUI } from "../../../../../Services/languageUI";
import React from "react";

interface ModalCreateGiftCardProps {
  show: boolean;
  setShowLoading: (e: boolean) => void;
  HandleCreatSuccess: () => void;
  handleClose: () => void;
}

const ModalCreateGiftCard = ({
  show,
  setShowLoading,
  HandleCreatSuccess,
  handleClose,
}: ModalCreateGiftCardProps) => {
  const languageUI = getLanguageUI().giftCard;
  const owner_id = useSelector(userSlice.selectorUserId);
  const [giftCardName, setGiftCardName] = useState<string>("");
  const [giftCardType, setGiftCardType] = useState<number>(2);
  const [neverExpire, setNeverExpire] = useState<number>(0);
  const [giftCardPercent, setGiftCardPercent] = useState<number>(0);
  const [giftCardQuantity, setGiftCardQuantity] = useState<number>();
  const [giftCardAmount, setDiscountAmount] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());

  const [messageError, setMessageError] = useState<string>("");
  const resetForm = () => {
    setGiftCardName("");
    setGiftCardType(2);
    setMessageError("");
    setGiftCardPercent(0);
    setDiscountAmount(0);
    setStartDate(new Date());
    setEndDate(new Date());
    setGiftCardQuantity(0);
  };
  const SaveVoucher = async () => {
    var discount_value = giftCardType === 1 ? giftCardPercent : giftCardAmount;
    if (!owner_id) {
      setMessageError(languageUI.errOwnerNotFound);
    } else if (giftCardName === "") {
      setMessageError(languageUI.errVoucherName);
    } else if (discount_value <= 0) {
      setMessageError(languageUI.errCoupon);
    } else if (!giftCardQuantity || giftCardQuantity <= 0) {
      setMessageError(languageUI.errCoupon);
    } else if (!startDate || startDate === "") {
      setMessageError(languageUI.errEnterDateStart);
    } else if (!endDate || endDate === "") {
      setMessageError(languageUI.errEnterDateEnd);
    } else {
      var dateStart = moment.utc(startDate).format("YYYY-MM-DD");
      var dateEnd = moment.utc(endDate).format("YYYY-MM-DD");
      if (dateStart > dateEnd) {
        setMessageError(languageUI.theStartDate);
      } else {
        setMessageError("");
        setShowLoading(true);
        const req = await addGiftVoucher(
          owner_id,
          giftCardName,
          giftCardType,
          discount_value,
          dateStart,
          dateEnd,
          giftCardQuantity,
          neverExpire
        );
        if (Number(req?.status) === 1) {
          HandleCreatSuccess();
        } else {
          setShowLoading(false);
          setMessageError(req?.message ?? "Fail");
        }
      }
    }
  };
  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-import-inventory"
      >
        <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
          <Modal.Body>
            <div className="px-48px">
              <p className="text-delete-modal-customer mb-0 text-center">
                {languageUI.createGiftCard}
              </p>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">
                  {languageUI.giftCardName}
                </p>
                <div className="input-add-table">
                  <input
                    type="text"
                    className="border-none focus-visiable-none text-input-add-table px-2 w-100"
                    placeholder={languageUI.enterGiftCardName}
                    value={giftCardName}
                    onChange={(e) => setGiftCardName(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <div className="mt-6">
                  <p className="text-add-table-name mb-1">
                    {languageUI.valueGiftCard}
                  </p>
                  <div>
                    <Form defaultValue={"option-inventory-1"}>
                      {/* <div className="d-flex justify-content-between align-items-center h-50px">
                        <Form.Check // prettier-ignore
                          type="radio"
                          id={`option-voucher-1`}
                          label={languageUI.percentGiftCard}
                          name="option-voucher"
                          className={`d-flex align-items-center w-fit radio-choose-option-inventory ${
                            giftCardType === 1 ? "text-green" : ""
                          }`}
                          checked={giftCardType === 1}
                          onClick={() => setGiftCardType(1)}
                        />
                        <div
                          className={`d-flex align-items-center gap-2 ${
                            giftCardType === 1 ? "" : "d-none"
                          }`}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <div
                              className="btn-remove-plus-number"
                              onClick={() => {
                                if (giftCardPercent > 0) {
                                  setGiftCardPercent(giftCardPercent - 1);
                                }
                              }}
                            >
                              <img src={minusGreen} alt="" />
                            </div>
                            <div className="input-number-table-multi">
                              <input
                                type="number"
                                className="input-number-table-multi w-fit border-none focus-visiable-none w-fit"
                                maxLength={3}
                                value={giftCardPercent.toString()}
                                onChange={(e) => {
                                  if (
                                    e.target.value &&
                                    Number(e.target.value) <= 0
                                  ) {
                                    return;
                                  }
                                  if (
                                    e.target.value &&
                                    Number(e.target.value) > 100
                                  ) {
                                    return;
                                  }
                                  setGiftCardPercent(Number(e.target.value));
                                }}
                              />
                            </div>
                            <div
                              className="btn-remove-plus-number"
                              onClick={() => {
                                if (giftCardPercent < 100) {
                                  setGiftCardPercent(giftCardPercent + 1);
                                }
                              }}
                            >
                              <img src={plusGreen} alt="" />
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="d-flex justify-content-between align-items-center h-50px">
                        <Form.Check // prettier-ignore
                          type="radio"
                          id={`option-voucher-2`}
                          label={languageUI.amountGiftCard}
                          className={`d-flex align-items-center w-fit radio-choose-option-inventory ${
                            giftCardType === 1 ? "" : "text-green"
                          }`}
                          name="option-voucher"
                          checked={giftCardType === 2}
                          onClick={() => setGiftCardType(2)}
                        />
                        <div
                          className={`d-flex align-items-center gap-2 ${
                            giftCardType === 1 ? "d-none" : ""
                          } `}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <div
                              className="btn-remove-plus-number"
                              onClick={() => {
                                if (giftCardAmount > 0) {
                                  setDiscountAmount(giftCardAmount - 1);
                                }
                              }}
                            >
                              <img src={minusGreen} alt="" />
                            </div>
                            <div className="input-number-table-multi">
                              <input
                                type="number"
                                className="input-number-table-multi w-fit border-none focus-visiable-none w-fit"
                                maxLength={3}
                                value={giftCardAmount.toString()}
                                onChange={(e) => {
                                  if (
                                    e.target.value &&
                                    Number(e.target.value) <= 0
                                  ) {
                                    return;
                                  }
                                  setDiscountAmount(Number(e.target.value));
                                }}
                              />
                            </div>
                            <div
                              className="btn-remove-plus-number"
                              onClick={() => {
                                setDiscountAmount(giftCardAmount + 1);
                              }}
                            >
                              <img src={plusGreen} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="mt-6 d-flex justify-content-between align-items-center">
                  <p className="text-add-table-name mb-0">
                    {languageUI.quantity}
                  </p>
                  <div className="input-gift-card">
                    <input
                      type="number"
                      className=" border-none focus-visiable-none w-full"
                      value={giftCardQuantity?.toString()}
                      onChange={(e) => {
                        if (e.target.value && Number(e.target.value) <= 0) {
                          return;
                        }
                        setGiftCardQuantity(Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between ">
                <div className="mt-3">
                  <Form>
                    <div className="d-flex gap-2 align-items-center ">
                      <Form.Check // prettier-ignore
                        type="radio"
                        id={`never-expire-1`}
                        name="option-voucher"
                        className={`d-flex align-items-center w-fit radio-choose-option-inventory ${
                          neverExpire === 0 ? "text-green" : ""
                        }`}
                        checked={neverExpire === 0}
                        onClick={() => setNeverExpire(0)}
                      />
                      <label
                        htmlFor="never-expire-1"
                        className="text-add-table-name"
                      >
                        {languageUI.giftCardExpiresIn}
                      </label>
                    </div>
                  </Form>
                  <div className="d-flex align-items-center gap-4 mt-2">
                    <div className="ex-date-gift-card ps-2 pe-1">
                      <DatePicker
                        disabled={neverExpire === 1 ? true : false}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="To day"
                        className={`border-none focus-visiable-none  text-input-add-table w-130px ${
                          neverExpire === 1 ? "disabled" : ""
                        }`}
                      />
                    </div>
                    <p className="text-add-table-name mb-0">To</p>
                    <div className="ex-date-gift-card ps-2 pe-1">
                      <DatePicker
                        disabled={neverExpire === 1 ? true : false}
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholderText="To day"
                        className={`border-none focus-visiable-none  text-input-add-table w-130px ${
                          neverExpire === 1 ? "disabled" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <Form>
                  <div className="d-flex gap-2 align-items-center mt-3">
                    <Form.Check // prettier-ignore
                      type="radio"
                      id={`never-expire-2`}
                      name="option-voucher"
                      className={`d-flex align-items-center w-fit radio-choose-option-inventory ${
                        neverExpire === 1 ? "text-green" : ""
                      }`}
                      checked={neverExpire === 1}
                      onClick={() => setNeverExpire(1)}
                    />
                    <label
                      htmlFor="never-expire-2"
                      className="text-add-table-name"
                    >
                      {languageUI.neverExpire}
                    </label>
                  </div>
                </Form>

                {/* <div className="">{languageUI.neverExpire}</div> */}
              </div>

              <div className="text-center text-danger text-italic">
                {messageError}
              </div>
              <div className="d-flex justify-content-between w-100 mt-6">
                <div
                  className="btn-import-inventory-footer "
                  onClick={handleClose}
                >
                  {languageUI.back}
                </div>
                <div
                  className="btn-import-inventory-footer border-12B064 text-white bg-0B2F88"
                  onClick={SaveVoucher}
                >
                  {languageUI.save}
                </div>
              </div>
            </div>
            <img src={leaf1} alt="" className="position-leaf1-voucher" />
            <img src={leaf2} alt="" className="position-leaf2-voucher" />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalCreateGiftCard;
