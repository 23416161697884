/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col } from "react-bootstrap"
import '../../StylesSetting.css'
import { imgLeaf, tagIcon, imgLeaf2, coins, CancelIcon, tags } from '../../../../components/Image';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as userSlice from "../../../../store/slices/userSlice";
import * as SettingInformation from "../../../../api/apiOwner/SettingInformation";
import ModalLoading from "../../../../components/ModalLoading";
import { getLanguageUI } from "../../../../Services/languageUI";
import { FormControlLabel, Switch } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import * as flagSlice from "../../../../store/slices/FlagSettingSlice";
interface ParamProp {
  ChangeSetting: () => void
}
const TipAndTagSetting: React.FC<ParamProp> = ({ChangeSetting}) => {
  const languageUI = getLanguageUI().setting
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const dispatch = useDispatch()
  const [ShowLoading, setShowLoading] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState<number>()
  const [taxValue, setTaxValue] = React.useState<number | string>('')
  const [arrayTip, setArrayTip] = React.useState<number[]>([])
  const [messError, setMessError] = React.useState<string>('')
  const [feeStripe, setFeeStripe] = React.useState<boolean>(false)

  const handleChangeInput = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleUpdateArray = () => {
    if (inputValue === undefined || Number(inputValue) <= 0) return
    const isValueExist = arrayTip.some((item) => item === Number(inputValue));
    if (isValueExist) {
      alert('This value already exists.');
      setInputValue(undefined);
      return;
    }
    if (arrayTip.length >= 3) {
      alert('Can only add 3 options.');
      setInputValue(undefined);
      return;
    }
    setArrayTip([...arrayTip, Number(inputValue)]);
    setInputValue(undefined)
  }
  const handleDeleteItems = (index: number) => {
    const updatedItems = arrayTip.filter((item, i) => i !== index);
    setArrayTip(updatedItems);
  };

  const gListTip = async () => {
    if (UserIdStore) {
      const Result = await SettingInformation.gListInfoTip(UserIdStore);
      if (Number(Result?.status) === 1) {
        if (Result?.listTip && Result?.listTip.length > 0) {
          var NewTip: number[] = []
          // eslint-disable-next-line array-callback-return
          Result?.listTip.map(item => {
            NewTip.push(Number(item?.percent));
          })
          setArrayTip([...NewTip]);
        } else {
          setArrayTip([5, 10, 15]);
        }
        setTaxValue(Number(Result?.tax) ?? 0)
        setFeeStripe(Number(Result?.fee_stripe) === 1 ? true : false)
      }
      dispatch(flagSlice.clearSetting())
    }
  }

  const saveTip = async () => {
    if (UserIdStore) {
      if (arrayTip.length !== 3) {
        setMessError("Tip must have 3 options.");
      } else if (!taxValue || Number(taxValue) < 0) {
        setMessError("Please enter tax amount!");
      } else {
        const Fee = feeStripe ? 1 : 0;
        setShowLoading(true)

        const Res = await SettingInformation.updateInfoTip(UserIdStore, arrayTip, Number(taxValue), Fee);
        if (Number(Res?.status) === 1) {
          updateTipToTerminal(UserIdStore)
          setShowLoading(false)
          setMessError("")
          dispatch(flagSlice.clearSetting())
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Update success. Please wait for 5 minutes to synchronize.",
            showConfirmButton: false,
            timer: 4000,
          });
          
        } else {
          setMessError(Res?.message ?? '');
          setShowLoading(false)
        }
        dispatch(flagSlice.clearSetting())
      }

    }
  }
  const updateTipToTerminal = async (owner_id: number) => {
    const req = await SettingInformation.updateInfoTipTerminal(owner_id, arrayTip)
  }

  React.useEffect(() => {
    gListTip()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="h-tip-tax flex justify-center bg-white ">
        <div className="position-relative shadow-button-default w-60 bg-white h-fit rounded-4 ">
          <div className="flex justify-center items-center pt-6 ">
            <img src={tagIcon} alt="" />
            <span className="text-4A4A6A ml-2 text-8 fw-600 font-urbansist">{languageUI.tiptax}</span>
          </div>
          <span className="text-8E8EA9 text-5 flex justify-center items-center text-center custom-content-Tag">{languageUI.automaticallyCalculate}</span>
          <div className=" bg-white custom-p-password mb-2">
            <div className="flex items-center justify-between radius-2 bg-F6F6F9 p-tip mb-1">
              <div className="flex flex-column w-100">
                <span>{languageUI.tipRequite3TipOption}</span>
                <div className="flex items-center">
                  <img src={coins} alt="" />
                  <input value={inputValue ?? ''} onChange={handleChangeInput} type="number" placeholder={languageUI.enterTheNumberOf}
                    className="bg-F6F6F9 border-none ml-1 w-100" />
                </div>

              </div>
              <button
                className="bg-white radius-2 p-plus text-0FA54A border-none text-7"
                onClick={()=>{handleUpdateArray();ChangeSetting()}}
              >
                +
              </button>
            </div>
            <div className="flex items-center flex-wrap py-5">
              {arrayTip.map((item, i) => (
                <div key={'tip' + item} className="position-relative p-option border-E8E8E8 radius-3 mr-2">
                  <div className=" bg-white">
                    <span className="text-4A4A6A text-4 fw-600 font-urbansist text-center">{item}</span>
                  </div>
                  <CancelIcon className="text-danger custom-cancel cursor" onClick={() => {handleDeleteItems(i);ChangeSetting()}} />
                </div>
              ))}

            </div>

            <div className="flex items-center justify-between radius-2 bg-F6F6F9 p-tip mt-1 mb-2">
              <div className="flex flex-column w-100">
                <span>{languageUI.tax}</span>
                <div className="flex items-center">
                  <img src={tags} alt="" />
                  <input type="number" value={taxValue} placeholder={languageUI.enterTheNumberOf} onChange={(e) => {setTaxValue(e.target.value ? Number(e.target.value) : '');ChangeSetting()}} className="bg-F6F6F9 border-none ml-1 w-100" />
                </div>
              </div>
            </div>
          </div>
          <div className="px-14" onClick={() => setFeeStripe(!feeStripe)}>
            <FormControlLabel className="text-5 text-4a4a6a text-switch-tip-tax"
              control={<Switch checked={feeStripe} />}
              label={languageUI.apply3Processing}
            />
            <p className="ms-4">{languageUI.yourCanPassYourStripe}</p>
          </div>
          <p className="text-danger fs-12 mb-2 text-italic text-center">{messError}</p>


          <div className="flex items-center px-14">
            <div className="pr-2 w-50">
              <button className="w-100 radius-2 text-5 bg-white text-4a4a6a border-DEE2E6 py-2 ">{languageUI.cancel}</button>
            </div>
            <div className="pl-2 w-50">
              <button className="w-100 radius-2 text-5 bg-0FA54A  text-white py-2 border-none " onClick={saveTip}>{languageUI.save}</button>
            </div>

          </div>

          <div className=" py-8">
            <div className="px-14">
              <p className="text-4">{languageUI.forYourReference}: </p>
              <span className="text-A5A5BA text-4">{languageUI.ortherRestaurantIncreased}</span>
            </div>

          </div>
          <img src={imgLeaf} alt="" className="custom-position-img" />
          <img src={imgLeaf2} alt="" className="custom-position-img2" />
          <div>

          </div>
        </div>
      </div>
    </>
  )
}

export default TipAndTagSetting