import { NumberLocale } from "yup/lib/locale";
import * as Request from "../Request";
import { InfoOrder } from "./orderConfilmApi";
export interface listSide {
  side_id: string;
  quantity: string;
}

export interface Tip {
  id?: string;
  percent?: string;
  delf?: string;
  timestamp?: string;
}
export interface config {
  tax: string;
  fee_stripe: string;
  key_google_map: string;
  show_rank: string;
}
export interface TipStaff {
  id?: string;
  staff_name?: string;
  startdate?: string;
  working_time_start?: string;
  working_time_end?: string;
  phone_number?: string;
}
export interface Result<T> {
  status: number;
  message?: string;
  data?: T;
}
export interface ResultConfig {
  status: number;
  message?: string;
  data?: config;
  order_id?: string;
  customer_name: string;
}
export interface ComboPayment {
  id?: string;
  method_name?: string;
}
export interface ResultStaff {
  status: number;
  message?: string;
  data?: TipStaff[];
}
export interface ResultComboPayment {
  status: number;
  message?: string;
  data?: ComboPayment[];
}
export interface ResultBill {
  status: number;
  message?: string;
  invoice_number?: string;
  custommer_name?: string;
  table_name?: string;
  timepay?: string;
}

export interface VoucherUsed {
  id?: string;
  voucher_id?: string;
  voucher_code?: string;
  customer_id?: string;
  discount_value?: string;
  type?: string;
  voucher_name?: string;
  expry_date?: string;
}
export interface cardInfoOrder {
  service_item_id?: string;
  price?: string;
  quanlity?: string;
  notefood_id?: string;
  side_id?: string;
  type?: string;
  notechef?: string;
}
//lấy combo phương thức thanh toán
export const getComBoPayment = async () => {
  return await Request.fetchAPI(`owner/gComboPaymentMethod.php`, "GET", {})
    .then((res: ResultComboPayment) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//lấy danh sách % nhận tip
export const getListTip = async (owner_id: number) => {
  return await Request.fetchAPI(`checkin/gListPercentTip.php`, "POST", {
    owner_id,
  })
    .then((res: Result<Tip[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//lấy cấu hình
export const getConfig = async (owner_id: number = 0) => {
  return await Request.fetchAPI(`checkin/gConfig.php`, "POST", { owner_id })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: {
          tax: "0",
          fee_stripe: "0",
          show_rank: "0",
        },
      };
    });
};
//lấy danh sách nhanh viên nhận tip
export const getListTipStaff = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListTipStaff.php`, "POST", { owner_id })
    .then((res: ResultStaff) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//gọi nhân viên đến
export const CallStaff = async (order_id: string) => {
  return await Request.fetchAPI(`checkin/callStaffHelp.php`, "POST", {
    order_id,
  })
    .then((res: Result<Tip[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
//thanh toán tiền mặt
export const PaymentCash = async (
  staffId: string,
  TipPrice: number,
  table_id: string,
  custommerId: string,
  ownerID: number,
  order_id: string,
  voucher_code: string = "",
  voucher_id: string = "0",
  discount: number = 0
) => {
  return await Request.fetchAPI(`checkin/upTipPriceOrder.php`, "POST", {
    staffId,
    TipPrice,
    table_id,
    custommerId,
    ownerID,
    order_id,
    voucher_code,
    voucher_id,
    discount,
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        order_id: "0",
      };
    });
};
//thanh toán vnpay
export const PaymentVNPay = async (
  custommerId: number,
  ownerID: number,
  TotalPay: number,
  tax_price: number,
  fee: number,
  order_id: number,
  staffId: number,
  tip_amount: number,
  discount: number,
  voucherCode: string,
  voucherId: number,
) => {
  return await Request.fetchAPI(`checkin/upPriceOrderVNpay.php`, "POST", {
    staffId,
    TotalPay,
    custommerId,
    ownerID,
    order_id,
    tax_price,
    fee,
    discount,
    tip_amount,
    voucherCode,
    voucherId
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: '',
      };
    });
};
//thanh toán visa
export const PaymentVisa = async (
  order_id: string,
  owner_id: number,
  customer_id: string,
  price: number,
  vat_amount: number = 0,
  customer_pay: number,
  feePrice: number,
  tokenId: string,
  tip_staff_id: string = "0",
  tip_amount: number = 0,
  voucher_code: string = "",
  voucher_id: string = "0",
  discount: number = 0
) => {
  return await Request.fetchAPI(`stripe/payment_product.php`, "POST", {
    order_id,
    owner_id,
    customer_id,
    price,
    vat_amount,
    customer_pay,
    tip_staff_id,
    tip_amount,
    voucher_code,
    voucher_id,
    discount,
    tokenId,
    feePrice,
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        order_id: "0",
        customer_name: "",
      };
    });
};
export const PaymentWithLinkStripe = async (
  owner_id: number,
  price: number
) => {
  return await Request.fetchAPI(`stripe/create-internt-applepay.php`, "POST", {
    owner_id,
    price,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
//cập nhật trạng thái đơn sau thanh toán applepay
export const upDateOrderPay = async (
  order_id: string,
  owner_id: number,
  customer_id: string,
  price: number,
  vat_amount: number = 0,
  customer_pay: number,
  feePrice: number,
  tip_staff_id: string = "0",
  tip_amount: number = 0,
  voucher_code: string = "",
  voucher_id: string = "0",
  discount: number = 0,
  paymentIntent: string = ""
) => {
  return await Request.fetchAPI(`stripe/submitOrderApplePay.php`, "POST", {
    order_id,
    owner_id,
    customer_id,
    price,
    vat_amount,
    customer_pay,
    tip_staff_id,
    tip_amount,
    voucher_code,
    voucher_id,
    discount,
    feePrice,
    paymentIntent,
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        order_id: "0",
        customer_name: "",
      };
    });
};
//thanh toán post
export const PaymentBillPOS = async (
  owner_id: number,
  table_id: string,
  TotalPay: number,
  tax_price: number,
  methodPay: number,
  listOrderPay: string[] = [],
  staffId: string,
  tip_amount: number,
  feePrice = 0,
  discountOrder = 0,
  voucher_code = '',
  voucher_id = ''
) => {
  return await Request.fetchAPI(`owner/PaymentOrderByPost.php`, "POST", {
    owner_id,
    table_id,
    TotalPay,
    tax_price,
    staffId,
    tip_amount,
    methodPay,
    listOrderPay: JSON.stringify(listOrderPay),
    feePrice,
    discountOrder,
    voucher_code,
    voucher_id
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        order_id: "0",
      };
    });
};
//thanh toán pickup
export const PaymentBillPickup = async (
  owner_id: number,
  table_id: string,
  TotalPay: number,
  tax_price: number,
  methodPay: number,
  listOrderPay: string[] = [],
  staffId: string,
  tip_amount: number,
  feePrice = 0,
  discountOrder = 0,
  voucher_code = '',
  voucher_id = ''
) => {
  return await Request.fetchAPI(`owner/PaymentOrderByPickup.php`, "POST", {
    owner_id,
    table_id,
    TotalPay,
    tax_price,
    staffId,
    tip_amount,
    methodPay,
    listOrderPay: JSON.stringify(listOrderPay),
    feePrice,
    discountOrder,
    voucher_code,
    voucher_id
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        order_id: "0",
      };
    });
};
//lấy thông tin bill
export const gInfoBill = async (order_id: string, customer_id: string) => {
  return await Request.fetchAPI(`checkin/gTransactionOrder.php`, "POST", {
    order_id,
    customer_id,
  })
    .then((res: ResultBill) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        invoice_number: "0",
        custommer_name: "",
        table_name: "",
        timepay: "",
      };
    });
};
//kiểm tra voucher
export const checkExistVoucher = async (
  owner_id: number,
  customer_id: string,
  voucherCode: string
) => {
  return await Request.fetchAPI(`checkin/checkVoucherUsed.php`, "POST", {
    owner_id,
    customer_id,
    voucherCode,
  })
    .then((res: Result<VoucherUsed>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const createOrderPickUp = async (
  custommerId: number,
  ownerID: number,
  requestMeal: string,
  cardInfor: InfoOrder[],
  totalPrice: number,
  sideDish: listSide[] = [],
  voucher_id: string,
  voucher_code: string,
  tip_amount: number,
  tip_staff_id: string,
  vat_amount: number,
  tokenId: string,
  feePrice: number,
  priceSides: number
) => {
  return await Request.fetchAPI(`stripe/createOrderPickup.php`, "POST", {
    custommerId,
    ownerID,
    requestMeal,
    cartInfo: JSON.stringify(cardInfor),
    totalPrice,
    sideDish: JSON.stringify(sideDish),
    voucher_id,
    voucher_code,
    tip_amount,
    tip_staff_id,
    vat_amount,
    tokenId,
    feePrice,
    priceSides,
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        order_id: "0",
        customer_name: "",
      };
    });
};
export const updateStatusPayOrderPickUp = async (
  custommerId: number,
  ownerID: number,
  requestMeal: string,
  cardInfor: InfoOrder[],
  totalPrice: number,
  sideDish: listSide[] = [],
  voucher_id: string,
  voucher_code: string,
  tip_amount: number,
  tip_staff_id: string,
  vat_amount: number,
  feePrice: number,
  priceSides: number,
  paymentIntent: string = ""
) => {
  return await Request.fetchAPI(
    `stripe/upStatusPayOrderPickupApple.php`,
    "POST",
    {
      custommerId,
      ownerID,
      requestMeal,
      cartInfo: JSON.stringify(cardInfor),
      totalPrice,
      sideDish: JSON.stringify(sideDish),
      voucher_id,
      voucher_code,
      tip_amount,
      tip_staff_id,
      vat_amount,
      paymentIntent,
      feePrice,
      priceSides,
    }
  )
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        order_id: "0",
        customer_name: "",
      };
    });
};
export const createOrderOnline = async (
  custommerId: number,
  ownerID: number,
  requestMeal: string,
  cardInfor: InfoOrder[],
  totalPrice: number,
  sideDish: listSide[] = [],
  voucher: string,
  voucher_code: string,
  tip_amount: number,
  tip_staff_id: string,
  vat_amount: number,
  tokenId: string,
  feePrice: number,
  maHD: string,
  feeShip: number,
  priceSides: number
) => {
  return await Request.fetchAPI(`stripe/createOrderOnline.php`, "POST", {
    custommerId,
    ownerID,
    requestMeal,
    cartInfo: JSON.stringify(cardInfor),
    totalPrice,
    sideDish: JSON.stringify(sideDish),
    voucher,
    voucher_code,
    tip_amount,
    tip_staff_id,
    vat_amount,
    tokenId,
    feePrice,
    maHD,
    feeShip,
    priceSides,
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        order_id: "0",
        customer_name: "",
      };
    });
};
export const updatePayOrderOnline = async (
  custommerId: number,
  ownerID: number,
  requestMeal: string,
  cardInfor: InfoOrder[],
  totalPrice: number,
  sideDish: listSide[] = [],
  voucher: string,
  voucher_code: string,
  tip_amount: number,
  tip_staff_id: string,
  vat_amount: number,
  feePrice: number,
  maHD: string,
  feeShip: number,
  priceSides: number,
  paymentIntent: string = ""
) => {
  return await Request.fetchAPI(`stripe/updateOrderApplePay.php`, "POST", {
    custommerId,
    ownerID,
    requestMeal,
    cartInfo: JSON.stringify(cardInfor),
    totalPrice,
    sideDish: JSON.stringify(sideDish),
    voucher,
    voucher_code,
    tip_amount,
    tip_staff_id,
    vat_amount,
    feePrice,
    maHD,
    feeShip,
    priceSides,
    paymentIntent,
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        order_id: "0",
        customer_name: "",
      };
    });
};
export const createOrderDelivery = async (
  customer_id: number,
  owner_id: number,
  cardInfor: InfoOrder[],
  totalPrice: number,
  dropoff_address: string,
  dropoff_business_name: string,
  dropoff_phone_number: string
) => {
  return await Request.fetchAPI(`checkin/gFeeDoordash.php`, "POST", {
    cartInfo: JSON.stringify(cardInfor),
    totalPrice,
    customer_id,
    owner_id,
    dropoff_address,
    dropoff_business_name,
    dropoff_phone_number,
  })
    .then((res: ResultConfig) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        order_id: "0",
        customer_name: "",
      };
    });
};
