

import Sheet from "react-modal-sheet";
import "./Styles.css"
import { useEffect, useState } from "react";
import { arrowLeft, checkCircle, lightBulb } from "../../../../components/ImgExport";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import * as paymentApi from '../../../../api/apiCheckin/paymentApi'
import React from "react";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface ModalSelectEmployeeTipProps {
    show: boolean;
    staffId?: string;
    setStaffName?: (name: string) => void;
    setStaffId?: (id: string) => void;
    handleClose: () => void;
    listStaff: paymentApi.TipStaff[]
    heightModal: number;
}

const ModalSelectEmployeeTip = ({
    show,
    handleClose,
    staffId = "",
    listStaff = [],
    setStaffId,
    setStaffName,
    heightModal
}: ModalSelectEmployeeTipProps) => {
    const languageUI = getLanguageCheckIn();
    const [NameSelected, setNameSelected] = useState<string>('')
    const [staffSelected, setStaffSelected] = useState<string>('')
    const [showModalConfirmSelectTip, setShowModalConfirmSelectTip] = useState(false);
    const handleCloseConfirm = () => {
        setShowModalConfirmSelectTip(false)
    }
    function handleConfirm(staff_id: string = '', staff_name: string = '') {
        setStaffSelected(staff_id)
        setNameSelected(staff_name)
        setStaffId?.(staff_id);
        setStaffName?.(staff_name)
        setShowModalConfirmSelectTip(false);
        handleClose();
    }
    const SubmitChoosed = () => {
        setStaffId?.(staffSelected);
        setStaffName?.(NameSelected)
        setShowModalConfirmSelectTip(false)
        handleClose()

    }
    useEffect(() => {
        if (show) {

            setStaffSelected(staffId);
        }
    }, [show])
    return (
        <div>
            <Sheet
                // ref={ref}
                isOpen={show}
                onClose={handleClose}
                snapPoints={[heightModal]}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <div className="px-3 h-100">
                            <p className="text-select-name-employee">{languageUI.Selectemployeewanttotip} </p>
                            <div className={`box-select-employee h-100-50px`}>
                                {listStaff.map((items, i) => (
                                    <div key={i} className={`${staffSelected == items.id ? "select-employee-items-2" : "select-employee-items"}`} onClick={() => handleConfirm(items?.id, items?.staff_name)}>
                                        <PersonOutlineIcon className={`icon-ployee-items ${staffSelected == items.id ? "text-ffffff" : "text-A5A5BA"}`} />
                                        <p className={`name-ployee-items ${staffSelected == items.id ? "text-ffffff" : "text-A5A5BA"}`}>{items.staff_name}</p>
                                    </div>
                                    // <div key={i} className={`d-flex justify-content-center align-items-center  ${valueTip == items.id ? "custom-choose-categories-2" : "custom-choose-categories"}`} onClick={() => { setValueTip(items.id) }}>
                                    //     <p className={`custom-text-choose-categorie mb-0 ${valueTip == items.id ? "text-ffffff" : "text-8E8EA9"}`}>{items.name}</p>
                                    // </div>
                                ))}
                            </div>
                        </div>
                    </Sheet.Content>
                    {/* <div className="py-3 px-3 fixed bottom-0 w-100 bg-white border-top">
                        <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={() => { setShowModalConfirmSelectTip(true); }}>
                            <img src={checkCircle} alt="" className="img-filter " />
                            Select
                        </button>
                    </div> */}
                </Sheet.Container>
                <Sheet.Backdrop className="bg-bg-backdrop" onTap={handleClose} />
            </Sheet>
            <Sheet
                // ref={ref}
                isOpen={showModalConfirmSelectTip}
                onClose={handleCloseConfirm}
                snapPoints={[300]}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <div className="px-3">
                            <div className="d-flex align-items-center">
                                <img src={lightBulb} alt="" />
                                <p className="text-select-name-employee mb-0">{languageUI.WejuswanttomakesuretheTipgoestotherightperson}</p>
                            </div>
                            <div className="">
                                <div className="select-employee-items-confirm">
                                    <PersonOutlineIcon className="icon-ployee-items-confirm" />
                                    <p className={`name-ployee-items-confirm `}>{NameSelected}</p>
                                </div>
                            </div>
                        </div>
                    </Sheet.Content>
                    <div className="py-3 px-3 fixed bottom-0 w-100 bg-white border-top d-flex gap-2 align-items-center">
                        <div className="position-relative" onClick={handleCloseConfirm}>
                            <img src={arrowLeft} alt="" />
                        </div>
                        <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={() => { SubmitChoosed() }}>
                            <img src={checkCircle} alt="" className="img-filter " />
                            {languageUI.Select}
                        </button>
                    </div>
                </Sheet.Container>
                <Sheet.Backdrop className="bg-bg-backdrop" onTap={() => { handleCloseConfirm(); }} />
            </Sheet>
        </div>
    );
};

export default ModalSelectEmployeeTip;
