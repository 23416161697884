import "./styles.css";
import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import socket from "../../../utils/socket";
import { useSelector } from "react-redux";
import * as userSlice from "../../../store/slices/userSlice";

const PaymentVNPay = () => {
  var vnp_HashSecret = "YP19TZUJTI6CR2LX9CSYTIKLQV33HGPW"; //Secret key
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [transactionData, setTransactionData] = useState({
    orderId: "",
    amount: "",
    orderInfo: "",
    responseCode: "",
    transactionNo: "",
    bankCode: "",
    payDate: "",
    isValidSignature: false,
  });
  const PushOrderSuccess = async (owner_id: string, vnp_TxnRef: string, amount: number) => {
    socket.emit("PushPaymentVnpayOrder", {
      ownerID: Number(owner_id), vnp_TxnRef
    });
  }
  useEffect(() => {
    // Lấy các tham số từ URL
    const urlParams = new URLSearchParams(window.location.search);

    const inputData: { [key: string]: string } = {};
    const secureHash = urlParams.get("vnp_SecureHash");

    // Tạo danh sách tham số cần xác thực từ URL
    urlParams.forEach((value, key) => {
      if (key.startsWith("vnp_") && key !== "vnp_SecureHash") {
        inputData[key] = value;
      }
    });

    // Sắp xếp tham số theo thứ tự tăng dần tên key
    const sortedKeys = Object.keys(inputData).sort();
    const hashData = sortedKeys.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(inputData[key])}`).join("&").replace(/%20/g, '+');

    // Tạo chữ ký HMAC SHA512
    const generatedHash = CryptoJS.HmacSHA512(hashData, vnp_HashSecret).toString(
      CryptoJS.enc.Hex
    );
    // Xác thực chữ ký
    const isValidSignature = generatedHash === secureHash;

    // Cập nhật state với dữ liệu và kết quả xác thực
    setTransactionData({
      orderId: urlParams.get("vnp_TxnRef") ?? '',
      amount: urlParams.get("vnp_Amount") ?? '',
      orderInfo: urlParams.get("vnp_OrderInfo") ?? '',
      responseCode: urlParams.get("vnp_ResponseCode") ?? '',
      transactionNo: urlParams.get("vnp_TransactionNo") ?? '',
      bankCode: urlParams.get("vnp_BankCode") ?? '',
      payDate: urlParams.get("vnp_PayDate") ?? '',
      isValidSignature,
    });

    if (isValidSignature &&
      urlParams.get("vnp_ResponseCode") === "00") {
      PushOrderSuccess(urlParams.get("owner_id") ?? '', urlParams.get("vnp_TxnRef") ?? '', Number(urlParams.get("vnp_Amount")) / 100)
    }
  }, []);


  return (
    <div className="payment-vnpay">
      <div className="info-payment">
        <p className="m-0 font-bold text-primary text-center text-2xl text-uppercase">
          Hóa đơn
        </p>
        <div className="flex justify-content-between mt-3">
          <p className="m-0 font-bold text-base">Mã đơn hàng:</p>
          <p className="m-0 font-bold text-base text-4a4a6a">{transactionData.orderId}</p>
        </div>
        <div className="flex justify-content-between mt-3">
          <p className="m-0 font-bold text-base">Số tiền:</p>
          <p className="m-0 font-bold text-base text-4a4a6a">{Number(transactionData.amount) / 100}</p>
        </div>
        <div className="flex justify-content-between mt-3">
          <p className="m-0 font-bold text-base">Nội dung thanh toán:</p>
          <p className="m-0 font-bold text-base text-4a4a6a">{transactionData.orderInfo}
          </p>
        </div>
        <div className="flex justify-content-between mt-3">
          <p className="m-0 font-bold text-base">
            Mã phản hồi (vnp_ReponsiveCode):
          </p>
          <p className="m-0 font-bold text-base text-4a4a6a">{transactionData.responseCode}</p>
        </div>
        <div className="flex justify-content-between mt-3">
          <p className="m-0 font-bold text-base">Mã GD Tại VNPAY:</p>
          <p className="m-0 font-bold text-base text-4a4a6a">{transactionData.transactionNo}</p>
        </div>
        <div className="flex justify-content-between mt-3">
          <p className="m-0 font-bold text-base">Mã Ngân hàng:</p>
          <p className="m-0 font-bold text-base text-4a4a6a">{transactionData.bankCode}</p>
        </div>
        <div className="flex justify-content-between mt-3">
          <p className="m-0 font-bold text-base">Thời gian thanh toán:</p>
          <p className="m-0 font-bold text-base text-4a4a6a">{transactionData.payDate}</p>
        </div>
        <div className="flex justify-content-between mt-3">
          <p className="m-0 font-bold text-base">Kết quả:</p>
          {transactionData.isValidSignature ? (
            transactionData.responseCode === "00" ? (
              <p className="m-0 font-bold text-base text-success">GD Thanh cong</p>
            ) : (
              <p className="m-0 font-bold text-base text-warning">GD Không thành công</p>
            )
          ) : (
            <p className="m-0 font-bold text-base text-warning">Chữ ký không hợp lệ</p>
          )}

        </div>
      </div>
    </div>
  );
};
export default PaymentVNPay;
