import "./Styles.css";
import { useEffect, useState } from "react";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import {
  billTable,
  calender,
  cartTable,
  clockTable,
  customerTable,
  databaseTable,
  iconSearchGreen,
  users,
  usersTable,
} from "../../components/ImgExport";
import * as paymentApi from "../../api/apiCheckin/paymentApi";
import Select from "react-select";
import * as custommerRecieptApi from "../../api/apiOwner/custommerRecieptApi";
import ContentTableCustomer from "./components/ContentTableCustomer/ContentTableCustomer";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment-timezone";
import ModalLoading from "../../components/ModalLoading";
import React from "react";
import { getLanguageUI } from "../../Services/languageUI";
import {
  ListPrinterByCatalog,
  gListNamePrinterElse,
} from "../../api/apiOwner/SettingPrinterApi";

interface CustomerRecieptsProps {
  listStaff?: paymentApi.TipStaff[];
}
const CustomerReciepts: React.FC<CustomerRecieptsProps> = () => {
  const languageUI = getLanguageUI().customerReceipts;
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [listStaff, setListStaff] = useState<paymentApi.TipStaff[]>([]); //list staff đc nhận tip
  const [searchStaff, setSearchStaff] = useState<string>(""); //id nhân viên muốn tìm
  const [listCustomerReceipt, setListCustomerReceipt] = useState<
    custommerRecieptApi.OrderCustommer[]
  >([]);
  const [searchCustomer, setSearchCustomer] = useState<string>(""); //phone hoặc tên khách hàng
  const [listDaySearch, setListDaySearch] = useState<
    { value: string; dayName: string }[]
  >([]);
  const [daySelected, setDaySelected] = useState<string>();
  const [listPrinterName, setListPrinterName] = useState<
    ListPrinterByCatalog[]
  >([]);

  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await gListNamePrinterElse(UserIdStore, pos_id);
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };
  const gListStaffTip = async () => {
    if (UserIdStore) {
      const StaffApi = await paymentApi.getListTipStaff(UserIdStore);
      if (Number(StaffApi.status) === 1) {
        setListStaff(StaffApi.data ?? []);
      }
    }
  };
  //chọn staff
  const handleOnchageStaff = (e: any) => {
    if (e?.value === undefined) {
      setSearchStaff("");
      gListCustomerRecept(1, "", daySelected, searchCustomer);
      return;
    }
    setSearchStaff(e?.value);
    gListCustomerRecept(1, e?.value, daySelected, searchCustomer);
  };

  //thay đổi search date
  const handleOnchageDate = (e: any) => {
    if (e?.value === undefined) {
      setDaySelected("");
      gListCustomerRecept(1, searchStaff, "", searchCustomer);
      return;
    }
    setDaySelected(e?.value);
    gListCustomerRecept(1, searchStaff, e?.value, searchCustomer);
  };

  //tìm kiếm custommer
  const gListCustomerRecept = async (
    page: number = 1,
    staffId: string = "",
    daySearch: string = "",
    searchKey: string = ""
  ) => {
    if (UserIdStore) {
      setShowLoading(true);
      const glistCustomer = await custommerRecieptApi.gListCustomerReceipts(
        UserIdStore,
        page,
        daySearch,
        staffId,
        searchKey
      );
      if (Number(glistCustomer.status) === 1) {
        setListCustomerReceipt(glistCustomer.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  const get30Day = () => {
    var today = new Date(
      moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)")
    );
    var listDay: { dayName: string; value: string }[] = [];
    for (let i = 0; i <= 30; i++) {
      var priorDate = new Date(
        new Date(
          moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)")
        ).setDate(today.getDate() - i)
      );
      if (i === 0) {
        let item = {
          dayName: "To day",
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        listDay.push(item);
      } else if (i === 1) {
        let item = {
          dayName: "Yesterday",
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        listDay.push(item);
      } else {
        let item = {
          dayName: moment(priorDate).format("DD-MM-YYYY"),
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        listDay.push(item);
      }
    }

    setListDaySearch([...listDay]);
  };

  const removeOrderById = async (order_id: string) => {
    setListCustomerReceipt(
      listCustomerReceipt.filter(
        (item) => Number(item?.id) !== Number(order_id)
      )
    );
  };

  useEffect(() => {
    if (UserIdStore) {
      gListStaffTip();
      get30Day();
      gListCustomerRecept();
      gListPrinter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="h-70px mt-header bg-white px-4 d-flex align-items-center border-bottom container-fluid custom-header-search">
        <div className="d-flex align-items-center gap-3 items-center border-right h-100 pe-2">
          <p className="fitlter-pos-menu mb-0 d-flex align-items-center">
            {languageUI.filter}
          </p>
          <div className="d-flex align-items-center border  px-2  rounded-8px h-42px">
            <img src={calender} alt="" />
            <Select
              className="react-select react-select-container w-180px  border-select-none"
              classNamePrefix="react-select"
              defaultValue={[""]}
              placeholder={languageUI.today}
              isClearable={true}
              options={listDaySearch.map((item) => {
                return {
                  value: item.value,
                  label: item.dayName,
                };
              })}
              onChange={(e: any) => handleOnchageDate(e)}
            />
          </div>
          <div className="d-flex align-items-center border px-2 rounded-8px h-42px">
            <img src={users} alt="" />
            <Select
              className="react-select react-select-container w-180px  border-select-none"
              classNamePrefix="react-select"
              defaultValue={[""]}
              placeholder={languageUI.selectStaff}
              isClearable={true}
              options={listStaff.map((item) => {
                return {
                  value: item.id,
                  label: item.staff_name,
                };
              })}
              onChange={(e: any) => handleOnchageStaff(e)}
            />
          </div>
        </div>
        <div className="d-flex align-items-center pl-50px gap-2 ">
          <img src={iconSearchGreen} alt="" />
          <input
            type="text"
            className="focus-visible-none input-find-by-phone-employee"
            placeholder={languageUI.findreceiptby}
            value={searchCustomer}
            onChange={(e) => setSearchCustomer(e?.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                gListCustomerRecept(
                  1,
                  searchStaff,
                  daySelected,
                  searchCustomer
                );
              }
            }}
          />
        </div>
      </div>
      <div className="w-100 d-flex flex-nowrap justify-content-between page-menu-post-table overflow-hidden">
        <div className="w-100 h-100 p-3">
          <div className="w-100 h-100 bg-white rounded-12px custom-h-body-page-customer">
            <p className="text-customer-receipts p-3 mb-0">
              {languageUI.customerReceipts}
            </p>
            <div className="table-container">
              <table className="w-100 px-2 table-customer ">
                <tr className="w-100 bg-F6F6F9 header-table-customer d-flex align-items-center">
                  <th className="text-title-table w-10 ps-2  d-flex align-items-center">
                    {languageUI.table}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={cartTable} alt="" className="" />{" "}
                    {languageUI.order}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={billTable} alt="" className="" />{" "}
                    {languageUI.bill}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={databaseTable} alt="" className="" />{" "}
                    {languageUI.tip}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={usersTable} alt="" className="mb-3px" />{" "}
                    {languageUI.staff}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={clockTable} alt="" className="mb-3px" />{" "}
                    {languageUI.timePrint}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={customerTable} alt="" className="mb-2px" />{" "}
                    {languageUI.customer}
                  </th>
                  <th className="text-title-table w-2"></th>
                </tr>

                <tbody className="customer-receipts">
                  {listCustomerReceipt.map((item, i) => (
                    <ContentTableCustomer
                      key={"td" + i}
                      customer={
                        item?.custommer_name ? item?.custommer_name : "-"
                      }
                      idOrder={`${item?.id}`}
                      numerical={item?.table_name ? item?.table_name : "-"}
                      priceBill={Number(item?.price)}
                      staff={item?.staff_name ? item?.staff_name : "-"}
                      timePrint={item?.timeorder}
                      tip={
                        Number(item?.tip_amount) > 0
                          ? Number(item?.tip_amount)
                          : 0
                      }
                      UserIdStore={UserIdStore ? UserIdStore : 0}
                      setShowLoading={(e) => setShowLoading(e)}
                      listPrinterName={listPrinterName}
                      removeOrderById={removeOrderById}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReciepts;
