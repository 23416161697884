import * as Request from "../Request";

export interface StaffWorking {
  id: string;
  staff_id: string;
  clock_in: string;
  clock_out: string;
  working_time: string;
  name: string;
  tip: string;
}

export interface StaffActive {
  id: string;
  name: string;
  owner_id: string;
  startdate: string;
  positon_id: string;
  working_time: string;
  positon_name: string;
}
export interface StaffSalary {
  id: string;
  staff_id: string;
  staff_name: string;
  working_time: string;
  salary: string;
  tip: string;
}

export interface DetailTimeSheet {
  id?: string;
  staff_id?: string;
  clock_in?: string;
  time_clock_out?: string;
  time_clock_in?: string;
  clock_out?: string;
  working_time?: string;
  name?: string;
  tip?: string;
  datesheet?: string;
  salary?: string;
  list_order?: ListOrder[];
}

export interface ListOrder {
  id: string;
  code: string;
  price: string;
  payment_method: string;
  timeend: string;
  custommer_id: string;
  owner_id: string;
  table_id: string;
  tip_amount: string;
  tip_staff_id: string;
  table_name: string;
  timeOrder: string;
  dateOrder: string;
}

export interface StaffActiveInfo {
  id: string;
  passcode: string;
  name: string;
  img: string;
  phone: string;
  address: string;
  owner_id: string;
  positon_id: string;
  working_time_start: string;
  working_time_end: string;
  automatic_clockout_time: string;
  gender: string;
  birthday: string;
  ss_number: string;
  active: string;
  tip: string;
  role: string;
  automatic_clockout: string;
  sex_name: string;
  positon_name: string;
  payroll_id: string;
  payroll_value: string;
  payroll_name: string;
}

export interface SalaryStaffWeek {
  id: string;
  staff_name: string;
  phone_number: string;
  payroll_id: string;
  payroll_value: string;
  payroll_name: string;
  time_working: string;
  total_salary: string;
  tip: number;
  history: HistoryClock[];
  totalBonus: string;
  totalDeduct: string;
}
export interface HistoryClock {
  id: string;
  clock_in: string;
  clock_out: string;
  working_time: string;
  payroll_value: string;
  tip: string;
  name?: string;
}
export interface TipPersonRule {
  id: string;
  positon_name: string;
  position_id: string;
  percent_amount: string | number;
}
export interface Result<T> {
  status: number;
  message?: string;
  data?: T;
}
export interface ResultProductWaitFeedback {
  status: number;
  message?: string;
  data?: {
    customer_info: customer_info;
    listFoodWaiting: listFoodWaiting[];
  };
}
export interface customer_info {
  customer_id: number;
  customer_name: string;
  store_name: string;
  googleBusinessID: string;
  admin_id: number;
}
export interface listFoodWaiting {
  service_item_id: number;
  name: string;
  order_id: number;
  rate: number;
  content: string;
}
export interface listPosition {
  positon_name: string;
  position_id: string;
  percent_amount: string | number;
}
export interface listStaffReview {
  staff_review_id: string;
  customer_id: string;
  review_type_id: string;
  staff_id: string;
  owner_id: string;
  content_feedback: string;
  date_review: string;
  time_review: string;
  type_name: string;
  phone: string;
  staff_name: string;
  customer_name: string;
  star_num: number;
}
export interface ResultListStaffReview {
  status: number;
  message?: string;
  data?: listStaffReview[];
  totalPage: number;
  total: string;
}

export const gListStaffWorkingByDate = async (
  owner_id: number,
  dateCheck: string = ""
) => {
  return await Request.fetchAPI(`owner/get-list-staff-by-date.php`, "POST", {
    owner_id,
    dateCheck,
  })
    .then((res: Result<StaffWorking[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const EditStaffTimeSheet = async (
  staff_id: string,
  timesheets_id: string,
  time_clockOut: string = ""
) => {
  return await Request.fetchAPI(`owner/edit-staff.php`, "POST", {
    staff_id,
    timesheets_id,
    time_clockOut,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const DetailStaffTimesheet = async (timesheets_id: string) => {
  return await Request.fetchAPI(`owner/detail-timesheets.php`, "POST", {
    timesheets_id,
  })
    .then((res: Result<DetailTimeSheet>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const gListStaffActive = async (owner_id: number, is_active: number) => {
  return await Request.fetchAPI(`owner/gListStaffActive.php`, "POST", {
    owner_id,
    is_active,
  })
    .then((res: Result<StaffActive[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const EditActiveStaff = async (staff_id: string, status: number) => {
  return await Request.fetchAPI(`owner/editActiveStaff.php`, "POST", {
    staff_id,
    status,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const gDetailStaffActive = async (staff_id: string) => {
  return await Request.fetchAPI(`owner/gDetailStaffActive.php`, "POST", {
    staff_id,
  })
    .then((res: Result<StaffActiveInfo>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const gListStaffSalary = async (
  owner_id: number,
  timesalary: string,
  payroll_id: string = ""
) => {
  return await Request.fetchAPI(`owner/gListStaffSalary.php`, "POST", {
    owner_id,
    timesalary,
    payroll_id,
  })
    .then((res: Result<StaffSalary[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const editInfomationStaff = async (
  owner_id: number,
  staff_id: string,
  passcode: string,
  staff_name: string,
  gender_id: string,
  birthday: string,
  phone: string,
  address: string,
  positon_id: string,
  active: number,
  working_time_start: string,
  working_time_end: string,
  automatic_clockout: number,
  automatic_clockout_time: string,
  payroll_id: string,
  payroll_value: string,
  tip: number,
  avatar: null | File = null
) => {
  return await Request.fetchAPI(`owner/edit-infomation-staff.php`, "POST", {
    owner_id,
    staff_id,
    passcode,
    staff_name,
    gender_id,
    birthday,
    automatic_clockout_time,
    phone,
    address,
    positon_id,
    active,
    working_time_end,
    working_time_start,
    automatic_clockout,
    payroll_id,
    payroll_value,
    tip,
    avatar,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const createAccountStaff = async (
  owner_id: number,
  passcode: string,
  staff_name: string,
  gender_id: string,
  birthday: string,
  phone: string,
  address: string,
  positon_id: string,
  active: number,
  working_time_start: string,
  working_time_end: string,
  automatic_clockout: number,
  automatic_clockout_time: string,
  payroll_id: string,
  payroll_value: string,
  tip: number,
  avatar: null | File = null
) => {
  return await Request.fetchAPI(`owner/create-account-staff.php`, "POST", {
    owner_id,
    passcode,
    staff_name,
    gender_id,
    birthday,
    phone,
    address,
    positon_id,
    active,
    working_time_end,
    working_time_start,
    automatic_clockout,
    payroll_id,
    payroll_value,
    tip,
    avatar,
    automatic_clockout_time,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const editClockin = async (
  timesheets_id: string,
  time_clockIn: string
) => {
  return await Request.fetchAPI(`owner/edit-clockin-staff.php`, "POST", {
    time_clockIn,
    timesheets_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const editClockOut = async (
  timesheets_id: string,
  time_clockOut: string
) => {
  return await Request.fetchAPI(`owner/edit-clockout-staff.php`, "POST", {
    time_clockOut,
    timesheets_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const showSalaryEmployee1Week = async (
  owner_id: number,
  startDay: string,
  endDay: string
) => {
  return await Request.fetchAPI(`owner/gSalaryWeekStaff.php`, "POST", {
    owner_id,
    startDay,
    endDay,
  })
    .then((res: Result<SalaryStaffWeek[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const showSalaryEmployee2Week = async (
  owner_id: number,
  startDay: string,
  endDay: string
) => {
  return await Request.fetchAPI(`owner/gSalary2WeekStaff.php`, "POST", {
    owner_id,
    startDay,
    endDay,
  })
    .then((res: Result<SalaryStaffWeek[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const showSalaryEmployeeMonth = async (
  owner_id: number,
  month: number
) => {
  return await Request.fetchAPI(`owner/gSalaryStaffMonth.php`, "POST", {
    owner_id,
    month,
  })
    .then((res: Result<SalaryStaffWeek[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const addSalaryBonus = async (
  owner_id: number,
  staff_id: number,
  bonus_day: string,
  is_bonus: string,
  amount: number
) => {
  return await Request.fetchAPI(`owner/addSalaryBonus.php`, "POST", {
    owner_id,
    staff_id,
    bonus_day,
    is_bonus,
    amount,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const gTipPercenForPosition = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gTipPercenForPosition.php`, "POST", {
    owner_id,
  })
    .then((res: Result<TipPersonRule[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const editTipPercenForPositionByOwner = async (
  owner_id: number,
  listPosition: listPosition[]
) => {
  return await Request.fetchAPI(
    `owner/editTipPercenForPositionByOwner.php`,
    "POST",
    { owner_id, listPosition: JSON.stringify(listPosition) }
  )
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const gStaffReviewByOwner = async (
  owner_id: number,
  page: number,
  review_type_id: number,
  limit: number
) => {
  return await Request.fetchAPI(`owner/gStaffReviewByOwner.php`, "POST", {
    owner_id,
    page,
    review_type_id,
    limit,
  })
    .then((res: ResultListStaffReview) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        totalPage: 0,
        total: "0",
      };
    });
};
export const checkReviewStaffExist = async (review_token: string) => {
  return await Request.fetchAPI(`owner/checkReviewStaffExist.php`, "POST", {
    review_token,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const addReviewStaff = async (
  review_token: string,
  owner_id: number,
  content_feedback: string,
  star_number: number,
  staff_id: number
) => {
  return await Request.fetchAPI(`owner/addReviewStaff.php`, "POST", {
    review_token,
    owner_id,
    content_feedback,
    star_number,
    staff_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const gListProductWaitFeedback = async (sms_send_code: string) => {
  return await Request.fetchAPI(`owner/gListProductWaitFeedback.php`, "POST", {
    sms_send_code,
  })
    .then((res: ResultProductWaitFeedback) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: {
          customer_info: {
            customer_id: 0,
            customer_name: "",
            store_name: "",
            googleBusinessID: "",
            admin_id: 0,
          },
          listFoodWaiting: [],
        },
      };
    });
};

export const addFeedbackFood = async (
  customer_id: number,
  staff_id: number,
  review_type_id: number,
  content_feedback_staff: string,
  listFood: listFoodWaiting[],
  star_num: number = 1
) => {
  return await Request.fetchAPI(`owner/addFeedbackFood.php`, "POST", {
    customer_id,
    staff_id,
    review_type_id,
    content_feedback_staff,
    listFood: JSON.stringify(listFood),
    star_num,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const sendMailLowRating = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/sendMailLowRating.php`, "POST", {
    owner_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
