import * as Request from "../Request";

export interface Result<T> {
  status: number,
  message: string,
  data: T,
}

export interface PointPageNote {
  point_page: string,
  discount_page: string
}

export interface TimeZone {
  timeZoneId?: string,
  time_name?: string,
  time_value?: string
}

export const GetAccountInfor = async (UserIdStore: any) => {
  return await Request.fetchAPI(`owner/gInfoOwner.php?id=${UserIdStore}`, "GET")
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gTimeZoneDefault = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gTimeZoneDefaultByOwner.php`, "POST", { owner_id })
    .then((res: Result<TimeZone>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined
      };
    });
};
export const checkActiveAccount = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/checkActiveAccount.php`, "POST", { owner_id })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: '0'
      };
    });
};
export const gPointPageNote = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gPointPageNote.php`, "POST", { owner_id })
    .then((res: Result<PointPageNote>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined
      };
    });
};
export const updateAccountInfor = async (
  owner_id: any,
  contact_person: string,
  store_name: string,
  address: string,
  email: string,
  city: string,
  suit_of_unit: string,
  business_type: any,
  states: string,
  id_card: number,
  CVC: any,
  date_card: any
) => {
  return await Request.fetchAPI(`owner/editOwner.php`, "POST", {
    owner_id: owner_id,
    contact_person: contact_person,
    store_name: store_name,
    address: address,
    email: email,
    city: city,
    suit_of_unit: suit_of_unit,
    business_type: business_type,
    states: states,
    id_card: id_card,
    CVC: CVC,
    date_card: date_card,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      console.log("err", err);
      return {
        err: err,
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
