/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Request from "../api/Request";
import Swal from "sweetalert2";
// actions
import { resetAuth } from "../redux/actions";
// import GoogleReviewLink from "./reviewLink";

// store
import { AppDispatch } from "../redux/store";

import { checkedCircle } from "../components/Image";
import { useParams } from "react-router-dom";
// import fetch from "node-fetch";
// components

interface RvProps {
  helpText?: string;
  bottomLinks?: any;
  isCombineForm?: boolean;
  children?: any;
  google?: any;
}

const Rv = ({
  helpText,
  bottomLinks,
  children,
  isCombineForm,
  google,
}: RvProps) => {
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [SMSInfor, setSMSInfor] = useState<Array<any>>([]);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showReviewSuccess, setShowReviewSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");
  const [ownerId, setOwnerId] = useState<number>(0);
  const [customerId, setCustomerId] = useState<number>(0);
  const [nameOwner, setNameOwner] = useState<any>("");
  const [nameCustomer, setNameCustomer] = useState<any>("");
  const [reviewText, setReviewText] = useState("");
  const [googleBusinessID, setGoogleBusinessID] = useState<any>("");
  const [isBad, setIsBad] = useState(false);

  const mobile_link =
    "https://search.google.com/local/writereview?placeid=" +
    `${googleBusinessID}`;

  const desktop_link =
    "https://search.google.com/local/writereview?placeid=" +
    `${googleBusinessID}`;
  const isMobile = window.innerWidth <= 600;
  const linkURL = isMobile ? mobile_link : desktop_link;

  // const [searchParams, setSearchParams] = useSearchParams();

  const { idSendSMS } = useParams();
  var sms_id = idSendSMS;

  const getInforSMS = async (sms_id: any) => {
    await Request.fetchAPI(`/checkin/gInfoSMS.php?id=${sms_id}`, "get")
      .then((res) => {
        if (Number(res.status) === 1) {
          setSMSInfor(res?.data);
          setOwnerId(res?.data?.admin_id);
          setCustomerId(res?.data?.customer_id);
          setNameOwner(res?.data?.nameStore);
          setNameCustomer(res?.data?.nameCustomer);
          setGoogleBusinessID(res?.data?.googleBusinessID);
        }
      })
      .catch((error: any) => console.log(error));
  };

  const handleSubmitFeelBack = async () => {
    if (reviewText === "" || null || undefined) {
      setCheckError(true);
      setErMess("Please enter your comment");
      return;
    }
    await Request.fetchAPI(`checkin/iReviewCheckin.php`, "post", {
      customer_id: customerId,
      owner_id: ownerId,
      sms_send_id: sms_id,
      review_type_id: activeTab,
      message: reviewText,
    })
      .then((res) => {
        if (Number(res?.status) === 1) {
          // Swal.fire({
          //   position: "center",
          //   icon: "success",
          //   title: "Submit success!",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          // if (activeTab == "3") {
          //   sendSmsReview(ownerId, customerId, res.data.id_review);
          // }
          // setReviewText("");
          // navigate(`/webcheckin/sendmenu/${ownerId}`);
          setShowReviewSuccess(true);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            // title: "Submit error, please try again!",
            title: res?.message,
            showConfirmButton: false,
            timer: 3000,
          });
          setReviewText("");
        }
      })
      .catch((error: any) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Submit error, please try again!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      });
  };

  const GoodReview = async () => {
    await Request.fetchAPI(`checkin/iReviewCheckin.php`, "post", {
      customer_id: customerId,
      owner_id: ownerId,
      sms_send_id: sms_id,
      review_type_id: 1,
      message: "Good",
    })
      .then((res) => {
        if (Number(res?.status) === 1) {
          setReviewText("");
        } else {
          // Swal.fire({
          //   position: "center",
          //   icon: "error",
          //   title: res?.message,
          //   showConfirmButton: false,
          //   timer: 3000,
          // });
          // setReviewText("");
          // return;
        }
      })
      .catch((error: any) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "please try again!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    dispatch(resetAuth());
    // getGoogleReviewUrl(googleBusinessID);

    // console.log(GoogleReviewLink());
  }, [dispatch]);

  useEffect(() => {
    getInforSMS(sms_id);
  }, [sms_id]);

  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  {showReviewSuccess ? (
                    <div className=" w-100 d-flex align-items-center justify-content-center">
                      <div className="box-payment-success">
                        <div className="d-flex gap-2 align-items-center justify-content-center">
                          <img
                            src={checkedCircle}
                            alt=""
                            className="h-50px w-50px"
                          />
                          <p className="text-primary text-successs-rv mb-0">
                            Submit Success
                          </p>
                        </div>
                        <p className="text-center text-notification-payment-success">
                          Thank you so much for your feedback.
                        </p>
                        <p className="text-center text-notification-payment-success">
                          This means everything to us and helps us identify
                          areas where we can improve our business to serve you
                          better.
                        </p>
                        <p className="text-center text-notification-payment-success">
                          Once again, we appreciate your time to write us
                          feedback. Thank you.
                        </p>
                      </div>
                    </div>
                  ) : showFeedbackForm ? (
                    <div>
                      <h4 className="text-center text-uppercase fw-bold">
                        {nameOwner} need your feedback
                      </h4>
                      <div className="d-flex align-items-center justify-content-center">
                        <i
                          className="fas fa-star fs-1 me-2 text-warning"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className={`fas fa-star fs-1 me-2 ${
                            !isBad ? "text-warning" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className={`fas fa-star fs-1 me-2 ${
                            !isBad ? "text-warning" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="fas fa-star fs-1 me-2"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="fas fa-star fs-1 me-2"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <Form.Control
                        className="my-3"
                        as="textarea"
                        rows={6}
                        value={reviewText}
                        onChange={(e: any) => setReviewText(e.target.value)}
                        placeholder={`Dear ${nameCustomer}, as owners of ${nameOwner}, we value the quality of our service and would like to hear true feedbacks from you in order to serve you better`}
                      />
                      <div className="mt-2">
                        <div className="px-5">
                          <p className="text-center m-0">
                            <p className="d-inline me-1">Empowered by</p>
                            <a className="d-inline" href="#">
                              Brijack POS
                            </a>
                            <p className="me-1 text-center m-0">
                              Your message will be promptly delivered to the{" "}
                              <br /> owner, ensuring direct and efficient
                              communication.
                            </p>
                          </p>
                        </div>
                      </div>
                      <p style={{ color: "#f1556c", textAlign: "center" }}>
                        {checkError ? errMess : ""}
                      </p>
                      <div className="text-end">
                        <Button
                          variant="outline-primary"
                          className="me-2"
                          onClick={() => setShowFeedbackForm(false)}
                        >
                          Back
                        </Button>
                        <Button
                          variant="primary"
                          onClick={handleSubmitFeelBack}
                        >
                          Submit feedback
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h4 className="mb-3 mt-4">{nameOwner}</h4>
                      <p className="mb-3 mt-3">
                        ~ Hi {nameCustomer}, how would you rate{" "}
                      </p>
                      <Nav
                        variant="pills"
                        className="flex-column review-tab"
                        defaultActiveKey={activeTab}
                        onSelect={(tab: any) => setActiveTab(tab)}
                      >
                        <Nav.Item>
                          <Nav.Link
                            className="border fw-bold my-1"
                            eventKey="1"
                            href={linkURL}
                            target="blank"
                            onClick={GoodReview}
                          >
                            <i className="me-2 far fa-laugh fs-4"></i>Great
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="border fw-bold my-1"
                            eventKey="2"
                            onClick={() => {
                              setIsBad(false);
                              setShowFeedbackForm(true);
                            }}
                          >
                            <i className="me-2 far fa-meh fs-4"></i> Just OK
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className="border fw-bold my-1"
                            eventKey="3"
                            onClick={() => {
                              setIsBad(true);
                              setShowFeedbackForm(true);
                            }}
                          >
                            <i className="me-2 far fa-frown-open fs-4"></i>Bad
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link
                            className="border fw-bold  my-1"
                            eventKey="4"
                            onClick={notReview}
                          >
                            <i className="me-2 far fa-frown-open fs-4"></i>
                            Haven't give review yet
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                      <div className="mt-2">
                        <div className="px-5">
                          <p className="text-center m-0">
                            <p className="d-inline me-1">Empowered by</p>
                            <a className="d-inline" href="#">
                              Brijack POS
                            </a>
                            <p className="me-1 text-center m-0">
                              Your message will be promptly delivered to the{" "}
                              <br /> owner, ensuring direct and efficient
                              communication.
                            </p>
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Rv;
