
import { useEffect, useRef, useState } from "react";
import "./Styles.css"
import * as paymentApi from "../../../../api/apiCheckin/paymentApi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDraggable } from "@dnd-kit/core";
import { Scale, Translate } from "@mui/icons-material";
interface SetupTableItemProps {
    NameTable?: string;
    StatusTable?: number;//0:Availabel,1: Reserved, 2:Paid	
    numberChair?: number;
    id?: string;
    checked?: boolean;
    ConfilmResetTable?: (table_id: string, tableName: string) => void;
    setTableSelected: (value: string, tableName: string, scale: number, rorate: number) => void
    ReloadTable: () => void
    indexMap: number;
    dragItem: any;
    dragOverItem: any;
    handleSort: () => void;
    addMultiTable: (id: string, tableName: string) => void;
    isMultiTable: boolean;
    tX: number;
    tY: number;
    size: number;
    rorate: number;
    shapeTable: string;


}
const SetupTableItem: React.FC<SetupTableItemProps> = ({ shapeTable, tX, tY, NameTable = '', rorate, size, numberChair = 0, id = '', checked = false, StatusTable = 0, setTableSelected, indexMap, dragItem, dragOverItem, isMultiTable = false, handleSort, addMultiTable }) => {
    const [sizeTable, setSizeTable] = useState<number>(0)
    const [tYNew, setTYNew] = useState<number>(0)
    const [tXNew, setTXNew] = useState<number>(0)
    const [tYNew2, setTYNew2] = useState<number>()
    const [tXNew2, setTXNew2] = useState<number>()

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: id,
    });
    // const style = transform ? {
    //   transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,

    // } : undefined;

    useEffect(() => {
        setTXNew(tX)
        setTYNew(tY)


    }, [tX, tY])
    useEffect(() => {
        setTXNew2(transform?.x)
        setTYNew2(transform?.y)
    }, [transform?.x, transform?.y])



    useEffect(() => {
        if (numberChair % 2 == 0) {
            setSizeTable(numberChair / 2)
        }
        else {
            setSizeTable((numberChair + 1) / 2)
        }
    }, [id])
    const style = {
        transform: `translate3d(${tXNew2 ? tXNew + tXNew2 : tXNew}px, ${tYNew2 ? tYNew + tYNew2 : tYNew}px, 0) rorate:(${rorate})`,
        scale: `${size / 50}`
    }

    return (
        <div className={`w-fit aspect-square  my-2`}
            ref={setNodeRef}
            style={{ transform: `translate3d(${tXNew2 ? tXNew + tXNew2 : tXNew}px, ${tYNew2 ? tYNew + tYNew2 : tYNew}px, 0)`, minWidth: '50px', width: `${size * 10}px`, position: 'absolute', top: '0px', left: '0px', minHeight: '50px', height: `${size * 10}px` }}
            {...listeners} {...attributes}
        >
            <div style={{ rotate: `${rorate}deg` }} className="h-100 w-100">
                <div className={`table-pos w-100 h-100 ${shapeTable === 'circle' ? "aspect-square" : ""}  d-flex justify-content-center align-items-center  ${checked ? "border-1px-FF7B2C" : ""} ${shapeTable === 'square' ? 'border-12px' : 'border-circle'}`} onClick={() => { isMultiTable ? addMultiTable(id, NameTable) : setTableSelected(id, NameTable, size, rorate); }} >
                    <div className={`circle-name-table ${StatusTable === 0 ? "available" : StatusTable === 1 ? "reserved" : "paid"}`} style={{ rotate: `${-rorate}deg` }}>
                        <p className="mb-0 text-name-table">{NameTable}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetupTableItem;
