

import "./Styles.css"
import { cartBagOrange, storeOrange } from "../../../../components/ImgExport";
import * as orderConfilmApi from "../../../../api/apiCheckin/orderConfilmApi"
import { FormatDolla, formatCurrencyVND } from "../../../../utils/format";
import { getLanguageUI } from "../../../../Services/languageUI";
import React, { useEffect, useState } from "react";
interface OrderListITemProps {
    status: boolean;
    textStatus?: string;
    order_id?: string;
    orderInfo: orderConfilmApi.InfoOrder[];
}

const OrderListITem = ({
    status,
    orderInfo = [],
    order_id = ''

}: OrderListITemProps) => {
    const languageUI = getLanguageUI().posTable
    const [rawData, setRawData] = useState<Record<string, any>>({})

    useEffect(() => {
        const data: Record<string, any> = {};
        const name: string[] = [];
        for (const it of orderInfo) {
            name.push(it?.name ?? "");
        }
        data[order_id] = 'A.' + name;
        setRawData({ ...data });

    }, [status])
    return (
        <>
            {
                orderInfo?.length > 0 ? (
                    orderInfo.map((item, index) => (
                        <div className="order-food-items" key={index}>
                            <div className="d-flex justify-content-between align-items-start gap-2">
                                <div>
                                    <p className="name-food mb-1">{item.name}</p>
                                    <div className="d-flex align-items-center gap-2 flex-wrap">
                                        {item.notefood_name && (
                                            <div className="status-items-order mt-1">
                                                <p className="mb-0 text-items-order">{item.notefood_name}</p>
                                            </div>
                                        )}

                                        <div className="status-items-order mt-1">
                                            <img src={status == false ? cartBagOrange : storeOrange} alt="" />
                                            <p className="mb-0 text-items-order">{item.form_order == '1' ? languageUI.dineIn : languageUI.takeAway}</p>
                                        </div>
                                    </div>
                                    {item?.side_name && (
                                        <div className="mb-2">
                                            <div className="d-flex gap-1 mt-1"><p className="mb-0 text-choose-a-side text-666687">+ {item.side_name}</p> <p className="mb-0 text-choose-a-side text-12B064">(Free x1)</p></div>
                                        </div>
                                    )}

                                    {item?.note && (
                                        <p className="text-note-information-card-items mb-0">{item?.note}</p>
                                    )}
                                </div>
                                <div className="d-flex align-items-center gap-1 pt-4">
                                    <div className="d-flex align-items-end gap-1 ">
                                        <div className="d-flex align-items-end gap-2px ">
                                            <div className="text-number-print mb-2px h-fit">{item.quantity}x</div>
                                        </div>
                                        <div className="d-flex align-items-end gap-2px">
                                            <div className="text-price-food-3 text-4A4A6A h-fit mb-2px">{formatCurrencyVND(Number(item.price))}</div>
                                            {/* <p className="text-x mb-0 mb-1">
                                                $
                                            </p> */}
                                        </div>
                                    </div>
                                    {/* <div className="d-flex gap-2px">
                                        <div className="text-price-food-2">{FormatDolla(Number(item.price))}</div>
                                        <div className="text-dollar-2">$</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (<></>)
            }
        </>

    );
};

export default OrderListITem;
