
import { Form } from "react-bootstrap";
import OrderItemsTable from "./components/OrderItemsTable/OrderItemsTable";
import { useNavigate } from "react-router-dom";
interface PaymentTableProps{
}
const PaymentTable: React.FC<PaymentTableProps> = ({ match }: any) => {
  const navigate = useNavigate();

  return (
    <div className=" mt-3 rounded-5 bg-white p-3">
      <div className="mb-3 d-flex justify-content-between">
        <div className=" bg-primary px-3 py-1 rounded-5 w-fit d-flex justify-content-center align-items-center cursor-pointer" onClick={()=>{navigate(-1)}}>
          <i className="fas fa-arrow-left text-white text-xl"></i>
        </div>
        <p className="text-3xl font-bold m-0">Bill</p>
        <div className=" bg-primary px-3 py-1 rounded-5 w-fit d-flex justify-content-center align-items-center opacity-0">
          <i className="fas fa-arrow-left text-white text-xl"></i>
        </div>
      </div>
      <div className="d-flex align-items-center mb-2">
        <Form.Check
          inline
          name="payment-all-bill"
          type="radio"
          value={0}
          className="cursor-pointer"
        />
        <p className="mb-0 text-xl">All</p>
      </div>
      <div>
        <div className="rounded-5 bg-gray-light p-3 mb-2">
          <div className="d-flex justify-content-between pe-2 mb-2">
            <div className="d-flex">
              <Form.Check
                inline
                name="payment-bill"
                type="radio"
                value={1}
                className="cursor-pointer"
              />
              <p className="mb-0">The dishes of the table: {3}</p>
            </div>
            <p className="mb-0">Order number: {3}</p>
            <p className="mb-0">Custom name: {"Khach hang A"}</p>
          </div>
          <div className="custum-scroll-food-bill custom-scroll-bar">
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
          </div>
        </div>
        <div className="rounded-5 bg-gray-light p-3">
          <div className="d-flex justify-content-between pe-2 mb-2">
            <div className="d-flex">
              <Form.Check
                inline
                name="payment-bill"
                type="radio"
                value={2}
                className="cursor-pointer"
              />
              <p className="mb-0">The dishes of the table: {3}</p>
            </div>
            <p className="mb-0">Order number: {3}</p>
            <p className="mb-0">Custom name: {"Khach hang A"}</p>
          </div>
          <div className="custum-scroll-food-bill custom-scroll-bar">
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
          </div>
        </div>
      </div>
      <div className="w-100 text-right px-3 mt-2">
          <button className="px-3 py-2 font-bold text-white bg-primary border-none rounded-4 text-xl" onClick={()=>{navigate("/web-owner/PaymentBillInofrmation")}}>Payment</button>
      </div>
    </div>
  );
};

export default PaymentTable;
