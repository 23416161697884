import React from "react";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FormInput } from "../../../../components";
import "../../StylesSetting.css";
import { googleIcon, store } from "../../../../components/Image";
// import Select from 'react-select';
import { copyIconAdmin } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import * as SettingInformationAPI from "../../../../api/apiOwner/SettingInformation";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import * as flagSlice from "../../../../store/slices/FlagSettingSlice";
import ModalLoading from "../../../../components/ModalLoading";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Select2 from "react-select";
import { ComboOption, ComboVoucher } from "../../../../api/apiOwner/comboApi";
import { getLanguageUI } from "../../../../Services/languageUI";
import { URLWeb } from "../../../../api/Request";
import { useDispatch } from "react-redux";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

interface InfomationProp {
  comboVoucher: ComboVoucher[];
  listTimeZone: ComboOption[];
  ChangeSetting: () => void;
}

const Infomation: React.FC<InfomationProp> = ({
  comboVoucher = [],
  listTimeZone = [],
  ChangeSetting,
}) => {
  const dispatch = useDispatch();
  const languageUI = getLanguageUI().setting;
  const UserIdStore = useSelector(userSlice.selectorUserId) ?? 0;
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [StoreName, setStoreName] = useState<any>("");
  const [GoogleBusinessID, setGoogleBusinessID] = useState<any>("");
  const [ReviewMessage, setReviewMessage] = useState<any>("");
  const [TypeSendReviewMessage, setTypeSendReviewMessage] = useState<number>(0);
  const [TimeSendMessage, setTimeSendMessage] = useState<number>(0); // hour
  const [hourSendCustomMessage, setHourSendCustomMessage] = useState<number>(0); // hour custom  message
  const [minuteSendMessage, setMinuteSendMessage] = useState<number>(0); // minute custom message
  const [customMessageContent, setCustomMessageContent] = useState<string>(""); //content custom message
  const [TypeSendCustomMessage, setTypeSendCustomMessage] = useState<number>(0); //type custom message
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [BirthdayMessage, setBirthdayMessage] = useState<any>("");

  const [TimeSendBirthdayMessage, setTimeSendBirthdayMessage] =
    useState<number>(0);
  const [PointMessage, setPointMessage] = useState<any>("");
  const [PointsCheckin, setPointsCheckin] = useState<number>(0);
  const [PercentPoint, setPercentPoint] = useState<number>(0);
  const [PointsReward, setPointsReward] = useState<number>(0);
  const [review_message_voucher_id, setReview_message_voucher_id] =
    useState<number>(0);
  const [birthday_voucher_id, setBirthday_voucher_id] = useState<number>(0);
  const [point_voucher_id, setPoint_voucher_id] = useState<number>(0);
  const [custom_message_voucher_id, setcustom_message_voucher_id] =
    useState<number>(0);
  const [TimeSendMessageCoupon, setTimeSendMessageCoupon] = useState<number>(0);
  const [InputFirstName, setInputFirstName] = useState<boolean>(true);
  const [InputDayOfBirth, setInputDayOfBirth] = useState<boolean>(true);
  const [showRewards, setShowRewards] = useState<boolean>(true);

  const [ShowCustomerPoint, setShowCustomerPoint] = useState<boolean>(true);
  const [counponMessage, setCounponMessage] = useState<
    SettingInformationAPI.CounponMessage[]
  >([]);
  const [urlOrder, setUrlOrder] = useState<string>("");
  const [pointPage, setPointPage] = useState<number>(0);
  const [pointDiscount, setPointDiscount] = useState<string>("");
  const [timeZone, setTimeZone] = useState<number>(0);
  const [ReviewMessageFeedback, setReviewMessageFeedback] = useState<any>("");
  const [TypeSendReviewMessageFeedback, setTypeSendReviewMessageFeedback] =
    useState<number>(0);
  const [TimeSendMessageFeedback, setTimeSendMessageFeedback] =
    useState<number>(0); // hour
  const [reviewStaffVoucherId, setReviewStaffVoucherId] = useState<number>(0);
  const [poitnFlappyBirdGame, setPoitnFlappyBirdGame] = useState<number>(0);
  const [poitnWhackAMoleGame, setPoitnWhackAMoleGame] = useState<number>(0);
  const [playsFlappyBrid, setPlaysFlappyBrid] = useState<number>(0);
  const [playsWhackAMole, setPlaysWhackAMole] = useState<number>(0);

  const getConfigGame = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const res = await SettingInformationAPI.gConfigGame(UserIdStore);
      if (Number(res.status) === 1) {
        setPoitnFlappyBirdGame(Number(res.data?.point_flappybird));
        setPoitnWhackAMoleGame(Number(res.data?.point_whackamole));
        setPlaysFlappyBrid(Number(res.data?.plays_flappybird));
        setPlaysWhackAMole(Number(res.data?.plays_whackamole));
        setShowRewards(
          Number(res.data?.is_show_redemption_game) === 1 ? true : false
        );
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  const updateShowRewardGame = async () => {
    setShowRewards(!showRewards);

    if (UserIdStore) {
      setShowLoading(true);
      const res = await SettingInformationAPI.upShowRewardGame(
        UserIdStore,
        !showRewards === true ? 1 : 0
      );
      if (Number(res.status) === 1) {
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  const saveConfigGame = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const res = await SettingInformationAPI.upConfigGame(
        UserIdStore,
        poitnFlappyBirdGame,
        poitnWhackAMoleGame,
        playsFlappyBrid,
        playsWhackAMole
      );
      if (Number(res.status) === 1) {
        setShowLoading(false);
      } else {
        // console.log(res.message);
        setShowLoading(false);
      }
    }
  };

  const maxLengthInput = 130;

  const GetInfomationOwner = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const Infomation = await SettingInformationAPI.InformationOwner(
        UserIdStore
      );
      if (Number(Infomation?.status) === 1 && Infomation?.data) {
        setStoreName(Infomation?.data?.name);
        setGoogleBusinessID(Infomation?.data?.googleBusinessID);
        setReviewMessage(Infomation?.data?.review_message);
        setCustomMessageContent(Infomation?.data?.customMessageContent ?? "");
        setHourSendCustomMessage(
          Infomation?.data?.hourSendCustomMessage
            ? Number(Infomation?.data?.hourSendCustomMessage)
            : 0
        );
        setMinuteSendMessage(
          Infomation?.data?.minuteSendMessage
            ? Number(Infomation?.data?.minuteSendMessage)
            : 0
        );
        setTypeSendCustomMessage(
          Infomation?.data?.TypeSendCustomMessage
            ? Number(Infomation?.data?.TypeSendCustomMessage)
            : 0
        );
        setTypeSendReviewMessage(
          Infomation?.data?.type_send_review_message
            ? Number(Infomation?.data?.type_send_review_message)
            : 0
        );
        setMinuteSendMessage(
          Infomation?.data?.minuteSendMessage
            ? Number(Infomation?.data?.minuteSendMessage)
            : 0
        );
        setTimeSendMessage(
          Infomation?.data?.time_send_message
            ? Number(Infomation?.data?.time_send_message)
            : 0
        );
        setBirthdayMessage(Infomation?.data?.birthday_message);
        setTimeSendBirthdayMessage(
          Infomation?.data?.time_send_birthday_message
            ? Number(Infomation?.data?.time_send_birthday_message)
            : 0
        );
        setPointMessage(Infomation?.data?.point_message);
        setPointsCheckin(
          Infomation?.data?.points_checkin
            ? Number(Infomation?.data?.points_checkin)
            : 0
        );
        setPercentPoint(
          Infomation?.data?.percent_point
            ? Number(Infomation?.data?.percent_point)
            : 0
        );
        setPointsReward(
          Infomation?.data?.points_reward
            ? Number(Infomation?.data?.points_reward)
            : 0
        );
        setTimeSendMessageCoupon(
          Infomation?.data?.time_send_message_coupon
            ? Number(Infomation?.data?.time_send_message_coupon)
            : 0
        );
        if (Number(Infomation?.data?.input_first_name) === 0) {
          setInputFirstName(true);
        } else {
          setInputFirstName(false);
        }

        if (Number(Infomation?.data?.input_day_of_birth) === 0) {
          setInputDayOfBirth(true);
        } else {
          setInputDayOfBirth(false);
        }

        if (Number(Infomation?.data?.show_customer_point) === 0) {
          setShowCustomerPoint(true);
        } else {
          setShowCustomerPoint(false);
        }
        setReview_message_voucher_id(
          Number(Infomation?.data?.review_message_voucher_id)
        );
        setBirthday_voucher_id(Number(Infomation?.data?.birthday_voucher_id));
        setPoint_voucher_id(Number(Infomation?.data?.point_voucher_id));
        setcustom_message_voucher_id(
          Number(Infomation?.data?.custom_message_voucher_id)
        );
        setCounponMessage(Infomation?.counponMessage ?? []);
        setUrlOrder(Infomation?.data?.url_order ?? "");
        setTimeZone(
          Infomation?.data?.timeZoneId
            ? Number(Infomation?.data?.timeZoneId)
            : 0
        );
        setReviewMessageFeedback(
          Infomation?.data?.feedback_staff_message
            ? Infomation?.data?.feedback_staff_message
            : ""
        );
        setTimeSendMessageFeedback(
          Infomation?.data?.time_send_review_staff
            ? Number(Infomation?.data?.time_send_review_staff)
            : 0
        );
        setTypeSendReviewMessageFeedback(
          Infomation?.data?.type_send_review_staff
            ? Number(Infomation?.data?.type_send_review_staff)
            : 0
        );
        setReviewStaffVoucherId(
          Infomation?.data?.review_staff_voucher_id
            ? Number(Infomation?.data?.review_staff_voucher_id)
            : 0
        );
        setPointPage(
          Infomation?.data?.point_page
            ? Number(Infomation?.data?.point_page)
            : 0
        );
        setPointDiscount(Infomation?.data?.discount_page ?? "");
        // setUserName(Infomation?.data?.email);
        // setMessage("");
      } else {
        setTimeZone(0);
        setStoreName("");
        setGoogleBusinessID("");
        setReviewMessage("");
        setTypeSendReviewMessage(0);
        setTimeSendMessage(0);
        setBirthdayMessage("");
        setTimeSendBirthdayMessage(0);
        setPointMessage("");
        setPointsCheckin(0);
        setPercentPoint(0);
        setPointsReward(0);
        setTimeSendMessageCoupon(0);
        setInputFirstName(true);
        setInputDayOfBirth(true);
        setShowCustomerPoint(true);
        setReviewMessageFeedback("");
        setTimeSendMessageFeedback(0);
        setTypeSendReviewMessageFeedback(0);
        setReviewStaffVoucherId(0);
      }
      setShowLoading(false);
    }
  };

  const changeSettingInfomation = async () => {
    // console.log({
    //     UserIdStore,
    //     StoreName,
    //     GoogleBusinessID,
    //     ReviewMessage,
    //     TypeSendReviewMessage,
    //     TimeSendMessage,
    //     BirthdayMessage,
    //     TimeSendBirthdayMessage,
    //     PointMessage,
    //     PointsCheckin,
    //     PercentPoint,
    //     PointsReward,
    //     TimeSendMessageCoupon,
    //     InputFirstName,
    //     InputDayOfBirth,
    //     ShowCustomerPoint,
    //     customMessageContent,
    //     hourSendCustomMessage,
    //     minuteSendMessage,
    //     TypeSendCustomMessage,
    //     counponMessage
    // });

    if (UserIdStore) {
      setShowLoading(true);
      const ChangeInformationOwner =
        await SettingInformationAPI.ChangeInformationOwner(
          UserIdStore,
          StoreName,
          GoogleBusinessID,
          ReviewMessage,
          TypeSendReviewMessage,
          TimeSendMessage,
          BirthdayMessage,
          TimeSendBirthdayMessage,
          PointMessage,
          PointsCheckin,
          PercentPoint,
          PointsReward,
          TimeSendMessageCoupon,
          InputFirstName,
          InputDayOfBirth,
          ShowCustomerPoint,
          customMessageContent,
          hourSendCustomMessage,
          minuteSendMessage,
          TypeSendCustomMessage,
          review_message_voucher_id,
          birthday_voucher_id,
          point_voucher_id,
          custom_message_voucher_id,
          counponMessage,
          timeZone,
          urlOrder,
          pointPage,
          pointDiscount,
          ReviewMessageFeedback,
          TypeSendReviewMessageFeedback,
          reviewStaffVoucherId,
          TimeSendMessageFeedback
        );
      if (Number(ChangeInformationOwner?.status) === 1) {
        // setTimeout(() => {
        //   setShowLoading(false);
        //   window.location.reload();
        // }, 2000);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Update success!",
          showConfirmButton: false,
          timer: 2000,
        });
        GetInfomationOwner();
        dispatch(flagSlice.clearSetting());
        setShowLoading(false);
        // console.log("Đổi mật khẩu thành công");
      } else {
        // setMessage(
        // ChangeInformationOwner?.message
        //   ? ChangeInformationOwner?.message
        //   : "Something went wrong. Please try again later!"
        // );
        Swal.fire({
          title: "Cancelled",
          text: ChangeInformationOwner?.message
            ? ChangeInformationOwner?.message
            : "Something went wrong. Please try again later!",
          icon: "error",
          confirmButtonColor: "#4a4fea",
          timer: 3000,
        });
      }
      setShowLoading(false);
    } else {
      //   navigate("/auth/login");
    }
  };
  const copyToClipboard = (text: String) => {
    const el: any = document.createElement("textarea"); // Tạo một textarea ẩn
    el.value = text; // Gán nội dung muốn sao chép vào textarea
    document.body.appendChild(el); // Thêm textarea vào body
    el.select(); // Chọn toàn bộ nội dung trong textarea
    document.execCommand("copy"); // Sao chép nội dung vào clipboard
    document.body.removeChild(el); // Xóa textarea
    setShowCopySuccess(true);
  };
  useEffect(() => {
    if (showCopySuccess) {
      setTimeout(() => {
        setShowCopySuccess(false);
      }, 1000);
    }
  }, [showCopySuccess]);
  useEffect(() => {
    if (UserIdStore) {
      setShowLoading(false);
      GetInfomationOwner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserIdStore]);

  useEffect(() => {
    getConfigGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const vietnameseChars =
    /[ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠƯĂÂÊÔƠƯĂÃẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂỄỆẾỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪỬỮỰàáâãèéêìíòóôõùúăđĩũơƯăâêôơưăãạảấầẩẫậắằẳẵặẹẻẽềềểễệếỉịọỏốồổỗộớờởỡợụủứừửữự]/g;
  const blockEmoji = (value: string, setvalue: any) => {
    const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
    const newValue = value.replace(regex, "");
    const newValue2 = newValue.replace(vietnameseChars, "");
    setvalue(newValue2);
  };

  const blockEmojiCouponMess = (
    value: string,
    setvalue: any,
    index: number
  ) => {
    var newMess = counponMessage;
    const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
    const newValue = value.replace(regex, "");
    const newValue2 = newValue.replace(vietnameseChars, "");
    newMess[index].message = newValue2;

    setCounponMessage([...newMess]);
  };

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="pt-header w-100 pb-100px h-body-qr-code">
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className="flex ">
            <Col xs={4} sm={4} md={4} lg={4}>
              <Form.Check
                className="flex items-center custom-switch-radio"
                type="switch"
                id="custom-switch"
                label={
                  <span className="text-101113 text-5 flex items-center">
                    {languageUI.allowCustomerInput}
                  </span>
                }
                checked={InputDayOfBirth}
                onChange={(e: any) => {
                  setInputDayOfBirth(!InputDayOfBirth);
                }}
              />
              <Form.Check
                className="flex items-center custom-switch-radio mt-2"
                type="switch"
                id="custom-switch-2"
                label={
                  <span className="text-101113 text-5 flex items-center">
                    {languageUI.showCustomerPoint}
                  </span>
                }
                checked={ShowCustomerPoint}
                onChange={(e: any) => {
                  setShowCustomerPoint(!ShowCustomerPoint);
                }}
              />
              {/* <div className="ps-4 text-5 text-101113 mt-2">
                {languageUI.textOver160Character}
              </div> */}
              <div className="mt-2">
                <div className="p-4 pe-0 border rounded-5 shadow-button-default bg-white">
                  <p className=" text-5 text-101113 mb-1">
                    {languageUI.exchangePoints}
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <Form.Check
                      className="flex items-center custom-switch-radio p-0"
                      type="switch"
                      id="show-rewards"
                      label={
                        <span className="text-101113 text-5 flex items-center">
                          {languageUI.showRewards}
                        </span>
                      }
                      checked={showRewards}
                      onChange={(e: any) => {
                        updateShowRewardGame();
                      }}
                    />
                    <button
                      className="font-bold text-white bg-primary border-none focus-visiable-none shadow-button-default rounded-4 px-6 py-2"
                      onClick={saveConfigGame}
                    >
                      {languageUI.save}
                    </button>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className=" text-5 text-101113 m-0">Flappy bird:</p>
                      <div className="d-flex align-items-center gap-2">
                        <p className="text-4 text-101113 m-0">Game round:</p>
                        <div className="input-setting-point">
                          <input
                            type="number"
                            inputMode="numeric"
                            className=" border-none focus-visiable-none w-full"
                            value={playsFlappyBrid.toString()}
                            onChange={(e) => {
                              if (
                                e.target.value &&
                                Number(e.target.value) <= 0
                              ) {
                                return;
                              }
                              setPlaysFlappyBrid(Number(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-2 align-items-center  mt-1">
                      <div className="input-setting-point">
                        <input
                          type="number"
                          className=" border-none focus-visiable-none w-full"
                          value={poitnFlappyBirdGame?.toString()}
                          onChange={(e) => {
                            if (e.target.value && Number(e.target.value) <= 0) {
                              return;
                            }
                            setPoitnFlappyBirdGame(Number(e.target.value));
                          }}
                        />
                      </div>
                      <p className="text-4text-101113 m-0">
                        {languageUI.pointGame}
                      </p>
                      <ArrowRightAltIcon className="text-xl text-11151f" />
                      <div className="input-setting-point">
                        <input
                          type="number"
                          className=" border-none focus-visiable-none w-full"
                          value={1}
                          readOnly
                        />
                      </div>
                      <p className="text-4text-101113 m-0">
                        {languageUI.pointRestaurant}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className=" text-5 text-101113 m-0">Whack A Mole:</p>
                      <div className="d-flex align-items-center gap-2">
                        <p className="text-4 text-101113 m-0">Game round:</p>
                        <div className="input-setting-point">
                          <input
                            type="number"
                            inputMode="numeric"
                            className=" border-none focus-visiable-none w-full"
                            value={playsWhackAMole.toString()}
                            onChange={(e) => {
                              if (
                                e.target.value &&
                                Number(e.target.value) <= 0
                              ) {
                                return;
                              }
                              setPlaysWhackAMole(Number(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-2 align-items-center  mt-1">
                      <div className="input-setting-point">
                        <input
                          type="number"
                          className=" border-none focus-visiable-none w-full"
                          value={poitnWhackAMoleGame?.toString()}
                          onChange={(e) => {
                            if (e.target.value && Number(e.target.value) <= 0) {
                              return;
                            }
                            setPoitnWhackAMoleGame(Number(e.target.value));
                          }}
                        />
                      </div>
                      <p className="text-4text-101113 m-0">
                        {languageUI.pointGame}
                      </p>
                      <ArrowRightAltIcon className="text-xl text-11151f" />
                      <div className="input-setting-point">
                        <input
                          type="number"
                          className=" border-none focus-visiable-none w-full"
                          value={1}
                          readOnly
                        />
                      </div>
                      <p className="text-4text-101113 m-0">
                        {languageUI.pointRestaurant}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <div className="d-flex">
                <Col xs={6} sm={6} md={6} lg={6}>
                  <span className="text-666687 text-7 fw-600 mx-3">
                    {languageUI.nameStore}
                  </span>
                  <div className="mx-3 my-2">
                    <div className="bg-white radius-2 border p-t-l flex items-center">
                      <img src={store} alt="" />
                      <input
                        type="text"
                        className="text-101113 text-5 ml-2 border-none focus-visiable-none w-100 text-truncate"
                        value={StoreName}
                        onChange={(e) => {
                          setStoreName(e.target.value);
                          ChangeSetting();
                        }}
                      />
                    </div>
                  </div>
                  {/* <p className='text-666687 text-4 fw-600 mx-3'>--kiosk-printing</p> */}
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <span className="text-666687 text-7 fw-600 mx-3">
                    Google bussiness ID
                  </span>
                  <div className="mx-3 mt-2 mb-2">
                    <div className="bg-white radius-3 border p-t-l flex items-center ">
                      <img src={googleIcon} alt="" />
                      <input
                        type="text"
                        className="text-101113 text-5 ml-2 border-none focus-visiable-none w-100 text-truncate"
                        value={GoogleBusinessID}
                        onChange={(e) => {
                          setGoogleBusinessID(e.target.value);
                          ChangeSetting();
                        }}
                      />
                    </div>
                  </div>
                  <span className="text-666687 text-7 fw-600 mx-3 mt-2">
                    {languageUI.timeZone}
                  </span>
                  <div className="mx-3 mt-2">
                    <div className="d-flex align-items-center border  px-2  rounded-8px h-42px w-100 bg-white">
                      <Select2
                        className="react-select react-select-container w-100  border-select-none"
                        classNamePrefix="react-select"
                        defaultValue={[""]}
                        placeholder="Time zone"
                        isClearable={false}
                        options={listTimeZone}
                        // onChange={(e) => setPaymentStatus(e?.value)}
                        value={listTimeZone.filter(
                          (item) => Number(item?.value) === Number(timeZone)
                        )}
                        onChange={(e: any) => {
                          if (e == null) {
                            setTimeZone(0);
                          } else {
                            setTimeZone(e.value);
                          }
                          ChangeSetting();
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <Col xs={12} sm={12} md={12} lg={12}>
                <p className="text-666687 text-4 fw-600 mx-3 mt-3">
                  {languageUI.LinkForCustomer}
                </p>
                <div className="mx-3 my-2">
                  <div className="bg-white radius-2 border p-t-l flex items-center gap-4 px-2">
                    <input
                      type="text"
                      className="text-101113 text-5 border-none focus-visiable-none w-100 text-truncate"
                      value={
                        URLWeb + "/online/login/" + btoa(UserIdStore.toString())
                      }
                    />
                    <img
                      src={copyIconAdmin}
                      alt=""
                      className="coppy-btn-link"
                      onClick={() =>
                        copyToClipboard(
                          URLWeb +
                            "/online/login/" +
                            btoa(UserIdStore.toString())
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
            </Col>
          </div>
        </Col>

        <div className="mt-3">
          <Col xs={12} sm={12} md={12} lg={12} className="flex flex-wrap">
            <Col xs={4} sm={4} md={4} lg={4} className="p-l-r-b">
              <div className="bg-white radius-3 p-2 h-100 flex flex-column justify-between">
                <div className="px-2">
                  <div className=" border-bottom mb-2 pb-2">
                    <span className="text-101113 text-5 ">
                      {languageUI.askForReviewMessage} (1)
                    </span>
                  </div>
                  <div>
                    <FormInput
                      label=""
                      type="textarea"
                      name="textarea"
                      className="resize-none p-2  border text-value-input-message"
                      rows="5"
                      key="textarea"
                      placeholder="Hi Friend!
                                                    This is ABC restaurant.
                                                    Long time no see! We miss you. Here’s a special 30% off cupon to welcome you back. Can’t wait to see you."
                      value={ReviewMessage}
                      maxLength={maxLengthInput}
                      onChange={(e: any) => {
                        blockEmoji(e.target.value, setReviewMessage);
                        // setReviewMessage(e.target.value);
                        ChangeSetting();
                      }}
                    />
                    <div className="d-flex justify-content-end w-100">
                      <small
                        className="count-text"
                        style={{ bottom: 5, right: 10 }}
                      >
                        {ReviewMessage?.length}/{maxLengthInput}
                      </small>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                      <Form.Check
                        type="radio"
                        id={`ask-review-1`}
                        label={languageUI.rightAway}
                        className={`ps-0 d-flex fs-6 custom-switch text-label-radio radio-setting-information`}
                        name="time-send-ask-review"
                        checked={TypeSendReviewMessage === 1 ? true : false}
                        onClick={(e: any) => {
                          setTypeSendReviewMessage(1);
                          ChangeSetting();
                        }}
                        onChange={(e) => {}}
                      />
                      <Form.Check
                        type="radio"
                        id={`ask-review-2`}
                        label={languageUI.customTime}
                        className={`ps-0 d-flex fs-6 custom-switch radio-setting-information`}
                        name="time-send-ask-review"
                        checked={TypeSendReviewMessage === 2 ? true : false}
                        onClick={(e: any) => {
                          setTypeSendReviewMessage(2);
                          ChangeSetting();
                        }}
                        onChange={(e) => {}}
                      />
                    </div>
                    {TypeSendReviewMessage === 2 && (
                      <div
                        key={"erer"}
                        className="d-flex gap-2 justify-content-between w-100 mt-3"
                      >
                        <div className="text-212134 text-5 my-2">
                          {languageUI.customTime}
                        </div>
                        <div className="flex items-center">
                          <div className="box-input-enter-time ">
                            <input
                              type="number"
                              className="input-enter-time border-none focus-visiable-none"
                              placeholder="00"
                              value={TimeSendMessage}
                              onChange={(e: any) => {
                                if (
                                  e.target.value &&
                                  (Number(e.target.value) < 0 ||
                                    Number(e.target.value) > 48)
                                ) {
                                  return;
                                } else {
                                  setTimeSendMessage(e.target.value);
                                }
                                ChangeSetting();
                              }}
                            />
                          </div>
                          <span className="text-A5A5BA ml-2 mr-2">
                            {languageUI.hours}
                          </span>
                          {/* <div className='box-input-enter-time '>
                                                        <input type="number" min={0} className='input-enter-time border-none focus-visiable-none' placeholder='00' value={minuteSendMessage}
                                                            onChange={(e: any) => {
                                                                if (e.target.value && (Number(e.target.value) < 0 || Number(e.target.value) > 60)) {
                                                                    // setMinuteSendMessage(0)
                                                                    return
                                                                } else {
                                                                    setMinuteSendMessage(e.target.value);
                                                                }

                                                            }} />
                                                    </div>
                                                    <span className='text-A5A5BA ml-2'>minutes</span> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-2 mt-3">
                  <Select
                    className="w-100"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-5"
                    placeholder={languageUI.chooseVoucherID}
                    value={review_message_voucher_id}
                    onChange={(e) => {
                      setReview_message_voucher_id(Number(e.target.value));
                      ChangeSetting();
                    }}
                  >
                    <MenuItem value={0}>Choose voucher ID</MenuItem>
                    {comboVoucher.map((voc, v) => (
                      <MenuItem value={Number(voc?.id)} key={`customtime${v}`}>
                        {voc?.voucher_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>

            <Col xs={4} sm={4} md={4} lg={4} className="p-l-r-b">
              <div className="bg-white radius-3 p-2 h-100 flex flex-column justify-between">
                <div className="px-2">
                  <div className=" border-bottom mb-2 pb-2">
                    <span className="text-101113 text-5 ">
                      {languageUI.birthdayMessage} (2)
                    </span>
                  </div>

                  <div>
                    <FormInput
                      label=""
                      type="textarea"
                      name="textarea2"
                      className="resize-none p-2 border border text-value-input-message"
                      rows="5"
                      key="textarea2"
                      placeholder="Happy Birthday
                                                    Dear customer! This is ABC Restaurant
                                                    As a token of our appreciation, enjoy 20% off!
                                                    Use this code at checkout"
                      value={BirthdayMessage}
                      maxLength={maxLengthInput}
                      onChange={(e: any) => {
                        blockEmoji(e.target.value, setBirthdayMessage);
                        ChangeSetting();
                      }}
                    />
                    <div className="d-flex justify-content-end w-100">
                      <small
                        className="count-text"
                        style={{ bottom: 5, right: 10 }}
                      >
                        {BirthdayMessage?.length}/{maxLengthInput}
                      </small>
                    </div>
                    <div className="text-212134 text-5 my-2">
                      {languageUI.timeSentBirthdayMessage}
                    </div>

                    <div className="flex items-center justify-between mt-2">
                      <Form.Check
                        type="radio"
                        id={`birthday-message-1`}
                        label={languageUI.rightAway}
                        className={`ps-0 d-flex fs-6 custom-switch radio-setting-information`}
                        name="birthday-message"
                        checked={TimeSendBirthdayMessage === 0 ? true : false}
                        onClick={(e: any) => {
                          setTimeSendBirthdayMessage(0);
                          ChangeSetting();
                        }}
                        onChange={(e) => {}}
                      />
                      <Form.Check
                        type="radio"
                        id={`birthday-message-2`}
                        label={languageUI.onBirthdayDay}
                        className={`ps-0 d-flex fs-6 custom-switch radio-setting-information`}
                        name="birthday-message"
                        checked={TimeSendBirthdayMessage === 1 ? true : false}
                        onClick={(e: any) => {
                          setTimeSendBirthdayMessage(1);
                          ChangeSetting();
                        }}
                        onChange={(e) => {}}
                      />
                    </div>
                  </div>
                </div>

                <div className="px-2">
                  <Select
                    className="w-100"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-5"
                    // value={age}
                    // value={age}
                    // label="Age"
                    placeholder={languageUI.chooseVoucherID}
                    value={birthday_voucher_id}
                    onChange={(e) => {
                      setBirthday_voucher_id(Number(e.target.value));
                      ChangeSetting();
                    }}
                  >
                    <MenuItem value={0}>Choose voucher ID</MenuItem>
                    {comboVoucher.map((voc, v) => (
                      <MenuItem value={Number(voc?.id)} key={`birth${v}`}>
                        {voc?.voucher_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>

            <Col xs={4} sm={4} md={4} lg={4} className="p-l-r-b">
              <div className="bg-white radius-3 p-2 h-100 flex flex-column justify-between">
                <div className="px-2 ">
                  <div className=" border-bottom mb-2 pb-2">
                    <span className="text-101113 text-5 ">
                      {languageUI.customMessage} (3)
                    </span>
                  </div>
                  <div>
                    <FormInput
                      label=""
                      type="textarea"
                      name="textarea"
                      className="resize-none p-2  border text-value-input-message"
                      rows="5"
                      key="textarea"
                      placeholder="Hi friend!
                                            Just nudging you about the invoice. You can also pay online too.
                                            Whenever, no rush, no pressure at all.
                                            Reach out if you need any help."
                      value={customMessageContent}
                      maxLength={maxLengthInput}
                      onChange={(e: any) => {
                        blockEmoji(e.target.value, setCustomMessageContent);
                        ChangeSetting();
                      }}
                    />
                    <div className="d-flex justify-content-end w-100">
                      <small
                        className="count-text"
                        style={{ bottom: 5, right: 10 }}
                      >
                        {customMessageContent?.length}/{maxLengthInput}
                      </small>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                      <Form.Check
                        type="radio"
                        id={`custom-message-1`}
                        label={languageUI.rightAway}
                        className={`ps-0 d-flex fs-6 custom-switch text-label-radio radio-setting-information`}
                        name="custom-message"
                        checked={Number(TypeSendCustomMessage) === 0}
                        onClick={(e: any) => {
                          setTypeSendCustomMessage(0);
                          ChangeSetting();
                        }}
                      />
                      <Form.Check
                        type="radio"
                        id={`custom-message-3`}
                        label={languageUI.noSent}
                        className={`ps-0 d-flex fs-6 custom-switch text-label-radio radio-setting-information`}
                        name="custom-message"
                        checked={Number(TypeSendCustomMessage) === 2}
                        onClick={(e: any) => {
                          setTypeSendCustomMessage(2);
                          ChangeSetting();
                        }}
                      />
                      <Form.Check
                        type="radio"
                        id={`custom-message-2`}
                        label={languageUI.customTime}
                        className={`ps-0 d-flex fs-6 custom-switch radio-setting-information`}
                        name="custom-message"
                        checked={Number(TypeSendCustomMessage) === 1}
                        onClick={(e: any) => {
                          setTypeSendCustomMessage(1);
                          ChangeSetting();
                        }}
                      />
                    </div>
                    {Number(TypeSendCustomMessage) === 1 && (
                      <>
                        <div className="text-212134 text-5 my-2">
                          Custom time
                        </div>
                        <div className="flex items-center">
                          <div className="box-input-enter-time ">
                            <input
                              type="number"
                              className="input-enter-time border-none focus-visiable-none"
                              placeholder="00"
                              value={hourSendCustomMessage}
                              onChange={(e: any) => {
                                if (
                                  e.target.value &&
                                  (Number(e.target.value) < 0 ||
                                    Number(e.target.value) > 23)
                                ) {
                                  return;
                                } else {
                                  setHourSendCustomMessage(e.target.value);
                                }
                                ChangeSetting();
                              }}
                            />
                          </div>
                          <span className="text-A5A5BA ml-2 mr-2">
                            {languageUI.hours}:
                          </span>
                          <div className="box-input-enter-time ">
                            <input
                              type="number"
                              min={0}
                              className="input-enter-time border-none focus-visiable-none"
                              placeholder="00"
                              value={minuteSendMessage}
                              onChange={(e: any) => {
                                ChangeSetting();
                                if (
                                  e.target.value &&
                                  (Number(e.target.value) < 0 ||
                                    Number(e.target.value) > 60)
                                ) {
                                  // setMinuteSendMessage(0)
                                  return;
                                } else {
                                  setMinuteSendMessage(e.target.value);
                                }
                              }}
                            />
                          </div>
                          <span className="text-A5A5BA ml-2">
                            {languageUI.minutes}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="px-2 mt-3">
                  <Select
                    className="w-100"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-5"
                    // value={age}
                    // value={age}
                    // label="Age"
                    placeholder="Choose voucher ID"
                    value={custom_message_voucher_id}
                    onChange={(e) => {
                      setcustom_message_voucher_id(Number(e.target.value));
                      ChangeSetting();
                    }}
                  >
                    <MenuItem value={0}>Choose voucher ID</MenuItem>
                    {comboVoucher.map((vov, v) => (
                      <MenuItem value={Number(vov?.id)} key={`custom${v}`}>
                        {vov?.voucher_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>
            {counponMessage.map((item, i) => (
              <Col
                xs={4}
                sm={4}
                md={4}
                lg={4}
                className="p-l-r-b"
                key={"counpon" + i}
              >
                <div className="bg-white radius-3 p-2 h-100 flex flex-column justify-between">
                  <div className="px-2">
                    <div className=" border-bottom mb-2 pb-2">
                      <span className="text-101113 text-5 ">
                        {languageUI.couponMessage} ({i + 4})
                      </span>
                    </div>
                    <div>
                      <FormInput
                        label=""
                        type="textarea"
                        name={`textareaCoupon${i}`}
                        className="resize-none p-2 border text-value-input-message"
                        rows="5"
                        key="textareaCoupon1"
                        value={item?.message}
                        maxLength={maxLengthInput}
                        onChange={(e) => {
                          blockEmojiCouponMess(
                            e.target.value,
                            setCounponMessage,
                            i
                          );

                          ChangeSetting();
                        }}
                      />
                      <div className="d-flex justify-content-end w-100">
                        <small
                          className="count-text"
                          style={{ bottom: 5, right: 10 }}
                        >
                          {counponMessage[i].message?.length}/{maxLengthInput}
                        </small>
                      </div>
                      <div className=" mt-2">
                        <Form.Check
                          type="radio"
                          id={`coupon-message-${i}`}
                          label={languageUI.sendWhenTheyNotVisit}
                          className={`ps-0 d-flex fs-6 custom-switch radio-setting-information`}
                          name={`coupon-message-right${i}`}
                          checked={Number(item?.type) === 1}
                          onClick={(e: any) => {
                            var newMess = counponMessage;
                            newMess[i].type = "1";
                            setCounponMessage([...newMess]);
                            ChangeSetting();
                          }}
                          onChange={(e) => {}}
                        />
                        {Number(item?.type) === 1 && (
                          <div className="mt-2 w-100 d-flex flex-wrap align-items-center gap-2">
                            <div className="d-flex  align-items-center">
                              <div className="d-flex gap-2 align-items-center">
                                <div className="number-select-month ms-4 w-120px">
                                  {/* <Select
                                    className='w-100 h-50px'
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select-month-1"
                                    placeholder='Choose voucher ID'
                                    defaultValue={"1"}
                                    value={item.time_value}
                                    onChange={(e) => {
                                      var newMess = counponMessage
                                      newMess[i].time_value = (e.target.value)
                                      setCounponMessage([...newMess])
                                      ChangeSetting();
                                    }}
                                  >
                                    <MenuItem value={"1"}>1</MenuItem>
                                    <MenuItem value={"2"}>2</MenuItem>
                                    <MenuItem value={"3"}>3</MenuItem>
                                    <MenuItem value={"4"}>4</MenuItem>
                                  </Select> */}
                                  {/* {i === 0 ? (
                                    <Select
                                      className='w-100 h-50px'
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select-month-1"
                                      placeholder='Choose voucher ID'
                                      defaultValue={"1"}
                                      value={item.time_value}
                                      onChange={(e) => {
                                        var newMess = counponMessage
                                        newMess[i].time_value = (e.target.value)
                                        setCounponMessage([...newMess])
                                        ChangeSetting();
                                      }}
                                    >

                                      <MenuItem value={"1"}>1</MenuItem>
                                      <MenuItem value={"2"}>2</MenuItem>
                                      <MenuItem value={"3"}>3</MenuItem>
                                      <MenuItem value={"4"}>4</MenuItem>
                                    </Select>
                                  ) : i === 1 ? (
                                    <Select
                                      className='w-100 h-50px'
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select-month-2"
                                      placeholder='Choose voucher ID'
                                      defaultValue={"5"}
                                      value={item.time_value}
                                      onChange={(e) => {
                                        var newMess = counponMessage
                                        newMess[i].time_value = (e.target.value)
                                        setCounponMessage([...newMess])
                                        ChangeSetting();
                                      }}
                                    >

                                      <MenuItem value={"5"}>5</MenuItem>
                                      <MenuItem value={"6"}>6</MenuItem>
                                      <MenuItem value={"7"}>7</MenuItem>
                                      <MenuItem value={"8"}>8</MenuItem>
                                    </Select>
                                  ) : (
                                    <Select
                                      className='w-100 h-50px'
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select-month-3"
                                      placeholder='Choose voucher ID'
                                      defaultValue={"9"}
                                      value={item.time_value}
                                      onChange={(e) => {
                                        var newMess = counponMessage
                                        newMess[i].time_value = (e.target.value)
                                        setCounponMessage([...newMess])
                                        ChangeSetting();
                                      }}
                                    >

                                      <MenuItem value={"9"}>9</MenuItem>
                                      <MenuItem value={"10"}>10</MenuItem>
                                      <MenuItem value={"11"}>11</MenuItem>
                                      <MenuItem value={"12"}>12</MenuItem>
                                    </Select>
                                  )} */}
                                  <div className="w-100 h-50px d-flex align-items-center justify-content-center">
                                    {item?.time_value}
                                  </div>
                                </div>
                                {/* <div className="btn-remove-plus-number">
                                                                    <img src={plusGreen} alt="" onClick={() => {
                                                                        var newMess = counponMessage;
                                                                        newMess[i].time_value = (Number(newMess[i].time_value) + 1).toString();
                                                                        setCounponMessage([...newMess])

                                                                    }} />
                                                                </div> */}
                              </div>
                            </div>
                            <div className="width-160px box-select-month px-2">
                              <div className="w-100  rounded-12px p-2 text-primary">
                                {Number(item?.option_time) === 1
                                  ? languageUI.month
                                  : languageUI.day}
                              </div>
                            </div>
                          </div>
                        )}

                        <Form.Check
                          type="radio"
                          id={`coupon-message-rigt${i}`}
                          label={languageUI.rightAway}
                          className={`ps-0 d-flex fs-6 custom-switch radio-setting-information mt-2`}
                          name={`coupon-message-${i}`}
                          checked={Number(item?.type) === 0 ? true : false}
                          onClick={(e: any) => {
                            var newMess = counponMessage;
                            newMess[i].type = "0";
                            setCounponMessage([...newMess]);
                            ChangeSetting();
                          }}
                          onChange={(e) => {}}
                        />
                        <Form.Check
                          type="radio"
                          id={`coupon-message-rigt${i}`}
                          label={languageUI.noSent}
                          className={`ps-0 d-flex fs-6 custom-switch radio-setting-information mt-2`}
                          name={`coupon-message-${i}`}
                          checked={Number(item?.type) === 2 ? true : false}
                          onClick={(e: any) => {
                            var newMess = counponMessage;
                            newMess[i].type = "2";
                            setCounponMessage([...newMess]);
                            ChangeSetting();
                          }}
                          onChange={(e) => {}}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="px-2 mt-3">
                    <Select
                      className="w-100"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-5"
                      placeholder="Choose voucher ID"
                      value={item?.voucher_id}
                      onChange={(e) => {
                        var newMess = counponMessage;
                        newMess[i].voucher_id = e.target.value;
                        setCounponMessage([...newMess]);
                        ChangeSetting();
                      }}
                      // onChange={handleChange}
                    >
                      {/* < MenuItem value="defaul" >Choose voucher ID</MenuItem> */}

                      <MenuItem value={"0"}>Choose voucher ID</MenuItem>
                      {comboVoucher.map((vov, v) => (
                        <MenuItem value={vov?.id} key={`couponmonth${i}-${v}`}>
                          {vov?.voucher_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </Col>
            ))}
            <Col xs={4} sm={4} md={4} lg={4} className="p-l-r-b">
              <div className="bg-white radius-3 p-2 h-100 flex flex-column justify-between">
                <div className="px-2">
                  <div className=" border-bottom mb-2 pb-2">
                    <span className="text-101113 text-5 ">
                      {languageUI.couponForCertain} (17)
                    </span>
                  </div>
                  <div>
                    <FormInput
                      label=""
                      type="textarea"
                      name="textarea3"
                      className="resize-none p-2 text-value-input-message border"
                      placeholder="Hi Friend!
                                                    This is ABC restaurant.
                                                    Long time no see! We miss you. Here’s a special 30% off coupon to welcome you back.Can’t wait to see you."
                      rows="5"
                      key="textarea3"
                      value={PointMessage}
                      maxLength={maxLengthInput}
                      onChange={(e: any) => {
                        blockEmoji(e.target.value, setPointMessage);
                        ChangeSetting();
                      }}
                    />
                    <div className="d-flex justify-content-end w-100">
                      <small
                        className="count-text"
                        style={{ bottom: 5, right: 10 }}
                      >
                        {PointMessage?.length}/{maxLengthInput}
                      </small>
                    </div>
                    <div className=" my-2 flex items-center">
                      <span className="text-212134 text-5 mr-2">
                        {languageUI.Reward}
                      </span>
                      <div className="mr-2 point-input">
                        <div className="box-input-enter-point ">
                          <input
                            type="number"
                            className="input-enter-point border-none focus-visiable-none"
                            placeholder="0"
                            value={PointsCheckin}
                            onChange={(e: any) => {
                              setPointsCheckin(e.target.value);
                            }}
                            readOnly
                          />
                        </div>
                      </div>

                      <span className="text-12B064 font-urbansist me-2">
                        {languageUI.point}
                      </span>
                      <span className="text-101113 text-5 ">
                        {languageUI.for1Spent}
                      </span>
                    </div>
                    <div className=" my-2">
                      <Form.Check
                        type="radio"
                        id={`point-message-1`}
                        label={languageUI.rightAway}
                        className={`ps-0 d-flex fs-6 custom-switch radio-setting-information`}
                        name="point-message"
                        checked={TimeSendMessageCoupon === 0 ? true : false}
                        onClick={(e: any) => {
                          setTimeSendMessageCoupon(0);
                          ChangeSetting();
                        }}
                        onChange={(e) => {}}
                      />
                      <div className="flex items-center">
                        <Form.Check
                          type="radio"
                          id={`point-message-2`}
                          label={languageUI.sentCouponWhenTheyReach}
                          className={`ps-0 d-flex fs-6 mr-2 custom-switch radio-setting-information`}
                          name="point-message"
                          checked={TimeSendMessageCoupon === 1 ? true : false}
                          onClick={(e: any) => {
                            setTimeSendMessageCoupon(1);
                            ChangeSetting();
                          }}
                          onChange={(e) => {}}
                        />
                        <div className="mr-2 point-input">
                          <div className="box-input-enter-time-2 ">
                            <input
                              type="number"
                              className="input-enter-time-2 border-none focus-visiable-none"
                              placeholder="00"
                              value={PointsReward}
                              onChange={(e: any) => {
                                setPointsReward(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <span className="text-12B064 font-urbansist">
                          {languageUI.point}
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="note-setting-this-sentence mb-1">
                        {languageUI.thisSentenceBelow}
                      </p>
                      <p className="mb-0">{languageUI.forEveryDollar}</p>
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0">{languageUI.onceYouAccumulate}</p>
                        <input
                          type="number"
                          className="w-60px h-30px"
                          value={pointPage}
                          onChange={(e) => {
                            if (Number(e.target.value) < 0) return;
                            setPointPage(Number(e.target.value));
                          }}
                        />
                        <p className="mb-0">{languageUI.points}</p>
                      </div>
                      <div className="d-flex align-items-center gap-2 flex-wrap">
                        <p className="mb-0">{languageUI.weWillProvide}</p>
                        <input
                          type="text"
                          className="w-60px h-30px"
                          value={pointDiscount}
                          onChange={(e) => setPointDiscount(e.target.value)}
                        />
                        <p className="mb-0">{languageUI.off}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-2 mt-3">
                  <Select
                    className="w-100"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-5"
                    // value={age}
                    // value={age}
                    // label="Age"
                    placeholder={languageUI.chooseVoucherID}
                    value={point_voucher_id}
                    onChange={(e) => {
                      setPoint_voucher_id(Number(e.target.value));
                      ChangeSetting();
                    }}
                  >
                    <MenuItem value={0}>Choose voucher ID</MenuItem>
                    {comboVoucher.map((vov, v) => (
                      <MenuItem value={Number(vov?.id)} key={`certain${v}`}>
                        {vov?.voucher_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className="p-l-r-b">
              <div className="bg-white radius-3 p-2 h-100 flex flex-column justify-between">
                <div className="px-2">
                  <div className=" border-bottom mb-2 pb-2">
                    <span className="text-101113 text-5 ">
                      {languageUI.customMessage} (18)
                    </span>
                  </div>
                  <div>
                    <FormInput
                      label=""
                      type="textarea"
                      name="textarea"
                      className="resize-none p-2  border text-value-input-message"
                      rows="5"
                      key="textarea"
                      placeholder="Hello!
                                                    This is ABC restaurant.
                                                    We'd love to hear your feedback about you server today to enhance our customer service. Your input matters to us.
                                                    Thank you for choosing ABC Restaurant!"
                      value={ReviewMessageFeedback}
                      maxLength={maxLengthInput}
                      onChange={(e: any) => {
                        blockEmoji(e.target.value, setReviewMessageFeedback);
                        // setReviewMessageFeedback(e.target.value);
                        ChangeSetting();
                      }}
                    />
                    <div className="d-flex justify-content-end w-100">
                      <small
                        className="count-text"
                        style={{ bottom: 5, right: 10 }}
                      >
                        {ReviewMessageFeedback?.length}/{maxLengthInput}
                      </small>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                      <Form.Check
                        type="radio"
                        id={`ask-review-staff-1`}
                        label={languageUI.rightAway}
                        className={`ps-0 d-flex fs-6 custom-switch text-label-radio radio-setting-information`}
                        name="time-send-ask-review-staff"
                        checked={
                          TypeSendReviewMessageFeedback === 1 ? true : false
                        }
                        onClick={(e: any) => {
                          setTypeSendReviewMessageFeedback(1);
                          ChangeSetting();
                        }}
                        onChange={(e) => {}}
                      />
                      <Form.Check
                        type="radio"
                        id={`ask-review-staff-0`}
                        label={languageUI.noSent}
                        className={`ps-0 d-flex fs-6 custom-switch text-label-radio radio-setting-information`}
                        name="time-send-ask-review-staff"
                        checked={
                          TypeSendReviewMessageFeedback === 0 ? true : false
                        }
                        onClick={(e: any) => {
                          setTypeSendReviewMessageFeedback(0);
                          ChangeSetting();
                        }}
                        onChange={(e) => {}}
                      />
                      <Form.Check
                        type="radio"
                        id={`ask-review-staff-2`}
                        label={languageUI.customTime}
                        className={`ps-0 d-flex fs-6 custom-switch radio-setting-information`}
                        name="time-send-ask-review-staff"
                        checked={
                          TypeSendReviewMessageFeedback === 2 ? true : false
                        }
                        onClick={(e: any) => {
                          setTypeSendReviewMessageFeedback(2);
                          ChangeSetting();
                        }}
                        onChange={(e) => {}}
                      />
                    </div>
                    {TypeSendReviewMessageFeedback === 2 && (
                      <div
                        key={"erer"}
                        className="d-flex gap-2 justify-content-between w-100 mt-3"
                      >
                        <div className="text-212134 text-5 my-2">
                          {languageUI.customTime}
                        </div>
                        <div className="flex items-center">
                          <div className="box-input-enter-time ">
                            <input
                              type="number"
                              className="input-enter-time border-none focus-visiable-none"
                              placeholder="00"
                              value={TimeSendMessageFeedback}
                              onChange={(e: any) => {
                                if (
                                  e.target.value &&
                                  (Number(e.target.value) < 0 ||
                                    Number(e.target.value) > 48)
                                ) {
                                  return;
                                } else {
                                  setTimeSendMessageFeedback(e.target.value);
                                }
                                ChangeSetting();
                              }}
                            />
                          </div>
                          <span className="text-A5A5BA ml-2 mr-2">
                            {languageUI.hours}
                          </span>
                          {/* <div className='box-input-enter-time '>
                                                        <input type="number" min={0} className='input-enter-time border-none focus-visiable-none' placeholder='00' value={minuteSendMessage}
                                                            onChange={(e: any) => {
                                                                if (e.target.value && (Number(e.target.value) < 0 || Number(e.target.value) > 60)) {
                                                                    // setMinuteSendMessage(0)
                                                                    return
                                                                } else {
                                                                    setMinuteSendMessage(e.target.value);
                                                                }

                                                            }} />
                                                    </div>
                                                    <span className='text-A5A5BA ml-2'>minutes</span> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-2 mt-3">
                  <Select
                    className="w-100"
                    labelId="demo-simple-select-staff-label"
                    id="demo-simple-select-5-5"
                    placeholder={languageUI.chooseVoucherID}
                    value={reviewStaffVoucherId}
                    onChange={(e) => {
                      setReviewStaffVoucherId(Number(e.target.value));
                      ChangeSetting();
                    }}
                  >
                    <MenuItem value={0}>Choose voucher ID</MenuItem>
                    {comboVoucher.map((voc, v) => (
                      <MenuItem value={Number(voc?.id)} key={`customtime${v}`}>
                        {voc?.voucher_name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>
          </Col>
        </div>
        <div className="d-flex w-100 justify-content-end px-6 mt-2">
          <button
            className="border-none bg-primary w-150px rounded-4 shadow-button-default text-white font-bold h-40px"
            onClick={changeSettingInfomation}
          >
            {languageUI.save}
          </button>
        </div>
      </div>
      <div
        className={`box-container-copy-success ${
          showCopySuccess ? "" : "d-none"
        }`}
      >
        <div className="box-copy-success">Copy Success</div>
      </div>
    </>
  );
};

export default Infomation;
