import { useEffect, useRef, useState } from "react";
import React from "react";
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { Cell, CellValue } from "react-table";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { records as data } from "./components/fakeDataTableSubscription";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";
import * as SubscriptionAdminAPI from "../../api/apiAdmin/SubscriptionAdmin";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

const SubscriptionAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const languageUI = getLanguageCheckIn();
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [ListPacked, setListPacked] = useState<Array<any>>([]);
  const [Description, setDescription] = useState<any>("");
  const [Price, setPrice] = useState<any>("");
  const [SMS, setSMS] = useState<any>("");
  const [MessageToast, setMessageToast] = useState<any>("");
  const [MessageToastSuccess, setMessageToastSuccess] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState(false);

  const idPackedEdit = useRef<any>(0);
  //
  const [modalEditPackage, setModalEditPackage] = useState(false);

  // Datatable setting
  // Datatable config

  const getInfoPackage = async (id: any) => {
    idPackedEdit.current = id;
    if (UserIdStore) {
      setShowLoading(true);
      const InfoPackage = await SubscriptionAdminAPI.GetInfoPackage(
        UserIdStore,
        id
      );
      console.log("InfoPackage", InfoPackage);

      if (Number(InfoPackage.status) === 1) {
        setDescription(InfoPackage?.data[0]?.description);
        setPrice(InfoPackage?.data[0]?.price);
        setSMS(InfoPackage?.data[0]?.cms);
        setMessageToast("");
      } else {
        setDescription("");
        setPrice("");
        setSMS("");
        setMessageToast(
          InfoPackage?.message
            ? InfoPackage?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const GetListPacked = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const ListPackage = await SubscriptionAdminAPI.ListPackage();
      if (Number(ListPackage.status) === 1) {
        setListPacked(ListPackage?.data);
      } else {

        setListPacked([]);
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const editPackage = async () => {
    if (SMS === "") {
      setMessageToast(languageUI.Pleaseentersmsquatity);
      return;
    }

    if (Price === "") {
      setMessageToast(languageUI.Pleaseenterprice);
      return;
    }

    if (UserIdStore) {
      setShowLoading(true);
      const EditPackage = await SubscriptionAdminAPI.EditPackage(
        idPackedEdit.current,
        Description,
        Price,
        SMS
      );
      if (Number(EditPackage.status) === 1) {
        setMessageToast("");
        setMessageToastSuccess(languageUI.Editpackedsuccess);
        setTimeout(() => {
          GetListPacked();
          setModalEditPackage(false);
          setShowLoading(false);
        }, 1000);
      } else {
        setMessageToastSuccess("");
        setMessageToast(
          EditPackage?.message
            ? EditPackage?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const ReloadForm = () => {
    setListPacked([]);
    setShowLoading(false);
    setModalEditPackage(false);
  };

  const ReloadFormModal = () => {
    setMessageToast("");
    setMessageToastSuccess("");
    // setPasswordAdd("");
    setDescription("");
    setPrice("");
    setSMS("");
  };

  const columns = [
    {
      Header: languageUI.Name + " package",
      // accessor: 'package_type',
      accessor: "namePackage",
      sort: false,
      Cell: (cell: Cell) => (
        <div
          className={`fw-bold ${cell.value === "Large"
            ? "text-pgk-large"
            : cell.value === "Supper"
              ? "text-pgk-super"
              : "text-pgk-basic"
            }`}
        >
          {cell.value}
        </div>
      ),
    },
    {
      Header: languageUI.Type,
      // accessor: 'package_expiry',
      accessor: "type",
      sort: false,
      Cell: (cell: Cell) => (
        <Alert
          className={`fw-bold py-0 px-2 d-inline-block rounded-5 mb-0`}
          variant={`${cell.value === "Monthly"
            ? "primary"
            : cell.value === "Yearly"
              ? "warning"
              : "danger"
            }`}
        >
          {cell.value}
        </Alert>
      ),
    },
    {
      Header: languageUI.Cash,
      accessor: "cash",
      sort: false,
      Cell: (cell: Cell) => (
        <div className="text-capitalize text-nowrap">{cell.value}$</div>
      ),
    },
    {
      Header: "Sms",
      accessor: "sms",
      sort: false,
      Cell: (cell: Cell) => (
        <div className="text-capitalize text-nowrap">{cell.value} Sms</div>
      ),
    },
    {
      Header: languageUI.Action,
      accessor: "action",
      sort: false,
      Cell: (cell: CellValue) => {
        return (
          <>
            <Button
              variant="outline-primary"
              className="square-btn p-0 d-flex rounded-circle"
              onClick={() => {
                getInfoPackage(cell?.row?.original?.id);
                setModalEditPackage(true);
              }}
            >
              <i className="fe-edit-2 m-auto"></i>
            </Button>
          </>
        );
      },
    },
  ];

  const sizePerPageList = [
    // {
    //     text: '5',
    //     value: 5,
    // },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "All",
      value: data.length,
    },
  ];
  let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
  let menuItemElOwner = document?.querySelectorAll(".menu-owner");
  menuItemElAdmin.forEach((item, i) => {
    menuItemElAdmin[i]?.classList.remove("d-none");
  });
  menuItemElOwner.forEach((item, i) => {
    menuItemElOwner[i]?.classList.add("d-none");
  });

  useEffect(() => {
    ReloadForm();
    GetListPacked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    ReloadFormModal();
  }, [modalEditPackage]);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Web admin", path: "#" },
          { label: "Subscription Lists", path: "#", active: true },
        ]}
        title={"Subscription Lists"}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal show={modalEditPackage} onHide={() => setModalEditPackage(false)}>
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title>{languageUI.EditPackage}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">{languageUI.Description}: </Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={Description}
                  type="description"
                  as="textarea"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder={languageUI.Description}
                  onChange={(e: any) => {
                    setDescription(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">{languageUI.Price}: </Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={Price}
                  type="number"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder={languageUI.Price}
                  onChange={(e: any) => {
                    setPrice(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">SMS: </Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={SMS}
                  type="number"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="SMS"
                  onChange={(e: any) => {
                    setSMS(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-2">
              <Form.Label className="pt-1">Message: </Form.Label>
            </div>
            <div className="col-10">
              <InputGroup className="mb-3">
                <Form.Control
                  value={MessagePacked}
                  as="textarea"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Message"
                  onChange={(e: any) => {
                    setMessagePacked(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </div> */}
          {/* <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="Description" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="number" placeholder="Price" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="number" placeholder="Sms" />
                    </Form.Group>
                    <Form.Group >
                        <Form.Control as="textarea" placeholder='Message' rows={3} />
                    </Form.Group> */}
        </Modal.Body>
        <div className="text-center text-success">{MessageToastSuccess}</div>
        <div className="text-center text-danger">{MessageToast}</div>
        <Modal.Footer className="justify-content-end border-top">
          <Button variant="primary" onClick={editPackage}>
            {languageUI.Save}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="py-3 px-2 rounded-3 mb-3 bg-white shadow">
        <Table
          overflowDropdown={false}
          columns={columns}
          data={ListPacked}
          // data={data}
          pageSize={10}
          sizePerPageList={sizePerPageList}
          isSortable={true}
          pagination={true}
          isSearchable={true}
        />
      </div>
    </>
  );
};

export default SubscriptionAdmin;
