
import { useState } from "react";
import { chervonRightPrimary } from "../../../../components/ImgExport";
import "./Styles.css"
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import * as clockinApi from "../../../../api/apiOwner/clockinApi";
import ModalSumaryClock from "../ModalSumaryClock/ModalSumaryClock";
import ModalOTPShowSalaryClock from "../ModalOTPShowSalaryClock/ModalOTPShowSalaryClock";
import React from "react";


interface ContentTableClockInOutProps {
    numerical: number;
    clockIn: string;
    clockOut: string;
    workTime: string;
    employee: string;
    staffId: string;
    timing_id: string;
    staffChoosed: string;
    setStaffChoosed: (id: string, staffid: string) => void;
}

const ContentTableClockInOut = ({
    clockIn,
    clockOut,
    employee,
    numerical,
    workTime,
    staffId,
    staffChoosed,
    timing_id,
    setStaffChoosed
}: ContentTableClockInOutProps) => {
    const UserIdStore = useSelector(userSlice.selectorUserId);
    const [listStaffTicket, setListStaffTicket] = useState<clockinApi.StaffTicket[]>([])//danh sách staff ticket
    const [infoStaff, setInfoStaff] = useState<clockinApi.InfoStaff>()//danh sách staff ticket
    const [showModalSumary, setShowModalSumary] = useState(false)
    const [showModalOtpSalary, setShowModalOtpSalary] = useState(false)

    const dismisModalShowSalary = () => {
        setShowModalOtpSalary(false)
    }


    const HandleOtpOwner = () => {

        gListStaffTicket()
    }



    const gListStaffTicket = async () => {
        if (staffId !== '' && timing_id !== '' && UserIdStore) {
            const res_ticket = await clockinApi.gStaffTicket(UserIdStore, staffId, timing_id);
            if (Number(res_ticket.status) === 1) {
                setListStaffTicket(res_ticket.data ?? []);
                setInfoStaff(res_ticket?.infoStaff)
                setShowModalSumary(true)
            }

        }
    }
    const dismisModalSumary = () => {
        setShowModalSumary(false)

    }

    return (
        <>
            <tr className={`w-100 d-table mt-3 ${staffChoosed === timing_id ? "bg-ccf6dc" : ""} `} onClick={() => { setStaffChoosed(timing_id, staffId) }}>
                <td className="text-content-table-clock text-32324D ps-2  w-10">{numerical}</td>
                <td className="text-content-table-clock text-269AFF  w-37">{employee}</td>
                <td className=" w-17 text-time-table-clock">{clockIn}</td>
                <td className=" w-17 text-time-table-clock">{clockOut}</td>
                <td className="text-content-table-clock text-228BE6  w-17">{workTime}</td>
                <td className="text-dollar-table-customer text-32324D w-5" onClick={() => { setShowModalOtpSalary(true) }}><img src={chervonRightPrimary} alt="" /></td>

            </tr>
            <ModalOTPShowSalaryClock handleClose={dismisModalShowSalary} show={showModalOtpSalary} UserIdStore={UserIdStore} handleSuccess={HandleOtpOwner} staffId={staffId} />
            <ModalSumaryClock handleClose={dismisModalSumary} show={showModalSumary} listStaffTicket={listStaffTicket} infoStaff={infoStaff} />
        </>
    );
};

export default ContentTableClockInOut;
