import * as languageUser from "./languageUser";
const Language = {
  EN: {
    modalGuest: {
      howManyGuest: "How many Guests?",
      enterGuestNumber: "Enter guest number",
      confirm: "Confirm",
    },
    modalAddSideDish: {
      chooseSideDish: "Choose Side Dish",
    },
    POSSystem: {
      station: "Station",
      createStation: "Create Station",
      STT: "No.",
      CreateAt: "Create at",
      action: "Action",
      status: "Status",
      mainStation: "Main Station",
      auxiliaryStation: "Auxiliary Station",
    },
    choosePOS: {
      back: "Back",
      name: "Name",
      select: "Select",
      noteMainPOS:
        "Important: The main station, which receives online orders and forwards them to the printer, should always be kept on to avoid disrupting the order printing process.",
    },
  },
  VN: {
    modalGuest: {
      howManyGuest: "Có Bao Nhiêu Khách Hàng?",
      enterGuestNumber: "Nhập số lượng khách hàng",
      confirm: "Xác nhận",
    },
    modalAddSideDish: {
      chooseSideDish: "Chọn Món Ăn Kèm",
    },
    POSSystem: {
      station: "Hệ thống POS",
      createStation: "Tạo POS",
      STT: "STT",
      CreateAt: "Ngày tạo",
      action: "Thao tác",
      status: "Trạng thái",
      mainStation: "POS ",
      auxiliaryStation: "Auxiliary Station",
    },
    choosePOS: {
      back: "Trở về",
      name: "Tên Máy",
      select: "Chọn",
      noteMainPOS:
        "Quan trọng: Trạm chính, nơi nhận đơn đặt hàng trực tuyến và chuyển chúng đến máy in, phải luôn được bật để tránh làm gián đoạn quá trình in đơn hàng.",
    },
  },
};
export const getLanguageUINew = () => {
  var lg = languageUser.getLanguageUser();
  if (lg !== 1) {
    const pElement = document.querySelectorAll("p");
    const inputElement = document.querySelectorAll("input");
    const divElement = document.querySelectorAll("div");
    const textareaElement = document.querySelectorAll("textarea");
    const h3Element = document.querySelectorAll("h3");
    const h5Element = document.querySelectorAll("h5");
    const h4Element = document.querySelectorAll("h4");

    if (pElement && pElement !== null) {
      for (let index = 0; index < pElement.length; index++) {
        pElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (textareaElement && textareaElement !== null) {
      for (let index = 0; index < textareaElement.length; index++) {
        textareaElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (inputElement && inputElement !== null) {
      for (let index = 0; index < inputElement.length; index++) {
        inputElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h5Element && h5Element !== null) {
      for (let index = 0; index < h5Element.length; index++) {
        h5Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h3Element && h3Element !== null) {
      for (let index = 0; index < h3Element.length; index++) {
        h3Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h4Element && h4Element !== null) {
      for (let index = 0; index < h4Element.length; index++) {
        h4Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (divElement && divElement !== null) {
      for (let index = 0; index < divElement.length; index++) {
        divElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
  }
  if (lg === 1) {
    return Language.EN;
  } else {
    return Language.VN;
  }
};
