import * as Request from "../Request";

export const GetPhoneNumber = async (UserIdStore: number) => {
  return await Request.fetchAPI(
    "admin/gInfoAdmin.php?id=" + UserIdStore,
    "get",
    {
      id: UserIdStore,
    }
  )
    .then((res: IListPackage) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const ChangePhone = async (UserIdStore: number, Phone: string) => {
  return await Request.fetchAPI("admin/settingPhoneNumber.php", "post", {
    id: UserIdStore,
    phone: Phone,
  })
    .then((res: IListPackage) => {
      return res;
    })
    .catch((err: IListPackage) => {
      return err;
    });
};

export interface IPackageData {
  phone?: string;
}
export interface IListPackage {
  message: string;
  status: number;
  data: IPackageData;
}

export interface infoSettingSuportAdmin {
  message: string;
  status: number;
  data: {
    phone: string;
    sms_support: string;
    web_support: string;
  };
}

export interface results<T> {
  message: string;
  status: number;
  data: T;
}

export const gSettingUpdatePhone = async (id: number) => {
  return await Request.fetchAPI(
    `admin/gSettingUpdatePhone.php?id=` + id,
    "GET",
    { id }
  )
    .then((res: infoSettingSuportAdmin) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: {
          phone: "",
          sms_support: "",
          web_support: "",
        },
      };
    });
};

export const settingSmsSupport = async (id: number, sms_support: string) => {
  return await Request.fetchAPI(`admin/settingSmsSupport.php`, "POST", {
    id,
    sms_support,
  })
    .then((res: results<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const settingWebSupport = async (id: number, web_support: string) => {
  return await Request.fetchAPI(`admin/settingWebSupport.php`, "POST", {
    id,
    web_support,
  })
    .then((res: results<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
