import { Form, Modal } from "react-bootstrap";
import './styles.css'
import { useEffect, useState } from "react";
import * as SettingPrinterApi from "../../../../../../api/apiOwner/SettingPrinterApi";
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface ModalChoosePrinterProps {
  show: boolean;
  owner_id: number;
  catalogId: string;
  handleClose: (status?: boolean) => void;
  setShowLoading: (status: boolean) => void
}
const ModalChoosePrinter = ({ handleClose, show, catalogId = '', owner_id = 0, setShowLoading }: ModalChoosePrinterProps) => {
  const languageUI = getLanguageUI().setting
  const [listPrinter, setListPrinter] = useState<SettingPrinterApi.ListPrinterConnect[]>([])
  const [listPrinterChoosed, setListPrinterChoosed] = useState<number[]>([])

  const gListPrinterWaitCatalog = async () => {
    setShowLoading(true);
    const req = await SettingPrinterApi.gListPrinterWait(owner_id, catalogId);
    if (Number(req?.status) === 1) {
      setShowLoading(false);
      setListPrinter(req?.data ?? [])
    } else {
      setShowLoading(false);
      setListPrinter([])
    }
  }

  const handleChoose = async (checked: boolean, printerId: number) => {
    if (checked) {
      setListPrinterChoosed([...listPrinterChoosed, printerId])
    } else {
      setListPrinterChoosed([...listPrinterChoosed.filter(item => item !== printerId)])
    }
  }

  const SubmitAdd = async () => {
    if (listPrinterChoosed.length === 0) {
    } else {
      setShowLoading(true)
      const req = await SettingPrinterApi.AddListPrinterWaitToCatalog(owner_id, catalogId, listPrinterChoosed);
      if (Number(req?.status) === 1) {
        setShowLoading(false)
        handleClose(true)
      } else {
        setShowLoading(false)
      }
    }
  }
  useEffect(() => {
    if (show) {
      setListPrinterChoosed([])
      gListPrinterWaitCatalog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className='modal-add-printer'
    >
      <Modal.Body>
        <h1 className="text-center">{languageUI.choosePrinter}</h1>
        <h4>{languageUI.listPrinter}</h4>
        <div className="mt-2 flex flex-column gap-2 h-300px overflow-y-scroll ">
          {listPrinter.length > 0 && listPrinter.map((item, i) => (
            <Form.Check // prettier-ignore
              key={i}
              type={'checkbox'}
              id={item?.id}
              label={item?.printer_name}
              checked={listPrinterChoosed.includes(Number(item?.id))}
              onClick={(e: any) => handleChoose(e.target.checked, Number(item?.id))}
            />
          ))}
        </div>
        <div className="mt-2 me-3 flex justify-content-end">
          <div className="text-white font-bold h-40px px-5 bg-primary w-fit d-flex justify-content-center align-items-center rounded-3" onClick={SubmitAdd}>{languageUI.submit}</div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ModalChoosePrinter