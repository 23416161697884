import { Form, Modal } from "react-bootstrap";
import "../Styles.css"
import { useEffect, useState } from "react";
import { leaf1, leaf2 } from "../../../../../components/ImgExport";
import { getLanguageUI } from "../../../../../Services/languageUI";

interface ModalCancelSubscriptionProps {
    show: boolean;
    handleClose: () => void;
    handleRemoveSubscription: () => void;
}

const ModalCancelSubscription = ({
    show,
    handleClose,
    handleRemoveSubscription,
}: ModalCancelSubscriptionProps) => {
    const languageUI = getLanguageUI().subscriptionPage
    const [listChecked, setListChecked] = useState<number[]>([])

    useEffect(() => {
        if (!show) {
            setListChecked([])
        }
    }, [show])

    const handleInputChange = async (checked: boolean, value: number) => {
        if (checked) {
            setListChecked([...listChecked, value]);
        } else {
            setListChecked(listChecked.filter(item => item !== value))
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="modal-delete-customer ">
                <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
                    <Modal.Body>
                        <div className="px-48px pb-4">
                            <p className="text-delete-modal-customer mb-0 text-center">{languageUI.areYouSureCancel}</p>
                            <p className="text-add-table-name mb-1 text-center mt-2">{languageUI.heyYouWhen}</p>
                            <div className="mt-6">
                                <Form className="d-flex flex-column gap-3">
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        id='check-1'
                                        label={languageUI.customerData}
                                        className="d-flex align-items-center text-lable-reason-cancel p-0"
                                        checked={listChecked.some(item => item === 1)}
                                        onChange={(e) => { handleInputChange(e.target.checked, 1) }}
                                    />
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        id='check-2'
                                        label={languageUI.customerContact}
                                        className="d-flex align-items-center text-lable-reason-cancel p-0"
                                        checked={listChecked.some(item => item === 2)}
                                        onChange={(e) => { handleInputChange(e.target.checked, 2) }}
                                    />
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        id='check-3'
                                        label={languageUI.theAbilityToDo}
                                        className="d-flex align-items-center text-lable-reason-cancel p-0"
                                        checked={listChecked.some(item => item === 3)}
                                        onChange={(e) => { handleInputChange(e.target.checked, 3) }}
                                    />
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        id='check-4'
                                        label={languageUI.increaseGoogleReview}
                                        className="d-flex align-items-center text-lable-reason-cancel p-0"
                                        checked={listChecked.some(item => item === 4)}
                                        onChange={(e) => { handleInputChange(e.target.checked, 4) }}
                                    />
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        id='check-5'
                                        label={languageUI.getBackLostCustomer}
                                        className="d-flex align-items-center text-lable-reason-cancel p-0"
                                        checked={listChecked.some(item => item === 5)}
                                        onChange={(e) => { handleInputChange(e.target.checked, 5) }}
                                    />
                                </Form>
                            </div>
                            <div className="d-flex justify-content-between w-100 mt-6">
                                <button className="btn-import-inventory-footer " onClick={handleClose}>{languageUI.back}</button>
                                <button className={`btn-import-inventory-footer  ${listChecked.length !== 5 ? "bg-C0C0CF" : "text-white bg-F24343"}`} disabled={listChecked.length !== 5} onClick={handleRemoveSubscription}>{languageUI.remove}</button>
                            </div>
                        </div>
                        <img src={leaf1} alt="" className="position-leaf1-voucher" />
                        <img src={leaf2} alt="" className="position-leaf2-voucher" />
                    </Modal.Body>
                </Modal.Dialog>

            </Modal>
        </>

    );
};

export default ModalCancelSubscription;
