import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Table {
  id?: string,
  table_name?: string,
  sort?: string,
  owner_id?: string,
  status?: string,
  status_name?: string,
  floor_id?: string,
  floor_name?: string,
  size?: string,
  is_cash?: boolean,
  is_visa?: boolean,
  tx?: number,
  ty?: number,
  scale?: number,
  rorate?: number,
  shape?: string
}

const initialTableState: Table[] = [];

const tableSlice = createSlice({
  name: "table",
  initialState: initialTableState,
  reducers: {
    setTable: (state, action: PayloadAction<Table[]>) => action.payload,

    clearTable: (state) => initialTableState,
  },
});

export const selectorTable = (state: { table: Table[] }) =>
  state.table;

export const {
  setTable,
  clearTable
} = tableSlice.actions;
export default tableSlice.reducer;
