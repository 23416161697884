import { useCallback, useEffect, useState } from "react";
import "./Styles.css";
import { plusWhite } from "../../../../components/ImgExport";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentTableVoucher from "./component/ContentTableVoucher";
import ModalCreateVoucher from "./component/ModalCreateVoucher";
import * as voucherApi from "../../../../api/apiOwner/voucherApi";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../components/ModalLoading";
import { getLanguageUI } from "../../../../Services/languageUI";

const VoucherOwner = () => {
  const languageUI = getLanguageUI().voucherPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [showModalCreateVoucher, setShowModalCreateVoucher] = useState(false);
  const [voucherList, setVoucherList] = useState<voucherApi.VoucherList[]>([]);
  const [voucherSameCode, setVoucherSamcode] = useState<
    voucherApi.voucherSameCode[]
  >([]);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const dissmisModalCreateVoucher = () => {
    setShowModalCreateVoucher(false);
  };
  const gListVoucher = useCallback(async () => {
    if (UserIdStore) {
      const req = await voucherApi.gListVoucherByOwner(UserIdStore);
      if (Number(req?.status) === 1) {
        setVoucherList(req?.data.listVoucher);
        setVoucherSamcode(req?.data.listVoucherSameCode);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  }, []);

  const HandleCreatSuccess = async () => {
    gListVoucher();
    setShowModalCreateVoucher(false);
  };
  useEffect(() => {
    setShowLoading(true);
    gListVoucher();
  }, [gListVoucher]);

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="px-3 pt-90px  h-100vh pb-5  w-100">
        <div className="bg-white  h-100 w-100 rounded-18px box-shadow-inventory ">
          <div className="d-flex justify-content-between align-items-center py-7 px-6">
            <p className="mb-0 text-inventory">{languageUI.voucher}</p>
            <div
              className="btn-create-voucher"
              onClick={() => setShowModalCreateVoucher(true)}
            >
              <img src={plusWhite} alt="" />
              {languageUI.createVouvherProgram}
            </div>
          </div>
          <div>
            <TableContainer className="border-top">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-12B064 text-4 fw-600 font-urbansist w-8">
                      {languageUI.noNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-10"
                    >
                      {languageUI.enable}
                    </TableCell>
                    {/* <TableCell align="left" className="text-12B064 text-4 fw-600 font-urbansist w-10">Voucher ID</TableCell> */}
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-14"
                    >
                      {languageUI.createAt}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-14"
                    >
                      {languageUI.expiryDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-19"
                    >
                      {languageUI.voucherName}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist w-10"
                    >
                      {languageUI.codeName}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="text-12B064 text-4 fw-600 font-urbansist w-10"
                    >
                      {languageUI.discountValue}
                    </TableCell>
                    <TableCell
                      align="right"
                      className="text-12B064 text-4 fw-600 font-urbansist w-15"
                    >
                      {languageUI.action}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="body-table-voucher">
                  {voucherList &&
                    voucherList.map((item, i) => (
                      <ContentTableVoucher
                        createAt={item?.createat}
                        discountValue={
                          item?.type_name === ""
                            ? "$" + item?.discount_value
                            : item?.discount_value + item?.type_name
                        }
                        numerical={i + 1}
                        voucherID={item?.id}
                        voucherCode={`#${item?.id}`}
                        voucherName={item?.voucher_name}
                        enable={Number(item?.enable)}
                        expiryDate={item?.expry_date}
                        key={`voucher${i}`}
                        setShowLoading={setShowLoading}
                        HandleEditSuccess={HandleCreatSuccess}
                        codeName={item.voucher_code}
                      />
                    ))}
                </TableBody>
              </Table>
              <div className="p-4">
                <p className="mb-0 text-inventory ">
                  {languageUI.voucherHaveSameCodeName}
                </p>
              </div>
              <Table>
                <TableBody className="body-table-voucher">
                  {voucherSameCode &&
                    voucherSameCode.map((item, i) => (
                      <ContentTableVoucher
                        createAt={item?.createat}
                        discountValue={
                          item?.type_name === ""
                            ? "$" + item?.discount_value
                            : item?.discount_value + item?.type_name
                        }
                        numerical={i + 1}
                        voucherID={item?.id}
                        voucherCode={`#${item?.id}`}
                        voucherName={item?.voucher_name}
                        enable={Number(item?.enable)}
                        expiryDate={item?.expry_date}
                        key={`voucher${i}`}
                        setShowLoading={setShowLoading}
                        HandleEditSuccess={HandleCreatSuccess}
                        codeName={item.voucher_code}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <ModalCreateVoucher
        handleClose={dissmisModalCreateVoucher}
        show={showModalCreateVoucher}
        HandleCreatSuccess={HandleCreatSuccess}
        setShowLoading={setShowLoading}
        UserIdStore={UserIdStore}
      />
    </>
  );
};

export default VoucherOwner;
