import { URL_SOCKET } from "./api/Request";

export const BASE_URL_SOCKET = URL_SOCKET;

export default {
    process: {
        env: {
            BASE_URL_SOCKET,
        }
    }
}