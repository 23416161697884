import * as Request from "../Request";

export interface Result<T> {
    status: string,
    message: string,
    data: T,
}
export interface ListImg {
    id: string,
    filename: string
}
export interface Feedback {
    id: string,
    owner_id: string,
    content: string,
    timestamp: string,
    listImg: ListImg[]
}

export const gHistoryFeedBack = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gHistoryFeedBack.php`, "POST", { owner_id })
        .then((res: Result<Feedback[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: [],
            };
        });
};

export const sendFeedBack = async (owner_id: number, content: string, filesUpload: string[] = []) => {
    return await Request.fetchAPI(`owner/send_feedback_owner.php`, "POST", { owner_id, content, filesUpload: JSON.stringify(filesUpload) })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: [],
            };
        });
};