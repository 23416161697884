import { io } from 'socket.io-client';
import { BASE_URL_SOCKET } from '../env'

const socket = io(BASE_URL_SOCKET);

export const initSocket = () => {
    // socket.on('disconnect', () => {
    //     console.log('disconnect socket');

    // })

    // socket.on('connect', () => {
    //     console.log('connect socket')

    // })

    // socket.on('reconnect', () => {
    //     console.log('reconnect socket');


    // })
}

export default socket;