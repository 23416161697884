import { KeyboardArrowRightIcon } from "../../../../../../../components/Image";
import Form from 'react-bootstrap/Form';
import './Styles.css'
import { useNavigate } from "react-router-dom";
import ModalActiveEmployee from "../../Modal/ModalActiveEmployee";
import { useState } from "react";
import { EditActiveStaff } from "../../../../../../../api/apiOwner/StaffApi";
interface ContentActiveEmployeeProps {
    no: number;
    employee: string;
    position: string;
    dateJoined: string;
    workingTime: string;
    active: boolean;
    staff_id: string;
    HandleActiveSuccess: () => void
    status: boolean,
    tabOptionEmployee: number

}

const ContentActiveEmployee = ({
    workingTime,
    no,
    employee,
    position,
    dateJoined,
    active = true,
    staff_id = '',
    status = true,
    tabOptionEmployee,
    HandleActiveSuccess
}: ContentActiveEmployeeProps) => {
    const navigate = useNavigate();
    const [showModalActive, setShowModalActive] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [message, setMessage] = useState<string>('')

    const ActiveHanle = async () => {
        var active = status ? 0 : 1;
        const req = await EditActiveStaff(staff_id, active);
        if (Number(req?.status) === 1) {
            setShowModalActive(false)
            HandleActiveSuccess()
        } else {
            setMessage(req?.message ?? 'Active fail')
        }

    }
    return (
        <>

            <tr className="d-flex align-items-center mb-2 w-100">
                <td className=" w-10 ps-2 border-bottom-none  d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">{no}</td>
                <td className=" w-20 border-bottom-none d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">{employee}</td>
                <td className="border-bottom-none w-20 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
                    {position}
                </td>
                <td className=" border-bottom-none w-20 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">{dateJoined}
                </td>
                <td className=" border-bottom-none w-20 d-flex align-items-center text-4 fw-600 text-228BE6 font-urbansist">{workingTime}</td>
                <td className="border-bottom-none w-10 d-flex align-items-center justify-between text-4 fw-600 text-666687 font-urbansist">
                    <Form>
                        <Form.Check // prettier-ignore
                            defaultChecked={active}
                            type="switch"
                            id="custom-switch"
                            checked={active ? true : false}
                            className="custom-input-size custom-color-switch"
                            onChange={() => setShowModalActive(true)}
                        />

                    </Form>
                    <div onClick={() => navigate("/web-owner/editstaffs", { state: { staff_id, tabOptionEmployee, tabSelect: 0 } })}>
                        <KeyboardArrowRightIcon className="text-12B064" />
                    </div>
                </td>
            </tr>
            <ModalActiveEmployee onHide={() => setShowModalActive(false)} status={status} show={showModalActive} namePloyee={employee} ActiveHanle={ActiveHanle} />
        </>
    )
}
export default ContentActiveEmployee