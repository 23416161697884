import React, { useCallback, useEffect, useState } from "react";
import "./Styles.css";
import { useSelector } from "react-redux";
import Select2 from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as userSlice from "../../store/slices/userSlice";
import { getLanguageUI } from "../../Services/languageUI";
import {
  gListOrderPickupWait,
  InfoOrder,
  OrderPickupWait,
} from "../../api/apiCheckin/orderConfilmApi";
import {
  gListNamePrinterElse,
  gNamePrinterByCatalog,
  ListPrinterByCatalog,
} from "../../api/apiOwner/SettingPrinterApi";
import ModalLoading from "../../components/ModalLoading";
import ContentTableOrder from "./components/OwnerOrderPage/Component/ContentTableCustomer";
import { arrowLeftPage, arrowRightPage } from "../../components/ImgExport";
import {
  gHistoryOrderByStaff,
  HistoreOrderStaff,
} from "../../api/apiOwner/customerApi";
import ModalPayBillTablePOS from "./components/ModalPayBillTablePOS/ModalPayBillTablePOS";
import { useNavigate } from "react-router-dom";

interface CustomerSelected {
  customer_id: string;
  phone: string;
  name: string;
}

const PickupPage: React.FC = () => {
  const languageUI = getLanguageUI().orderPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [pageGoTo, setPageGoTo] = useState<number>();
  const [limitRow, setLimitRow] = useState<number>(50);
  const [listOrder, setListOrder] = useState<OrderPickupWait[]>([]);
  const [orderPayInfo, setOrderPayInfo] = useState<OrderPickupWait[]>([]);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalPayBillTable, setShowModalPayBillTable] = useState(false);
  const navigate = useNavigate();
  const [listPrinterName, setListPrinterName] = useState<
    ListPrinterByCatalog[]
  >([]);

  const dismisModalPayBillTable = () => {
    setShowModalPayBillTable(false);
  };
  const optionPage = [
    {
      value: 50,
      page: `${languageUI.show} 50 / ${languageUI.page}`,
    },
    {
      value: 100,
      page: `${languageUI.show} 100 / ${languageUI.page}`,
    },
    {
      value: 0,
      page: "Show all",
    },
  ];

  const gListAllOrder = useCallback(
    async (page: number, limit: number, order_id?: number) => {
      if (UserIdStore) {
        setShowLoading(true);
        const reqCus = await gListOrderPickupWait(UserIdStore, page, limit);
        if (Number(reqCus?.status) === 1) {
          setListOrder(reqCus?.data?.listOrder ?? []);
          setTotalPage(
            reqCus?.data?.totalPage ? Number(reqCus?.data?.totalPage) : 1
          );
          if (order_id) {
            setOrderPayInfo(
              reqCus?.data?.listOrder
                ? reqCus?.data?.listOrder.filter(
                  (item) => Number(item?.order_id) === Number(order_id)
                )
                : []
            );
          }

          setShowLoading(false);
        } else {
          setShowLoading(false);
        }
      }
    },
    []
  );
  const gListPrinter = useCallback(async () => {
    if (UserIdStore) {
      const req = await gNamePrinterByCatalog(UserIdStore, 1, pos_id);
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  }, []);

  useEffect(() => {
    setPageGoTo(pageCurrent);
    gListAllOrder(pageCurrent, limitRow);
  }, [pageCurrent, limitRow]);

  const handleChangeShowRow = (value: number) => {
    setLimitRow(value);
    setPageCurrent(1);
  };
  const pagingRender = () => {
    if (totalPage <= 5) {
      let pagelist = [];
      for (let index = 1; index <= totalPage; index++) {
        pagelist.push(
          <button
            key={"page" + index}
            className={` ${pageCurrent === index
              ? "btn-page-customer-receipts-target"
              : "btn-page-customer-receipts"
              }`}
            onClick={() => {
              setPageCurrent(index);
            }}
          >
            {index}
          </button>
        );
      }
      return pagelist;
    } else {
      let pagelist = [];
      if (pageCurrent <= 4) {
        for (let index = 1; index <= 3; index++) {
          pagelist.push(
            <button
              key={"page" + index}
              className={` ${pageCurrent === index
                ? "btn-page-customer-receipts-target"
                : "btn-page-customer-receipts"
                }`}
              onClick={() => {
                setPageCurrent(index);
              }}
            >
              {index}
            </button>
          );
        }
        pagelist.push(
          <button key={"page..."} className={`btn-page-customer-receipts`}>
            ...
          </button>
        );
        pagelist.push(
          <button
            key={"page" + totalPage}
            className={` ${pageCurrent === totalPage
              ? "btn-page-customer-receipts-target"
              : "btn-page-customer-receipts"
              }`}
            onClick={() => {
              setPageCurrent(totalPage);
            }}
          >
            {totalPage}
          </button>
        );
      } else {
        //p=5
        var dem = 0;
        for (
          let index = pageCurrent - 3;
          index <=
          (totalPage - pageCurrent > 4 ? pageCurrent + 3 : totalPage - 1);
          index++
        ) {
          if (dem === 0) {
            pagelist.push(
              <button
                key={"page1"}
                className={` ${pageCurrent === 1
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
                  }`}
                onClick={() => {
                  setPageCurrent(1);
                }}
              >
                {1}
              </button>
            );
            pagelist.push(
              <button key={"page...2"} className={`btn-page-customer-receipts`}>
                ...
              </button>
            );
            dem++;
          }
          pagelist.push(
            <button
              key={"page" + index}
              className={` ${pageCurrent === index
                ? "btn-page-customer-receipts-target"
                : "btn-page-customer-receipts"
                }`}
              onClick={() => {
                setPageCurrent(index);
              }}
            >
              {index}
            </button>
          );
        }
        if (totalPage - pageCurrent > 4) {
          pagelist.push(
            <button key={"page...3"} className={`btn-page-customer-receipts`}>
              ...
            </button>
          );
          pagelist.push(
            <button
              key={"page" + totalPage}
              className={` ${pageCurrent === totalPage
                ? "btn-page-customer-receipts-target"
                : "btn-page-customer-receipts"
                }`}
              onClick={() => {
                setPageCurrent(totalPage);
              }}
            >
              {totalPage}
            </button>
          );
        } else {
          pagelist.push(
            <button
              key={"page" + totalPage}
              className={` ${pageCurrent === totalPage
                ? "btn-page-customer-receipts-target"
                : "btn-page-customer-receipts"
                }`}
              onClick={() => {
                setPageCurrent(totalPage);
              }}
            >
              {totalPage}
            </button>
          );
        }
      }

      return pagelist;
    }
    // return pagelist;
  };

  const handleSuccess = (
    tableOL: any = [],
    offModal: boolean = false,
    order_id?: number
  ) => {

    // if (offModal) {
    setShowModalPayBillTable(false);
    // }

    gListAllOrder(pageCurrent, limitRow, order_id);
  };

  const payBill = (items: OrderPickupWait) => {
    setOrderPayInfo([{ ...items }]);
    setShowModalPayBillTable(true);
  };
  const chooseBillHandle = (guest: number, order_id: string = "") => {
    if (order_id !== "") {
      navigate("/web-owner/posmenu", {
        state: { order_id, guest, isPickup: true },
      });
    } else {
      navigate("/web-owner/posmenu", {
        state: { guest, isPickup: true },
      });
    }
  };
  useEffect(() => {
    gListPrinter();
  }, [gListPrinter]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="w-100 h-100vh d-flex justify-content-center align-items-center">
        <div className="bg-white radius-18px table-order-owner w-95 mt-5">
          <table className="table">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-14 text-center text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.timeOrder}
                </th>
                <th
                  scope="col"
                  className="w-7 text-center text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.STT}
                </th>
                <th
                  scope="col"
                  className="w-14 text-center text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.order}
                </th>
                <th
                  scope="col"
                  className="w-14 text-center text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.totalItems}
                </th>
                <th scope="col" className="w-15 text-center">
                  <span className="text-18px text-center text-12B064 fw-600 font-urbansist mr-3">
                    {languageUI.phone}
                  </span>
                </th>
                <th scope="col" className="w-15 text-center">
                  <span className="text-18px text-center text-12B064 fw-600 font-urbansist mr-3">
                    {languageUI.customer}
                  </span>
                </th>
                <th
                  scope="col"
                  className="w-14 text-center text-18px text-12B064 fw-600 font-urbansist"
                >
                  {languageUI.invoiceMethod}
                </th>
                <th
                  scope="col"
                  className="w-7 text-center text-18px text-12B064 fw-600 font-urbansist"
                ></th>
              </tr>
            </thead>
            <tbody className="h-body-table-order">
              {listOrder.map((items, i) => (
                <tr className="d-flex align-items-center mb-2 h-fit" key={i}>
                  <td className="border-bottom-none  text-center text-17px text-4A4A6A w-14">
                    {items?.time_order}
                  </td>
                  <td className="border-bottom-none  text-center text-17px text-4A4A6A w-7">
                    {i + 1}
                  </td>
                  <td className="border-bottom-none  text-center text-17px text-269AFF w-14">
                    #{items?.order_id}
                  </td>
                  <td className="border-bottom-none  text-center text-17px text-269AFF w-14">
                    {items?.listFood.length}
                  </td>
                  <td className="border-bottom-none  text-center text-18px text-4A4A6A w-15">
                    {items?.customer_phone}
                  </td>
                  <td className="border-bottom-none  text-center text-18px text-4A4A6A w-15">
                    {items?.name}
                  </td>
                  <td className="border-bottom-none  text-center text-18px text-4A4A6A w-14">
                    {languageUI.pickup}
                  </td>
                  <td className="border-bottom-none  text-center text-18px text-4A4A6A w-7">
                    <button
                      className="h-40px rounded-3 text-14px border-none bg-primary font-bold text-white px-4"
                      onClick={() => payBill(items)}
                    >
                      {languageUI.pay}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="w-100 h-66px d-flex justify-content-center align-items-center border-top">
            <div className="d-flex h-fit d-flex  align-items-center">
              <p className="mb-0 h-fit text-page-table-bottom">
                {languageUI.total} {totalPage} {languageUI.pages}
              </p>
              <button
                className="btn-page-customer-receipts"
                onClick={() => {
                  pageCurrent > 1
                    ? setPageCurrent(pageCurrent - 1)
                    : setPageCurrent(1);
                }}
              >
                <img src={arrowLeftPage} alt="" />
              </button>
              {pagingRender()}
              <button
                className="btn-page-customer-receipts"
                onClick={() => {
                  pageCurrent !== totalPage
                    ? setPageCurrent(pageCurrent + 1)
                    : setPageCurrent(totalPage);
                }}
              >
                <img src={arrowRightPage} alt="" />
              </button>
              <div className="d-flex align-items-center border  rounded-8px h-42px mx-4">
                {/* <Select
                                menuPlacement="top"
                                className="react-select react-select-container w-150px  border-select-none "
                                classNamePrefix="react-select"
                                defaultValue={[""]}
                                placeholder="Show 50 / page"
                                isClearable={true}
                                options={optionPage.map((item) => {
                                    return {
                                        value: item.value,
                                        label: item.page,
                                    };
                                })}
                                onChange={(e) => { handleChangeShowRow(e?.value) }}
                            /> */}
                <Select2
                  className="h-42px p-0"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-5"
                  placeholder="Choose voucher ID"
                  value={limitRow}
                  onChange={(e: any) =>
                    handleChangeShowRow(Number(e.target.value))
                  }
                >
                  {optionPage.map((item, i) => (
                    <MenuItem key={i} value={item?.value}>
                      {" "}
                      {item.page}
                    </MenuItem>
                  ))}
                </Select2>
              </div>
              <p className="mb-0 text-page-table-bottom me-2">
                {languageUI.goto}
              </p>
              <div className="input-text-page">
                <input
                  type="number"
                  className="w-100 text-page-table-bottom border-none px-2"
                  readOnly={totalPage === 1}
                  value={pageGoTo}
                  onChange={(e) => {
                    if (e.target.value && Number(e.target.value) > 0) {
                      setPageGoTo(Number(e.target.value));
                    } else {
                      setPageGoTo(undefined);
                    }
                  }}
                  onBlur={(e) => {
                    setPageCurrent(
                      Number(e.target.value) <= 0
                        ? 1
                        : Number(e.target.value) > totalPage
                          ? totalPage
                          : Number(e.target.value)
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPayBillTablePOS
        listPrinterName={listPrinterName}
        handleSuccess={handleSuccess}
        handleClose={dismisModalPayBillTable}
        show={showModalPayBillTable}
        OrderWait={orderPayInfo}
        table_name={"pickup"}
        table_id={"0"}
        listTable={[]}
        gListFloorByOwner={() => { }}
        chooseBillHandle={chooseBillHandle}
        handleMoveBillSuccess={() => { }}
        floor_id={""}
        isPickup={true}
      />
    </>
  );
};
export default PickupPage;
