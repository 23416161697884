
import { Button, Modal, Form } from "react-bootstrap";
import "./Styles.css"
import { useEffect, useState } from "react";
import * as paymentApi from "../../../../api/apiCheckin/paymentApi";
import React from "react";

interface ModalChooseNameTipProps {
    show: boolean;
    staffId?: string;
    setStaffId?: (id: string) => void;
    listStaff?: paymentApi.TipStaff[];
    handleClose: () => void;
}

const ModalChooseNameTip = ({
    show,
    staffId = "",
    listStaff = [],
    setStaffId,
    handleClose,

}: ModalChooseNameTipProps) => {
    const [showModalConfirmChooseNameTip, setShowModalConfirmChooseNameTip] = useState(false)
    const [NameSelected, setNameSelected] = useState<string>()
    const [staffSelected, setStaffSelected] = useState<string>('')
    const dismisModalConfirmChooseNameTip = () => {
        setShowModalConfirmChooseNameTip(false);
    }
    function handleConfirm(value: string) {
        setNameSelected(listStaff.filter(item => item.id == value)[0]?.staff_name ?? '')
        setShowModalConfirmChooseNameTip(true)
    }

    const cancelModalConfirmChooseNameTip = () => {
        setNameSelected("")
        setStaffSelected(staffId);
        setShowModalConfirmChooseNameTip(false)
    }
    const ConfirmModalConfirmChooseNameTip = () => {
        setStaffId?.(staffSelected);
        setShowModalConfirmChooseNameTip(false)
        handleClose()
    }
    useEffect(() => {
        if (show) {
            setStaffSelected(staffId);
        }
    }, [show])
    return (
        <>
            <Modal className="modal-add-to-card" show={show} onHide={handleClose} centered>
                <div className="w-100 pt-10 px-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                        <Button className="opacity-0" variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                        <p className="text-xl font-bold m-0">Employee</p>
                        <Button className="" variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
                <Modal.Body>
                    <p className="font-bold mb-0">Choose employee name:</p>
                    <Form.Select aria-label="Default select example" value={staffSelected} onChange={(e: any) => { setStaffSelected(e.target.value); handleConfirm(e.target.value); }}>
                        <option value={0}>choose staff to receive tips</option>
                        {listStaff.map((items, i) => (
                            <option value={items?.id} key={items.id}>{items.staff_name}</option>
                        ))}
                    </Form.Select>
                </Modal.Body>
            </Modal>
            <Modal className="modal-add-to-card" show={showModalConfirmChooseNameTip} onHide={dismisModalConfirmChooseNameTip} centered>

                <Modal.Body>
                    <p className="font-bold mb-0 text-center">{`${NameSelected == "" ? "Confirm to uncheck staff to receive tips?" : "We just want to make sure the Tip goes to the right person."}`}</p>
                    {NameSelected != "" && (
                        <div className="w-100 d-flex justify-content-center mt-2">
                            <button className="h-40px bg-primary border-none rounded-5 px-3 text-white font-bold" disabled={true}>{NameSelected}</button>
                        </div>
                    )}

                    <div className="w-100 pt-10 px-2">
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <Button className="" variant="danger" onClick={cancelModalConfirmChooseNameTip}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={() => { ConfirmModalConfirmChooseNameTip(); }}>
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    );
};

export default ModalChooseNameTip;
