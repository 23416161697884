import Modal from "react-bootstrap/Modal";
import "./Styles.css";
import { formatCurrencyVND } from "../../../../utils/format";
import { useEffect, useState } from "react";
import { plus } from "../../../../components/ImgExport";
import * as ChooseMenu from "../../../../api/apiCheckin/chooseMenuApi";
import default_product from "../../../../assets/images/default_product.png";
import { getLanguageUI } from "../../../../Services/languageUI";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface ModalAddFoodOrderProps {
  tableID: string;
  show: boolean;
  food_id?: string;
  foodName?: string;
  noteFood?: ChooseMenu.NoteFood[];
  side_id?: string;
  price?: number;
  imageFood?: string;
  maxSide: number;
  typeFood?: number;
  addToCart?: (
    food_id: string,
    foodName: string,
    price: number,
    side_id: string,
    maxSide: number,
    typeFood: number,
    priceSide?: number,
    noteFood_id?: string,
    noteFood_name?: string,
    notechef?: string,
    imageFood?: string,
    statusOrder?: number,
    optionNoteFoodChoose?: ChooseMenu.NoteOptionChoose[]
  ) => void;
  handleClose: () => void;
  listOptionNote: ChooseMenu.listNoteOptionFood[];
}
const ModalAddFoodOrder = ({
  show,
  foodName = "",
  noteFood = [],
  side_id = "",
  price = 0,
  imageFood = "",
  food_id = "",
  addToCart,
  maxSide,
  typeFood = 1,
  handleClose,
  listOptionNote,
  tableID,
}: ModalAddFoodOrderProps) => {
  const languageUI = getLanguageUI().posMenu;
  const [valueNoteFood, setValueNoteFood] = useState<number>(0);
  const [noteFoodName, setNoteFoodName] = useState<string>("");
  const [noteChef, setNoteChef] = useState<string>("");
  const [messError, setMessError] = useState("");
  const [descriptionAddon, setDescriptionAddon] = useState("");
  const [amountAddon, setAmountAddon] = useState<number>();
  const [showAddFoodCustom, setShowAddFoodCustom] = useState<boolean>(false);

  const [activeOptionNoteFood, setActiveOptionNoteFood] = useState<string[]>(
    []
  );
  const [optionNoteFoodChoose, setOptionNoteFoodChoose] = useState<
    ChooseMenu.NoteOptionChoose[]
  >([]);
  const [priceSide, setPriceSide] = useState<number>(0);
  const [changeToGo, setChangeToGo] = useState<boolean>(false);
  const [listOptionNoteFood, setlistOptionNoteFood] = useState<
    ChooseMenu.listNoteOptionFood[]
  >([]);

  const listActiveOptionNoteFood = (
    id: string,
    optionName: string,
    price: string
  ) => {
    let nameOtionPrice = optionName + (Number(price) > 0 ? ": " + formatCurrencyVND(Number(price)) : "");
    if (activeOptionNoteFood.includes(nameOtionPrice)) {
      if (optionName !== "") {
        setActiveOptionNoteFood(
          activeOptionNoteFood.filter((item) => item !== nameOtionPrice)
        );
        setOptionNoteFoodChoose(
          optionNoteFoodChoose.filter((item) => Number(item?.id) !== Number(id))
        );
        setPriceSide(priceSide - Number(price));
      }
    } else {
      if (optionName !== "") {
        setActiveOptionNoteFood([...activeOptionNoteFood, nameOtionPrice]);
        setOptionNoteFoodChoose([
          ...optionNoteFoodChoose,
          { id, price, option_name: optionName },
        ]);
        setPriceSide(priceSide + Number(price));
      }
    }
  };
  function generateNewId(array: ChooseMenu.listNoteOptionFood[]) {
    const existingIds = new Set(array.map((item) => item.id));
    let newId: string;
    do {
      newId = Math.floor(Math.random() * 1000).toString();
    } while (existingIds.has(newId));
    return newId;
  }
  const ApplyAddon = () => {
    if (descriptionAddon === "") {
      setMessError(languageUI.PleaseEnterAddOnDescription);
      return;
    }
    if (amountAddon === undefined) {
      setMessError(languageUI.PleaseEnterAddOnDescription);
      return;
    }
    let item: ChooseMenu.listNoteOptionFood = {
      id: generateNewId(listOptionNoteFood),
      food_id: generateNewId(listOptionNoteFood),
      option_name: descriptionAddon,
      price: amountAddon ? amountAddon.toString() : "0",
    };
    let nameOtionPrice =
      descriptionAddon + (Number(amountAddon) > 0 ? ": " + formatCurrencyVND(amountAddon) : "");
    setlistOptionNoteFood([...listOptionNoteFood, item]);
    setActiveOptionNoteFood([...activeOptionNoteFood, nameOtionPrice]);
    setOptionNoteFoodChoose([...optionNoteFoodChoose, item]);
    setPriceSide(priceSide + Number(amountAddon));
    setShowAddFoodCustom(false);
  };

  const handleAddCart = () => {
    if (noteFood.length > 0 && valueNoteFood == 0) {
      setMessError("Please choose node for this food!");
    } else {
      const note =
        (noteChef !== "" && activeOptionNoteFood.length > 0
          ? noteChef + ", "
          : noteChef) + activeOptionNoteFood.join(", ");
      addToCart?.(
        food_id,
        foodName,
        price,
        side_id,
        maxSide,
        typeFood,
        priceSide,
        valueNoteFood.toString(),
        noteFoodName,
        note,
        imageFood,
        changeToGo ? 2 : 1,
        optionNoteFoodChoose
      );
      handleClose();
    }
  };
  const handleChange = (event: any) => {
    const newText = event.target.value;

    if (newText.length <= 255) {
      setNoteChef(newText);
    }
  };
  useEffect(() => {
    if (show) {
      setlistOptionNoteFood([...listOptionNote]);
      setMessError("");
      setNoteFoodName("");
      setNoteChef("");
      setValueNoteFood(0);
      setPriceSide(0);
      setActiveOptionNoteFood([]);
      setOptionNoteFoodChoose([]);
      if (tableID) {
        setChangeToGo(false);
      } else {
        setChangeToGo(true);
      }
    }
  }, [show, listOptionNote]);
  useEffect(() => {
    setDescriptionAddon("");
    setAmountAddon(undefined);
  }, [showAddFoodCustom]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-add-food-order"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="position-relative">
          <div className="d-flex gap-3">
            <div className="d-flex gap-2">
              <div className="box-img-add-food-owner">
                <img
                  src={imageFood}
                  className="img-fluid w-100 h-100 object-fit-fill rounded-5 mb-1 flex-shrink-0 object-fit-cover"
                  onError={(e) => {
                    e.currentTarget.src = default_product;
                  }}
                  alt=""
                />
              </div>
              <div>
                <p className="name-food-add-to-pos-menu mb-0 mt-2">
                  {foodName}
                </p>
                <div className="d-flex align-items-center gap-2px mt-1">
                  <div className="text-price-modal-food-pos-menu">
                    {formatCurrencyVND(price)}
                  </div>
                  {/* <div className="text-modal-dollar-pos-menu mb-2">$</div> */}
                </div>
              </div>
            </div>
            <div
              className={`  ${tableID !== "" ? "" : "d-none"} ${changeToGo ? "btn-togo-add-food-2" : "btn-togo-add-food"
                }`}
              onClick={() => {
                setChangeToGo(!changeToGo);
              }}
            >
              {languageUI.togo}
            </div>
          </div>

          <div className="d-flex gap-2 flex-wrap mt-2">
            {noteFood.map((items, i) => (
              <div
                key={i}
                className={`rounded-4  d-flex align-items-center bg-white text-green px-2 py-1 ${valueNoteFood === Number(items?.notefood_id)
                  ? "bg-primary text-white  border-1px-primary"
                  : "border"
                  }`}
                onClick={() => {
                  setValueNoteFood(Number(items?.notefood_id));
                  setNoteFoodName(items?.notefood_name ?? "");
                }}
              >
                <p
                  className={`m-0 text-choose-status-order-modal ${valueNoteFood === Number(items?.notefood_id)
                    ? "text-white"
                    : ""
                    }`}
                >
                  {items?.notefood_name}
                </p>
              </div>
            ))}
          </div>
          {listOptionNoteFood.length > 0 ? (
            <div className="mt-2">
              <p className="name-food-add-to-pos-menu mb-0 ">
                {languageUI.noteFood}
              </p>
              <div className="d-flex flex-wrap gap-2 mt-2">
                {listOptionNoteFood.map((item, index) => (
                  <div
                    className={` w-fit name-food-add-to-pos-menu px-3 py-1 text-14px   rounded-4 ${activeOptionNoteFood.includes(
                      item.option_name +
                      (Number(item.price) > 0 ? ": " + formatCurrencyVND(Number(item.price)) : "")
                    )
                      ? "text-white bg-FF7B2C border-FF7B2C"
                      : "text-4a4a6a border"
                      }`}
                    key={`list-option-note-${item.id}`}
                    onClick={() =>
                      listActiveOptionNoteFood(
                        item?.id ?? "",
                        item.option_name ?? "",
                        item.price ?? ""
                      )
                    }
                  >
                    {item.option_name}
                    {Number(item.price) > 0 ? ": " + formatCurrencyVND(Number(item.price)) : ""}
                  </div>
                ))}
                <div
                  className={` w-fit name-food-add-to-pos-menu px-3 py-1 text-14px   rounded-4 
                     
                        text-4a4a6a border
                    `}
                  onClick={() => setShowAddFoodCustom(true)}
                >
                  {languageUI.customAddOns}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="mt-2">
            <textarea
              className="text-more-request"
              placeholder={languageUI.noteForKitchen}
              id="textarea"
              value={noteChef}
              onChange={handleChange}
              maxLength={255}
            ></textarea>
            <div className="w-100 d-flex justify-content-end px-3">
              <small className="text-muted text-right">
                {noteChef.length}/{255}
              </small>
            </div>
          </div>
          <div className="btn-add-card-modal my-3" onClick={handleAddCart}>
            <img src={plus} alt="" />
            {languageUI.addToCard}
          </div>
          {messError != "" && (
            <div className="text-italics text-danger text-center">
              <i className="fas fa-exclamation-triangle"></i> {messError}
            </div>
          )}
          {showAddFoodCustom && (
            <div className={`add-custom-food `}>
              <div className="w-100 d-flex justify-content-end">
                <button
                  className="border-none bg-transparent p-0"
                  onClick={() => setShowAddFoodCustom(false)}
                >
                  <CloseRoundedIcon
                    className="text-999999"
                    style={{ color: "#999999" }}
                  />
                </button>
              </div>
              <p className="mb-1 name-food-add-to-pos-menu ">
                {languageUI.Description}
              </p>
              <input
                type="text"
                className="w-100 h-30px rounded-3 border-999999 px-3"
                placeholder={languageUI.PleaseEnterAddOnDescription}
                value={descriptionAddon}
                onChange={(e) => setDescriptionAddon(e.target.value)}
              />
              <p className="name-food-add-to-pos-menu mt-2 mb-1">
                {languageUI.EnterAmount}
              </p>
              <input
                inputMode="numeric"
                type="number"
                className="w-100 h-30px rounded-3 border-999999 px-3"
                placeholder={languageUI.PleaseEnterAddOnPrice}
                value={amountAddon}
                onChange={(e: any) => {
                  if (Number(e.target.value) < 0) return;
                  setAmountAddon(e.target.value);
                }}
              />
              <div className="d-flex justify-content-center">
                <button
                  className="border-none bg-primary p-0 font-bold text-white rounded-3 h-40px mt-2 px-4"
                  onClick={ApplyAddon}
                >
                  {languageUI.apply}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalAddFoodOrder;
