import { Accordion, Form, Modal } from "react-bootstrap";
import "./Styles.css";
import OrderListITem from "../../../webcheckin/components/OrderListITem/OrderListITem";
import * as orderConfilmApi from "../../../../api/apiCheckin/orderConfilmApi";
import { useEffect, useState } from "react";
// import * as orderConfilmApi from "../../api/apiCheckin/orderConfilmApi";
import * as paymentApi from "../../../../api/apiCheckin/paymentApi";
import { FormatDolla, parseDolla } from "../../../../utils/format";
import { chevronDown } from "../../../../components/ImgExport";
import * as userSlice from "../../../../store/slices/userSlice";
import ModalSelectEmployeeTip from "../../../webcheckin/components/ModalSelectEmployeeTip/ModalSelectEmployeeTip";
import ModalToast from "../ModalToast/ModalToastErr";
import { useSelector } from "react-redux";
import { getLanguageUI } from "../../../../Services/languageUI";
import OrderListITemPrintPosMenu from "../../../webcheckin/components/OrderListITem/OrderListITemPrintPosMenu";
import printJS from "print-js";
import {
  CancelPaymentIntent,
  CreatePaymentIntent,
  PaymentIntentStripe,
} from "../../../../api/apiOwner/stripeApi";
import ModalPaymentError from "../ModalPaymentError/ModalPaymentError";
import ModalReader from "../ModalReader";
import ModalSplitBill from "../ModalSplitBill/ModalSplitBill";
import AddIcon from "@mui/icons-material/Add";
import ModalLoading from "../../../../components/ModalLoading";
import * as SettingPrinterApi from "../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../api/apiOwner/printerApi";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import { ClickAwayListener, Tooltip } from "@mui/material";
import React from "react";

interface ModalPayBillCashTablePOSProps {
  show: boolean;
  table_name?: string;
  table_id?: string;
  listStaff?: paymentApi.TipStaff[];
  tipList?: paymentApi.Tip[];
  OrderWait?: orderConfilmApi.OrderWait[];
  handleClose: () => void;
  handleSuccess: () => void;
  handlePlitBill?: (type: number) => void;
  showToastPrint?: (mess: string) => void;
  listPrinterName: SettingPrinterApi.ListPrinterByCatalog[];
}

const ModalPayBillCashTablePOS = ({
  show,
  table_name = "",
  table_id = "",
  OrderWait = [],
  listStaff = [],
  tipList = [],
  handleSuccess,
  handleClose,
  handlePlitBill,
  showToastPrint,
  listPrinterName,
}: ModalPayBillCashTablePOSProps) => {
  const languageUI = getLanguageUI().posTable;
  const UserIdStore = useSelector(userSlice.selectorUserId) ?? 0;
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [tax, setTax] = useState<number>(0);
  // const [tipList, setTipList] = useState<paymentApi.Tip[]>([])
  const [tipName, setTipName] = useState<string>(""); //tên % tip
  const [valueTipCustom, setValueTipCustom] = useState<number | string>("");
  const [valueTip, setValueTip] = useState<number>(0); //id tip đang chọn
  const [TipPrice, setTipPrice] = useState<number>(0);
  const [staffName, setStaffName] = useState<string>(""); //tên staff dc chọn nhận tip
  const [showModalChooseNameTip, setShowModalChooseNameTip] = useState(false);
  const [chooseDiscountType, setChooseDiscountType] = useState<number>(1);
  const [staffId, setStaffId] = useState<string>(""); //staff dc chọn nhận tip
  const [choosepaymentMethodCash, setChoosePaymentMethodCash] = useState(true); //id tip đang chọn
  const [listOrderPay, setListOrderPay] = useState<string[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [receivedAmount, setReceivedAmount] = useState<string>(""); //tiền trả
  const [reMainingAmount, setreMainingAmount] = useState<number | string>(""); // tiền thối
  const [messageError, setMessageError] = useState<string>("");
  const [showModalAlertFail, setshowModalAlertFail] = useState(false);
  const [discountOrder, setDiscountOrder] = useState<number>(0);
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [StripeTerminal, setStriperTiminal] = useState<any>();
  const [showModalPaymentErr, setShowModalPaymentErr] =
    useState<boolean>(false);
  const [messageErrPayment, setMessageErrPayment] = useState("");
  const [showModalReader, setShowModalReader] = useState(false);
  const [paymentIntentId, setpaymentIntentId] = useState("");
  const [showModalSplitBill, setShowModalSplitBill] = useState(false);
  const [orderSplitBill, setOrderSplitBill] =
    useState<orderConfilmApi.OrderWait>();
  const [openToolTip, setOpenToolTip] = useState(false);
  const [feeStripe, setFeeStripe] = useState<boolean>(false);
  const [feePrice, setFeePrice] = useState<number>(0);

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const dismisMoalSplitBill = () => {
    setShowModalSplitBill(false);
  };
  const handleSuccessSplitBil = async (id: string) => {
    setOrderSplitBill(undefined);
    dismisMoalSplitBill();
    handlePlitBill?.(1);
    setListOrderPay([...listOrderPay, id]);
    setShowLoading(false);
  };

  const dismisModalReader = () => {
    setShowModalReader(false);
  };

  const dismisModalPaymentErr = () => {
    setShowModalPaymentErr(false);
  };

  const dismisModalChooseNameTip = () => {
    setShowModalChooseNameTip(false);
  };
  const handleChangeAllOrder = (checked: boolean) => {
    if (checked) {
      setListOrderPay(
        OrderWait.map((item) => {
          return item.order_id;
        })
      );
    } else {
      setListOrderPay([]);
    }
  };
  const HandleChangOrder = (
    checked: boolean,
    order_id: string,
    tip_staff_id: string,
    staff_name: string,
    tip_amount: string
  ) => {
    if (checked) {
      setListOrderPay([order_id]);
      if (tip_staff_id && Number(tip_staff_id) > 0) {
        setTipName("");
        setValueTip(0);
        setStaffId(tip_staff_id);
        setStaffName(staff_name);
        setValueTipCustom(Number(tip_amount) > 0 ? Number(tip_amount) : "");
        setTipPrice(Number(tip_amount));
      } else {
        setTipName("");
        setValueTip(0);
        setStaffId("");
        setStaffName("");
        setValueTipCustom("");
        setTipPrice(0);
      }
    } else {
      setTipName("");
      setValueTip(0);
      setListOrderPay([...listOrderPay.filter((item) => item != order_id)]);
      setStaffId("");
      setStaffName("");
      setValueTipCustom("");
      setTipPrice(0);
    }
  };

  //tính lại tổng tiền của hóa đơn
  useEffect(() => {
    var total = 0;
    var discount = 0;
    OrderWait.map((item) => {
      if (listOrderPay.some((ord) => item.order_id == ord)) {
        total += Number(item?.totalPrice);
        discount += Number(item?.discount);
      }
    });
    setDiscountOrder(discount);
    setTotalPrice(total);
  }, [listOrderPay]);
  const gConfig = async () => {
    if (UserIdStore) {
      const config = await paymentApi.getConfig(UserIdStore);
      if (config.status == 1) {
        setTax(Number(config?.data?.tax) ?? 0);
        setFeeStripe(Number(config?.data?.fee_stripe) === 1 ? true : false);
      }
    }
  };
  useEffect(() => {
    if (OrderWait.length === 1) {
      setListOrderPay([...listOrderPay, OrderWait[0]?.order_id]);
      if (
        OrderWait[0]?.tip_staff_id &&
        Number(OrderWait[0]?.tip_staff_id) > 0
      ) {
        setTipName("");
        setValueTip(0);
        setStaffId(OrderWait[0]?.tip_staff_id);
        setStaffName(OrderWait[0]?.staff_name);
        setValueTipCustom(
          Number(OrderWait[0]?.tip_amount) > 0
            ? Number(OrderWait[0]?.tip_amount)
            : ""
        );
        setTipPrice(Number(OrderWait[0]?.tip_amount));
      } else {
        setTipName("");
        setValueTip(0);
        setStaffId("");
        setStaffName("");
        setValueTipCustom("");
        setTipPrice(0);
      }
    }
  }, [OrderWait]);
  function handleChangeInput(tip?: string) {
    if (tip) {
      setValueTipCustom(Number(tip));
      setValueTip(0);
      setTipName("");
      setTipPrice(Number(tip));
    } else {
      setValueTipCustom("");
      setValueTip(0);
      setTipName("");
      setTipPrice(0);
    }
  }
  useEffect(() => {
    if (receivedAmount != "") {
      var total =
        totalPrice +
        feePrice +
        TipPrice +
        (totalPrice * tax) / 100 -
        discountOrder;
      if (Number(receivedAmount) > total) {
        setreMainingAmount((Number(receivedAmount) - total).toFixed(2));
      } else {
        setreMainingAmount("");
      }
    } else {
      setreMainingAmount("");
    }
  }, [receivedAmount]);
  useEffect(() => {
    if (choosepaymentMethodCash === false && feeStripe) {
      setFeePrice(((totalPrice + TipPrice) * 3) / 100);
    } else {
      setFeePrice(0);
    }
  }, [totalPrice, choosepaymentMethodCash]);

  const SubmitPayBill = () => {
    var total =
      totalPrice +
      feePrice +
      TipPrice +
      (totalPrice * tax) / 100 -
      discountOrder;
    if (listOrderPay.length == 0) {
      setMessageError("Please select the invoice to pay!");
      setshowModalAlertFail(true);
    } else if (TipPrice > 0 && staffId == "") {
      setMessageError("Please select staff to receive tips!");
      setshowModalAlertFail(true);
    } else if (choosepaymentMethodCash && staffId != "" && TipPrice <= 0) {
      setMessageError("Tipping must be greater than 0!");
      setshowModalAlertFail(true);
    } else {
      if (choosepaymentMethodCash) {
        if (receivedAmount && Number(receivedAmount) < total) {
          setMessageError(
            "The amount received must be greater than or equal to the total price."
          );
          setshowModalAlertFail(true);
        } else {
          PaymentBillPost(1);
        }
      } else {
        // PaymentBillPost(2)
        PaymentIntentClient();
      }
    }
  };

  const PaymentBillPost = async (type: number) => {
    setShowLoading(true);
    var TotalPay =
      totalPrice +
      feePrice +
      TipPrice +
      (totalPrice * tax) / 100 -
      discountOrder;
    // var tax_price = tax > 0 ? (totalPrice * tax / 100) : 0;
    var tip_amount = TipPrice > 0 ? TipPrice : 0;
    // console.log({ listOrderPay, table_id, ownerID: UserIdStore, TotalPay, tax, staffId, tip_amount, type });
    if (UserIdStore) {
      const payHandle = await paymentApi.PaymentBillPOS(
        UserIdStore,
        table_id,
        TotalPay,
        tax,
        type,
        listOrderPay,
        staffId,
        tip_amount,
        feePrice
      );
      if (payHandle.status == 1) {
        handleSuccess();
        setShowLoading(false);
        PrintCard();
      } else {
        setMessageError(payHandle.message ?? "");
        setshowModalAlertFail(true);
        setShowLoading(false);
      }
    }
  };

  useEffect(() => {
    if (show) {
      gConfig();
      // setListOrderPay([...OrderWait.map(item => { return item?.order_id })]);
    }
  }, [show]);
  function PrintCard2() {
    const printUID = new Date().getTime();
    const config: any = {
      printable: "print-bill-table-pos-card", //id DOM element
      documentTitle: `CardPrint-${printUID}`, //title html document send to iframe
      targetStyles: ["*"], //accepts all the styles
      targetStyle: ["*"], //accepts all the styles
      frameId: "cardprint", //custom frame id
      type: "html", //print type html
      onError: (error: any) => {
        console.error("Error Printing: ", error);
      },
      onPrintDialogClose: () => {
        console.info("Print Window Closed");
      },
    };

    printJS(config);
  }
  const isZInListFood = (
    z: number[],
    listFood: orderConfilmApi.InfoOrder[]
  ) => {
    return z.every((zItem) => listFood.some((item) => item.typeFood === zItem));
  };
  const PrintCard = async () => {
    try {
      const printBillList = OrderWait.filter((item) =>
        listOrderPay.some((val) => Number(val) === Number(item?.order_id))
      );

      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (itempr) => {
          if (
            printBillList.some((itr) =>
              isZInListFood(itempr?.printType, itr.listFood)
            )
          ) {
            const rawhtml =
              `
            <!DOCTYPE html>
            <html lang="en">
  
            <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Hóa đơn</title>
            <style type="text/css">
              .name-food {
                font-weight: 600;
              }
  
              .quanlity-number {
                font-weight: 600;
              }
  
              p, h6, h4 {
                margin-bottom: 0;
                margin-top: 0;
                line-height: normal;
              }
  
              .fw-normal{
                 !important;
              }
            </style>
  
            </head>
  
            <body>
  
            <div id="bill" style="width: 100%; padding-right: 45px;">
              <h1 style="width: 100%; margin:  auto; text-align: center">Bill</h1>
              <div style="width: 100%;">
                <h5 class="" style="; margin: 0; text-align: start;">${Number(table_id) > 0 ? `Table name: ${table_name}` : ""
              }</h5>
              </div>
              
              
              ${printBillList
                .map((item, i) =>
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  itempr?.printType.some((zItem) =>
                    item.listFood.some((iItem) => iItem.typeFood === zItem)
                  )
                    ? `
              <div style="display: table; width: 100% ; padding-top: 10px;  padding-right: 10px;">
                 <div style = "display: table-row; padding-top: 10px; width: 100%" >
  
                  <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                    <h4>- Bill-${item.order_id}</h4>
                  </div>
                  <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                  </div>
                </div >
                </div >
  
             ${item.listFood
                      .map((cart, i) =>
                        itempr?.printType?.some((ty) => ty === cart.typeFood)
                          ? `
                <div style="display: table; width: 100%">
             <div style = "display: table-row; padding-top: 10px; width: 100%" >
  
                  <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                  <h6 class="fw-normal" style=" margin: 0; text-align: start;">${i + 1
                          }.${cart?.name}</h6>
                    ${cart?.note
                            ? `<p class="fw-normal" style="font-size: 24px ; "  >-${cart?.note}</p>`
                            : ""
                          }
                      
                    ${cart?.notefood_name
                            ? `<p class="fw-normal"  style="width: 100% ;font-size: 24px ; ; white-space: nowrap">-${cart?.notefood_name}</p>`
                            : ""
                          }
                  </div>
                  <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                  <h6 class="fw-normal quanlity-number; margin-top: 10px !important ">${Number(
                            cart?.quantity
                          )}x${Number(cart?.price)}$</h6>
                    ${Number(cart?.form_order) !== 1
                            ? `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">Take away</h6>`
                            : `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">Diner</h6>`
                          }
                  </div>
                </div >
                </div >
  
                `
                          : ``
                      )
                      .join(" ")}
             ${item.listSide?.length
                      ? item?.listSide.length > 0 &&
                      `
              <h6 style=" margin: 0; text-align: start; font-size: 24px " ><li>Free Side</li></h6>
              ${item?.listSide
                        .map(
                          (item, index) =>
                            `
              <h6 style=" margin: 0; text-align: start; font-size: 24px " >${index + 1
                            } ${item.name} (x${item.quantity})</h6>
              `
                        )
                        .join(" ")}
            `
                      : ""
                    }
            `
                    : ``
                )
                .join(" ")}
             <div style="display: table; width: 100%;  padding-right: 40px;">
          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
              <p class="fw-normal" style="font-size: 24px ; " >Sub Total:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
            <p class="fw-normal" style="font-size: 24px ; " >$${FormatDolla(
                  totalPrice
                )}</p>
              </div>
            </div>
            </div>
             <div style="display: table; width: 100%">
  
            <div style = "display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
              <p class="fw-normal " style="font-size: 24px ; ">Tip:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
              <p class="fw-normal " style="font-size: 24px ; ">$${TipPrice > 0 ? FormatDolla(TipPrice) : 0
              }</p>
            </div>
          </div>
          </div>
            <div style="display: table; width: 100%">
  
            <div style = "display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
              <p class="fw-normal" style="font-size: 24px ; ">Tip for:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
              <p class="fw-normal" style="font-size: 24px ; ">${staffName !== "" ? staffName : "---"
              }</p>
            </div>
          </div>
          </div>
             <div style="display: table; width: 100%">
  
            <div style = "display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
              <p class="fw-normal" style="font-size: 24px ; ">Tax:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
              <p class="fw-normal" style="font-size: 24px ; ">$${tax > 0 ? FormatDolla((totalPrice * tax) / 100) : 0
              }</p>
            </div>
          </div>
          </div>
             <div style="display: table; width: 100%">
  
            <div style = "display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
              <p style="font-size: 24px ; ">Discount:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
              <p style="font-size: 24px ; ">$${discountOrder > 0 ? FormatDolla(discountOrder) : 0
              }</p>
              </div>
          </div>
          </div>` +
              (!choosepaymentMethodCash && feeStripe
                ? `<div style="display: table; width: 100%">
  
              <div style="display: table-row; padding-top: 0px; width: 100%" >
                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
                  <p style="font-size: 24px ; ">Convenience fee:</p>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                  <p style="font-size: 24px ; ">$${feePrice > 0 ? FormatDolla(feePrice) : 0
                }</p>
                </div>
              </div>
            </div>`
                : ``) +
              `
            <div style="display: table; width: 100%">
  
          <div style = "display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
              <p style="; font-size: 30px ;">Total:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
              <p style=" ; font-size: 30px">$${FormatDolla(
                totalPrice +
                feePrice -
                discountOrder +
                TipPrice +
                (totalPrice * tax) / 100
              )}</p>
              </div>
          </div>
          </div>
          
              <div style="width: 100%; margin-top: 38px">
    <h6 class="" style="font-weight: 400; margin: 0; text-align: center;white-space: nowrap; overflow: hidden">------------------------------------------------------------</h6>

                <h5 class="" style="; margin: 0; text-align: center;">${nameStore}</h5>
                <h5 class="" style="; margin: 0; text-align: center;">${addressStore}</h5>
                <h5 class="" style="; margin: 0; text-align: center;">${phoneOwnerStore}</h5>
              </div>
              <h2 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">Thank you!</h2>
            </div >
            </body >
            </html >
    `;
            const req = await PrintOrderPage(
              UserIdStore ?? 0,
              itempr.printer_name,
              rawhtml.toString()
            );
            if (!req?.status) {
              // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
              // setShowToastError(true)
              // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
              // showToastPrint?.(req?.message ?? ("Error printing on " + item.printer_name))
            }
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setshowModalAlertFail(true);
      }
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  ////////////////

  //tạo hóa đơn thanh toán
  async function PaymentIntentClient() {
    setShowModalReader(true);
    var TotalPay =
      totalPrice +
      feePrice +
      TipPrice +
      (totalPrice * tax) / 100 -
      discountOrder;
    var tip_amount = TipPrice > 0 ? TipPrice : 0;
    const req = await CreatePaymentIntent(
      UserIdStore ?? 0,
      Number(TotalPay) * 100,
      pos_id
    );
    if (Number(req?.status) === 1) {
      // return req?.secret
      // setShowModalReader(false)
      setpaymentIntentId(req?.paymentIntents);
      PaymentWithReader(req?.paymentIntents ?? "");
    } else {
      setShowModalReader(false);
      setMessageErrPayment(req.message ?? "");
      setShowModalPaymentErr(true);
      return "";
    }
  }

  //tiến hàng chờ wuetj thẻ
  const PaymentWithReader = async (paymentIntents: string) => {
    var TotalPay =
      totalPrice +
      feePrice -
      discountOrder +
      TipPrice +
      (totalPrice * tax) / 100;
    // var tip_amount = TipPrice > 0 ? (TipPrice) : 0;

    const req = await PaymentIntentStripe(
      UserIdStore ?? 0,
      paymentIntents,
      pos_id
    );
    if (Number(req?.status) === 1) {
      var tip_amount = req?.tipAmount ?? 0;
      const payHandle = await paymentApi.PaymentBillPOS(
        UserIdStore,
        table_id,
        TotalPay,
        tax,
        2,
        listOrderPay,
        staffId,
        tip_amount,
        feePrice
      );
      if (payHandle.status == 1) {
        handleSuccess();
        setShowLoading(false);
        dismisModalReader();
        PrintCard();
      } else {
        dismisModalReader();
        setMessageErrPayment(payHandle.message ?? "");
        setShowModalPaymentErr(true);
      }
    } else {
      setShowModalReader(false);
      if (!req?.message?.includes("canceled")) {
        setMessageErrPayment(req.message ?? "");
        setShowModalPaymentErr(true);
      }
      return "";
    }
  };

  const CancelPayment = async () => {
    if (paymentIntentId !== "") {
      const req = await CancelPaymentIntent(
        UserIdStore ?? 0,
        paymentIntentId,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setpaymentIntentId("");
        dismisModalReader();
      } else {
        dismisModalReader();
        setMessageErrPayment(req.message ?? "");
        setShowModalPaymentErr(true);
        return "";
      }
    }
  };
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <ModalReader
        show={showModalReader}
        handleClose={CancelPayment}
        amount={
          totalPrice +
          feePrice -
          discountOrder +
          TipPrice +
          (totalPrice * tax) / 100
        }
      />
      <ModalPaymentError
        show={showModalPaymentErr}
        handleClose={dismisModalPaymentErr}
        content={messageErrPayment}
      />
      <Modal show={show} onHide={handleClose} className="right">
        <Modal.Dialog className="bg-gray-light">
          <Modal.Header className="position-relative">
            <div className="d-flex align-items-center pt-3 gap-2">
              {/* <Form.Check
                                inline
                                name="checkBoxTable"
                                type={"checkbox"}
                                id={"1"}
                            /> */}
              <p className="text-table-pay-bill-2 mb-0 ">{table_name}</p>
              <p className="text-table-pay-bill-2 mb-0 ">
                {listOrderPay.length}/{OrderWait.length}
              </p>
            </div>
            {/* <div className="btn-close-modal">
                            <CloseSharpIcon />
                        </div> */}
          </Modal.Header>
          <Modal.Body>
            <div className="body-modal-pay-bill-table flex-row">
              <div className="d-flex container-pay-pill-table flex-column">
                <div className="bg-FEFEFE overflow-y-scroll w-460px pe-2 pb-3 border-right flex-shink-0 h-100vh-312px scroll-hidden">
                  <Form.Check
                    inline
                    name="checkBoxTableAll"
                    type={"checkbox"}
                    id={"1"}
                    className="check-box-payment d-flex items-center w-fit"
                    size={20}
                    label={languageUI.chooseAll}
                    checked={listOrderPay.length === OrderWait.length}
                    onClick={(e: any) => handleChangeAllOrder(e.target.checked)}
                  />
                  {OrderWait.length > 0 &&
                    OrderWait.map((item, i) => (
                      <div className="d-flex align-items-start w-100" key={i}>
                        <Form.Check
                          inline
                          name="checkBoxTable"
                          type={"checkbox"}
                          id={"1"}
                          checked={listOrderPay.some(
                            (orp) => orp == item?.order_id
                          )}
                          onClick={(e: any) =>
                            HandleChangOrder(
                              e.target.checked,
                              item?.order_id,
                              item?.tip_staff_id,
                              item?.staff_name,
                              item?.tip_amount
                            )
                          }
                          className="mt-20px check-box-payment"
                          size={20}
                        />
                        <Accordion
                          defaultActiveKey="0"
                          key={i}
                          className="w-100"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="d-flex align-items-center">
                                <div className="text-bill-pay-bill mb-0 d-flex gap-1 align-iems-center">
                                  <p className="mb-0 text-212134">
                                    {languageUI.bill} -
                                  </p>
                                  <p className="mb-0 text-FAA300">
                                    #{item?.order_id}
                                  </p>
                                  <p className="mb-0 text-212134">
                                    {Number(item?.custommer_id) !== 0
                                      ? `(${item?.name})`
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-flex flex-column gap-2">
                                <OrderListITem
                                  orderInfo={item?.listFood}
                                  status={true}
                                />
                                {item?.listSide && item.listSide.length > 0 && (
                                  <div>
                                    <p className="title-pay-bill-customer mb-0">
                                      <li>{languageUI.sideDish}</li>
                                    </p>
                                    {item?.listSide?.map((item, index) => (
                                      <div className="ps-3">
                                        <p className="text-side-dish mb-0 text-414151f">
                                          {index + 1}. {item.name} (x
                                          {item.quantity})
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {item?.listFood &&
                                  item?.listFood.length > 1 &&
                                  Number(item?.tip_amount) === 0 &&
                                  Number(item?.discount) === 0 && (
                                    <div
                                      className="w-100 h-50px flex justify-content-center align-items-center border-primary rounded-5"
                                      onClick={() => {
                                        setOrderSplitBill(item);
                                        setShowModalSplitBill(true);
                                      }}
                                    >
                                      <AddIcon className="icon-split-bill" />
                                      <p className="text-split-bill mb-0">
                                        {languageUI.splitbill}
                                      </p>
                                    </div>
                                  )}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ))}
                </div>
                {/* <div className="w-460px px-2">
                                    <div className="mt-2">
                                        <p className="text-table-pay-bill-2">{languageUI.addTip}</p>
                                        <div className="d-flex justify-content-between">
                                            {tipList.length > 0 && tipList.map((items, i) => (
                                                <div key={i} className={`d-flex justify-content-center align-items-center w-30  ${valueTip == Number(items.id) ? "custom-choose-tip-2" : "custom-choose-tip"}`} onClick={() => { setValueTip(Number(items.id)); setTipName(items?.percent ?? ""); setTipPrice(totalPrice * Number(items?.percent) / 100); setValueTipCustom('') }}>
                                                    <p className={`custom-text-choose-categorie text-choose-tip mb-0 ${valueTip == Number(items.id) ? "text-ffffff" : "text-8E8EA9 "}`}>{items.percent} % (${FormatDolla(totalPrice * Number(items?.percent) / 100)})</p>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                                        <input
                                            type="number"
                                            className="input-voucher focus-visible-none w-100"
                                            placeholder={languageUI.customer}
                                            value={valueTipCustom}
                                            onChange={(e) => handleChangeInput(e.target.value)}

                                        />
                                        <p className="mb-0 text-other mr-6px">$</p>
                                    </div>
                                    <div className={`d-flex justify-content-between align-items-center border-1px-gray rounded-16px mt-2`}>
                                        <div className="input-voucher focus-visible-none w-100" onClick={() => setShowModalChooseNameTip(true)}>{staffName != '' ? staffName : languageUI.employeeWantToTip}</div>
                                        <img src={chevronDown} alt="" />
                                    </div>
                                </div> */}
                <div className="w-460px pe-3">
                  {/* <p className="text-table-pay-bill-2 mb-1">Discount</p>
                                    <div className="custom-typing-discount px-2 mt-2">
                                        <input
                                            type="text"
                                            className="input-voucher focus-visible-none w-100"
                                            placeholder="Discount"
                                        />
                                        <div className={`d-flex gap-2 align-items-center `}>
                                            <div className={`custom-icon-typing-discount ${chooseDiscountType == 1 ? "text-FF7B2C border-FF7B2C" : ""}`}>
                                                <AttachMoneySharpIcon />
                                            </div>
                                            <div className={`custom-icon-typing-discount ${chooseDiscountType == 2 ? "text-FF7B2C border-FF7B2C" : ""}`}>
                                                <PercentSharpIcon />
                                            </div>
                                        </div>
                                    </div> */}
                  <p className="text-table-pay-bill-2 mb-1 mt-2">
                    {languageUI.paymentMethod}
                  </p>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <div
                      className={`d-flex justify-content-center align-items-center w-48  ${choosepaymentMethodCash == true
                        ? "custom-choose-payment-method-2"
                        : "custom-choose-payment-method"
                        }`}
                      onClick={() => setChoosePaymentMethodCash(true)}
                    >
                      <p
                        className={`custom-text-choose-categorie text-choose-tip mb-0 ${choosepaymentMethodCash == true
                          ? "text-FF7B2C"
                          : "text-8E8EA9 "
                          }`}
                      >
                        {languageUI.payWidthCash}
                      </p>
                    </div>
                    <div
                      className={`d-flex justify-content-center align-items-center w-48  ${choosepaymentMethodCash == false
                        ? "custom-choose-payment-method-2"
                        : "custom-choose-payment-method"
                        }`}
                      onClick={() => {
                        setChoosePaymentMethodCash(false);
                        setValueTip(0);
                        setTipName("");
                        setTipPrice(0);
                        setValueTipCustom("");
                      }}
                    >
                      <p
                        className={`custom-text-choose-categorie text-choose-tip mb-0 ${choosepaymentMethodCash == false
                          ? "text-FF7B2C"
                          : "text-8E8EA9 "
                          }`}
                      >
                        {languageUI.payWidthCard}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`mt-2 ${choosepaymentMethodCash == false ? "d-none" : ""
                      }`}
                  >
                    <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                      <input
                        type="number"
                        className="input-voucher focus-visible-none w-100"
                        placeholder={languageUI.rececievedAmount}
                        value={receivedAmount}
                        onChange={(e: any) => {
                          setReceivedAmount(e.target.value);
                        }}
                      />
                      <p className="mb-0 text-other mr-6px">$</p>
                    </div>
                    <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                      <input
                        type="number"
                        disabled
                        className="input-voucher focus-visible-none w-100 bg-transparent"
                        placeholder={languageUI.remainingAmount}
                        value={reMainingAmount}
                      />
                      <p className="mb-0 text-other mr-6px">$</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-pay-bill-table d-flex flex-column h-100vh-60px">
                <div className="w-460px px-2">
                  {choosepaymentMethodCash && (
                    <>
                      <div className="mt-2">
                        <p className="text-table-pay-bill-2">
                          {languageUI.addTip}
                        </p>
                        <div className="d-flex justify-content-between">
                          {tipList.length > 0 &&
                            tipList.map((items, i) => (
                              <div
                                key={i}
                                className={`d-flex justify-content-center align-items-center w-30  ${valueTip == Number(items.id)
                                  ? "custom-choose-tip-2"
                                  : "custom-choose-tip"
                                  }`}
                                onClick={() => {
                                  setValueTip(Number(items.id));
                                  setTipName(items?.percent ?? "");
                                  setTipPrice(
                                    (totalPrice * Number(items?.percent)) / 100
                                  );
                                  setValueTipCustom("");
                                }}
                              >
                                <p
                                  className={`custom-text-choose-categorie text-choose-tip mb-0 ${valueTip == Number(items.id)
                                    ? "text-ffffff"
                                    : "text-8E8EA9 "
                                    }`}
                                >
                                  {items.percent} % ($
                                  {FormatDolla(
                                    (totalPrice * Number(items?.percent)) / 100
                                  )}
                                  )
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                        <input
                          type="number"
                          className="input-voucher focus-visible-none w-100"
                          placeholder={languageUI.customer}
                          value={valueTipCustom}
                          onChange={(e) => handleChangeInput(e.target.value)}
                        />
                        <p className="mb-0 text-other mr-6px">$</p>
                      </div>
                    </>
                  )}
                  <div
                    className={`d-flex justify-content-between align-items-center border-1px-gray rounded-16px mt-2 mb-2`}
                  >
                    <div
                      className="input-voucher focus-visible-none w-100"
                      onClick={() => setShowModalChooseNameTip(true)}
                    >
                      {staffName != ""
                        ? staffName
                        : languageUI.employeeWantToTip}
                    </div>
                    <img src={chevronDown} alt="" />
                  </div>
                  {/* <p className="text-table-pay-bill-2 mb-1">Discount</p>
                                    <div className="custom-typing-discount px-2 mt-2">
                                        <input
                                            type="text"
                                            className="input-voucher focus-visible-none w-100"
                                            placeholder="Discount"
                                        />
                                        <div className={`d-flex gap-2 align-items-center `}>
                                            <div className={`custom-icon-typing-discount ${chooseDiscountType == 1 ? "text-FF7B2C border-FF7B2C" : ""}`}>
                                                <AttachMoneySharpIcon />
                                            </div>
                                            <div className={`custom-icon-typing-discount ${chooseDiscountType == 2 ? "text-FF7B2C border-FF7B2C" : ""}`}>
                                                <PercentSharpIcon />
                                            </div>
                                        </div>
                                    </div> */}
                </div>
                {/* <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> <div className="d-flex align-items-center">
                                                <Form.Check
                                                    inline
                                                    name="checkBoxTable"
                                                    type={"checkbox"}
                                                    id={"1"}
                                                />
                                                <div className="text-bill-pay-bill mb-0 d-flex gap-1 align-iems-center"><p className="mb-0 text-212134">Bill -</p><p className="mb-0 text-FAA300">#4587491</p></div>
                                            </div></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="d-flex flex-column gap-2">
                                                    <OrderListITem orderInfo={dataFake} status={true} textStatus="ádasdasdasdas" />
                                                    <OrderListITem orderInfo={dataFake} status={false} textStatus="ádasdasdasdas" />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header> <div className="d-flex align-items-center">
                                                <Form.Check
                                                    inline
                                                    name="checkBoxTable"
                                                    type={"checkbox"}
                                                    id={"1"}
                                                />
                                                <div className="text-bill-pay-bill mb-0 d-flex gap-1 align-iems-center"><p className="mb-0 text-212134">Bill -</p><p className="mb-0 text-FAA300">#4587491</p></div>
                                            </div></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="d-flex flex-column gap-2">
                                                    <OrderListITem orderInfo={dataFake} status={true} textStatus="ádasdasdasdas" />
                                                    <OrderListITem orderInfo={dataFake} status={false} textStatus="ádasdasdasdas" />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion> */}
                {/* <div className="w-460px pe-3">

                                    <p className="text-table-pay-bill-2 mb-1 mt-2">{languageUI.paymentMethod}</p>
                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                        <div className={`d-flex justify-content-center align-items-center w-48  ${choosepaymentMethodCash == true ? "custom-choose-payment-method-2" : "custom-choose-payment-method"}`} onClick={() => setChoosePaymentMethodCash(true)}>
                                            <p className={`custom-text-choose-categorie text-choose-tip mb-0 ${choosepaymentMethodCash == true ? "text-FF7B2C" : "text-8E8EA9 "}`}>{languageUI.payWidthCash}</p>
                                        </div>
                                        <div className={`d-flex justify-content-center align-items-center w-48  ${choosepaymentMethodCash == false ? "custom-choose-payment-method-2" : "custom-choose-payment-method"}`} onClick={() => setChoosePaymentMethodCash(false)}>
                                            <p className={`custom-text-choose-categorie text-choose-tip mb-0 ${choosepaymentMethodCash == false ? "text-FF7B2C" : "text-8E8EA9 "}`}>{languageUI.payWidthCard}</p>
                                        </div>
                                    </div>
                                    <div className={`mt-2 ${choosepaymentMethodCash == false ? "d-none" : ""}`}>
                                        <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                                            <input
                                                type="number"
                                                className="input-voucher focus-visible-none w-100"
                                                placeholder={languageUI.rececievedAmount}
                                                value={receivedAmount}
                                                onChange={(e: any) => { setReceivedAmount(e.target.value); }}
                                            />
                                            <p className="mb-0 text-other mr-6px">$</p>
                                        </div>
                                        <div className="d-flex justify-content-between border-1px-gray rounded-16px mt-2">
                                            <input
                                                type="number"
                                                disabled
                                                className="input-voucher focus-visible-none w-100 bg-transparent"
                                                placeholder={languageUI.remainingAmount}
                                                value={reMainingAmount}
                                            />
                                            <p className="mb-0 text-other mr-6px">$</p>
                                        </div>
                                    </div>
                                </div> */}
                <div className="w-460px px-2 pb-5">
                  <div className=" w-100 h-100 bill-payment-confirm p-20px">
                    <div className="h-100">
                      <p className="text-table-pay-bill-2 text-center pt-3 mb-60px">
                        {languageUI.summary}
                      </p>
                      <div>
                        <div className="d-flex justify-content-between px-4 mb-1">
                          <p className="name-payment-bill">
                            {languageUI.itemsTotal}
                          </p>
                          <div className="d-flex">
                            <p className="mb-0 price-payment-bill">
                              {FormatDolla(totalPrice)}
                            </p>
                            <p className="mb-0 dollar-payment-bill">$</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between px-4 mb-1">
                          <p className="name-payment-bill">
                            Tip {`${tipName != "" ? "(" + tipName + "%)" : ""}`}
                          </p>
                          <div className="d-flex">
                            <p className="mb-0 price-payment-bill">
                              {FormatDolla(TipPrice)}
                            </p>
                            <p className="mb-0 dollar-payment-bill">$</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between px-4 mb-1">
                          <p className="name-payment-bill">{languageUI.tax}</p>
                          <div className="d-flex">
                            <p className="mb-0 price-payment-bill">
                              {tax > 0
                                ? FormatDolla((totalPrice * tax) / 100)
                                : 0}
                            </p>
                            <p className="mb-0 dollar-payment-bill">$</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between px-4 mb-1">
                          <p className="name-payment-bill">
                            {languageUI.Discount}
                          </p>
                          <div className="d-flex">
                            <p className="mb-0 price-payment-bill">
                              {FormatDolla(discountOrder)}
                            </p>
                            <p className="mb-0 dollar-payment-bill">$</p>
                          </div>
                        </div>
                        {!choosepaymentMethodCash && feeStripe && (
                          <div className="d-flex justify-content-between  mb-1">
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <div className="tooltip-payment-web-checkin">
                                <Tooltip
                                  className=""
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={openToolTip}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  placement="right"
                                  title="The convenience fee is charged per transaction by a third-party vendor. We do not retain any portion of this fee."
                                >
                                  <div
                                    className="d-flex gap-1 align-items-end w-fit"
                                    onClick={() => setOpenToolTip(true)}
                                  >
                                    <div className="name-payment-bill">
                                      Convenience fee
                                    </div>
                                    <HelpOutlineSharpIcon className="" />
                                  </div>
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                            <div className="d-flex gap-2px">
                              <div className="mb-0 price-payment-bill">
                                {FormatDolla(feePrice)}
                              </div>
                              <div className="mb-0 dollar-payment-bill">$</div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="px-4">
                        <div className="notification-price-payment d-flex overflow-hidden">
                          <div className="d-flex align-items-center ps-3 w-50">
                            <div>
                              <p className="name-payment-bill mb-1">
                                {languageUI.Total}
                              </p>
                              <div className="d-flex align-items-center gap-2px">
                                <p className="mb-0 price-payment-confirm">
                                  {FormatDolla(
                                    totalPrice +
                                    feePrice -
                                    discountOrder +
                                    TipPrice +
                                    (totalPrice * tax) / 100
                                  )}
                                </p>
                                <p className="mb-2 name-payment-bill">$</p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="w-50 bg-FE841B d-flex justify-content-center align-items-center"
                            onClick={SubmitPayBill}
                          >
                            <p className="mb-0 text-submit-payment-bill">
                              {languageUI.payBill}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none">
              <div
                className="d-flex flex-column w-100"
                id="print-bill-table-pos-card"
              >
                <div className="border-bottom-black position-relative line-height-normal">
                  <div>
                    <p className="mb-0 id-order-customer text-black line-height-normal">
                      {nameStore}
                    </p>
                    <div className="d-flex gap-1 align-items-end">
                      <p className="mb-0 id-order-customer text-black line-height-normal">
                        Add:
                      </p>
                      <p className="mb-0 text-items-order-print text-black">
                        {addressStore}
                      </p>
                    </div>
                    {/* <div className="d-flex gap-1 align-items-end">
                                            <p className="mb-0 id-order-customer  text-black line-height-normal">Phone:</p>
                                            <p className="mb-0 text-items-order-print text-black">{phoneOwnerStore}</p>
                                        </div> */}
                  </div>
                  <div className="d-flex align-items-center gap-2 text-black">
                    {/* {Number(table_id) > 0 && (
                      <div className="title-infor-bill-customer-print line-height-normal">
                        {NameTable}
                      </div>
                    )} */}
                    <div className={` border-bottom-black h-1 w-100`}></div>
                    {/* <div className="d-flex align-items-center ">
                <p className="mb-0 order-customer text-black line-height-normal">Order</p>
                <p className="mb-0 id-order-customer text-black line-height-normal">#{order_id}</p>
              </div> */}
                  </div>
                  <div className={`d-flex flex-column`}>
                    {OrderWait.map(
                      (order, j) =>
                        listOrderPay.some((item) => order.order_id == item) && (
                          <div
                            className="title-pay-bill-customer "
                            key={"Order" + j}
                          >
                            Bill-{order.order_id}
                            {order.listFood.map((cart, i) => (
                              <OrderListITemPrintPosMenu
                                key={"cart" + i}
                                name={cart?.name ?? ""}
                                notefood_name={cart?.notefood_name ?? ""}
                                form_order={`${Number(cart?.form_order) === 1 ? "1" : "2"
                                  }`}
                                price={Number(cart?.price)}
                                quantity={Number(cart?.quantity)}
                              />
                            ))}
                          </div>
                        )
                    )}

                    {/* <OrderListITemPrint orderInfo={listFood} status={true} /> */}
                  </div>
                </div>
                <div className="">
                  <div className={` border-bottom-black`}>
                    <div
                      className={`d-flex align-items-end justify-content-between `}
                    >
                      <p className="title-pay-bill-customer line-height-normal mb-0 text-black">
                        Sub total
                      </p>
                      <p className="price-pay-bill-customer  line-height-normal mb-0 text-black">
                        ${FormatDolla(totalPrice)}
                      </p>
                    </div>
                    {Number(TipPrice) > 0 && (
                      <div className="mb-0">
                        <p className="title-pay-bill-customer mb-0 text-black line-height-normal flex-shink-0">
                          Tip for:{" "}
                        </p>
                        <div className="d-flex gap-1 align-items-start justify-between line-height-normal">
                          <p className="title-pay-bill-customer mb-0 text-black line-height-normal">
                            {staffName}
                          </p>
                          <p className="price-pay-bill-customer mb-0 h-fit text-black line-height-normal flex-shink-0">
                            {FormatDolla(Number(TipPrice))}$
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="d-flex align-items-end justify-content-between">
                      <p className="title-pay-bill-customer line-height-normal mb-0 text-black">
                        Tax
                      </p>
                      <p className="price-pay-bill-customer  line-height-normal mb-0 text-black">
                        {tax > 0 ? FormatDolla((totalPrice * tax) / 100) : 0}$
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-end justify-content-between">
                      <p className="title-pay-bill-customer line-height-normal align-items-end mb-0 text-black">
                        Discount
                      </p>
                      <div className="d-flex gap-2px align-items-end">
                        <p className="price-pay-bill-customer d-flex mb-0 h-fit text-black line-height-normal">
                          {discountOrder}$
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between m-0">
                      <p className="title-pay-bill-customer line-height-normal mb-0 d-flex align-items-end text-black m-0">
                        Total
                      </p>
                      <div className="d-flex align-items-end m-0">
                        <p className="price-pay-bill-customer-total m-0 h-fit text-black line-height-normal">
                          {FormatDolla(
                            totalPrice +
                            feePrice -
                            discountOrder +
                            TipPrice +
                            (totalPrice * tax) / 100
                          )}
                          $
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
        <ModalSplitBill
          handleClose={dismisMoalSplitBill}
          show={showModalSplitBill}
          orderInfo={orderSplitBill}
          handleSuccess={handleSuccessSplitBil}
          setShowLoading={setShowLoading}
        />

        <ModalSelectEmployeeTip
          heightModal={600}
          listStaff={listStaff}
          staffId={staffId}
          setStaffId={(id: string) => setStaffId(id)}
          setStaffName={(name: string) => setStaffName(name)}
          handleClose={dismisModalChooseNameTip}
          show={showModalChooseNameTip}
        />
        <ModalToast
          handleClose={() => setshowModalAlertFail(false)}
          show={showModalAlertFail}
          content={messageError}
        ></ModalToast>
      </Modal>
    </>
  );
};

export default ModalPayBillCashTablePOS;
