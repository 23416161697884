import * as Request from "../Request";

export const GetReviewFromCustomer = async (
  UserIdStore?: number,
  customerId?: number,
  startDate?: string,
  endDate?: string,
  type?: number
) => {
  return await Request.fetchAPI(
    "/owner/gReviewFromUser.php?owner_id=" +
      UserIdStore +
      "&&customer_id=" +
      customerId +
      "&&date_start=" +
      startDate +
      "&&date_end=" +
      endDate +
      "&&type=" +
      type,
    "GET",
    {}
  )
    .then((res: IListReview) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export interface IListReviewData {
  id?: number;
  customer_id?: number;
  owner_id?: number;
  review_type_id?: number;
  name?: string;
  start?: string;
  phone?: string;
  username?: string;
}
export interface IListReview {
  message?: string;
  status?: number;
  data?: IListReviewData[];
}
