import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import "./styles.css";
import { UpdateTypeFoodSection } from "../../../../api/apiOwner/templateMenuApi";

interface ContentTableFoodSectionProps {
  index: number;
  nameFood: string;
  value: number;
  foodId: number;
  handleChangeValue: () => void;
}
const ContentTableFoodSection = ({
  nameFood,
  value,
  handleChangeValue,
  index,
  foodId,
}: ContentTableFoodSectionProps) => {
  const [statusSelect, setStatusSelect] = useState<number>(0);
  useEffect(() => {
    setStatusSelect(value);
  }, []);

  const saveFoodSection = async (type: number) => {
    await UpdateTypeFoodSection(foodId, type);
  };
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell align="left">{index}</TableCell>
        <TableCell>{nameFood}</TableCell>
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === 1}
                onChange={() => {
                  setStatusSelect(1);
                  saveFoodSection(1);
                }}
                value={1}
                sx={{
                  color: "#0B2F88",
                  "&.Mui-checked": {
                    color: "#0B2F88",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === 2}
                onChange={() => {
                  setStatusSelect(2);
                  saveFoodSection(2);
                }}
                value={2}
                sx={{
                  color: "#0B2F88",
                  "&.Mui-checked": {
                    color: "#0B2F88",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>{" "}
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === 3}
                onChange={() => {
                  setStatusSelect(3);
                  saveFoodSection(3);
                }}
                value={3}
                sx={{
                  color: "#0B2F88",
                  "&.Mui-checked": {
                    color: "#0B2F88",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>{" "}
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === 4}
                onChange={() => {
                  setStatusSelect(4);
                  saveFoodSection(4);
                }}
                value={4}
                sx={{
                  color: "#0B2F88",
                  "&.Mui-checked": {
                    color: "#0B2F88",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>{" "}
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === 6}
                onChange={() => {
                  setStatusSelect(6);
                  saveFoodSection(6);
                }}
                value={6}
                sx={{
                  color: "#0B2F88",
                  "&.Mui-checked": {
                    color: "#0B2F88",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>{" "}
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === 7}
                onChange={() => {
                  setStatusSelect(7);
                  saveFoodSection(7);
                }}
                value={7}
                sx={{
                  color: "#0B2F88",
                  "&.Mui-checked": {
                    color: "#0B2F88",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="d-flex justify-content-center mt-1 w-100">
            <div className="">
              <Radio
                checked={statusSelect === 8}
                onChange={() => {
                  setStatusSelect(8);
                  saveFoodSection(8);
                }}
                value={8}
                sx={{
                  color: "#0B2F88",
                  "&.Mui-checked": {
                    color: "#0B2F88",
                    margin: 0,
                  },
                }}
              />
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ContentTableFoodSection;
