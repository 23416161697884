import * as Request from "../Request";
export interface Notification {
  id: string;
  title: string;
  content: string;
  custommer_id: string;
  type: string;
  is_read: string;
  order_id: string;
  timestamp: string;
  name: string;
  table_name: string;
  floor_id: string;
  floor_name: string;
  day: string;
  header: string;
  body: string;
  table_id: string;
  timeline?: string;
  is_end_payment?: string;
  staff_name: string;
}

export interface Result<T> {
  status: number;
  message?: string;
  data?: T;
}
export const countNotification = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/countNotification.php`, "POST", {
    owner_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "0",
      };
    });
};
export const upIsreadNotificationtoSee = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/upIsreadtosee.php`, "POST", { owner_id })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "0",
      };
    });
};
export const upAllIsreadtoseen = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/upAllIsreadtoseen.php`, "POST", {
    owner_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "0",
      };
    });
};
export const upIsreadNotificationtoSeenbyId = async (
  notification_id: string
) => {
  return await Request.fetchAPI(`owner/upIsreadtoseenbyId.php`, "POST", {
    notification_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "0",
      };
    });
};
export const gListNotification = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListNotification.php`, "POST", {
    owner_id,
  })
    .then((res: Result<Notification[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const delNotificationById = async (notification_id: string) => {
  return await Request.fetchAPI(`owner/delNotificationById.php`, "POST", {
    notification_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
