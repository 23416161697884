import { Accordion, Form, Modal } from "react-bootstrap";
import "./Styles.css";
import {
  leaf1,
  leaf2,
  trashBlack,
  trashRed,
} from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import $ from "jquery";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";

interface ModalDeleteBillCustomerProps {
  show: boolean;
  UserIdStore?: number;
  handleSuccess?: () => void;
  handleClose: () => void;
}

const ModalDeleteBillCustomer = ({
  show,
  UserIdStore = 0,
  handleClose,
  handleSuccess,
}: ModalDeleteBillCustomerProps) => {
  const [otp, setOtp] = useState("");
  const [messageError, setMessageError] = useState("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const handleChange = (enteredOtp: string) => {
    setOtp(enteredOtp);
  };

  const setClockIn = async () => {
    if (otp.length !== 4) {
      setMessageError("Please enter passcode!");
    } else if (UserIdStore) {
      setShowLoading(true);
      const resStatus = await PasscodeApi.checkPasscode(UserIdStore, otp);
      if (Number(resStatus.status) === 1) {
        if (Number(resStatus?.role) === 1) {
          // setIsOwner?.(true)
          localStorage.setItem("isOwner", "1");
          setShowLoading(false);
        } else {
          // setIsOwner?.(false)
          localStorage.setItem("isOwner", "0");
          setShowLoading(false);
        }
        handleClose();
        handleSuccess?.();
      } else {
        setMessageError(resStatus?.message ?? "");
        setShowLoading(false);
      }
    }
  };
  useEffect(() => {
    const lengthInput = $(".otp input");

    if (lengthInput != null) {
      lengthInput.trigger("focus");
    }
  }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer"
      >
        <Modal.Dialog>
          <Modal.Body>
            <div className="position-relative px-3">
              <div className="d-flex align-items-center gap-2px">
                <img src={trashBlack} alt="" />
                <p className="text-delete-modal-customer mb-0">Delete</p>
              </div>
              <p className="text-content-modal-delete-customer mt-2">
                Are you sure you want to delete? Please input pin code to detele
                bill
              </p>
              <p className="text-center text-danger text-italic mb-1">
                {messageError}
              </p>
              <div className="otp-input mb-2 d-flex justify-content-between w-100">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={4}
                  placeholder="0"
                  isInputNum={true}
                  className="otp"
                />
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className="btn-cancel-modal-delete-customer"
                  onClick={handleClose}
                >
                  Cancel
                </div>
                <div
                  className="btn-cancel-modal-delete-customer border-delete"
                  onClick={setClockIn}
                >
                  <img src={trashRed} alt="" className="" />
                  <p className="mb-0 text-delete-modal-customer-btn">Delete</p>
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1" />
              <img src={leaf2} alt="" className="img-leaf2" />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalDeleteBillCustomer;
