import React from "react";
import { KeyboardArrowRightIcon } from "../../../../../../../components/Image";
import DrawerSalaryDetail from "../../Drawer/DrawerSalaryDetail";
interface ContentTableSalaryDetailProps {
    table: string;
    order: string;
    bill: string;
    tip: string;
    timePoint: string;

}
const ContentTableSalaryDetail = ({
    timePoint,
    table,
    order,
    bill,
    tip,
}: ContentTableSalaryDetailProps) => {
    const [showDrawerSalaryDetail, setDrawerSalaryDetail] = React.useState<boolean>(false)
    return (
        <>
            <DrawerSalaryDetail showDrawerSalaryDetail={showDrawerSalaryDetail} dismistDrawerSalaryDetail={() => setDrawerSalaryDetail(!showDrawerSalaryDetail)} />
            <tr className="d-flex align-items-center mb-2">
                <td className=" w-20 ps-2 border-bottom-none  d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">{table}</td>
                <td className="w-20 border-bottom-none d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">{order}</td>
                <td className="ml-5 border-bottom-none w-20 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
                    ${bill}
                </td>
                <td className="border-bottom-none w-20 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">${tip}
                </td>
                <td className="border-bottom-none w-20 d-flex align-items-center text-4 fw-600 text-228BE6 font-urbansist">{timePoint}</td>

            </tr>
        </>
    )
}
export default ContentTableSalaryDetail