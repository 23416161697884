import { Checkbox, FormControlLabel } from "@mui/material";
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface FoodSelectItemProps {
  id: string;
  checked: number;
  name: string;
  discount: number;
  ChangeSetting?: () => void;
  changeDiscountFood: (id: string, e: string) => void;
  handleChangeSelectFood: (foodId: string, checked: boolean) => void;
}
const FoodSelectItem = ({
  id,
  checked,
  name,
  discount,
  changeDiscountFood,
  handleChangeSelectFood,
  ChangeSetting,
}: FoodSelectItemProps) => {
  const languageUI = getLanguageUI().setting;
  return (
    <FormControlLabel
      key={`list-food-select-${id}`}
      className="mt-2"
      control={
        <Checkbox
          className="custom-radio-circle text-primary p-0"
          onChange={(e) => {
            handleChangeSelectFood(id, e.target.checked);
          }}
          checked={Number(checked) === 1}
        />
      }
      label={
        <div className="d-flex align-items-start gap-3">
          <div className="w-400px">
            <p className="mb-0 font-bold text-16px text-4a4a6a">{name}</p>
          </div>
          <div className="d-flex align-items-center gap-2 h-fit">
            <input
              type="text"
              value={discount}
              maxLength={3}
              className="input-choose-discount fous-visiable-none w-50px"
              readOnly={Number(checked) === 0}
              onChange={(e) => {
                changeDiscountFood(id, e.target.value);
                ChangeSetting?.();
              }}
            />
            <p className="mb-0 font-bold text-16px text-4a4a6a">
              % {languageUI.discount}
            </p>
          </div>
        </div>
      }
    />
  );
};
export default FoodSelectItem;
