import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { CloudUploadOutlinedIcon } from '../../../../../../components/Image';
import './Styles.css'

interface DropzoneProps {
    onUpload: (url: string) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onUpload }) => {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];
            const imageUrl = URL.createObjectURL(file);
            onUpload(imageUrl);
        },
        [onUpload]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false,
    });

    return (
        <div className="" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Kéo thả tệp ảnh vào đây...</p>
            ) : (
                <CloudUploadOutlinedIcon />
            )}
        </div>
    );
};

export default Dropzone;