// import axiosClient, { ResponseSuccess } from "../axiosClient";
import * as Request from "../Request";

export const LoginAdmin = async (UserName: string, Password: string) => {
  return await Request.fetchAPI("admin/login_admin.php", "POST", {
    username: UserName,
    password: Password,
  })
    .then((res: IListGroupCustomer) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export interface ILoginData {
  id?: any;
  permission?: any;
  permission_id?: number | string
  role?: any;
}
export interface IListGroupCustomer {
  message: string;
  status: number;
  data: ILoginData[];
}
