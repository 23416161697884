import { Modal } from "react-bootstrap";
import "./Styles.css";
import { handClick, leaf1, leaf2 } from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";
import ModalLoading from "../../../../components/ModalLoading";
import $ from "jquery";
import { getLanguageUI } from "../../../../Services/languageUI";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { setTimekeeping } from "../../../../api/apiOwner/clockinApi";
import { getLanguageUINew } from "../../../../Services/languageNew";

interface ModalEnterGuestProps {
  show: boolean;
  handleClose: () => void;
  redirectOrder: (guest: number) => void;
}

const ModalEnterGuest = ({
  show,
  handleClose,
  redirectOrder,
}: // setIsOwner
ModalEnterGuestProps) => {
  const languageUI = getLanguageUINew().modalGuest;
  const [valueGuest, setValueGuest] = useState<string>("");
  const LanguageUI = getLanguageUI().posTable;
  // const handleEnterKeyboard = (value: string) => {
  //   setValueGuest(String(valueGuest) + value);
  //   console.log(String(valueGuest) + value);
  // };
  const loopKeyboard = () => {
    const elements = [];
    for (let index = 1; index <= 30; index++) {
      elements.push(
        <div
          className="item-keyboard-guest"
          onClick={() => {
            ConfirmGuests(index);
          }}
        >
          {index}
        </div>
      );
    }
    return elements;
  };

  const ConfirmGuests = (guest: number) => {
    if (guest || guest > 0) {
      handleClose();
      redirectOrder(guest);
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-enter-guest border-modal-16px "
      >
        <Modal.Dialog className="p-0 w-100">
          <Modal.Body className="w-100 p-0">
            <div className="w-100 d-flex flex-column align-items-center">
              <h2>{languageUI.howManyGuest}</h2>
              <div className="w-100 px-4 flex gap-4 align-items-center mt-3">
                <input
                  type="number"
                  inputMode="numeric"
                  className="border-999999 rounded-3 px-3 text-lg h-40px text-4a4a6a"
                  placeholder={languageUI.enterGuestNumber}
                  value={String(valueGuest)}
                  onChange={(e) => {
                    if (Number(e.target.value) < 0) {
                      setValueGuest("");
                    } else {
                      setValueGuest(String(e.target.value));
                    }
                  }}
                />
                <button
                  className="h-40px w-100px border-primary bg-primary rounded-4 text-white font-bold"
                  onClick={() => ConfirmGuests(Number(valueGuest))}
                >
                  {languageUI.confirm}
                </button>
              </div>
            </div>
            <div className="box-keyboard-guest gap-3 px-4 mt-4">
              {loopKeyboard()}
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalEnterGuest;
