import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FloorSlice {
  id: string;
  owner_id: string;
  floor_name: string;
  sort: string;
  num_table: string;
  color: string;
}

const initialFloorState: FloorSlice[] = [
  {
    id: "",
    owner_id: "",
    floor_name: "",
    sort: "",
    num_table: "",
    color: "#0B2F88",
  },
];

const floorSlice = createSlice({
  name: "floor",
  initialState: initialFloorState,
  reducers: {
    setFloor: (state, action: PayloadAction<FloorSlice[]>) => action.payload,

    clearFloor: (state) => initialFloorState,
  },
});

export const selectorFloor = (state: { floor: FloorSlice[] }) => state.floor;

export const { setFloor, clearFloor } = floorSlice.actions;
export default floorSlice.reducer;
