import React from "react";
import { KeyboardArrowRightIcon } from "../../../../../components/Image";
import HistoryOder from "./Drawer/HistoryOder";
interface ContentTableReportProps {
    lastVisit: string;
    timeVisit: number;
    point: number;
    name: string;
    phone: string;
    totalAmount: string;
    customer_id?: string;
    checked?: boolean;
    handleChecked?: (check: boolean, id: string, phone: string, name: string) => void;
}
const ContentTableCustomer = ({
    totalAmount,
    phone = '',
    name = '',
    lastVisit,
    timeVisit,
    point,
    checked = false,
    customer_id = '',
    handleChecked
}: ContentTableReportProps) => {
    const [showDrawerHistory, setDrawerHistory] = React.useState<boolean>(false)

    return (
        <>
            <HistoryOder showDrawerHistory={showDrawerHistory} dismisDrawerHistory={() => setDrawerHistory(!showDrawerHistory)} customer_id={customer_id} />
            <tr className="d-flex align-items-center">
                <th scope="row" className="w-5 border-bottom-none"><input type="checkbox" checked={checked} onClick={(e: any) => handleChecked?.(e.target.checked, customer_id, phone, name)} /></th>
                <td className="border-bottom-none text-17px text-4A4A6A w-10">{lastVisit}</td>
                <td className="border-bottom-none text-17px text-269AFF w-10">{timeVisit}</td>
                <td className="border-bottom-none text-17px text-269AFF w-10">{point}</td>
                <td className="border-bottom-none text-17px text-4A4A6A w-35">{name}</td>
                <td className="border-bottom-none text-18px text-4A4A6A w-15">{phone}</td>
                <td className="border-bottom-none cursor w-10" >
                    <div className=" flex items-center justify-between">
                        <span className=" text-18px text-4A4A6A">{totalAmount}</span>

                    </div>
                </td>
                <td className="w-5 border-bottom-none" onClick={() => setDrawerHistory(!showDrawerHistory)}> <KeyboardArrowRightIcon className="text-12B064" /></td>
            </tr>
        </>
    )
}
export default ContentTableCustomer