import { Form } from "react-bootstrap";
import "./Styles.css";
import {
  pencilEdit,
  trashRedRemoveFloor,
} from "../../../../components/ImgExport";
import default_product from "../../../../assets/images/default_product.png";
import { Droppable } from "react-beautiful-dnd";
import { FormatDolla } from "../../../../utils/format";
import React from "react";
import {
  FoodGroup,
  ListNoteOptionFood,
  NoteFood,
} from "../../../../api/apiOwner/templateMenuApi";
import html2canvas from "html2canvas";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";

interface MenuItemProps {
  id?: string;
  status?: boolean;
  img: string;
  foodName: string;
  className?: string;
  foodGroupName?: string;
  foodSTT: number;
  priceFood: number;
  hasFoodGroup?: boolean;
  handleDelFood?: () => void;
  handleEditFood?: () => void;
  activeFoodMenu?: (id?: any) => void;
  optionFood?: ListNoteOptionFood[];
  noteFood?: NoteFood[];
  typeFood?: string;
  decreption: string;
}

const MenuItem = ({
  id = "",
  foodSTT,
  status,
  img,
  className,
  foodName,
  foodGroupName,
  hasFoodGroup = true,
  priceFood = 0,
  optionFood,
  noteFood,
  typeFood,
  handleDelFood,
  handleEditFood,
  activeFoodMenu,
  decreption,
}: MenuItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: Number(id) });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <>
      <div key={Number(id)} ref={setNodeRef} style={style}>
        <div
          className={` d-flex justify-content-between align-items-center flex-wrap  mb-2 h-140px ${className}`}
          style={{ marginLeft: "20px" }}
        >
          <div className="d-flex gap-4 h-100 align-items-center">
            <div className="d-flex gap-2 h-100">
              <div className="box-img-food-name">
                <div className="w-50 h-100 bg-0B2F88 d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-1 text-numberic-no">No.</p>
                  <div className="fw-bold  text-numberic">({foodSTT})</div>
                </div>
                <div className="w-50 h-100 ">
                  <img
                    src={img}
                    alt=""
                    className="h-100 w-100 object-fit-cover"
                    onError={(e) => {
                      e.currentTarget.src = default_product;
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center">
                <div className="food-name-menu-items mb-1 text-truncate-container">
                  <p className="m-0"> {foodName}</p>
                </div>
                <p className="mb-0 d-flex gap-2px mb-1 price-food-name-menu">
                  {FormatDolla(priceFood)}{" "}
                  <p className="mb-1 text-dollar-food-menu-items">$</p>
                </p>
                <div className="">
                  <div className="d-flex gap-1">
                    <Form.Check
                      type="switch"
                      id="rating-allow-customer-point"
                      className="d-flex align-items-center checked-blue"
                      checked={status}
                      onChange={(e: any) => {
                        activeFoodMenu?.(id);
                      }}
                    />
                    <p className="mb-0 active-category">Active food?</p>
                  </div>
                  <div className="title-typeFood mt-1">{typeFood}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            {noteFood && (
              <div className="  max-h-130px d-flex flex-wrap gap-1 box-notefood ">
                {noteFood?.map((item, index) => (
                  <div key={index} className="title-noteFood">
                    {item.notefood_name}
                  </div>
                ))}
              </div>
            )}

            {optionFood && (
              <div className="d-flex gap-1 flex-wrap overflow-y-scroll max-h-130px  ">
                {optionFood?.map((item, index) => (
                  <div key={index} className="title-note-option-food">
                    {item.option_name}: ${item.price}
                  </div>
                ))}
              </div>
            )}
            {decreption && (
              <div className="d-flex gap-1 flex-wrap overflow-y-scroll max-h-130px  ">
                <div className=" decreption-food">{decreption}</div>
              </div>
            )}

            <div className="d-flex gap-2">
              <div className="mr-5">
                <button
                  className="btn-edit-delete-floor"
                  {...attributes}
                  {...listeners}
                >
                  <ControlCameraIcon />
                </button>
              </div>

              <button className="btn-edit-delete-floor" onClick={handleDelFood}>
                <img src={trashRedRemoveFloor} alt="" />
              </button>
              <button
                className="btn-edit-delete-floor"
                onClick={handleEditFood}
              >
                <img src={pencilEdit} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuItem;
