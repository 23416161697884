import Topbar from "../../../../layouts/Topbar";
import { Form } from "react-bootstrap";
import "./Styles.css";
import Button from "@mui/joy/Button";
import { CloudUploadOutlinedIcon } from "../../../../components/Image";
import { useEffect, useState } from "react";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import {
  Feedback,
  gHistoryFeedBack,
  sendFeedBack,
} from "../../../../api/apiOwner/feedbackApi";
import ModalToast from "../ModalToast/ModalToastErr";
import moment from "moment-timezone";
import { targetImg } from "../../../../api/urlImage";
import ModalLoading from "../../../../components/ModalLoading";
import { thumnailDefault } from "../../../../components/ImgExport";
import { getLanguageUI } from "../../../../Services/languageUI";
// import { Document, Page } from 'react-pdf';
const FeedBack: React.FC = () => {
  const languageUI = getLanguageUI().feedback;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [content, setContent] = useState<string>("");
  const [imageSelected, setImageSelected] = useState<Array<string>>([]); //img đc chọn
  const [MessageToast, setMessageToast] = useState<string>("");
  const [MessageToastSuccess, setMessageToastSucess] = useState<string>("");
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const [historyFeedback, setHistoryFeedback] = useState<Feedback[]>([]);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);

  const onSelectFile = async (picture: any) => {
    if (imageSelected.length + picture.length > 3) {
      setMessageToast("Only 3 photos are allowed to be uploaded!");
    } else {
      setMessageToast("");
      const arrImg: any = [];
      for (var i = 0; i < picture.length; i++) {
        var file = picture[i];
        if (!file.type.match("image")) continue;
        var picReader = new FileReader();
        picReader.readAsDataURL(file);
        // eslint-disable-next-line no-loop-func
        const photo = await new Promise((resolve, reject) => {
          picReader.addEventListener("load", function (event) {
            var picFile: any = event.target?.result;
            resolve(picFile);
          });
        });
        arrImg.push(photo);
      }
      setImageSelected([...imageSelected, ...arrImg]);
    }
  };
  const delImge = (index: number) => {
    setMessageToast("");
    setImageSelected(imageSelected.filter((item, i) => i !== index));
  };
  const SubmitSend = async () => {
    if (!UserIdStore) {
      setMessageToast(languageUI.ownerNotFound);
    } else if (content.trim() === "") {
      setMessageToast(languageUI.contentIsRequired);
    } else {
      setShowLoading(true);
      const req = await sendFeedBack(UserIdStore, content, imageSelected);
      if (Number(req?.status) === 1) {
        setMessageToastSucess(languageUI.thankYourFeedback);
        setShowToastSuccess(true);
        gHistoryFeedback();
        setContent("");
        setImageSelected([]);
      } else {
        setMessageToast(req?.message ?? "");
        setShowLoading(false);
      }
    }
  };

  const gHistoryFeedback = async () => {
    if (UserIdStore) {
      const req = await gHistoryFeedBack(UserIdStore);
      if (Number(req?.status) === 1) {
        setHistoryFeedback(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  useEffect(() => {
    setShowLoading(true);
    gHistoryFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <ModalToast
        show={showToastSuccess}
        handleClose={() => setShowToastSuccess(false)}
        content={MessageToastSuccess}
      />
      <div className="">{/* <Topbar pageName="Feedback" /> */}</div>
      <div className="w-100 mt-18 px-12  flex gap-4">
        <div className="h-fit-content w-50 radius-18px bg-white p-6 mt-12">
          <span className="text-6 font-urbansist fw-600 text-4A4A6A mb-1">
            {languageUI.yourFeedback}
          </span>
          <span className="text-17px font-urbansist text-8E8EA9 my-18px hidden-content-3-line">
            {languageUI.pleaseGiveUsYour}
          </span>
          <div className="pt-3">
            <Form className="custom-radius-text-area custom-h-textarea">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder={languageUI.enterFeedback}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </Form.Group>
            </Form>
          </div>
          <span className="text-18px font-urbansist fw-600 text-4A4A6A mt-6">
            {languageUI.attachFile}
          </span>
          <div className="d-flex gap-2 overflow-x-scroll scroll-hidden">
            {imageSelected.map((imageSrc, i) => (
              <div className="custom-show-img-feedback" key={i}>
                <div
                  className="btn-delete-img-choose"
                  onClick={() => {
                    delImge(i);
                  }}
                >
                  x
                </div>
                <img src={imageSrc} alt="" className="h-100 w-100" />
              </div>
            ))}
          </div>
          <div className="radius-3 border-dashed w-100 mt-2 position-relative">
            <Button
              component="label"
              role={undefined}
              tabIndex={-1}
              variant="outlined"
              color="neutral"
              startDecorator={
                <CloudUploadOutlinedIcon className="text-12B064" />
              }
              className="py-2 w-100 justify-content-start border-none"
              // onClick={handleUploadClick}
            >
              <div className="flex flex-column">
                <span className="text-15px font-urbansist fw-600 text-4A4A6A mb-1">
                  {languageUI.dragAdnDropToUpload}
                </span>
                <span className="text-15px font-urbansist  text-a5a5ba">
                  {languageUI.descriptionLimitsText}
                </span>
              </div>

              <input
                type="file"
                accept="image/*"
                className="custom-input-upload-img"
                maxLength={3}
                multiple
                onChange={(e) => onSelectFile(e.target.files)}
                // onChange={handleFileChange}
              />
            </Button>
          </div>

          <div className="text-danger text-center">{MessageToast}</div>
          <button
            className="w-100 border-none bg-0B2F88 text-white py-2 radius-6px text-17px font-urbansist mt-2"
            onClick={SubmitSend}
          >
            {languageUI.send}
          </button>
        </div>
        <div className="h-fit-content w-50 radius-18px bg-white p-6 mt-12">
          <div className="text-6 font-urbansist fw-600 text-4A4A6A mb-6">
            {languageUI.history}
          </div>
          <div className="h-content-history overflow-y-scroll">
            {historyFeedback.map((item, i) => (
              <div className="px-6 flex flex-column" key={`feed${i}`}>
                <span className="text-18px font-urbansist fw-600 text-4A4A6A ">
                  {languageUI.feedBack}{" "}
                  {moment(item?.timestamp).format("DD/MM/YYYYY")}
                </span>
                <span className="ms-2 text-17px font-urbansist text-8E8EA9  hidden-content-3-line">
                  {item?.content}
                </span>
                <div className="ms-2 d-flex gap-2 overflow-x-scroll scroll-hidden">
                  {item?.listImg &&
                    item?.listImg.map((img, j) => (
                      <div className="custom-show-img-feedback" key={`img${j}`}>
                        <img
                          src={targetImg + img?.filename}
                          alt=""
                          className="h-100 w-100"
                          onError={(e) => {
                            e.currentTarget.src = thumnailDefault;
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedBack;
