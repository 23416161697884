import Modal from 'react-bootstrap/Modal';
import "./Styles.css"
import { useEffect, useState } from 'react';
import * as ChooseMenu from "../../../../api/apiCheckin/chooseMenuApi";
import { Form } from 'react-bootstrap';
import { MenuInfo, addFoodToGroup } from '../../../../api/apiOwner/templateMenuApi';
import { getLanguageUI } from '../../../../Services/languageUI';

interface ModalAddAnotherDishProps {
    show: boolean;
    food_id?: string;
    foodName?: string;
    noteFood?: ChooseMenu.NoteFood[];
    side_id?: string;
    price?: number;
    imageFood?: string;
    addToCart?: (food_id: string, foodName: string, price: number, side_id: string, noteFood_id?: string, noteFood_name?: string, notechef?: string, imageFood?: string) => void;
    handleClose: (load?: boolean) => void;
    listFoodItem?: string[];
    id: string;
    ListFood?: MenuInfo[];
    setShowLoading: (show: boolean) => void
}
const ModalAddAnotherDish = ({
    show,
    handleClose,
    listFoodItem = [],
    id = '',
    ListFood = [],
    setShowLoading
}: ModalAddAnotherDishProps) => {
    const languageUI = getLanguageUI().menuTemplate
    const [listFoodSelected, setListFoodSelected] = useState<string[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [messError, setMessError] = useState("")

    const AddFoodInGroup = async (checked: boolean, foodId: string) => {
        if (checked) {
            setListFoodSelected([...listFoodSelected, foodId])
        } else {
            setListFoodSelected(listFoodSelected.filter(item => item !== foodId))

        }
    }

    const SubmitAddFood = async () => {
        if (id === '') {
            setMessError("Menu not found");
        } else if (listFoodSelected.length === 0) {
            setMessError("Please choose food!")
        } else {
            setShowLoading(true)
            const req = await addFoodToGroup(id, listFoodSelected);
            if (Number(req?.status) === 1) {
                handleClose(true)
            } else {
                setShowLoading(false)
            }
        }

    }
    useEffect(() => {
        if (show) {
            setListFoodSelected(listFoodItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-add-food-order"
            >
                <Modal.Body>
                    <Modal.Header>
                        <div className='items-dish-choose text-white '>
                            <h3 className='m-0 text-white'>
                                {languageUI.chooseTheFoodItem}
                            </h3>
                        </div>
                    </Modal.Header>
                    <div className='box-add-another-dish'>
                        <div className='container-box-add-dish-group h-400px overflow-y-scroll'>
                            <Form>
                                {ListFood.map((item, i) => (
                                    <div className='mt-2 items-dish-choose-container' key={i}>
                                        <Form.Check // prettier-ignore
                                            className='d-flex gap-2 align-items-center'
                                            type='checkbox'
                                            id={`default-${i}`}
                                            label={item?.name}
                                            checked={listFoodSelected.some(foo => foo === item?.id)}
                                            onClick={(e: any) => AddFoodInGroup(e.target.checked, item?.id)}
                                        />
                                    </div>
                                ))}


                            </Form>
                        </div>
                    </div>
                    <Modal.Footer>
                        <div className='w-100 d-flex justify-content-between mt-3'>
                            <div className='btn-footer-add-dish-group ' onClick={() => handleClose()}>
                                {languageUI.cancel}
                            </div>
                            <div className='btn-footer-add-dish-group bg-primary' onClick={SubmitAddFood}>
                                {languageUI.save}
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ModalAddAnotherDish;