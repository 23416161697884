import * as Request from "../Request";

export const InCome = async (UserIdStore: number, startDate: string, endDate: string, ) => {
    return await Request.fetchAPI('admin/inCome.php?admin_id=' + UserIdStore + '&&date_start=' + startDate + '&&date_end=' + endDate, 'GET', {

    }).then((res:IListPackage) => {
        return res;
    }).catch((err:any) => {
        return  {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: []
        }
    });
};

export interface IPackageData {
    total?: number;
    BM?: number;
    BY?: number;
    B5Y?: number;
    LM?: number;
    LY?: number;
    L5Y?: number;
    SM?: number;
    SY?: number;
    S5Y?: number;
}
export interface IListPackage {
    message: string,
    status: number,
    data: IPackageData []
}