import React, { useEffect, useState } from "react";
import { getLanguageUI } from "../../../../../../Services/languageUI";
import { StaffWorking } from "../../../../../../api/apiOwner/StaffApi";
import { GroupIcon } from "../../../../../../components/Image";
import ContentWorkingEmployee from "../ContentTable/ContentTableEmployee/ContentEmployeeWorking";
import {
  ListPrinterByCatalog,
  gListNamePrinterElse,
} from "../../../../../../api/apiOwner/SettingPrinterApi";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../../../store/slices/userSlice";

interface EmployeeWorkingTodayProp {
  listEmployeeWorking: StaffWorking[];
  ReloadWorking?: () => void;
}
const EmployeeWorkingToday: React.FC<EmployeeWorkingTodayProp> = ({
  listEmployeeWorking = [],
  ReloadWorking,
}) => {
  const languageUI = getLanguageUI().staffPage;
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [listPrinterName, setListPrinterName] = useState<
    ListPrinterByCatalog[]
  >([]);
  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await gListNamePrinterElse(UserIdStore, pos_id);
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };
  useEffect(() => {
    gListPrinter();
  }, []);
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th
              scope="col"
              className="w-5 text-18px text-12B064 fw-600 font-urbansist"
            >
              {languageUI.noNumber}
            </th>
            <th
              scope="col"
              className="w-35  text-18px text-12B064 fw-600 font-urbansist"
            >
              <GroupIcon className="mr-3" />
              {languageUI.employee}{" "}
            </th>
            <th scope="col" className="w-15 ">
              <div className="flex justify-between  items-center">
                <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">
                  {languageUI.clockIn}
                </span>
              </div>
            </th>
            <th scope="col" className="w-15">
              <div className="flex items-center">
                <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">
                  {languageUI.clockOut}
                </span>
              </div>
            </th>
            <th scope="col" className="w-20 ">
              <div className="flex items-center">
                <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">
                  {languageUI.workingTime}
                </span>
              </div>
            </th>
            <th scope="col" className="w-10">
              <div className="flex items-center w-100 justify-content-center">
                <span className="text-18px text-12B064 fw-600 font-urbansist mr-3 text-center">
                  {languageUI.edit}
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="h-body-table-employee">
          {listEmployeeWorking.map((row, i) => (
            <ContentWorkingEmployee
              key={`emw${i}`}
              no={i + 1}
              employee={row?.name}
              clockIn={row?.clock_in}
              clockOut={row?.clock_out}
              workingTime={row?.working_time}
              id_timesheet={row?.id}
              ReloadWorking={ReloadWorking}
              listPrinterName={listPrinterName}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default EmployeeWorkingToday;
