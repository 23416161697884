import Modal from "react-bootstrap/Modal";
import "./Styles.css";
import { Form } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import { OrderWait } from "../../../../api/apiCheckin/orderConfilmApi";
import { getLanguageUI } from "../../../../Services/languageUI";
import { memo, useState } from "react";
import ModalEnterGuest from "../ModalEnterGuest/ModalEnterGuest";
import { useNavigate } from "react-router-dom";

interface ModalChooseBillOrderProps {
  show: boolean;
  OrderWait?: OrderWait[];
  chooseBillHandle: (guest: number, order_id?: string) => void;
  handleClose: () => void;
}
const ModalChooseBillOrder = ({
  show,
  OrderWait = [],
  chooseBillHandle,
  handleClose,
}: ModalChooseBillOrderProps) => {
  const navigate = useNavigate();
  const languageUI = getLanguageUI().posTable;
  const [showModalGuest, setShowModalGuest] = useState(false);

  const dismisModalGuest = () => {
    setShowModalGuest(false);
  };

  const redirectOrder = (guest: number) => {
    navigate(
      "/web-owner/posmenu"
      //    {
      //   state: { table_id: table_id, table_name, floor_id, guest }
      //   ,
      // }
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-choose-bill-order"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="w-100 d-flex flex-column">
          <div className=" mb-2">
            <button
              className="py-2 px-5 text-white border-none rounded-12px bg-primary d-flex align-items-center "
              onClick={() => setShowModalGuest(true)}
            >
              <AddIcon className="text-white" />
            </button>
            <p className="name-food mb-0 mt-2">{languageUI.chooseBillOrder}</p>
          </div>
          <div className="max-height-350px d-flex flex-column gap-2 scroll-hidden">
            {OrderWait.map((item, i) => (
              <div className="d-flex align-items-center w-100" key={`or${i}`}>
                <Form.Check
                  className="d-flex align-items-center w-100 p-0 "
                  type="radio"
                  name={`choose-bill${i}`}
                  id={`ord${i}`}
                  onClick={() =>
                    chooseBillHandle(item.guestNumber, item?.order_id)
                  }
                  label={
                    <div className="h-40px w-100 d-flex align-items-center rounded-12px border px-2">
                      <p className="mb-0 ">Bill#{item?.order_id}</p>
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <ModalEnterGuest
          handleClose={dismisModalGuest}
          show={showModalGuest}
          redirectOrder={redirectOrder}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(ModalChooseBillOrder);
