import React, { useState } from "react";
import * as StaffApi from "../../../../../../../api/apiOwner/StaffApi";
import {
  convertDateString,
  formatCurrencyVND,
  FormatDolla,
} from "../../../../../../../utils/format";
import printJS from "print-js";
import moment from "moment-timezone";
import * as SettingPrinterApi from "../../../../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../../../../api/apiOwner/printerApi";
import ModalToast from "../../../../ModalToast/ModalToastErr";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../../../../store/slices/userSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import { getLanguageUI } from "../../../../../../../Services/languageUI";

interface ContentTableSalaryProps {
  no: number;
  name: string;
  payer: string;
  commission: number;
  timeWorking: string;
  totalWorEarn: number;
  totalTip: number;
  totalPay: number;
  endWeek?: string;
  startWeek?: string;
  payroll_id: string;
  is_month?: boolean;
  history?: StaffApi.HistoryClock[];
  UserIdStore?: number | null;
  listPrinterName: SettingPrinterApi.ListPrinterByCatalog[];
  handleOpenModalSalaryBonus: (id: number, nameStaff: string) => void;
  bonus: number;
  deduct: number;
  id: number;
}
const ContentTableSalaryEmployee = ({
  no,
  name,
  payer,
  commission = 0,
  timeWorking = "0",
  totalWorEarn = 0,
  totalTip = 0,
  totalPay = 0,
  endWeek,
  startWeek,
  history = [],
  payroll_id,
  is_month = false,
  UserIdStore,
  listPrinterName,
  handleOpenModalSalaryBonus,
  bonus,
  deduct,
  id,
}: ContentTableSalaryProps) => {
  const languageUI = getLanguageUI().staffPage;
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");

  const PrintCard = async () => {
    try {
      const rawhtml = `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6, h4 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
              font-weight: 400;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px">
            <h3 style="width: 100%; margin:  auto; text-align: center">Report</h3>
            <div style="width: 100%;">
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">Pay to: ${name}</h6>
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">Print date:${moment(
        new Date()
      ).format("MMM DD/YYYY")}</h6>
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">For perior:</h6>
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${is_month
          ? startWeek
          : convertDateString(startWeek ?? "", "MMM dd yyyy") +
          " - " +
          convertDateString(endWeek ?? "", "MMM dd yyyy")
        } </h6>
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">Time working: ${timeWorking} ${payer}</h6>
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">Total work earn: ${formatCurrencyVND(
          totalWorEarn
        )}</h6>
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">Total work earn: Total tip: ${formatCurrencyVND(
          totalTip
        )}</h6>
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">Total work earn: Total pay: ${formatCurrencyVND(
          totalPay
        )}</h6>
          </div>
            
               <div style="display: table; width: 100%">

           ${history
          .map(
            (item, i) =>
              `

           <div style = "display: table-row; padding-top: 10px; width: 100%; padding-right: 10px" >

                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  ">
                <p  style=" margin: 0; text-align: start; font-size: 24px ">${item?.name
                ? item?.name
                : moment(item?.clock_in, "YYYY-MM-DD h:mm:ss").format(
                  "MM/DD/YY"
                )
              }</p>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;">
                <p class="quanlity-number; margin-top: 10px !important " style="font-size: 24px ; ">${formatCurrencyVND(
                Number(item?.payroll_value)
              )}</p>
                  
                </div>
                 <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;">
                <p class="quanlity-number; margin-top: 10px !important " style="font-size: 24px ; ">${formatCurrencyVND(
                Number(item?.tip)
              )}</p>
                  
                </div>
              </div >


              `
          )
          .join(" ")}
              </div >
          </div >
          </body >
          </html >
  `;
      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (item) => {
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            item.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
            // setShowToastError(true)
            // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  return (
    <>
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <tr className="d-flex align-items-center mb-2">
        <td className=" w-5 ps-2 border-bottom-none  d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
          {no}
        </td>
        <td className="w-10 border-bottom-none d-flex align-items-center text-4 fw-600 text-228BE6 font-urbansist text-two-line-name-table">
          {name}
        </td>
        <td className="border-bottom-none w-10 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
          {payer}
        </td>
        <td className="border-bottom-none w-10 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist">
          {formatCurrencyVND(commission)}
        </td>
        <td className="border-bottom-none w-5 d-flex align-items-center text-4 fw-600 text-666687 font-urbansist text-align-center text-center">
          {timeWorking}
        </td>
        <td className="border-bottom-none w-10 d-flex align-items-center justify-between text-4 fw-600 text-666687 font-urbansist">
          {formatCurrencyVND(totalWorEarn)}
        </td>
        <td className="border-bottom-none w-10 d-flex align-items-center justify-between text-4 fw-600 text-666687 font-urbansist">
          {formatCurrencyVND(totalTip)}
        </td>
        <td className="border-bottom-none w-10 d-flex align-items-center justify-between text-4 fw-600 text-666687 font-urbansist">
          {formatCurrencyVND(totalPay + bonus - deduct)}
        </td>
        <td className="border-bottom-none w-10 d-flex align-items-center justify-between text-4 fw-600 text-666687 font-urbansist">
          {formatCurrencyVND(deduct)}
        </td>
        <td className="border-bottom-none w-10 d-flex align-items-center justify-between text-4 fw-600 text-666687 font-urbansist">
          {formatCurrencyVND(bonus)}
        </td>
        <td className="border-bottom-none w-10 d-flex align-items-center gap-2 justify-between text-4 fw-600 text-666687 font-urbansist">
          <div
            className="text-4 fw-600 font-urbansist btn-print-salary-staff-week bg-orange w-50 d-flex justify-content-center"
            onClick={() => handleOpenModalSalaryBonus(id, name)}
          >
            <EditIcon className="text-white" />
          </div>
          <div
            className="text-4 fw-600 font-urbansist btn-print-salary-staff-week w-50 d-flex justify-content-center"
            onClick={PrintCard}
          >
            {languageUI.print}
          </div>
        </td>
      </tr>
    </>
  );
};
export default ContentTableSalaryEmployee;
