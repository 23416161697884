import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface DetailFood {
  id: string,
  service_id: string,
  service_item_code: string,
  name: string,
  price: string,
  discount: string,
  imageCourse?: string,
  donvitinh?: string,
  side_id?: string,
  max_side?: string,
  NoteFood?: NoteFood[],
  Side_Dishes?: Side_Dishes[]
}
export interface NoteFood {
  notefood_id?: string,
  notefood_name?: string
}
export interface Side_Dishes {
  id?: string,
  name?: string,
  price?: string
}
const initialInfoFoodState: DetailFood[] = [];

const InfoFoodSlice = createSlice({
  name: "infofood",
  initialState: initialInfoFoodState,
  reducers: {
    setListInfoFood: (state, action: PayloadAction<DetailFood[]>) => action.payload,

    clearInfoFood: (state) => initialInfoFoodState,
  },
});
export const selectorListInfoFood = (state: { infofood: DetailFood[] }) =>
  state.infofood;

export const {
  setListInfoFood,
  clearInfoFood
} = InfoFoodSlice.actions;
export default InfoFoodSlice.reducer;
