/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as Request from "../api/Request";

// actions

// store
import { useSelector } from "react-redux";
import * as userSlice from "../store/slices/userSlice";

// components


interface ShowReviewProps {
  helpText?: string;
  bottomLinks?: any;
  isCombineForm?: boolean;
  children?: any;
}

const ShowReview = ({
  helpText,
  bottomLinks,
  children,
  isCombineForm,
}: ShowReviewProps) => {
  const nameStore = useSelector(userSlice.selectorNameStore);
  const [customerInfor, setCustomerInfor] = useState<any>("");
  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");
  const [messageReview, setMessageReview] = useState<any>("");
  const [nameOwner, setNameOwner] = useState<any>("");

  // let id_review = searchParams.get('id_review');
  const { id_review } = useParams();
  // var sms_id = idSendSMS;
  const getInforReview = async () => {
    await Request.fetchAPI(
      `/checkin/gInfoReviewCheckin.php?id=${id_review}`,
      "get"
    )
      .then((res) => {
        if (Number(res.status) === 1) {
          setMessageReview(res?.data?.message);
          setNameOwner(res?.data?.name);
        }
      })
      .catch((error: any) => console.log(error));
  };

  useEffect(() => {
    getInforReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <div>
                    <h4 className="text-center text-uppercase fw-bold">
                      {nameOwner} need your feedback
                    </h4>
                    <div className="d-flex align-items-center justify-content-center">
                      <i
                        className="fas fa-star fs-1 me-2 text-warning"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fas fa-star fs-1 me-2 text-warning"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fas fa-star fs-1 me-2 text-warning"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fas fa-star fs-1 me-2"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fas fa-star fs-1 me-2"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                    <Form.Control
                      disabled={true}
                      className="my-3"
                      as="textarea"
                      rows={6}
                      value={messageReview}
                      placeholder={`Dear ${customerInfor.name}, as owners of ${nameStore}, we value the quality of our service and would like to hear true feedbacks from you in order to serve you better`}
                    />
                    <p style={{ color: "#f1556c", textAlign: "center" }}>
                      {checkError ? errMess : ""}
                    </p>
                    {/* <div className="text-end">
                      <Button variant="primary">Submit feedback</Button>
                    </div> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ShowReview;
