import { Form, Modal } from "react-bootstrap";
import { leaf1 } from "../../../../components/ImgExport";
import "./Styles.css"

import * as comboApi from "../../../../api/apiOwner/comboApi";
import { useEffect, useState } from "react";

interface ModalCustomerSideProps {
    show: boolean;
    comboSideDish: comboApi.ComboSideDish[];
    listSide: SideChoosed[];
    handleClose: () => void;
    maxSide: string | number;
    onSave: (newList: SideChoosed[], max: number) => void;
}
interface SideChoosed {
    id: string, name: string
}
const ModalCustomerSide = ({
    handleClose,
    comboSideDish,
    listSide,
    show,
    maxSide = '',
    onSave
}: ModalCustomerSideProps) => {
    const [listSideTmp, setListSideTmp] = useState<SideChoosed[]>([])
    const [maxSideTmp, setMaxSideTmp] = useState<string | number>(1)
    const [searchSide, setSearchSide] = useState<string>('')

    const HandleCheck = (checked: boolean, id: string, name: string) => {
        if (!checked) {
            setListSideTmp([...listSideTmp.filter(item => item?.id !== id)])
        } else {
            setListSideTmp([...listSideTmp, { id, name }])
        }
    }
    const onSaveSide = () => {
        handleClose()
        onSave(listSideTmp, Number(maxSideTmp))
    }
    const searchHrs = async (value: any) => {
        var val = value.toLowerCase();
        comboSideDish &&
            comboSideDish.forEach((item, i) => {
                if (
                    item?.name.toLowerCase().indexOf(val) > -1
                ) {

                    const els = document.querySelector(".SideDish" + item?.id);
                    els?.setAttribute(
                        "class",
                        "p-0 text-label-checkbox-raw SideDish" + item?.id
                    );
                } else {
                    const els = document.querySelector(".SideDish" + item?.id);
                    els?.setAttribute(
                        "class",
                        "p-0 text-label-checkbox-raw d-none SideDish" + item?.id
                    );
                }
            });
    };
    useEffect(() => {
        if (show) {
            setListSideTmp([...listSide]);
            setMaxSideTmp(maxSide)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])
    return (
        <Modal show={show} onHide={handleClose} className="add-raw-materials">
            <Modal.Dialog className="bg-gray-light">
                <Modal.Body>
                    <div className="pt-4 px-48px  pb-120px">
                        <p className="mb-1 create-new-food text-center">Customer can choose <br /> number of side dish</p>
                        <div className="d-flex justify-content-between mt-2">
                            <div className="d-flex align-items-center w-100 border px-2 rounded-8px h-42px w-fit">
                                <input type="text" className="border-none foscus-visiable-none w-100 bg-tranparent input-qlt-raw" placeholder="search side dish" value={searchSide} onChange={(e) => { setSearchSide(e.target.value); searchHrs(e.target.value); }} />
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2 mt-2 h-calc-footer-raw overflow-y-scroll">
                            <Form className="d-flex flex-column gap-2">
                                {comboSideDish.map((item, i) => (
                                    <Form.Check // prettier-ignore
                                        key={`side${i}`}
                                        type="checkbox"
                                        id={`default-${i}`}
                                        label={item?.name}
                                        className={`p-0 text-label-checkbox-raw SideDish${item?.id}`}
                                        checked={listSideTmp.some(side => side.id === item?.id)}
                                        onClick={(e: any) => HandleCheck(e.target.checked, item?.id, item?.name)}
                                        onChange={(e) => { }}
                                    />
                                ))}
                            </Form>

                        </div>
                        <img src={leaf1} alt="" className="position-leaf1" />
                        <div className="box-btn-add-new-food">
                            <div className="d-flex justify-content-between align-items-center px-68px w-100">
                                <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>Cancel</div>
                                <div className=" btn-save-cancel-floor text-primary bg-white border-green" onClick={onSaveSide}>Save side dish</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal.Dialog>

        </Modal>
    )
}
export default ModalCustomerSide