import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface FoodCategory {
  id?: string,
  owner_id?: string,
  service_code?: string,
  name?: string,
  role?: string,
  date_begin?: string,
  date_end?: string,
  img?: string,
  discount?: string
}

const initialFoodCategoryState: FoodCategory[] = [];

const FoodCategorySlice = createSlice({
  name: "foodcategory",
  initialState: initialFoodCategoryState,
  reducers: {
    setListFoodCategory: (state, action: PayloadAction<FoodCategory[]>) => action.payload,

    clearFoodCategory: (state) => initialFoodCategoryState,
  },
});
export const selectorListFoodCategory = (state: { foodcategory: FoodCategory[] }) =>
  state.foodcategory;

export const {
  setListFoodCategory,
  clearFoodCategory
} = FoodCategorySlice.actions;
export default FoodCategorySlice.reducer;
