// import { EastIcon, arrowDouble } from "../../../../../../components/Image"
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Form } from "react-bootstrap";
import { upEnableVoucher } from "../../../../api/apiOwner/voucherApi";
import { getLanguageUI } from "../../../../Services/languageUI";
import { formatCurrencyVND } from "../../../../utils/format";

interface ContentTableGiftVoucherProps {
  no: number;
  voucherID: string;
  voucherName: string;
  discountValue: string;
  enable: number;
  startdate: string;
  enddate: string;
  type: number;
  quantity: number;
  quantity_used: number;
  is_never_expire?: number;
  remain?: number;
  send_to?: string;
  staff_name?: string;
  create_at: string;
}
const ContentTableGiftVoucher = ({
  voucherID,
  discountValue,
  no,
  voucherName,
  enable = 0,
  enddate,
  quantity,
  quantity_used,
  startdate,
  type,
  is_never_expire = 0,
  remain,
  send_to,
  staff_name,
  create_at,
}: // HandleEditSuccess

  ContentTableGiftVoucherProps) => {
  const languageUI = getLanguageUI().giftCard;
  const navigate = useNavigate();
  const [checked, setChecked] = useState<boolean>(false);

  const enableVoucher = async (id: string, enable: number) => {
    await upEnableVoucher(id, enable);
  };
  useEffect(() => {
    setChecked(enable === 1 ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <TableRow className="d-flex align-items-center">
        <TableCell className="text-269AFF text-14px fw-500  font-urbansist w-4 border-none">
          {no}
        </TableCell>
        <TableCell className="text-32324D text-14px fw-500  font-urbansist w-8 border-none">
          <Form.Check
            type="switch"
            id="custom-switch"
            className="h-20px w-36px mb-1"
            checked={checked}
            onChange={(e) => {
              setChecked(!checked);
              enableVoucher(voucherID, !checked ? 1 : 0);
            }}
          />
        </TableCell>
        <TableCell className="text-269AFF text-14px fw-500  font-urbansist w-12 border-none">
          {create_at}
        </TableCell>
        <TableCell
          align="left"
          className="w-12 text-32324D text-14px fw-500 font-urbansist  border-none"
        >
          {is_never_expire === 0 ? `${startdate}-${enddate}` : "Never expire"}
        </TableCell>

        <TableCell
          align="left"
          className="text-32324D text-14px fw-600 font-urbansist w-16 border-none"
        >
          {voucherName}
        </TableCell>
        <TableCell
          align="left"
          className="text-32324D text-14px fw-500 font-urbansist w-8 border-none"
        >
          {staff_name}
        </TableCell>
        <TableCell
          align="left"
          className="text-32324D text-14px fw-500 font-urbansist w-8 border-none"
        >
          {send_to}
        </TableCell>
        <TableCell
          align="right"
          className="text-32324D text-14px fw-500 font-urbansist w-6 border-none"
        >
          {remain ? `${formatCurrencyVND(remain)}` : 0}
        </TableCell>
        <TableCell
          align="right"
          className="text-32324D text-14px fw-500 font-urbansist w-8 border-none"
        >
          {type === 2 ? "$" + discountValue : discountValue + "%"}
        </TableCell>
        <TableCell
          align="right"
          className="text-32324D text-14px fw-500 font-urbansist w-6 border-none"
        >
          {quantity}
        </TableCell>
        <TableCell
          align="right"
          className="text-32324D text-14px fw-500 font-urbansist w-6 border-none"
        >
          {quantity_used}
        </TableCell>
        <TableCell
          align="right"
          className="text-blue text-14px fw-500 font-urbansist w-6 border-none"
          onClick={() => {
            navigate(
              "/web-owner/giftCardDetail/" + voucherID + "/" + is_never_expire
            );
          }}
        >
          {languageUI.detail}
        </TableCell>
      </TableRow>
    </>
  );
};
export default ContentTableGiftVoucher;
