
import { Button, Modal, FloatingLabel, Form } from "react-bootstrap";
// import "./Styles.css"
import { useEffect, useState } from "react";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface ModalCustomTipProps {
    show: boolean;
    valueTipCustom?: number | string;
    handleChangeInput?: (value?: number) => void;
    handleClose: () => void;
    DelCart?: () => void
}

const ModalCustomTip = ({
    show,
    handleChangeInput,
    valueTipCustom,
    handleClose,
    DelCart
}: ModalCustomTipProps) => {
    const languageUI = getLanguageCheckIn();
    const [tipNumber, setTipNumber] = useState<number | string>();

    useEffect(() => {
        if (show) {

            if (Number(valueTipCustom) > 0) {
                setTipNumber(valueTipCustom);
            } else {
                setTipNumber(0)
            }

        }
    }, [show])
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={true}
        >
            {/* <Modal.Header closeButton>

            </Modal.Header> */}
            <Modal.Body>
                <h4>{languageUI.PleaseEnterTip}</h4>
                <FloatingLabel
                    controlId="floatingInput"
                    label={languageUI.Entertip}
                    className="mb-3"

                >
                    <Form.Control
                        placeholder={languageUI.Entertip}
                        inputMode="numeric"
                        type="number"
                        value={tipNumber}
                        onChange={(e) => e.target.value ? setTipNumber(Number(e.target.value)) : setTipNumber(undefined)} />
                </FloatingLabel>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    {languageUI.Close}
                </Button>
                <Button variant="primary" onClick={() => { handleClose(); handleChangeInput?.(Number(tipNumber)) }}>
                    {languageUI.SaveChanges}
                </Button>
            </Modal.Footer>
        </Modal>

    );
};

export default ModalCustomTip;
