import * as Request from "../Request";

export interface Result<T> {
  status: number;
  message?: string;
  data: T;
}

export interface ResultListVoucher<T> {
  status: number;
  message?: string;
  data: T;
}

export interface VoucherUsed {
  id?: string;
  voucher_id?: string;
  voucher_code?: string;
  customer_id?: string;
  discount_value?: string;
  type?: string;
  voucher_name?: string;
  expry_date?: string;
}
export interface VoucherCheckPass {
  id?: string;
  voucher_id?: string;
  voucher_code?: string;
  customer_id?: string;
  discount_value?: string;
  type?: string;
  voucher_name?: string;
  expry_date?: string;
  is_end?: string;
  is_gifvoucher?: string;
  startdate?: string;
  enddate?: string;
  enable?: string;
  is_multi_use?: string;
  is_never_expire?: string;
}
export interface VoucherList {
  id: string;
  voucher_name: string;
  createat: string;
  expry_date: string;
  enable: string;
  discount_value: string;
  description: string;
  type: string;
  owner_id: string;
  voucher_code: string;
  type_name: string;
}
export interface voucherSameCode {
  id: string;
  voucher_name: string;
  createat: string;
  expry_date: string;
  enable: string;
  discount_value: string;
  description: string;
  type: string;
  owner_id: string;
  voucher_code: string;
  type_name: string;
}

export interface VoucherDetail {
  id?: string;
  voucher_name?: string;
  createat?: string;
  expry_date?: string;
  enable?: string;
  discount_value?: string;
  description?: string;
  type?: string;
  owner_id?: string;
}

export interface listVoucherTotal {
  listVoucher: VoucherList[];
  listVoucherSameCode: voucherSameCode[];
}

export const gListVoucherByOwner = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListVoucher.php`, "POST", { owner_id })
    .then((res: ResultListVoucher<listVoucherTotal>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: {
          listVoucher: [],
          listVoucherSameCode: [],
        },
      };
    });
};
export const CreateVoucherByOwner = async (
  owner_id: number,
  voucher_name: string,
  type: number,
  discount_value: number,
  expiry_date: number,
  is_multi_use: number,
  voucher_code: string
) => {
  return await Request.fetchAPI(`owner/create-voucher.php`, "POST", {
    owner_id,
    voucher_name,
    type,
    discount_value,
    expiry_date,
    is_multi_use,
    voucher_code,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const EditVoucherByOwner = async (
  voucher_id: string,
  voucher_name: string,
  type: number,
  discount_value: number,
  expiry_date: number
) => {
  return await Request.fetchAPI(`owner/edit-voucher.php`, "POST", {
    voucher_id,
    voucher_name,
    type,
    discount_value,
    expiry_date,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const gDetailVoucher = async (voucher_id: string) => {
  return await Request.fetchAPI(`owner/gDetailVoucher.php`, "POST", {
    voucher_id,
  })
    .then((res: Result<VoucherDetail>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: {},
      };
    });
};

export const upEnableVoucher = async (voucher_id: string, enable: number) => {
  return await Request.fetchAPI(`owner/upEnableVoucher.php`, "POST", {
    voucher_id,
    enable,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const DeleteVoucher = async (voucher_id: string, owner_id: number) => {
  return await Request.fetchAPI(`owner/deleteVoucher.php`, "POST", {
    voucher_id,
    owner_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const checkVoucher = async (voucherCode: string, owner_id: number) => {
  return await Request.fetchAPI(`owner/checkVoucher.php`, "POST", {
    voucherCode,
    owner_id,
  })
    .then((res: Result<VoucherCheckPass>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const checkOrderHaveVoucher = async (order_id: string) => {
  return await Request.fetchAPI(`owner/checkShowTabVoucher.php`, "POST", {
    order_id,
  })
    .then((res: Result<number>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: 0,
      };
    });
};

export const addGiftCardByPos = async (
  owner_id: number,
  voucher_name: string,
  discount_value: number,
  send_from: string,
  send_to: string,
  phone_send: string,
  email_send: string,
  schedule_send: string,
  staff_id: number,
  is_now: number
) => {
  return await Request.fetchAPI(`owner/createGiftCardByPos.php`, "POST", {
    owner_id,
    voucher_name,
    discount_value,
    send_from,
    send_to,
    phone_send,
    email_send,
    schedule_send,
    staff_id,
    is_now,
  })
    .then((res: Result<number>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: 0,
      };
    });
};
//tiến hành thanh toán
export const PaymentIntentGift = async (
  owner_id: number,
  paymentIntents: string,
  pos_id: number
) => {
  return await Request.fetchAPI(`stripe/paymentWithReader.php`, "POST", {
    owner_id,
    paymentIntents,
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
        secret: "",
        tipAmount: 0,
      };
    });
};
