import React, { useEffect, useState } from "react";
import {
  CardPaymentNotification,
  DishesOrderNotification,
  SupportNotification,
} from "./SVGChangeColor/SVGChangeColor";
import "./Styles.css";
import { upIsreadNotificationtoSeenbyId } from "../api/apiOwner/notificationApi";
import { useNavigate } from "react-router-dom";
import { getLanguageUI } from "../Services/languageUI";

interface ItemsNotificationProps {
  status: "order" | "payment" | "support";
  table_name: string;
  body: string;
  order_id: string;
  tableId: string;
  floorId: string;
  is_read: string;
  notificationId: string;
}

const ItemsNotification = ({
  status,
  table_name,
  body,
  order_id,
  floorId,
  tableId,
  is_read = "0",
  notificationId,
}: ItemsNotificationProps) => {
  const languageUI = getLanguageUI().notification;
  const [newNotification, setNewNotification] = useState(true);
  const navigator = useNavigate();
  const ReadNotication = async () => {
    setNewNotification(false);
    const req = await upIsreadNotificationtoSeenbyId(notificationId);
    if (Number(req?.status) === 1) {
      navigator("/web-owner/tablepos", {
        state: { tableId, order_id, floorId },
      });
    }
  };
  useEffect(() => {
    if (Number(is_read) === 2) {
      setNewNotification(false);
    } else {
      setNewNotification(true);
    }
  }, [notificationId, is_read]);
  return (
    <>
      <div
        className={`box-items-notification position-relative ${
          newNotification ? "border-DCDCE4" : ""
        }`}
        onClick={() => ReadNotication()}
      >
        <div className="d-flex gap-2">
          <div className="box-img-notification-items">
            {status === "order" ? (
              <DishesOrderNotification fill="#0B2F88" />
            ) : status === "support" ? (
              <SupportNotification fill="#0B2F88" />
            ) : (
              <CardPaymentNotification fill="#0B2F88" />
            )}
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex gap-1">
              <p className="mb-0 text-items-notification text-4a4a6a">
                {table_name ?? "Pickup"}
              </p>
              <p className="mb-0 text-A5A5BA-2 text-items-notification d-flex gap-1">
                ({languageUI.orderNumber}:{" "}
                <p className="mb-0 text-12B064 text-items-notification">
                  {order_id}
                </p>
                )
              </p>
            </div>
            <p className="text-option-status-note mb-0">{body}</p>
          </div>
        </div>
        <div
          className={`new-notification ${newNotification ? "" : "d-none"}`}
        ></div>
      </div>
    </>
  );
};

export default ItemsNotification;
