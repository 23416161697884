import { useEffect, useState } from 'react'
import { chevronRightWhite } from '../../../../components/ImgExport'
import './Styles.css'
import BillORderCounterHistory from './BillORderCounterHistory'
import { useNavigate } from 'react-router-dom'
import * as ketchenApi from '../../../../api/apiOwner/ketchenApi';
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from '../../../../components/ModalLoading'
import Swal from "sweetalert2";
import { getLanguageUI } from '../../../../Services/languageUI'

const KitchenCounterHistory = () => {
    const languageUI = getLanguageUI().kitchenCounter
    const navigate = useNavigate()
    const [ShowLoading, setShowLoading] = useState<boolean>(false);
    const UserIdStore = useSelector(userSlice.selectorUserId);
    const [listOrder, setListOrder] = useState<ketchenApi.OrderWaiting[]>([])

    const gOrderComplete = async () => {
        if (UserIdStore) {
            const req = await ketchenApi.gOrderKetchenCompleted(UserIdStore)
            if (Number(req?.status) === 1) {
                setListOrder(req?.data ?? [])
                setShowLoading(false)
            } else {
                setShowLoading(false)
            }
        }
    }
    const RestoreOrder = async (order_id: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {
                SubmitRestore(order_id)
            }
        })
    }
    const SubmitRestore = async (orderId: string) => {
        setShowLoading(true)
        const req = await ketchenApi.RestoreStatusOrder(orderId);
        if (Number(req?.status) === 1) {
            gOrderComplete()
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Complete",
                showConfirmButton: false,
                timer: 1000,
            });
        } else {
            Swal.fire({
                position: "center",
                icon: "error",
                title: req?.message ?? "Update Fail",
                showConfirmButton: false,
                timer: 3000,
            });
            setShowLoading(false)
        }
    }
    useEffect(() => {
        setShowLoading(true)
        gOrderComplete()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="w-100 h-100vh bg-F3F5F7">
            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
            <div className='custom-position-fixed z-10'>
                <div className="position-relative">
                    <div className={`header-kitchen-counter`}>
                        <div className="d-flex align-items-center gap-1"><span className="mb-0 text-home-header-counter">{languageUI.home} / <span className="mb-0 text-request-header-counter" >{languageUI.history}</span></span></div>
                        <div className='btn-history-counter' onClick={() => navigate('/kitchencounter')}>
                            <img src={chevronRightWhite} alt="" className='rotate-180deg' />
                            {languageUI.backToRequest}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`custom-column-counter-food px-48px  pt-48px w-100 mt-76px overflow-x-scroll scroll-hidden h-calc-100vh-121px`}>
                {listOrder.map((item, i) => (
                    <BillORderCounterHistory listFood={item?.listFood} orderId={item?.id} tableName={item?.table_name} timeOrder={item?.timeProduct} key={`order${i}`} RestoreOrder={RestoreOrder} />
                ))}
            </div>
        </div>
    )
}
export default KitchenCounterHistory