import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { handClick } from "../../../../../components/ImgExport"
import React, { useState } from "react";
import * as userSlice from "../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { changeCardNumberV3 } from "../../../../../api/apiOwner/Subscription";
import ModalLoading from "../../../../../components/ModalLoading";
import { getLanguageUI } from "../../../../../Services/languageUI";
interface ChangeCardFormProps {
  handleClose: (status?: boolean) => void;
}
const ChangeCardForm = ({ handleClose }: ChangeCardFormProps) => {
  const languageUI = getLanguageUI().subscriptionPage
  const [messageError, setMessageError] = useState('');
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [showLoading, setShowLoading] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const SubmitChange = async (e: any) => {
    // if (bankNumber.length !== 16) {
    //     setMessageError('Invalid card number')
    // } else if (bankDateCard.length !== 7) {
    //     setMessageError('Invalid date card')
    // } else if (bankCVC === '') {
    //     setMessageError('Please enter cvc')
    // } else {
    //     setMessageError('')
    //     setShowLoading(true)
    // const req = await SubscriptionAPI.changeCardNumber(UserIdStore, bankNumber, bankCVC, bankDateCard);
    //     if (Number(req?.status) === 1) {
    //         setShowLoading(false)
    //         handleClose(true)
    //     } else {
    //         setMessageError(req?.message ?? '')
    //         setShowLoading(false)
    //     }
    // }
    e.preventDefault();

    setShowLoading(true)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      setMessageError('Make sure to disable form submission until Stripe.js has loaded.')
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (cardElement) {
      const { token, error } = await stripe.createToken(cardElement);
      if (error) {
        // Handle errors with the card element
        setMessageError(error?.message ?? '')
        console.error(error);
      } else {
        if (token) {
          setMessageError('')
          // console.log(token);
          // handleCreateToken(token?.card?.last4 ?? '', token);
          const req = await changeCardNumberV3(UserIdStore ?? 0, token?.id, token?.card?.last4 ?? '');
          if (Number(req?.status) === 1) {
            setShowLoading(false)
            handleClose(true)
          } else {
            setMessageError(req?.message ?? '')
            setShowLoading(false)
          }
        } else {
          setShowLoading(false)
          setMessageError('Connect fail!')
        }


      }
    }
  }
  return (
    <>
      <ModalLoading show={showLoading} onHide={() => setShowLoading(false)} />
      <form onSubmit={SubmitChange} className='m-0 p-0'>
        {messageError !== '' && (
          <div className="text-danger text-center ">{messageError}</div>
        )}
        <div className="row m-0 px-3">
          {/* <CardElement /> */}
          <div className="col-12 mt-2">
            <div className="w-100 border  rounded-16px  py-3 px-2  border">
              <label className='w-100'>
                {languageUI.cardNumber}
                <CardNumberElement />
              </label>

            </div>
          </div>
          <div className="col-7 mt-2" >
            <div className="w-100 border w-100 rounded-16px py-3 px-2   border">
              <label className='w-100'>
                {languageUI.expiryDate}
                <CardExpiryElement />
              </label>
            </div>

          </div>
          <div className="col-5 mt-2">
            <div className="w-100 border w-100 rounded-16px py-3 px-2   border">
              <label className='w-100'>
                CVC
                <CardCvcElement />
              </label>
            </div>
          </div>

        </div>
        <div className="w-100 d-flex justify-content-center">
          <button className="btn-check-in-out d-flex flex-column justify-content-center mt-3 align-items-center bg-btn-check-in-clock" type='submit'>
            <img src={handClick} alt="" className="mb-1" />
            {languageUI.submit}
          </button>
        </div>
      </form></>
  )
}
export default ChangeCardForm