import "./Styles.css";
import { useEffect, useState } from "react";
import { formatCurrencyVND, FormatDolla } from "../../../../utils/format";
import {
  cartBagOrange,
  minusOrange,
  plusOrange,
  storeOrange,
} from "../../../../components/ImgExport";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface OrderFoodCardItemProps {
  textStatus: string;
  status: boolean;
  notefood_name?: string;
  food_id?: string;
  cart_id?: string;
  price?: number;
  priceOld?: number;
  foodName?: string;
  notechef?: string;
  quantity?: number;
  service_item_id?: string;
  side_name?: string;
  table_id?: string;
  dismisBtnDelete?: boolean;
  totalOnFood?: number;
  insufficient?: boolean;
  slcon?: number;
  showHappyHour?: number;
  DelCart?: (cartid: string) => void;
  UpdateQuantityCart?: (cartid: string, quantitynew: number) => void;
}
const OrderFoodCardItem: React.FC<OrderFoodCardItemProps> = ({
  status,
  textStatus,
  notefood_name,
  food_id,
  cart_id,
  price = 0,
  foodName,
  notechef,
  quantity = 0,
  service_item_id,
  side_name,
  table_id,
  dismisBtnDelete,
  totalOnFood,
  insufficient,
  slcon = 0,
  priceOld = 0,
  showHappyHour = 0,
  DelCart,
  UpdateQuantityCart,
}) => {
  const [quantityTmp, setQuantityTmp] = useState<number>(0);
  const languageUI = getLanguageCheckIn();
  useEffect(() => {
    setQuantityTmp(Number(quantity));
  }, [service_item_id, quantity]);
  const handClickPlus = () => {
    if (cart_id) {
      UpdateQuantityCart?.(cart_id, quantityTmp + 1);
      setQuantityTmp(quantityTmp + 1);
    }
  };
  const handClickMinus = () => {
    if (cart_id) {
      if (quantityTmp > 1) {
        UpdateQuantityCart?.(cart_id, quantityTmp - 1);
        setQuantityTmp(quantityTmp - 1);
      } else {
        DelCart?.(cart_id);
      }
    }
  };

  return (
    <div className="order-food-items">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="name-food mb-1">{foodName}</p>
          {/* <div className="d-flex gap-2px">
            <div className="text-dollar">$</div>
            <div className="text-price-food">{FormatDolla(price)}</div>
          </div> */}
          {showHappyHour > 0 ? (
            <div className=" position-relative d-flex gap-2">
              <div className="d-flex gap-2px">
                {/* <div className="text-dollar-old">$</div> */}
                <del className="text-price-food-old">
                  {formatCurrencyVND(priceOld)}
                </del>
              </div>
              <div className="d-flex gap-2px">
                {/* <div className="text-dollar">$</div> */}
                <div className="text-price-food">{formatCurrencyVND(price)}</div>
              </div>
            </div>
          ) : (
            <div className="d-flex gap-2px">
              {/* <div className="text-dollar">$</div> */}
              <div className="text-price-food">{formatCurrencyVND(price)}</div>
            </div>
          )}
          <div className="d-flex align-items-center gap-2">
            {notefood_name && (
              <div className="status-items-order mt-1">
                <p className="mb-0 text-items-order">{notefood_name}</p>
              </div>
            )}

            <div className="status-items-order mt-1">
              <img src={status == false ? cartBagOrange : storeOrange} alt="" />
              <p className="mb-0 text-items-order">
                {status == false ? languageUI.Dinein : languageUI.Takeaway}
              </p>
            </div>
          </div>
          {side_name && (
            <div className="mb-2">
              <div className="d-flex gap-1 mt-1">
                <p className="mb-0 text-choose-a-side text-666687">
                  + {side_name}
                </p>{" "}
                <p className="mb-0 text-choose-a-side text-12B064">(Free x1)</p>
              </div>
            </div>
          )}

          {notechef && (
            <p className="text-note-information-card-items mb-0">{notechef}</p>
          )}
        </div>
        <div className="d-flex flex-column align-items-center gap-8px">
          <button className="btn-number-order" onClick={handClickPlus}>
            <img src={plusOrange} alt="" />
          </button>
          <p className="mb-0 text-number-order">{quantity}</p>
          <button className="btn-number-order" onClick={handClickMinus}>
            <img src={minusOrange} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderFoodCardItem;
