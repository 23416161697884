import { ApexOptions } from "apexcharts";
import { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { Col, Row } from "react-bootstrap";
import HyperDatepicker from "../../components/Datepicker";
import chartDataLabel from "./components/dataChartLabel/dataChart.json";

// components
import PageTitle from "../../components/PageTitle";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";
import * as CustomerOverviewAdminAPI from "../../api/apiAdmin/CustomerOverviewAdmin";
import moment from "moment-timezone";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

const CustomerOverviewAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation()

  const UserIdStore = useSelector(userSlice.selectorUserId);
  const languageUI = getLanguageCheckIn();
  const [Total, setTotal] = useState<number>(0);
  const [BM, setBM] = useState<number>(0);
  const [BY, setBY] = useState<number>(0);
  const [B5Y, setB5Y] = useState<number>(0);
  const [LM, setLM] = useState<number>(0);
  const [LY, setLY] = useState<number>(0);
  const [L5Y, setL5Y] = useState<number>(0);
  const [SM, setSM] = useState<number>(0);
  const [SY, setSY] = useState<number>(0);
  const [S5Y, setS5Y] = useState<number>(0);
  const [Message, setMessage] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

  const newday = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
  const dateNow = newday.getDate();
  const monthNow =
    Number(newday.getMonth()) + 1 < 10
      ? "0" + (Number(newday.getMonth()) + 1)
      : newday.getMonth() + 1;
  const yearNow = newday.getFullYear();
  const startDateFormat = useRef<any>(yearNow + "/" + monthNow + "/" + dateNow);
  const endDateFormat = useRef<any>(yearNow + "/" + monthNow + "/" + dateNow);

  const onDateChangeStart = (date: Date) => {
    if (date) {
      setSelectedStartDate(date);
      var month =
        Number(date.getMonth()) + 1 < 10
          ? "0" + (Number(date.getMonth()) + 1)
          : date.getMonth() + 1;
      var year = date.getFullYear();
      var day =
        Number(date.getDate()) < 10
          ? "0" + Number(date.getDate())
          : date.getDate();
      startDateFormat.current = year + "/" + month + "/" + day;
      GetInfomationRevenue();
    }
  };
  const onDateChangeEnd = (date: Date) => {
    if (date) {
      setSelectedEndDate(date);
      var month =
        Number(date.getMonth()) + 1 < 10
          ? "0" + (Number(date.getMonth()) + 1)
          : date.getMonth() + 1;
      var year = date.getFullYear();
      var day =
        Number(date.getDate()) < 10
          ? "0" + Number(date.getDate())
          : date.getDate();
      endDateFormat.current = year + "/" + month + "/" + day;
      GetInfomationRevenue();
    }
  };

  const GetInfomationRevenue = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const InCome = await CustomerOverviewAdminAPI.InCome(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current
      );
      if (Number(InCome.status) === 1) {
        setTotal(Number(InCome.data[0].total));
        setBM(Number(InCome.data[0]?.BM));
        setBY(Number(InCome.data[0].BY));
        setB5Y(Number(InCome.data[0].B5Y));
        setLM(Number(InCome.data[0].LM));
        setLY(Number(InCome.data[0].LY));
        setL5Y(Number(InCome.data[0].L5Y));
        setSM(Number(InCome.data[0].SM));
        setSY(Number(InCome.data[0].SY));
        setS5Y(Number(InCome.data[0].S5Y));
        setMessage("");
      } else if (Number(InCome?.status) === 3) {
        // Đăng nhập sai trang (nhầm trang admin và owner)
        setShowLoading(false);
        navigate("/auth/logout");
        setMessage(
          InCome?.message
            ? InCome?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      } else {
        setTotal(0);
        setBM(0);
        setBY(0);
        setB5Y(0);
        setLM(0);
        setLY(0);
        setL5Y(0);
        setSM(0);
        setSY(0);
        setS5Y(0);
        setMessage(
          InCome?.message
            ? InCome?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
      }

      setShowLoading(false);
    } else {
      setShowLoading(false);
      navigate("/web-admin/login");
    }
  };

  const apexOpts: ApexOptions = {
    chart: {
      height: 378,
      type: "line",
      offsetY: 10,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
      marker: {
        show: false,
      },
    },

    stroke: {
      show: false,
      width: [2, 3],
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
      },
    },
    colors: [
      "#1A66C0",
      "#F3BA00",
      "#FF98B9",
      "#39B44A",
      "#F7A9A0",
      "#FF0505",
      "#D591ED",
      "#4D455D",
      "#E96479",
      "#7DB9B6",
    ],
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        let x = value.toString();
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(".") + " $";
      },
      offsetY: -12,
      distributed: true,
    },
    labels: ["Total", "BM", "BY", "B5Y", "LM", "LY", "L5Y"],
    // legend: {
    //     offsetY: 7,
    //     labels: {
    //         colors: colors,
    //         useSeriesColors: false
    //     },
    // },
    grid: {
      padding: {
        bottom: 20,
        left: 35,
        right: 35,
      },
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,

      labels: {
        formatter: function (value) {
          let x = value.toString();
          var parts = x.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          return parts.join(".") + " $";
        },
      },
    },
  };

  const series = [
    {
      // name: 'Revenue',
      type: "column",

      data: [
        {
          x: "Total",
          y: Total,
          // y: 225000,
          fillColor: "#1A66C0",
        },
        {
          x: "BM",
          y: BM,
          fillColor: "#F3BA00",
          label: "abc",
        },
        {
          x: "BY",
          y: BY,
          fillColor: "#FF98B9",
        },
        {
          x: "B5Y",
          y: B5Y,
          fillColor: "#39B44A",
        },
        {
          x: "LM",
          y: LM,
          fillColor: "#F7A9A0",
        },
        {
          x: "LY",
          y: LY,
          fillColor: "#FF0505",
        },
        {
          x: "L5Y",
          y: L5Y,
          fillColor: "#D591ED",
        },
        {
          x: "SM",
          y: SM,
          fillColor: "#4D455D",
        },
        {
          x: "SY",
          y: SY,
          fillColor: "#E96479",
        },
        {
          x: "S5Y",
          y: S5Y,
          fillColor: "#7DB9B6",
        },
      ],
    },
    // {
    //     name: 'Sales',
    //     type: 'line',
    //     data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
    // },
  ];

  let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
  let menuItemElOwner = document?.querySelectorAll(".menu-owner");
  menuItemElAdmin.forEach((item, i) => {
    menuItemElAdmin[i]?.classList.remove("d-none");
  });
  menuItemElOwner.forEach((item, i) => {
    menuItemElOwner[i]?.classList.add("d-none");
  });

  useEffect(() => {
    if (UserIdStore) {
      GetInfomationRevenue();
    }
  }, [location.pathname, UserIdStore]);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Customer", path: "#" },
          { label: "Overview", path: "#", active: true },
        ]}
        title={"Customer Overview"}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="py-3 px-3 rounded-3 mb-3 bg-white shadow">
        <div className="d-flex align-items-center flex-wrap filter-chart">
          <h4 className="fw-bold">{languageUI.Income}</h4>
          <div className="filter-date-chart ms-auto">
            <Row className="align-items-end justify-content-end mx-n1 w-100">
              <Col xs={12} sm={12} md={5} lg={5} xl={5} className="px-1">
                <div>
                  <label className="form-label">{languageUI.Datestart}</label>
                  <br />
                  <HyperDatepicker
                    hideAddon={true}
                    value={selectedStartDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      onDateChangeStart(date);
                    }}
                  // showMonthYearPicker
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={2} lg={2} xl={2} className="px-1">
                <div className="text-center form-control px-0 border-0">{languageUI.to}</div>
              </Col>
              <Col xs={12} sm={12} md={5} lg={5} xl={5} className="px-1">
                <div>
                  <label className="form-label">{languageUI.Datefinish}</label> <br />
                  <HyperDatepicker
                    hideAddon={true}
                    value={selectedEndDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      onDateChangeEnd(date);
                    }}
                  // showMonthYearPicker
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Chart
          options={apexOpts}
          series={series}
          type="line"
          height={380}
          className="apex-charts mt-4"
        />
        <Col xs={12} sm={12} md={10} lg={10} className="mx-auto">
          <div>
            <Row className="mt-n2">
              {chartDataLabel.map((item, index) => (
                <Col key={index} xs={12} sm={12} md={3} lg={3} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div
                      className="rounded-circle shadow-sm me-2"
                      style={{
                        height: 20,
                        width: 20,
                        backgroundColor: `${item.color}`,
                      }}
                    ></div>
                    <div className="fw-bold fs-6">{item.name}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </div>
    </>
  );
};

export default CustomerOverviewAdmin;
