import { useEffect, useState } from "react";
import { withSwal } from "react-sweetalert2";
import html2canvas from "html2canvas";

// components
import PageTitle from "../../components/PageTitle";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import * as Request from "../../api/Request";
import * as tableApi from "../../api/apiOwner/tableApi";
import QRCodeItems from "./components/QRCodeItems/QRCodeItems";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import ModalLoading from "../../components/ModalLoading";
import {
  imgDetailQr,
  qrCodeDecoBottomLeft,
  qrCodeDecoTopRight,
} from "../../components/Image";
import { camera, subtract } from "../../components/ImgExport";
import { getLanguageUI } from "../../Services/languageUI";

const QRCodeOwner = withSwal((props: any) => {
  const languageUI = getLanguageUI().qrCode;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [listTable, setListTable] = useState<tableApi.TableInfo[]>([]);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [nameBranch, setNameBranch] = useState<string>();

  //Get info owner

  const getInforOwner = async (id_owner: any) => {
    await Request.fetchAPI(`checkin/gInfoOwner.php?id=${id_owner}`, "get").then(
      (res) => {
        if (Number(res?.status) === 1) {
          setNameBranch(res?.data?.name);
        }
      }
    );
  };
  const gListTable = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const ListTB = await tableApi.gListAllTable(UserIdStore, 1);
      if (Number(ListTB.status) === 1) {
        setListTable(ListTB.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  useEffect(() => {
    if (UserIdStore) {
      getInforOwner(UserIdStore);
      gListTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function downloadURI(uri: string, name: string) {
    var link = document.createElement("a");

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    // clearDynamicLink(link);
    setShowLoading(false);
  }

  function DownloadAsImage(element: any, name: string) {
    html2canvas(element).then(function (canvas) {
      var myImage = canvas.toDataURL();
      downloadURI(myImage, name);
    });
  }
  // const downAll = () => {
  // listTable.map(async (item, i) => {
  //   const el: any = document.querySelector(".QRTableItem" + item?.id);
  //   await DownloadAsImage(el, item?.table_name + ".png");
  // })=
  // }

  const downOne = (id: string, name: string) => {
    setShowLoading(true);
    const el: any = document.querySelector(".QRTableItem" + id);
    DownloadAsImage(el, name + ".jpeg");
  };

  function convertAndDownloadAll() {
    const contents = document.querySelectorAll(".QRTable");
    console.log("Đang tải....");

    const contentArray = Array.from(contents);

    function convertAndDownloadNext() {
      if (contentArray.length > 0) {
        const content: any = contentArray.shift();

        const filename =
          listTable[contents.length - contentArray.length - 1]?.table_name +
          ".png";

        html2canvas(content).then(function (canvas) {
          const imgURL = canvas.toDataURL("image/png");
          const downloadLink = document.createElement("a");
          downloadLink.href = imgURL;
          downloadLink.download = filename;

          // Simulate a click event to trigger the download
          downloadLink.click();

          // Chuyển đổi và tải về tiếp theo
          convertAndDownloadNext();
        });
      }
    }

    // Bắt đầu chuyển đổi và tải về từng phần tử một
    convertAndDownloadNext();
  }

  return (
    <>
      <div className="">{/* <Topbar pageName="QR Code" /> */}</div>
      <PageTitle
        breadCrumbItems={[
          { label: "QR Code", path: "#" },
          { label: "Scan QR Code", path: "#", active: true },
        ]}
        title={"QR Code"}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="pb-5 pt-3 px-4 h-body-qr-code">
        <div className=" w-fit px-3 py-3 mb-2 custom-btn-print-download-all border d-flex flex-column z-20 ">
          <div className=" d-flex  justify-content-end  w-100">
            <button
              className="btn-download-print-download d-flex align-items-center px-3 gap-2 py-2 w-100"
              onClick={convertAndDownloadAll}
            >
              <SimCardDownloadOutlinedIcon className="text-FF7B2C h-24px w-24px" />
              <p className="mb-0 text-FF7B2C text-btn-print-download">
                {languageUI.downloadAll}
              </p>
            </button>
          </div>
          {/* <div className=" d-flex  justify-content-end w-100">
            <button className="btn-download-print-download d-flex align-items-center px-3 gap-2 py-2 w-100">
              <PrintIcon className="text-FF7B2C h-24px w-24px" />
              <p className="mb-0 text-FF7B2C text-btn-print-download" onClick={PrintCard}>{languageUI.printAll}</p>
            </button>
          </div> */}
        </div>
        <div className="d-flex flex-wrap gap-3">
          {listTable.map((item, i) => (
            <QRCodeItems
              classRef={` QRTableItem${item?.id}`}
              NameFloor={item?.floor_name}
              NameTable={item?.table_name ?? ""}
              tableId={item?.id ?? ""}
              nameBranch={nameBranch}
              key={"tb" + i}
              downOne={downOne}
              QRCode={
                <QRCode
                  style={{ width: "100%", height: "fit-content" }}
                  id="qrcode"
                  value={`${Request.URL_QRCode}/${UserIdStore}/${item?.id}`}
                  level={"H"}
                />
              }
            />
          ))}
        </div>
        <div
          className="position-fixed "
          style={{ top: "1000000px", right: "10000px" }}
        >
          <div id="printcard-front">
            <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
              {listTable.length > 0 &&
                listTable.map((item, i) => (
                  <div
                    className="#page-break w-100 d-flex justify-content-center align-items-center "
                    style={{
                      pageBreakBefore: "always",
                      backgroundColor: "white",
                    }}
                    key={i}
                  >
                    {/* <div
                      className={`w-370px mb-3 bg-white`}
                      style={{
                        width: "370px",
                        marginBottom: "12px",
                        flexShrink: 0,
                        borderRadius: "40px",
                      }}
                    >
                      <div
                        className={` rounded-5 bg-white border-bottom border-primary shadow position-relative bg-white  QRTable`}
                        style={{
                          borderRadius: "40px",
                          backgroundColor: "white",
                          borderBottom: " 1px solid #d7d7d7",
                          position: "relative",
                          borderColor: "#0B2F88 !important",
                          boxShadow:
                            "0px 0px 35px 0px rgba(154, 161, 171, 0.15)",
                          overflow: "hidden",
                          padding: "50px",
                        }}
                        id="QR_img"
                      >
                        <div
                          className="bg-white"
                          style={{ backgroundColor: "white" }}
                        >
                          <img
                            src={qrCodeDecoBottomLeft}
                            style={{
                              position: "absolute",
                              left: 0,
                              bottom: 30,
                              height: 135,
                              zIndex: 0,
                            }}
                            alt=""
                          />
                          <img
                            src={qrCodeDecoTopRight}
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 30,
                              height: 135,
                              zIndex: 0,
                            }}
                            alt=""
                          />
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "8px",
                            }}
                          >
                            <div
                              style={{
                                width: "fit-content",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "12px",
                                overflow: "hidden",
                                zIndex: 10,
                                position: "relative",
                              }}
                            >
                              <div
                                id="name-table-print"
                                style={{
                                  backgroundColor: "#FF7B2C",
                                  paddingTop: "4px",
                                  paddingBottom: "4px",
                                  paddingLeft: "24px",
                                  paddingRight: "24px",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  gap: "2px",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    fontFamily: "Roboto",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                    lineHeight: "22px",
                                    letterSpacing: "0px",
                                    color: "white",
                                    width: "50px",
                                  }}
                                >
                                  {item.table_name}
                                </p>
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "22px",
                                    letterSpacing: "0px",
                                    color: "white",
                                    width: "50px",
                                  }}
                                >
                                  {languageUI.floor} {item.floor_name}
                                </p>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#0B2F88",
                                  paddingLeft: "24px",
                                  paddingRight: "24px",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 0,
                                    fontFamily: "Roboto",
                                    fontSize: "28px",
                                    fontWeight: 600,
                                    lineHeight: "30px",
                                    letterSpacing: "-0.5px",
                                    color: "white",
                                  }}
                                >
                                  {nameBranch}
                                </p>
                              </div>
                            </div>
                          </div>
                          <h5
                            style={{
                              textAlign: "center",
                              marginBottom: 0,
                              fontFamily: "Roboto",
                              fontSize: "20px",
                              fontWeight: 600,
                              lineHeight: "30px",
                              letterSpacing: "-0.5px",
                              color: "#212134",
                            }}
                          >
                            {languageUI.scanToView}
                            <br />
                          </h5>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              justifyContent: "center",
                              marginBottom: "8px",
                            }}
                          >
                            <h3
                              style={{
                                textAlign: "center",
                                marginTop: "0px",
                                marginBottom: "0px",
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "26px",
                                letterSpacing: "0px",
                                color: "#8E8EA9",
                              }}
                            >
                              {languageUI.byUsingYourPhone}
                            </h3>
                            <img src={camera} alt="" />
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "8px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className="center-qr-code"
                              style={{
                                width: "77%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                                position: "relative",
                              }}
                            >
                              <img
                                src={subtract}
                                className="img-fluid w-100"
                                style={{
                                  width: "100%",
                                }}
                                alt=""
                              />
                              <div
                                style={{
                                  width: "80%",
                                  height: "fit-content",
                                  borderRadius: "16px",
                                  position: "absolute",
                                  right: 0,
                                  left: 0,
                                  textAlign: "center",
                                  margin: "auto",
                                  marginLeft: "20px",
                                }}
                              >
                                <QRCode
                                  style={{
                                    width: "100%",
                                    height: "fit-content",
                                  }}
                                  id="qrcode"
                                  value={`${Request.URL_QRCode}/${UserIdStore}/${item?.id}`}
                                  level={"H"}
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                marginBottom: "0",
                                paddingRight: "35px",
                                marginTop: "4px",
                                textAlign: "right",
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "22px",
                                letterSpacing: "0px",
                                color: "#A5A5BA",
                              }}
                            >
                              - BriJack POS -
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "12px",
                              marginBottom: "8px",
                            }}
                          >
                            <h5
                              style={{
                                width: "300px",
                                textAlign: "center",
                                marginBottom: 0,
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "26px",
                                letterSpacing: "0px",
                                color: "#A5A5BA",
                              }}
                            >
                              {languageUI.orderDirectlyIn}
                              <br style={{ textAlign: "center" }}></br>
                              {languageUI.bringYourOrder}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div
                      className="w-100 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        className={`w-370px mb-3  QRTable`}
                        style={{
                          width: "370px",
                          marginBottom: "12px",
                          flexShrink: 0,
                          backgroundColor: "white",
                          borderRadius: "16px",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          className={`bg-white border-bottom border-primary shadow position-relative`}
                          style={{
                            borderRadius: "16px",
                            padding: "18px 24px",
                            backgroundColor: "white",
                            border: " 1px solid #0B2F88",
                            position: "relative",
                            // borderColor: "#0B2F88 !important",

                            overflow: "hidden",
                          }}
                          id="QR_img"
                        >
                          <div
                            className="bg-white"
                            style={{ backgroundColor: "white" }}
                          >
                            {/* <img
                  src={qrCodeDecoBottomLeft}
                  alt=""
                  style={{ position: "absolute", left: 0, bottom: 30, height: 135, zIndex: 0 }}
                />
                <img
                  src={qrCodeDecoTopRight}
                  alt=""
                  style={{ position: "absolute", right: 0, top: 30, height: 135, zIndex: 0 }}
                /> */}
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "8px",
                                backgroundColor: "white",
                              }}
                            >
                              <div
                                style={{
                                  width: "fit-content",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "12px",
                                  overflow: "hidden",
                                  zIndex: 10,
                                  position: "relative",
                                  backgroundColor: "white",
                                }}
                              >
                                <div
                                  id="name-table-print"
                                  style={{
                                    backgroundColor: "#FF7B2C",
                                    paddingTop: "4px",
                                    paddingBottom: "4px",
                                    paddingLeft: "24px",
                                    paddingRight: "24px",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    printColorAdjust: "exact",
                                    zIndex: "20",
                                    flexDirection: "column",
                                    gap: "2px",
                                    flexShrink: 0,
                                  }}
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginBottom: 0,
                                      fontFamily: "Roboto",
                                      fontSize: "20px",
                                      fontWeight: 500,
                                      lineHeight: "normal",
                                      letterSpacing: "0px",
                                      color: "white",
                                    }}
                                  >
                                    {item.table_name}
                                  </p>
                                  {/* <p
                                    style={{
                                      textAlign: "center",
                                      marginBottom: 0,
                                      fontFamily: "Roboto",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      lineHeight: "normal",
                                      letterSpacing: "0px",
                                      color: "white",
                                      display: "flex",
                                      flexWrap: "nowrap",
                                      flexShrink: 0,
                                    }}
                                  >
                                    Floor {item.floor_name}
                                  </p> */}
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#0B2F88",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginBottom: 0,
                                      fontFamily: "Roboto",
                                      fontSize: "18px",
                                      fontWeight: 600,
                                      lineHeight: "normal",
                                      letterSpacing: "0px",
                                      color: "white",
                                      alignItems: "center",
                                    }}
                                  >
                                    {nameBranch}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "8px",
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "white",
                              }}
                            >
                              <div
                                className="center-qr-code"
                                style={{
                                  width: "77%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "12px",
                                  position: "relative",
                                  backgroundColor: "white",
                                }}
                              >
                                <div style={{ width: "60%" }}>
                                  <h5
                                    style={{
                                      textAlign: "center",
                                      marginBottom: 0,
                                      fontFamily: '"Jua", sans-serif',
                                      fontSize: "20px",
                                      fontWeight: 600,
                                      lineHeight: "normal",
                                      letterSpacing: "-0.5px",
                                      color: "#212134",
                                      width: "100%",
                                    }}
                                  >
                                    {languageUI.scanQRCodeNow}
                                    <br />
                                  </h5>
                                </div>
                                <div
                                  style={{
                                    width: "40%",
                                    height: "fit-content",
                                    borderRadius: "16px",
                                    right: 0,
                                    left: 0,
                                    textAlign: "center",
                                    margin: "auto",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <QRCode
                                    style={{
                                      width: "100%",
                                      height: "fit-content",
                                    }}
                                    id="qrcode"
                                    value={`${Request.URL_QRCode}/${UserIdStore}/${item?.id}`}
                                    level={"H"}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                justifyContent: "center",
                                marginBottom: "8px",
                                marginTop: "12px",
                              }}
                            >
                              <h3
                                style={{
                                  textAlign: "center",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontFamily: "Roboto",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  lineHeight: "normal",
                                  letterSpacing: "0px",
                                  color: "#8E8EA9",
                                }}
                              >
                                {languageUI.byUsingYourPhone}
                              </h3>
                              <img src={camera} alt="" />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "white",
                              }}
                            >
                              <h5
                                style={{
                                  textAlign: "center",
                                  marginBottom: 0,
                                  fontFamily: '"Jua", sans-serif',
                                  fontSize: "20px",
                                  fontWeight: 600,
                                  lineHeight: "normal",
                                  letterSpacing: "-0.5px",
                                  color: "#212134",
                                  width: "200px",
                                }}
                              >
                                {languageUI.doYouWantToFind}
                                <br />
                              </h5>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "8px",
                                backgroundColor: "white",
                              }}
                            >
                              <img
                                src={imgDetailQr}
                                alt=""
                                className="w-full"
                                style={{ width: "100%" }}
                              />
                            </div>

                            {/* <div>
                  <p
                    style={{
                      marginBottom: "0",
                      paddingRight: "35px",
                      marginTop: "4px",
                      textAlign: "right",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0px",
                      color: "#A5A5BA"
                    }}
                  >- BriJack POS -</p>
                </div> */}
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "4px",
                                marginBottom: "8px",
                              }}
                            >
                              <h5
                                style={{
                                  width: "300px",
                                  textAlign: "center",
                                  marginBottom: 0,
                                  fontFamily: "Roboto",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  lineHeight: "normal",
                                  letterSpacing: "0px",
                                  color: "#A5A5BA",
                                  zIndex: 10,
                                  marginTop: "0px",
                                  position: "relative",
                                }}
                              >
                                {languageUI.orderDirectlyIn}
                                <br style={{ textAlign: "center" }}></br>
                                {languageUI.bringYourOrder}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default QRCodeOwner;
