import * as Request from "../Request";

export const CustomerOverview = async (UserIdStore: number, startDate: string, endDate: string, type: number) => {
    return await Request.fetchAPI('owner/customerOverview.php?admin_id=' + UserIdStore + '&&date_start=' + startDate + '&&date_end=' + endDate + '&&type=' + type, 'GET', {
    }).then((res:ICustomerOverview) => {
        return res;
    }).catch((err:any) => {
        return  {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: undefined
        }
    });
};

export interface ICustomerOverviewData {
    total_customer?: number;
    total_number_of_new_customer?: number;
    total_number_of_return_customer?: number;
}
export interface ICustomerOverview {
    message: string,
    status: number,
    data: ICustomerOverviewData
}