import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  imgLeaf,
  imgLeaf2,
  LocalMallIcon,
} from "../../../../../../components/Image";
import * as custommerRecieptApi from "../../../../../../api/apiOwner/custommerRecieptApi";
import * as userSlice from "../../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import "./Styles.css";
import ModalLoading from "../../../../../../components/ModalLoading";
import moment from "moment";
import { FormatDolla } from "../../../../../../utils/format";
import OrderListITemPrint from "../../../../../webcheckin/components/OrderListITem/OrderListITemPrint";
import * as SettingPrinterApi from "../../../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../../../api/apiOwner/printerApi";
import ModalToast from "../../../ModalToast/ModalToastErr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface drawerRight {
  openDrawer: boolean;
  order: string;
  listPrinterName?: SettingPrinterApi.ListPrinterByCatalog[];
  dismisDrawer: () => void;
}
const OderDetailHistory: React.FC<drawerRight> = ({
  openDrawer,
  order,
  listPrinterName = [],
  dismisDrawer,
}) => {
  const languageUI = getLanguageUI().customerPage;
  const languagePrinterUI = getLanguageUI().printPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  const [detailOrder, setDetailOrder] =
    useState<custommerRecieptApi.DetailBill>();
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [listFood, setListFood] = useState<custommerRecieptApi.FoodDetail[]>(
    []
  );
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const [openToolTip, setOpenToolTip] = useState(false);

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };
  const gDetailOrder = async () => {
    if (UserIdStore) {
      setShowLoading?.(true);
      const gDetail = await custommerRecieptApi.gDetailOrderCustomer(
        UserIdStore,
        order
      );
      if (Number(gDetail?.status) === 1) {
        setDetailOrder(gDetail?.data ?? undefined);
        setListFood(gDetail?.data?.listFood ?? []);
        setShowLoading?.(false);
      } else {
        setShowLoading?.(false);
      }
    }
  };

  useEffect(() => {
    if (openDrawer) {
      gDetailOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer]);
  const PrintCard = async () => {
    try {
      const rawhtml =
        `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6, h4 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
              font-weight: 400;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px">
            <h3 style="width: 100%; margin:  auto; text-align: center">${
              languagePrinterUI.orderDetail
            }</h3>
            <div style="width: 100%;">
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languagePrinterUI.printDateTime
              }:</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${moment(
                detailOrder?.timestart,
                "YYYY-MM-DD hh:mm:ss"
              ).format("DD/MM/YYYY - hh:mm:ss")}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languagePrinterUI.customer
              }: ${
          detailOrder?.custommer_name ? detailOrder?.custommer_name : "---"
        }</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languagePrinterUI.phoneNumber
              }: ${detailOrder?.phone ? detailOrder?.phone : "---"}</h6>
            </div>
            
            

           ${listFood
             .map(
               (cart, i) =>
                 `
              <div style="display: table; width: 100%">
           <div style = "display: table-row; padding-top: 10px;  padding-right: 10px;width: 100%" >
                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                <h6  style=" margin: 0; text-align: start;">${i + 1}.${
                   cart?.name
                 }</h6>
                  ${
                    cart?.note
                      ? `<p class="" style="font-size: 24px ; " >-${cart?.note}</p>`
                      : ""
                  }
                    
                  ${
                    cart?.notefood_name
                      ? `<p class=""  style="width: 100% ; font-size: 24px; white-space: nowrap">-${cart?.notefood_name}</p>`
                      : ""
                  }
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <h6 class="quanlity-number; margin-top: 10px !important " font-size: 24px>${Number(
                  cart?.quantity
                )}x${Number(cart?.price)}$</h6>
                  ${
                    Number(cart?.form_order) !== 1
                      ? `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">${languagePrinterUI.takeAway}</h6>`
                      : `<h6 style=" flex-shrink: 0;  white-space: nowrap; font-weight: 600;">${languagePrinterUI.diner}</h6>`
                  }
                </div>
              </div >
              </div >

              `
             )
             .join(" ")}
           <div style="width: 100%;">
                <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
            </div>
      
           <div style="display: table; width: 100%;  padding-right: 40px;">
        <div style = "display: table-row; padding-top: 0px; width: 100%" >
          <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.subTotal}:</p>
          </div>
          <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
              <p style="font-size: 24px ; ">$${
                Number(detailOrder?.sub_total)
                  ? FormatDolla(Number(detailOrder?.sub_total))
                  : 0
              }</p>
            </div>
          </div>
          </div>
           <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.tax}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">$${
              Number(detailOrder?.sub_total)
                ? FormatDolla(
                    (Number(detailOrder?.sub_total) *
                      Number(detailOrder?.tax_percent)) /
                      100
                  )
                : 0
            }</p>
          </div>
        </div>
        </div>
          <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.discount}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">$${FormatDolla(
              Number(detailOrder?.discount)
            )}</p>
            </div>
        </div>
        </div>
           <div style="display: table; width: 100%">
          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">Tip:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">$${FormatDolla(
              Number(detailOrder?.tip_amount)
            )}</p>
          </div>
        </div>
        </div>
        ` +
        (Number(detailOrder?.feePrice) > 0
          ? `<div style="display: table; width: 100%">

            <div style="display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
                <p style="font-size: 24px ; ">${
                  languagePrinterUI.ConvenienceFee
                }:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <p style="font-size: 24px ; ">$${FormatDolla(
                  Number(detailOrder?.feePrice)
                )}</p>
              </div>
            </div>
          </div>`
          : ``) +
        `
          <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.tipFor}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
             <p style="font-size: 24px ; ">${
               detailOrder?.staff_name ? detailOrder?.staff_name : "---"
             }</p>
          </div>
        </div>
        </div>
          
         
           <div style="display: table; width: 100%">

        <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <h6>${languagePrinterUI.total}:</h6>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <h6>$${FormatDolla(Number(detailOrder?.total_order))}</h6>
            </div>
        </div>
        </div>
        
       
          </div >
          </body >
          </html >
  `;
      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (item) => {
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            item.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
            // setShowToastError(true)
            // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Drawer
        className="custom-btn-close custom-w-drawer"
        anchor="right"
        onClose={dismisDrawer}
        open={openDrawer}
      >
        <div className="w-100  position-relative">
          <div className="mt-16">
            <span className="flex justify-center text-4A4A6A text-8 pb-5 fw-600 font-urbansist">
              {languageUI.orderDetail}
            </span>
          </div>
          <div className="border-bottom pb-6 px-12">
            <div className="flex justify-between items-center pb-3">
              <span className="text-15px fw-600 font-urbansist text-666687">
                {languageUI.printDateTime}
              </span>
              <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                {moment(detailOrder?.timestart, "YYYY-MM-DD hh:mm:ss").format(
                  "DD/MM/YYYY - hh:mm:ss"
                )}
              </span>
            </div>
            {Number(detailOrder?.custommer_id) > 0 && (
              <>
                <div className="flex justify-between items-center pb-3">
                  <span className="text-15px fw-600 font-urbansist text-666687">
                    {languageUI.customer}
                  </span>
                  <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                    {detailOrder?.custommer_name}
                  </span>
                </div>
                <div className="flex justify-between items-center pb-3">
                  <span className="text-15px fw-600 font-urbansist text-666687">
                    {languageUI.phoneNumber}
                  </span>
                  <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                    {detailOrder?.phone}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="border-bottom pb-6 h-content-orderdetail">
            <div className="pb-3 px-12">
              {listFood.map((item, i) => (
                <div key={i}>
                  <div className="flex justify-between items-center pt-4 ">
                    <span className="text-6 fw-600 font-urbansist text-4A4A6A">
                      {item?.name}
                    </span>
                    <span className="text-18px fw-700 font-urbansist text-4A4A6A">
                      {item.quantity}x{FormatDolla(Number(item.price))}
                    </span>
                  </div>
                  <div>
                    {Number(item?.side_id) > 0 && (
                      <div className="flex items-center my-1">
                        <span className="text-17px font-urbansist text-4A4A6A">
                          + {item.side_name}{" "}
                        </span>
                        <span className="text-17px font-urbansist text-12B064 ml-2">
                          (Free x1)
                        </span>
                      </div>
                    )}
                    {item?.description && (
                      <span className="text-15px font-urbansist text-a5a5ba">
                        {item?.description}
                      </span>
                    )}
                  </div>
                  <div>
                    <div className="flex items-center my-1">
                      {item.notefood_name && (
                        <span className="text-14px font-urbansist text-12B064 radius-2 bg-F6F9F8 p-2">
                          {item.notefood_name}
                        </span>
                      )}

                      <span className="flex items-center text-14px font-urbansist text-FF7B2C bg-FFF2EA radius-2 ml-2 p-1">
                        <LocalMallIcon className="mr-1 text-20px" />
                        {item.form_order === "1"
                          ? languageUI.dineIn
                          : languageUI.takeAWay}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="border-bottom px-12">
            <div className="flex justify-between items-center pt-4 ">
              <span className="text-18px fw-600 font-urbansist text-212134">
                {languageUI.subTotal}
              </span>
              <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                $
                {Number(detailOrder?.sub_total)
                  ? FormatDolla(Number(detailOrder?.sub_total))
                  : 0}
              </span>
            </div>
            <div className="flex justify-between items-center py-4 ">
              <span className="text-18px fw-600 font-urbansist text-212134">
                {languageUI.tax}
              </span>
              <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                $
                {Number(detailOrder?.sub_total)
                  ? FormatDolla(
                      (Number(detailOrder?.sub_total) *
                        Number(detailOrder?.tax_percent)) /
                        100
                    )
                  : 0}
              </span>
            </div>
          </div>
          <div className=" px-12">
            <div className="flex justify-between items-center pt-4 ">
              <span className="text-18px fw-600 font-urbansist text-212134">
                {languageUI.discount}
              </span>
              <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                ${FormatDolla(Number(detailOrder?.discount))}
              </span>
            </div>
            {Number(detailOrder?.tip_amount) > 0 && (
              <div className="flex justify-between items-center py-4 ">
                <span className="text-18px fw-600 font-urbansist text-212134">
                  {languageUI.tipFor} {detailOrder?.staff_name}
                </span>
                <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                  ${FormatDolla(Number(detailOrder?.tip_amount))}
                </span>
              </div>
            )}
            {Number(detailOrder?.feePrice) > 0 && (
              <div className="flex justify-between items-center py-4">
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div className="tooltip-payment-web-checkin">
                    <Tooltip
                      className=""
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={openToolTip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="right"
                      title="The convenience fee is charged per transaction by a third-party vendor. We do not retain any portion of this fee."
                    >
                      <div
                        className="d-flex gap-1 align-items-end w-fit"
                        onClick={() => setOpenToolTip(true)}
                      >
                        <div className="text-18px fw-600 font-urbansist text-212134">
                          {languageUI.ConvenienceFee}
                        </div>
                        <HelpOutlineSharpIcon className="" />
                      </div>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
                <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                  ${FormatDolla(Number(detailOrder?.feePrice))}
                </span>
              </div>
            )}
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-18px fw-600 font-urbansist text-212134 ml-1">
                  {languageUI.total}
                </span>
                <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                  (+ {languageUI.tax})
                </span>
              </div>
              <div>
                <span className="text-FF7B2C text-6 fw-600 font-urbansist">
                  ${FormatDolla(Number(detailOrder?.total_order))}
                </span>
              </div>
            </div>
          </div>
          <div className="px-15">
            <div className="btn-print-bill-customer" onClick={PrintCard}>
              {languageUI.print}
            </div>
          </div>

          <img src={imgLeaf} alt="" className="custom-position-img" />
          <img src={imgLeaf2} alt="" className="custom-position-oder-detail" />
        </div>
        <div className="d-none">
          <div
            className="d-flex flex-column w-100"
            id="print-bill-customer-order"
          >
            <div className="border-bottom-black position-relative">
              <div className="border-bottom-black d-flex flex-column">
                <span className="text-6 fw-600   m-0 line-height-normal">
                  {nameStore}
                </span>
                <span className="text-18px fw-700   m-0 line-height-normal">
                  {addressStore}
                </span>
                {/* <span className="text-18px fw-700  text-4A4A6A m-0 line-height-normal">{phoneOwnerStore}</span> */}
              </div>
              <span className="title-infor-bill-customer-print mb-0 text-black text-center m-0 line-height-normal">
                Order detail
              </span>
              <div className="mt-1 border-bottom-black">
                <div className="">
                  <p className="mb-0 text-items-order-print text-black mb-0 text-black line-height-normal">
                    Print date - time
                  </p>
                  <p className="mb-0 text-items-order-print text-black mb-0 text-black line-height-normal">
                    {moment(
                      detailOrder?.timestart,
                      "YYYY-MM-DD hh:mm:ss"
                    ).format("DD/MM/YYYY - hh:mm:ss")}
                  </p>
                </div>
                <div className="d-flex align-items-end justify-content-between  flex flex-wrap">
                  <p className="mb-0 text-items-order-print text-black mb-0 text-black line-height-normal">
                    Customer
                  </p>
                  <p className="mb-0 text-items-order-print text-black mb-0 text-black line-height-normal">
                    {Number(detailOrder?.custommer_id) > 0
                      ? detailOrder?.custommer_name
                      : "-----"}
                  </p>
                </div>
                <div className="d-flex align-items-end justify-content-between ">
                  <p className="mb-0 text-items-order-print text-black mb-0 text-black line-height-normal">
                    Phone number
                  </p>
                  <p className="mb-0 text-items-order-print text-black flex-shink-0 mb-0 text-black line-height-normal">
                    {Number(detailOrder?.custommer_id) > 0
                      ? detailOrder?.phone
                      : "-----"}
                  </p>
                </div>
              </div>
              <div className={`d-flex flex-column`}>
                <OrderListITemPrint orderInfo={listFood} status={true} />
              </div>
            </div>
            <div className="">
              <div className={` border-bottom-black`}>
                <div
                  className={`d-flex align-items-end justify-content-between  `}
                >
                  <p className="title-pay-bill-customer mb-0 text-black line-height-normal">
                    Sub total
                  </p>
                  <p className="price-pay-bill-customer mb-0 text-black line-height-normal">
                    $
                    {Number(detailOrder?.sub_total)
                      ? FormatDolla(Number(detailOrder?.sub_total))
                      : 0}
                  </p>
                </div>
                <div className="d-flex align-items-end justify-content-between ">
                  <p className="title-pay-bill-customer mb-0 text-black line-height-normal">
                    Tax
                  </p>
                  <p className="price-pay-bill-customer mb-0 text-black line-height-normal">
                    $
                    {Number(detailOrder?.sub_total)
                      ? FormatDolla(
                          (Number(detailOrder?.sub_total) *
                            Number(detailOrder?.tax_percent)) /
                            100
                        )
                      : 0}
                  </p>
                </div>
              </div>
              <div className="">
                <div className="d-flex align-items-end justify-content-between ">
                  <p className="title-pay-bill-customer align-items-end mb-0 text-black line-height-normal">
                    Discount
                  </p>
                  <div className="d-flex gap-2px align-items-end">
                    <p className="price-pay-bill-customer d-flex mb-0 h-fit text-black line-height-normal ">
                      {detailOrder?.discount}$
                    </p>
                  </div>
                </div>
                {Number(detailOrder?.tip_amount) > 0 && (
                  <div className="d-flex align-items-end justify-content-between ">
                    <div>
                      <p className="title-pay-bill-customer mb-0 text-black line-height-normal">
                        Tip for:{" "}
                      </p>
                      <p className="title-pay-bill-customer mb-0 text-black line-height-normal">
                        {detailOrder?.staff_name}
                      </p>
                    </div>
                    <div className="d-flex gap-2px align-items-end">
                      <p className="price-pay-bill-customer mb-0 h-fit text-black line-height-normal">
                        ${FormatDolla(Number(detailOrder?.tip_amount))}
                      </p>
                    </div>
                  </div>
                )}
                <div className="d-flex align-items-end justify-content-between ">
                  <p className="title-pay-bill-customer mb-0 d-flex align-items-end text-black line-height-normal">
                    Total
                  </p>
                  <div className="d-flex gap-2px align-items-end">
                    <p className="price-pay-bill-customer-total mb-0 h-fit  text-black line-height-normal">
                      ${FormatDolla(Number(detailOrder?.total_order))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default OderDetailHistory;
